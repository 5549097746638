import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'utcTime'})
export class UtcTimePipe implements PipeTransform {

  transform(value: any): any {

    if (value < 0) {
      return '';
    }

    const dateValue = new Date(value);

    return new Date(
      dateValue.getUTCFullYear(),
      dateValue.getUTCMonth(),
      dateValue.getUTCDate(),
      dateValue.getUTCHours(),
      dateValue.getUTCMinutes(),
      dateValue.getUTCSeconds()
    );
  }

}
