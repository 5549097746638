import { Component, OnInit, Input } from '@angular/core';
import { Subscriber } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-to-be-renewed',
  templateUrl: './to-be-renewed.component.html',
  styleUrls: ['./to-be-renewed.component.css']
})
export class ToBeRenewedComponent implements OnInit {
  totalRent: number = 0;
  totalOccupiedRent: number = 0;
  totalCollectedRent: number = 0;
  totalExpenses: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter:any;
  @Input() pagination: any;
  date: Date = new Date();

  subscription = new Subscriber();
  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
     public admin: AdminService,
     public translate:TranslateService,
  ) { }

  ngOnInit() {
  }

}
