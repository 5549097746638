import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InternalServiceService } from '../internal-service.service';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../shared/lang/translate.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-company-add-edit',
    templateUrl: './company-add-edit.component.html',
    styleUrls: ['./company-add-edit.component.css']
})

export class CompanyAddEditComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: InternalServiceService,
        public admin: AdminService,
        public translate: TranslateService,
        public formBuilder: FormBuilder
    ) {
        this.getCompanyDetails();
    }

    formGroup: FormGroup = this.formBuilder.group({
        name: new FormControl('', [Validators.compose([Validators.required])]),
        commercialRegistration: new FormControl('', [Validators.compose([Validators.required])]),
        companyNumber: new FormControl('', []),
        authorizedPersonName: new FormControl('', []),
        authorizedPersonID: new FormControl('', []),
        about: new FormControl('', []),
        isVat: new FormControl(false, []),
        vatNumber: new FormControl('', []),
        vatPercentage: new FormControl('', []),

        authorizedPersonContactCode: new FormControl('', []),
        authorizedPersonContact: new FormControl('', []),
        address: new FormControl('', [Validators.compose([Validators.required])]),
        noOfManagedCount: new FormControl('', []),
        logo: this.formBuilder.group({
            thumbnail: new FormControl('', []),
            original: new FormControl('', []),
            type: new FormControl('', []),
            name: new FormControl('', [])
        }),
        document: this.formBuilder.array([]),
    });

    get document(): FormArray {
        return this.formGroup.get('document') as FormArray;
    }

    userData: any = [];
    event;
    userId;
    show: boolean = false;

    public userSettings4: any = {
        showSearchButton: false,
        showCurrentLocation: false,
        geoCountryRestriction: ['bh'],
        inputPlaceholderText: this.translate.lang.Home_Place_EnterAddress,
        locationIconUrl: 'http://www.myiconfinder.com/uploads/iconsets/369f997cef4f440c5394ed2ae6f8eecd.png',
        noOfRecentSearchSave: 8
    };


    ngOnInit() {

    }

    keyPress2(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.formGroup.value.authorizedPersonContact = '';
            return false;
        }
    }



    removeDocument(ind: number) {
        console.log(ind);

        console.log(
            this.formGroup.get('document').value);

        if (this.formGroup.get('document').value.length) {
            this.document.removeAt(ind);
        }

    }

    getCompanyDetails() {
        this.admin.getData(apiUrl._getCompanyProfile, {}).subscribe(res => {
            const data: any = res.data;
            this.userData = res.data;
            this.show = true;

            this.userId = data.userId;

            this.formGroup.get('about').patchValue(data.about);
            this.formGroup.get('address').patchValue(data.address);
            setTimeout(() => {
                this.userSettings4['inputString'] = data.address;
                this.userSettings4 = Object.assign({}, this.userSettings4);
            }, 6000);
            this.formGroup.get('authorizedPersonContact').patchValue(data.authorizedPersonContact);
            this.formGroup.get('authorizedPersonContactCode').patchValue(data.authorizedPersonContactCode);
            this.formGroup.get('authorizedPersonID').patchValue(data.authorizedPersonID);
            this.formGroup.get('authorizedPersonName').patchValue(data.authorizedPersonName);
            this.formGroup.get('commercialRegistration').patchValue(data.commercialRegistration);
            this.formGroup.get('logo').get('original').patchValue(data.logo.original);
            this.formGroup.get('logo').get('thumbnail').patchValue(data.logo.thumbnail);
            this.formGroup.get('logo').get('type').patchValue(data.logo.type);
            this.formGroup.get('logo').get('name').patchValue('');
            this.formGroup.get('name').patchValue(data.name);
            if (data.isVat) {
                this.formGroup.get('isVat').patchValue(data.isVat);
            } else {
                this.formGroup.get('isVat').patchValue(false);
            }

            if (data.vatNumber) {
                this.formGroup.get('vatNumber').patchValue(data.vatNumber);
            }

            if (data.isVat) {
                this.formGroup.get('vatPercentage').patchValue(data.vatPercentage);
            }

            data.document.forEach(item => {
                this.creattDocumentForm(item);
            });
        });

    }


    onChange(e) {
        console.log(e);

        if (e) {
            this.formGroup.get('vatNumber').setValidators([Validators.required]);
            this.formGroup.get('vatNumber').updateValueAndValidity();
            this.formGroup.get('vatPercentage').setValidators([Validators.required]);
            this.formGroup.get('vatPercentage').updateValueAndValidity();
        } else {
            this.formGroup.get('vatNumber').clearValidators();
            this.formGroup.get('vatNumber').updateValueAndValidity();
            this.formGroup.get('vatPercentage').clearValidators();
            this.formGroup.get('vatPercentage').updateValueAndValidity();

        }
    }

    creattDocumentForm(data) {
        const docFormGroup: FormGroup = this.formBuilder.group({
            thumbnail: new FormControl(data.thumbnail, []),
            original: new FormControl(data.original, []),
            type: new FormControl(data.type, []),
            name: new FormControl('', [])
        });
        this.document.push(docFormGroup);
        console.log(this.document);

    }

    onSubmit() {
        if (this.formGroup.valid) {
            const data: any = this.formGroup.value;
            const request: any = {};

            request.name = data.name;
            request.commercialRegistration = data.commercialRegistration;
            request.companyNumber = data.companyNumber;
            request.authorizedPersonName = data.authorizedPersonName;
            request.authorizedPersonID = data.authorizedPersonID;
            request.about = data.about;
            request.authorizedPersonContactCode = data.authorizedPersonContactCode;
            request.authorizedPersonContact = data.authorizedPersonContact;
            request.address = data.address;
            request.noOfManagedCount = data.noOfManagedCount;

            if (data.logo.original) {
                request.logo = data.logo;
            }

            if (this.document.length) {
                request.document = this.document.value;
            } else {
                request.document = [];
            }

            request.isVat = data.isVat;

            if (data.isVat && data.vatNumber) {
                request.vatNumber = data.vatNumber;
            }

            if (data.isVat && data.vatPercentage) {
                request.vatPercentage = data.vatPercentage;
            }


            this.api.submitPutForm(request, apiUrl._editCompanyProfile).subscribe(response => {
                this.admin.toast('Company Updated', '', 'success');
                this.router.navigate(['managerDetail/' + this.userId]);
            });

            console.log(request);


        } else {
            Object.keys(this.formGroup.controls).forEach(formKey => {
                this.formGroup.get(formKey).markAsTouched({ onlySelf: true });
            });

        }

    }


    hasError(event) {
    }

    onCountryChange(event) {
        this.formGroup.get('authorizedPersonContactCode').patchValue(event.dialCode);
    }

    telInputObject(event) {
        this.event = event;
    }

    getNumber(event) {
        const numArray = event.split(this.formGroup.value.authorizedPersonContact);
        this.formGroup.patchValue({ authorizedPersonContact: numArray[1] });
    }


    upload(event, type) {

        if (event.target.files && event.target.files.length) {
            const fileType = event.target.files[0].type;
            if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'application/pdf') {
                const file = event.target.files[0];

                let docType = "img";

                if (fileType == 'application/pdf') {
                    docType = 'pdf';
                }

                this.api.uploadImage(file, docType).subscribe(success => {
                    if (type === 'logo') {
                        this.formGroup.get('logo').get('original').patchValue(success.data.original);
                        this.formGroup.get('logo').get('thumbnail').patchValue(success.data.thumbnail);
                        this.formGroup.get('logo').get('type').patchValue(success.data.type);
                    }

                    console.log(type);

                    if (type === 'document') {
                        this.creattDocumentForm(success.data);
                    }


                    // const temp = this.userForm.value.image;
                    // temp.push({original: success.data.original, thumbnail: success.data.thumbnail});
                    // this.userForm.patchValue({image: temp});
                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }

    autoCompleteCallback1(selectedData: any) {
        if (selectedData.response) {
            this.formGroup.get('address').patchValue(selectedData.response ? selectedData.data.formatted_address : '');
        }
    }

}
