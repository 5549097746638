
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { AdminService } from '../../../services/admin.service';
import { apiUrl } from 'src/app/global/global';
import * as CONSTANTS from '../../../services/constants';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SharedService } from '../../../shared/shared.service';
import { TranslateService } from "../../../shared/lang/translate.service";

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

    userForm: FormGroup;
    identityForm: FormGroup;
    typeData: any[] = [];
    docAdded: boolean = false;
    userData: any = {};
    maxDate: Date = new Date();
    showError: Boolean = false;
    name: string = '';
    @ViewChild('tel') tel: any;
    @ViewChild('tel2') tel2: any;
    @ViewChild('requestClose') requestClose: ElementRef;
    event;
    indentityInfo = ['a', 'b'];
    model: any = [];
    extension: any[] = [];
    identityArray: any = [];
    new: boolean = false;
    imageChangedEvent: any = '';
    croppedImagebase: any = '';
    showCropper = false;
    croppedImageFile: any;
    minDate = new Date();
    inItOpt: any = { initialCountry: 'bh' };
    submit: boolean = false;
    USERTYPE: any = localStorage.getItem('userType');

    public userSettings4: any = {
        showSearchButton: false,
        showCurrentLocation: false,
        geoCountryRestriction: ['bh'],
        inputPlaceholderText: this.translate.lang.Home_Place_EnterAddress,
        locationIconUrl: 'http://www.myiconfinder.com/uploads/iconsets/369f997cef4f440c5394ed2ae6f8eecd.png',
        noOfRecentSearchSave: 8
    };

    get userDocument(): FormArray {
        return this.userForm.get('userDocument') as FormArray;
    }

    constructor(
        private fb: FormBuilder,
        private route: Router,
        private api: InternalServiceService,
        public admin: AdminService,
        private sharedService: SharedService,
        public translate: TranslateService,
        private ngZone: NgZone,
    ) {
        this.userForm = fb.group({
            fullName: [''],
            phoneNumber: [{ value: '', disabled: true }],
            address: [''],
            callingCode: [''],
            image: [''],
            dateOfBirth: [new Date(), Validators.required],
            about: [''],
            job: [''],
            email: [''],
            country: [''],
            userDocument: this.fb.array([this.createDocument()]),

        });

        if (this.USERTYPE !== 'TENANT' && this.USERTYPE != 'TEAM_MEMBER') {
            this.userForm.registerControl('phoneNumberToDisplay', new FormControl(''));
            this.userForm.registerControl('phoneNumberToDisplayCallingCode', new FormControl('+973'));
            this.userForm.registerControl('isRecieveChat', new FormControl(false));
            this.userForm.registerControl('isShowName', new FormControl(false));
            this.userForm.registerControl('isShowPhone', new FormControl(false));
            this.userForm.registerControl('isRecievePaymentConfirmation', new FormControl(false));
        }

        if (this.USERTYPE != 'TENANT') {
            this.userForm.registerControl('logo', new FormControl('', []));
        }
    }



    keyPress4(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.identityForm.value.name = '';
            return false;
        }
    }

    keyPress3(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (e.keyCode != 0 && e.keyCode != 32) {
            if (!isArabic.test(e.key)) {
                this.userForm.value.arName = '';
                return false;
            }
        }
    }


    keyPress2(e, numberToBeShow?) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            numberToBeShow ? this.userForm.value.phoneNumberToDisplay = '' : this.userForm.value.phoneNumber = '';
            return false;
        }
    }

    keyPress(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.fullName = '';
            return false;
        }
    }

    createDocument() {
        this.identityForm = this.fb.group({
            '_id': [''],
            'type': [''],
            'documentSize': [''],
            'cprNumber': [''],
            'documentExpiryDate': [''],
            'arName': [''],
            'original': ['', [Validators.required]],
            'thumbnail': [''],
            'name': ['', [Validators.required]],
            'identity': ['', [Validators.required]]
        });
        return this.identityForm;
    }

    get f() { return this.identityForm.controls };


    ngOnInit() {
        this.getDetail();
        window.scrollTo(0, 0);
        this.getProperty();
    }


    getProperty() {
        this.admin.showLoader = false;
        this.api.getList({ type: 'Identity' }, apiUrl._getPropertyType).subscribe(success => {
            if (success.data.propertyType[0])
                this.typeData = success.data.propertyType[0].propertyData;
            console.log(this.userForm.controls.userDocument);
        });
    }

    getDetail() {
        const temp = JSON.parse(localStorage.getItem('loginData'));
        const data = {
            'userId': temp['_id']
        };
        this.api.getList(data, apiUrl._getUserDetail).subscribe(success => {
            const temp = success.data;
            this.model = success.data;
            console.log(this.model);

            for (const x in this.userForm.controls) {
                if (temp[x]) {
                    if (x == 'dateOfBirth') {
                        this.userForm.patchValue({ [x]: new Date(temp[x]) });
                    } else {
                        if (x != 'userDocument')
                            this.userForm.patchValue({ [x]: temp[x] });
                    }
                }
            }

            this.identityArray = this.model.userDocument;
            this.identityArray.forEach(element => {
                this.extension.push(element.type);
                element.original = element.image.original
                element.thumbnail = element.image.thumbnail
                delete element.image;
            });

            if (this.USERTYPE !== 'TENANT' && this.USERTYPE != 'TEAM_MEMBER') {
                this.userForm.get('phoneNumberToDisplay').patchValue(this.userForm.get('phoneNumberToDisplay').value);
                this.userForm.get('isRecieveChat').patchValue(!this.userForm.get('isRecieveChat').value);
                this.userForm.get('isRecievePaymentConfirmation').patchValue(!this.userForm.get('isRecievePaymentConfirmation').value);
                this.userForm.get('isShowName').patchValue(!this.userForm.get('isShowName').value);
                this.userForm.get('isShowPhone').patchValue(!this.userForm.get('isShowPhone').value);
            }

            /*for (const x of this.admin.numberCode) {
                if (x.code == temp.callingCode) {
                    this.event.intlTelInput('setCountry', x.flag);
                    break;
                }
            }*/
        });
    }

    onSubmit(form) {

        this.submit = true;
        for (const x in this.userForm.controls) {
            if (typeof this.userForm.value[x] == 'string') {
                this.userForm.patchValue({ [x]: this.userForm.value[x].trim() });
            }
        }

        // if (form.valid  ) {

        // delete this.userForm.controls.userDocument
        // this.userForm.controls.userDocument.patchValue({

        // })

        this.showError = false;
        const data = {};
        for (const x in this.userForm.controls) {
            if (x == 'dateOfBirth') {
                data[x] = +new Date(form.value[x]);
            } else {
                if (x != 'userDocument') {
                    data[x] = form.value[x];
                }
            }
        }

        data['isRecieveChat'] = !data['isRecieveChat'];
        data['isShowName'] = !data['isShowName'];
        data['isShowPhone'] = !data['isShowPhone'];
        data['isRecievePaymentConfirmation'] = !data['isRecievePaymentConfirmation'];

        // data['userDocument'] = this.identityArray;
        this.api.submitForm(data, apiUrl._editProfile).subscribe(
            success => {
                this.admin.toast('Profile Updated', '', 'success');
                localStorage.setItem('adminToken', success.data.accessToken);
                const userData: any = success.data;
                this.sharedService.changeUserData(userData);
                if (this.USERTYPE === 'TENANT') {
                    this.route.navigate(['/tenants/profile/' + userData._id]);
                } else {
                    this.route.navigate(['/landlord/profile/' + userData._id]);
                }
                this.submit = false;
            }
        );
        // } else {
        //     this.showError = true;
        // }
    }

    getNumber(event, numberToBeShow?) {

        console.log(event)

        if (!numberToBeShow) {
            const numArray = event.split(this.userForm.value.callingCode);
            this.userForm.patchValue({
                phoneNo: numArray[1]
            });
        }


        if (numberToBeShow) {
            console.log(this.userForm.value.phoneNumberToDisplayCallingCode)
            const numArray = event.split(this.userForm.value.phoneNumberToDisplayCallingCode);
            console.log(numArray)
            this.userForm.patchValue({
                phoneNumberToDisplay: numArray[1]
            });
        }

    }

    hasError(event) {
    }

    check() {
        return localStorage.getItem('userType') != 'LANDLORD'
    }

    uploadIdentityImage(event) {
        if (event.target.files && event.target.files.length && this.userForm.value.userDocument.length < 10) {
            const temp = event.target.files[0].type;
            if (temp == 'image/jpeg' || temp == 'application/pdf' || temp == 'image/png' || temp == 'image/jpg' || temp == 'image/pdf') {
                const file = event.target.files[0];
                this.api.uploadImage(file, file.name.split('.').pop() == 'pdf' ? 'pdf' : 'img').subscribe(success => {
                    this.identityForm.patchValue({ original: success.data.original });
                    this.identityForm.patchValue({ thumbnail: success.data.thumbnail });
                    this.identityForm.patchValue({ type: success.data.type });

                    this.extension.push(success.data.type);
                    if (event.target.files[0].name.length > 12) {
                        let temp = (event.target.files[0].name).substr(0, 5);
                        let temp1 = (event.target.files[0].name).substr(event.target.files[0].name.length - 6);
                        this.name = temp + '.....' + temp1;
                    } else {
                        this.name = event.target.files[0].name;
                    }
                    this.showError = false;

                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }


    uploadDoc() {
        if (this.userDocument.length < 10) {
            this.showError = true;
            if (this.identityForm.valid) {
                this.showError = false;
                this.docAdded = true;
                this.identityArray.push(this.userForm.controls.userDocument.value[0]);
                console.log(this.identityArray);
                this.identityForm.patchValue({
                    '_id': '',
                    'type': '',
                    'documentSize': '',
                    'cprNumber': '',
                    'documentExpiryDate': '',
                    'arName': '',
                    'original': '',
                    'thumbnail': '',
                    'name': '',
                    'identity': ''
                })
                this.name = '';
            }
        }
    }

    deleteFile(i) {
        // this.userDocument.removeAt(i);
        this.identityArray.splice(i, 1);
        this.extension.splice(i, 1);
        if (this.docAdded == false) {
            this.continue();
        }
        this.docAdded = false;
        console.log(this.identityArray);

    }

    onCountryChange(event, numberToBeShow?) {

        if (!numberToBeShow) {
            this.userForm.patchValue({
                callingCode: event.dialCode,
                // phoneNo: ''
            });
        }

        if (numberToBeShow) {
            this.userForm.patchValue({
                phoneNumberToDisplayCallingCode: event.dialCode,
            });
        }
    }

    telInputObject(event) {
        console.log(event)
        this.event = event;
    }

    checkType(x) {
        return x.image.original.split('.').pop() == 'pdf';
    }


    continue() {
        let data = {};
        let arr = [];

        if (this.identityArray.length == 0) {
            return;
        }

        this.identityArray.forEach(element => {

            if (!element._id) {
                delete element._id;
                element.documentExpiryDate = +new Date(element.documentExpiryDate);
                arr.push(element)
            }
            else {
                arr.push(element);
            }

            data = {
                userDocument: arr
            }

        });


        this.api.submitForm(data, apiUrl._uploadDocument).subscribe(
            success => {
                console.log(success);
                this.new = false;
                this.docAdded = false;
                localStorage.setItem('adminToken', success.data.accessToken);
                this.identityArray = success.data.documentData;
                this.identityArray.forEach(element => {
                    console.log(element);
                    this.extension.push(element.type);
                    element.original = element.image.original
                    element.thumbnail = element.image.thumbnail
                    if (element.image) {
                        delete element.image;
                    }
                });


            })
    }

    onUpload() {
        // if (event.target.files && event.target.files.length) {
        const temp = this.croppedImageFile.type;
        if (temp == 'image/jpeg' || temp == 'image/png' || temp == 'image/jpg') {
            const file = this.croppedImageFile;
            this.api.uploadImage(file, 'img').subscribe(success => {
                document.getElementById('modalClose').click();
                this.userForm.patchValue({
                    image: {
                        original: success.data.original,
                        thumbnail: success.data.thumbnail
                    }
                });
            });

            // } else {
            //     this.admin.errorAlert('Invalid format!', '');
            // }
        }
    }

    //    ==================================================================================================================

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImagebase = event.base64;
        this.croppedImageFile = event.file;
    }

    imageLoaded() {
        document.getElementById('openCropper').click();
        this.showCropper = true;
    }

    uplodeImage() {
        this.onUpload();
    }

    uploadImage(event) {
        if (event.target.files && event.target.files.length) {
            const fileType = event.target.files[0].type;
            if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
                const file = event.target.files[0];
                this.api.uploadImage(file, file.name.split('.').pop() === 'pdf' ? 'pdf' : 'img').subscribe(success => {
                    this.userForm.patchValue({
                        logo: {
                            original: success.data.original,
                            thumbnail: success.data.thumbnail,
                            name: 'Logo Image',
                            type: 'Image'
                        }
                    });
                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }

    locationForm: any = { lat: '', lng: '', address: '' };

    public setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.locationForm = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

            });
        }
    }

    autoCompleteCallback1(selectedData: any) {
        if (selectedData.response) {
            this.userForm.get('address').patchValue(selectedData.response ? selectedData.data.formatted_address : '');
            this.locationForm = {
                lat: selectedData.response ? selectedData.data.geometry.location.lat : '',
                lng: selectedData.response ? selectedData.data.geometry.location.lng : '',
                address: selectedData.response ? selectedData.data.formatted_address : '',
            };
        }
    }

    markerMoved(e) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': e.coords }, (res, status) => {
            if (status === google.maps.GeocoderStatus.OK && res.length) {
                this.ngZone.run(() => this.setLocation(res[0]));
            }
        });
    }

    setLocation(place) {
        console.log(place);
        this.userForm.get('address').patchValue(place.formatted_address ? place.formatted_address : '');
        this.locationForm.address = place.formatted_address;
        this.userSettings4.inputString = place.formatted_address;
        this.userSettings4 = Object.assign({}, this.userSettings4);
        this.locationForm.lat = place.geometry.location.lat();
        this.locationForm.lng = place.geometry.location.lng();
    }
}
