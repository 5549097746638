export const locale = {
  lang: "en",
  data: {
    app: {
      Search: "Search",
      Residential: "Residential",
      Commercial: "Commercial",
      ViewAll: "View All",
      Date: "Date",
      Type: "Type",
      Property: "Property",
      All: "All",
      BHD: "BHD",
      Action: "Action",
      Bank: "Bank",
      NoData: "No Data",
      Status: "Status",
      Sent: "Sent",
      Received: "Received",
      AddProperty: "ADD PROPERTY",
      DragAndDrop: "Drag & Drop or ",
      UploadManually: "Upload Manually ",
      EnterPhoneNumber: "Enter Phone Number",
      Submit: "Submit",
      LogIn: "Log In",
      LogOut: "Log Out",
      LogOutMsg: "Are you sure, you want to log out?",
      Month: "Month",
      Cancel: "Cancel",
      Next: "Next",
      startDate: "Start Date",
      endDate: "End Date",
      Required: "Required",
      Status_Pending: "Pending",
      Status_Resolved: "Resolved",
      Status_Reopen: "Reopen",
      Status_Cancelled: "Cancelled",
      Status_Completed: "Completed",
      Status_Approved: "Approved",
      Email: "Email",
      ClearAll: "Clear All",
      Year: "Year",
      More: "More",
      Bath: "Bath",
      Bed: "Bed",
      sqft: "sqm",
      OK: "OK",
      BHK: "BHK",
      Favourite: "Favourite",
      // Header
      Header_Explore: "Explore",
      Header_Landlord_Manager: "Landlord/Company",
      Header_Tenant: "Tenant",
      Header_Contact_Us: "Contact Us",
      Header_RegisterNow: "Register Now",
      Header_MyProfile: "My Profile",
      Header_Dashboard: "Dashboard",
      Header_SignOut: "Logout",
      //roles
      rate: "Rate",
      // Home
      Home_FindTheHomeYouLove: "Find the Home you Love",
      Home_Place_EnterAddress: "Enter Address",
      Home_Place_Address: "Address",
      Home_Place_Min: "min",
      Home_Place_Max: "max",
      Home_SearchHome: "Search Home",
      Home_HowItWorks: "How it works?",
      Home_DiscoverHowToImproveYourRealestateExperience:
        "Discover how to improve your Real Estate Experience",
      Home_Landlord: "Landlord",
      Home_Landlord_Text: [
        "Collect Rent Online",
        "Advertise Property",
        "Choose Great Tenants",
        "Communicate with team and tenants",
        "And lots more….",
      ],
      Home_PropertyManager: "Property Manager",
      Home_PropertyManager_Text: [
        "Manage and Track Rents",
        "Create Team Member",
        "Seamless Payments",
        "Maintenance",
        "And lots more….",
      ],
      Home_Tenant: "Tenant",
      Home_Tenant_Text: [
        "Pay Rent Online",
        "Search New Property",
        "Contact Landlord/Company",
        "View History",
        "And lots more….",
      ],
      Home_ViewVideo: "View Video",
      Home_LearnMore: "Learn more",
      Home_FindYourDreamProperty: "Find your Dream Property",
      Home_MostPopularProperties: "Most Popular Properties",
      Home_FindARentProperties: "Rent Properties in Bahrain Now",
      Home_SearchNewProperty: "Search a New Property",
      Home_NewlyAddedProperties: "Newly Added Properties",
      Home_ExploreAllCategoriesOfProperties:
        "Explore all categories of properties",
      Home_ExploreAllTypesOfProperty: "Explore all types of Properties",
      Home_JoinUsNow: "Join us Now !",
      Home_LiveTheNewLevel: "Live the New Level of RealEstate Experience",
      Home_OurBestAgentTeam: "Our Best Agent Team",
      Home_WhatPeopleSay: "What People Say About Eglooz",
      Home_Eglooz_Agent_Manager: "Eglooz Company",
      Home_ViewDetails: "View Details",
      Home_OurClientTestimonial: "Our Client Testimonial",
      Home_NeedHelpGettingStarted: "Need help getting started?",
      Home_OurProfessionalsWouldLoveToAssistYou:
        "Our professionals would love to assist you.",
      Home_PlaceYourPhoneNumberHere: "Place your phone number here…",
      // Home_ContactOurHappyUserCenter: 'Contact our Happiness Center',
      Home_ContactOurHappyUserCenter: "Contact our Happiness Center",
      Home_EnterYourName: "Enter your name…",
      Home_EnterYourEmailAddress: "Enter your email address…",
      Home_WriteYourMessageHere: "Write your message here…",
      Home_SearchByCity: "SEARCH BY CITY",
      Home_COMPANY: "COMPANY",
      Home_InstallApp: "INSTALL APP",
      Home_ForLandlord: "For Landlord",
      Home_ForManager: "For Company",
      Home_ForTenant: "For Tenant",
      Home_AboutUs: "About us",
      Home_ContactUs: "Contact Us",
      Home_FAQ: "FAQ",
      Home_TermsAndConditions: "Terms and Conditions",
      Home_DownloadFromAppStore: "Download from App Store",
      Home_DownloadFromPlayStore: "Download from Play Store",
      Home_FOLLOWUSON: "FOLLOW US ON",
      Home_AddPrivacyHere: "Privacy Policy",
      // Landlord/Manager
      HowItWork_Title: "The Future Nxt",
      HowItWork_SubTitle: "A Smart Renting Experience",
      // HowItWork_Text: 'Book verified homes with great amenities, World charm and cutting-edge surrounding cuisines within the city - A homes one must have!',
      HowItWork_Text:
        "An Advanced Platform to List, Search, Rent, Pay & Manage your Property.",
      HowItWork_HowItWorks: "How it Works",
      HowItWork_LearnHowItWorks: "Making it Easy for Landlord & Company.",
      // HowItWork_Step1_Title: 'Safe, Secure & Identity fraud-free platform',
      HowItWork_Step1_Title: "Safe, Secure & KYT implemented",
      // HowItWork_Step1_Text: 'Eglooz implements KYC (Know Your Customer) business process to identify and welcome real users only.',
      HowItWork_Step1_Text:
        "Eglooz introduced KYT (Know Your Tenant) business process to identify and welcome real users only, To be able to better serve customers and manage risk more wisely.",
      HowItWork_Step2_Title: "Free, Online RealEstate Management System",
      // HowItWork_Step2_Text: 'Eglooz facilitates Landlords and Managers with a powerful system and functionality to manage their rented properties hassle free.',
      HowItWork_Step2_Text:
        "Eglooz facilitates Landlords, Managers and Tenants with a powerful system and functionality to manage their rented properties hassle free.",
      HowItWork_Step3_Title: "Free & Effective property marketing tool",
      HowItWork_Step3_Text:
        "No more wasting time on fake tenants. Eglooz connects you to thousands of potential and real tenants.",
      HowItWork_Step4_Title: "Three S’s of real estate rent collection",
      HowItWork_Step4_Text:
        "Eglooz makes your rent collection process easiest ever with the three S’s “Smart Simple Secure”. Eglooz takes care of your rent book, automated invoice, e-receipts, history, and several other features.",
      HowItWork_Step6_Title: "Close your deal on the go",
      HowItWork_Step6_Text:
        "With Eglooz Smart Contract tool & KYT (Know Your Tenant) process, you can easily select your next tenant, sign your contract online and receive your first payment instantly.",
      HowItWork_Step5_Title: "Landlord, Managers & Tenant Communications",
      HowItWork_Step5_Text:
        "Eglooz empowers Landlord, Managers & Tenant to communicate via Chat, auto notifications, reminder emails, and direct calling. We are forever connected.",
      HowItWork_Step7_Title: "Manage your Maintenance",
      HowItWork_Step7_Text:
        "Eglooz at your fingertips maintains and refurbishes your living experience. Simply raise your query online, track the progress and receive/send auto reminders.",
      HowItWork_Step8_Title: "Create and follow your team",
      HowItWork_Step8_Text:
        "Team management made easy. Just create a role and assign privileges, and track the achievements.",
      HowItWork_Step9_Title: "Reports & Analytics",
      HowItWork_Step9_Text:
        "Your success is our reward! Enjoy a full flash of updated reports with our Advanced Analytical Dashboards and RentBook Savvy View!",
      HowItWork_Step10_Title: "User Experience",
      // HowItWork_Step10_Text: 'Your Smile is our Strength! We are seeking to provide RealEstate market with especial and unique experience. Introduction of PropTech in Bahrain – Our Mission! We hear you and here for you!',
      HowItWork_Step10_Text:
        "Your Smile is our Strength! We are seeking to provide RealEstate market with especial and unique experience. Introducing PropTech in Bahrain – Our Mission! We hear you and here for you!",
      HowItWork_End_Text:
        "Checkout our Website, Sign up & Download App for more information",
      WithEgloozSince: "With Eglooz Since",
      // Tenant Page
      TenantPage_title_text:
        "An Advance Platform to List, Search, Rent, Pay & Manage your Property.",
      TenantPage_LearnHowItWorks: "Learn how it works, as a Tenant",
      TenantPage_Step1_Title: "Search, Book and Rent your Property",
      TenantPage_Step1_Text:
        "Select your prefer location & property, easily apply and grab the opportunity.",
      TenantPage_Step2_Title: "Your Cash is in your Debit Card",
      TenantPage_Step2_Text:
        "No more ATM’s or dealing with Cash payment. Simply pay rent using Benefit Payment Gateway Online .",
      TenantPage_Step3_Title: "Real Time Notification & Alerts",
      TenantPage_Step3_Text:
        "Instant and accurate for all the Reminders & Updates.",
      TenantPage_Step4_Title: "Build your Renting Profile Credibility",
      TenantPage_Step4_Text:
        "Shared information will automatically ease your onboarding with Landlord.",
      TenantPage_Step5_Title: "Transactions",
      TenantPage_Step5_Text:
        "View and download your records for your upcoming, past & overdue payments.",
      TenantPage_Step6_Title: "Easy Connect",
      TenantPage_Step6_Text:
        "Chat with your Landlord/Company anytime, for any queries or maintenance.",
      TenantPage_Step7_Title: "Rate & Review Property",
      TenantPage_Step7_Text:
        "Review Rating of properties, and Rate your property.",
      // Side Navbar
      SideNav_Dashboard: "Dashboard",
      SideNav_Properties: "Properties",
      SideNav_Tasks: "Tasks",
      SideNav_MyDate: "My Date",
      SideNav_Payment: "Payment",
      SideNav_Bank: "Bank",
      SideNav_Withdrawals: "Withdrawals",
      SideNav_Tenants: "Tenants",
      SideNav_Requests: "Requests",
      SideNav_VacateRequests: "Vacate Requests",
      SideNav_Team: "Team",
      SideNav_ManageRole: "Manage Role",
      SideNav_Chat: "Chat",
      SideNav_OtherReports: "Other Reports",
      SideNav_Setting: "Setting",
      SideNav_Logout: "Logout",
      SideNav_Rate: "Rate",
      Reviews: "Reviews",
      // Dashboard
      Dashboard_RentBook: "Rent Book",
      Dashboard_Chips_Invoiced: "Invoiced",
      Dashboard_Chips_Overdue: "Overdue",
      Dashboard_Chips_NotInvoiced: "Not Invoiced",
      Dashboard_CurrentMonth: "Current Month",
      Dashboard_Transaction_Received: "Transaction (Received)",
      Dashboard_NextMonthInvoice: "Next Month Invoice",
      Dashboard_CarryoverInvoice: "Carryover Invoice",
      Dashboard_NextMonthPayment: "Next Month Payment",
      Dashboard_CarryoverPayment: "Carryover Payment",
      Dashboard_TotalAndAccruedPaymentsGraph:
        "Total and Accrued payments graph",
      Dashboard_MonthlyFullPayments:
        "Monthly full Payments overview, This data is updated every month.",
      Dashboard_TotalProperties: "Total Properties",
      Dashboard_TotalRequests: "Total Requests",
      Dashboard_ApprovalPending: "Approval Pending",
      Dashboard_Incomplete: "Incomplete",
      Dashboard_Inactive: "Inactive",
      Dashboard_Resolved: "Resolved",
      Dashboard_OccupiedProperty: "Occupied Property",
      Dashboard_VacantProperty: "Vacant Property",
      Dashboard_BookedProperty: "Booked Property",
      Dashboard_AwaitInvitation: "Await Invitation",
      Dashboard_MaintenanceRequest: "Maintenance Request",
      Dashboard_BookingRequest: "Booking Request",
      Dashboard_QueriesRequest: "Queries Request",
      Dashboard_TenantRequest: "Tenant Request",
      Dashboard_Chart_Occupied: "Occupied",
      Dashboard_Chart_Vacant: "Vacant",
      Dashboard_Chart_Booked: "Booked",
      Dashboard_ManageYourNewTenants:
        "Manage your New Tenants, Property maintenance, Queries and General Requests",
      Dashboard_TableHeading_Property: "Property",
      Dashboard_TableHeading_TenantName: "Tenant Name",
      Dashboard_TableHeading_PhoneNo: "Phone No",
      Dashboard_TableHeading_Recurrence: "Recurrence",
      Dashboard_TableHeading_Status: "Status",
      Dashboard_MyReminder: "My Reminder",
      Dashboard_ManageYourPropertyMaintenanceReminder:
        "Manage your Property maintenance reminder",
      Dashboard_TenantsList: "Tenants List",
      Dashboard_TeamsList: "Teams List",
      Dashboard_ViewManageTenants: "View Manage tenants",
      Dashboard_ViewManageTeams: "View Manage teams",
      Dashboard_Accured: "Accured",
      Dashboard_Recieved: "Received",
      Dashboard_AwaitInvite: "Await Invite",
      Dashboard_PaymentAndHistory: "Payment & History",
      Dashboard_History: "History",
      Dashboard_CurrentOccupiedProperty: "Current Occupied Property",
      Dashboard_PendingAgreement: "Pending Agreement",
      Dashboard_VacatePropertyRequest: "Vacate Property Request",
      Dashboard_PropertyInvitations: "Property Invitations",
      Dashboard_MaintainceAndQuery: "Maintenance and Query",
      Dashboard_MaintenanceReminder: "Maintenance Reminder",
      Dashboard_PaymentsGraph: "Payments Graph",
      Dashboard_Request_QueryAndMaintenance: "Request (Query & Maintenance)",
      Dashboard_ManageYourMaintenanceQuery: "Manage your maintenance & query",
      Dashboard_TableHading_Owner: "Owner",
      Dashboard_TableHading_MAINTAINENCE: "MAINTAINENCE",
      Dashboard_TableHading_REMAINDER: "REMAINDER",
      Dashboard_ReceivedTotalPayments: "Received Total Payments",
      Dashboard_TableOption_EveryMonth: "Every Month",
      Dashboard_TableOption_Quarterly: "Quarterly",
      Dashboard_TableOption_HalfYearly: "Half Yearly",
      Dashboard_TableOption_Yearly: "Yearly",
      Dashboard_TableOption_None: "None",
      Dashboard_PastProperty: "Past Property",
      Dashboard_TableHading_QUERY: "QUERY",
      Property_Tab_Occupied: "Occupied",
      Property_Tab_Booked: "Booked",
      Property_Tab_Vacant: "Vacant",
      Property_Tab_Pending: "Pending",
      Property_Tab_Incomplete: "Incomplete",
      Property_Tab_AwaitInvitation: "Await Invitation",
      Property_Tab_Inactive: "Inactive",
      Property_Option_EditProperty: "Edit Property ",
      Property_Option_Agreement: "Agreement",
      Property_Option_BankAccount: "Bank Account",
      Property_Option_PropertyStatus: "Property Status",
      Property_Option_View_Change_Edit_Bank: "View, Change / Edit Bank",
      Property_Option_View_ChangeEditAgreement: "View, Change / Edit Agreement",
      Property_Option_FeatureProperty: "Feature Property",
      Property_Option_VacateRequest: "Vacate Request",
      Property_MyCurrentRentals: "My Current Rentals",
      Property_PreviousTenants: "Previous Tenants",
      Property_CurrentTenant: "Current Tenant",
      Property_NoTenantInformationAvailable: "No Tenant information available",
      Property_InviteTenants: "Invite Tenants",
      Property_Note_ToSendInvitations:
        "Note : To send invitations to multiple tenants, visit the website for bulk upload",
      Property_MoveInDate: "Move in Date",
      Property_MoveDate: "Move Date",
      Property_DoYouWantGenerateInvoice:
        "Do you want to generate invoice for the below rent duration?",
      Property_ContractPeriod: "Contract Period",
      Property_SendInvite: "Send Invite",
      Property_Months: "Months",
      Property_ContractFinishIn: "Contract valid until",
      Property_since: "since",
      Property_NoAmenities: "No Amenities",
      Property_NumberOfParking: "Number of parking",
      Property_Bathroom: "Bathroom",
      Property_Bedroom: "Bedroom",
      Property_Overview: "Overview",
      Payment_ReceivedPayments: "Received Payments",
      Payment_InvoicedPayments: "Invoiced Payments",
      Payment_OverduePayments: "Overdue Payments",
      Payment_NotInvoicedPayments: "Not Invoiced Payments",
      Payment_NextMonthInvoice: "Next Month Invoice",
      Payment_NextMonthPayment: "Next Month Payment",
      Payment_CarryoverInvoice: "Carryover Invoice",
      Payment_CarryoverPayment: "Carryover Payment",
      Payment_PaymentsHistory: "Payments History",
      Payment_Received: "Received",
      Payment_TableHeading_TenantName: "Tenant Name",
      Payment_TableHeading_PaidOn: "Paid On",
      Payment_TableHeading_DueDate: "Due Date",
      Payment_TableHeading_RentCharges: "Rent Charges",
      Payment_TableHeading_TransactionId: "Transaction Id",
      Payment_TableHeading_InvoiceNo: "Invoice No",
      Payment_TableHeading_SendReminder: "Send a Reminder",
      Payment_Option_Select: "Select",
      Payment_Option_SendInvoiceReminder: "Send Invoice Reminder",
      Payment_Option_ViewInvoice: "View Invoice",
      Payment_Option_DownloadInvoice: "Download Invoice",
      Payment_InvoiceNo: "Invoice No.",
      Payment_Status_Paid: "Paid",
      Payment_Status_NotPaid: "Not Paid",
      Payment_Status_Due: "Due",
      Payment_Details_From: "From",
      Payment_Details_To: "To",
      Payment_Details_SecurityFees: "Security Fees",
      Payment_Details_GrandTotal: "Grand Total",
      Payment_Details_PayNow: "Pay Now",
      Payment_Details_PaidByCash: "Paid By Cash",
      Payment_Details_PaidByCheque: "Paid By Cheque",
      Payment_Details_Poweredby: "Powered by",
      Payment_Details_InvoiceDetails: "Invoice Details",
      Payment_Details_Invoiceof: "Invoice of",
      Payment_Details_IssueDate: "Issue Date",
      //cookies
      cookies:
        "We use cookies to enhance your experience and to improve the functionality and our internet site. By continuing to visit this site you agree to our use of Cookies.",
      // Bank
      Bank_AccountName: "Account name",
      Bank_AccountNumber: "Account number",
      Bank_IBANnumber: "IBAN Number",
      Bank_AddBankAccount: "Add Bank Account",
      Bank_Update: "Update",
      Bank_Not_Approved: 'Not Approved',
      Add_Bank_BankInformation: "Bank Information",
      Add_Bank_BankName: "Bank Name ",
      Add_Bank_BranchName: "Branch Name",
      Add_Bank_SwiftCode: "Swift Code ",
      Add_Bank_BankLetter_HeaderOfBankStatement:
        "Bank Letter / Header of Bank Statement",
      Add_Bank_YourAccountPhoto:
        "Your account photo is collected for double check and to avoid any entry mistakes.",
      Withdrawals_AccruedPayment: "Accrued Payment",
      Withdrawals_ReceivedPayments: "Received Payments",
      Withdrawals_Withdrawals: "Withdrawals",
      Withdrawals_PropertyName: "Property Name",
      Withdrawals_DueDate: "Due Date",
      Withdrawals_RentCharges: "Rent Charges",
      Withdrawals_Fees: "Fees",
      Withdrawals_YouReceived: "You Received",
      Withdrawals_InvoiceAmount: "Invoice Amount",
      Tenants: "Tenants",
      Tenants_Tab_ListOfTenants: "List of Tenants",
      Tenants_Tab_PastTenants: "Past Tenants",
      Tenants_TableHeading_TenantName: "Tenant Name",
      Tenants_TableHeading_Address: "Address",
      Tenants_TableHeading_PhoneNo: "Phone No",
      Tenants_TableHeading_Mail: "Mail",
      Tenants_TableHeading_Since: "Since",
      Tenants_TableHeading_Chat: "Chat",
      Tenants_TableHeading_Rating: "Rating",
      Requests_Requests: "Requests",
      Requests_DateRange: "Date Range",
      Requests_selectDateRange: "Select Date Range",
      Requests_ApplyFilter: "Apply Filter",
      Requests_ClearFilter: "Clear Filter",
      Requests_Tab_BookingRequest: "Booking Request",
      Requests_Tab_Maintenance: "Maintenance",
      Requests_Tab_Queries: "Queries",
      Requests_Tab_MaintenanceReminder: "Reminder",
      Requests_TableHeading_Subject: "Subject",
      Requests_TableHeading_Property: "Property",
      Requests_TablBOOKEDeHeading_DateTime: "Date Time",
      Requests_TableHeading_SubmittedBy: "Submitted By",
      Requests_TableHeading_ShareWith: "Share With",
      Requests_Add_PlaceRequest: "Place a Request",
      Requests_Add_PlaceQuery: "Place a Query",
      Requests_Add_AddReminder: "Add Reminder",
      Requests_AddForm_SelectProperty: "Select Property",
      Requests_AddForm_SelectAddress: "Select Address",
      Requests_AddForm_Subject: "Subject",
      Requests_AddForm_Importance: "Importance",
      Requests_AddForm_Recurrence: "Recurrence",
      Requests_AddForm_RecurrenceDate: "Recurrence Date",
      Requests_AddForm_Description: "Description",
      Requests_AddForm_Photo: "Photo",
      Requests_AddForm_DoYouWantToShareReminder:
        "Do you want to share this reminder with your tenant?",
      Requests_AddForm_Yes: "Yes",
      Requests_AddForm_No: "No",
      Requests_PerMonth: "Per Month",
      Requests_AddForm_YouCanUploadOnlyFiveImages: "You can upload only 5 images",
      Requests_RequestDetails: "Request Details",
      Requests_Photos: "Photos",
      Requests_ClickTheButtonIfThisRequestResolved:
        "Click the button if this request has been resolved.",
      Requests_ClickTheButtonIfThisReopenMaintenanceRequest:
        "Click on the button if you want to reopen maintenance request.",
      Requests_Resolve: "Resolve",
      Requests_Notes: "Notes",
      Requests_AreYouSureYouWantToReopenTheRequest:
        "Are you sure you want to reopen the request?",
      Requests_RequestSent: "Request Sent",
      Requests_PaymentPending: "Payment Pending",
      Requests_Reject: "Reject",
      Requests_Reason: "Reason",
      // Vacate Requests
      VacateRequest_Tab_Sent: "Sent",
      VacateRequest_Tab_Received: "Received",
      VacateRequest_VacateDate: "Vacate Date",
      VacateRequest_VacateDetails: "Vacate Details",
      VacateRequest_RemainingPeriod: "Remaining Period",
      VacateRequest_ReasonVacateProperty: "Reason to Vacate property",
      VacateRequest_UploadedDocument: "Uploaded document",
      VacateRequest_PaymentStatus_PaymentPending: "Payment Pending",
      VacateRequest_PaymentStatus_Accept: "Accept",
      VacateRequest_PaymentStatus_RequestAccepted: "Request Accepted",
      VacateRequest_PaymentStatus_Rejected: "Rejected",
      VacateRequest_PaymentStatus_Accepted: "Accepted",
      VacateRequest_AdvanceRentAmount: "Advance Rent Amount",
      VacateRequest_Pay: "Pay",
      VacateRequest_MakePayment: "Make Payment",
      VacateRequest_PaymentDetails: "Payment details",
      VacateRequest_MonthlyRent: "Monthly Rent",
      VacateRequest_AdvanceRent: "Advance Rent",
      VacateRequest_TotalRent: "Total Rent",
      VacateRequest_Total: "Total",
      VacateRequest_NoRequests: "No Requests",
      VacateRequest_Other: "Other",
      VacateRequest_Browse: "Browse",
      VacateRequest_DragAndDropImageHere: "Drag & Drop Image Here",
      VacateRequest_Max5MB: "Max 5 MB",
      // Team
      Team_Team: "Team",
      Team_TableHeading_Name: "Name",
      Team_TableHeading_RoleName: "Role Name",
      Team_Option_Edit: "Edit",
      Team_Option_ActiveStatus: "Active Status",
      Team_Option_ManageProperty: "Manage Property",
      Team_Option_Delete: "Delete",
      Team_AddForm_EnterFullName: "Enter Full Name",
      Team_AddForm_EnterEmailId: "Enter email id",
      Team_AddForm_RoleType: "Role Type",
      Team_AddTeam: "Add a Team Member",
      Team_EditTeam: "Edit Team",
      Team_TeamControl: "Team Control",
      Team_CreatedRole: "Created Role",
      Team_AddNewRole: "Add New Role",
      properties: "Properties",
      invoice: "Invoice",
      request: "Request",
      vacate: "Vacate",
      tenants: "Tenants",
      withdrawals: "Withdrawals",
      team: "Team",
      bank: "Bank",
      reports: "Reports",
      view: "View",
      export: "Export",
      recieveAsCash: "Receive As Cash",
      markResolve: "Mark Resolve",
      canAcceptOrReject: "Can Accept Or Reject",
      doVacateRequest: "Do Vacate Request",
      add: "Add",
      edit: "Edit",
      Team_EnterRoleName: "Enter role name",
      Download_Pdf: "Download PDF",
      //    chat
      Chat_MessageBoard: "Message Board",
      Chat_SearchForPerson: "Search for a person",
      Chat_Offline: "Offline",
      Chat_Online: "Online",
      Chat_TypeYourMessageHere: "Type your message here …",
      // Reports
      Reports_Tab_CollectedPayments: "Payments History",
      Reports_Tab_LatePayment: "Late Payment",
      Reports_TableHeading_PropertyName: "Property Name",
      Reports_TableHeading_Address: "Address",
      Reports_TableHeading_PaidOn: "Paid On",
      Reports_TableHeading_RentCharges: "Rent Charges",
      Reports_Options_Download_CSV: "Download CSV",
      Reports_Options_GenerateReport: "Generate Report",
      // Setting
      Setting_Tab_ChangePassword: "Change Password",
      Setting_Tab_ChangePassword_CurrentPassword: "Current Password",
      Setting_Tab_ChangePassword_NewPassword: "New Password",
      Setting_Tab_ChangePassword_ConfirmPassword: "Confirm Password",
      Setting_Tab_ContactUs: "Contact us",
      Setting_Tab_TermsAndConditions: "Terms & Conditions",
      // Add Property
      InvoiceDetails: "First invoice/es details",
      PreviewInvoice: "Preview Invoice",
      AddProperty_AddProperty: "Add Property",
      AddProperty_PleaseNote: "Please Note",
      AddProperty_PText:
        "To complete the property upload process, you are requested to share any of the below documents to approve the ownership of the uploaded property.",
      AddProperty_Deed: "Deed",
      AddProperty_AddressCard: "Address Card",
      AddProperty_ElectricityBill: "Electricity Bill",
      AddProperty_AnyOtherDocumnets: "Any Other Documents",
      AddProperty_DontRemindThis: "Don't remind this page again ",
      AddProperty_Continue: "Continue",
      AddProperty_AddPropertyManually: "Add Property Manually",
      AddProperty_UploadFromCSV: "Upload From a CSV",
      AddProperty_MyProperties: "My Properties",
      AddProperty_Etemplate: "Eglooz Template",
      AddProperty_RecentAgreement: "Select recent Agreement",
      AddProperty_OwnAgreement: "Upload your own Agreement",
      AddProperty_Submit: "Submit",
      AddProperty_Filter: " Filter",
      AddProperty_NEXTSTEP: "NEXT STEP",
      AddProperty_SortBy: "Sort by",
      AddProperty_Tab_Information: "Information",
      AddProperty_Tab_Location: "Location",
      AddProperty_Tab_PaymentDetails: "Payment Details",
      AddProperty_Tab_PropertyDetails: "Property Details",
      AddProperty_Tab_Specifications: "Specifications",
      AddProperty_Tab_Gallery: "Gallery",
      AddProperty_Tab_Documents: "Documents",
      AddProperty_Information_OwnerName: "OWNER NAME",
      AddProperty_Information_PHONE: "PHONE",
      AddProperty_Information_PropertyName: "PROPERTY NAME",
      AddProperty_Information_SelectType: "SELECT TYPE",
      AddProperty_Information_PropertyType: "PROPERTY TYPE",
      AddProperty_Information_PropertyStatus: "PROPERTY STATUS",
      AddProperty_Location_UnitNo: "Unit No",
      AddProperty_Location_BuildingNo: "BUILDING NO",
      AddProperty_Location_RoadName: "ROAD NAME/NO",
      AddProperty_Location_BlockNo: "Block No",
      AddProperty_Location_City: "City",
      AddProperty_Location_Country: "Country",
      AddProperty_Location_Location: "Location",
      AddProperty_PaymentDetails_RentAmount: "Rent Amount",
      AddProperty_PaymentDetails_Monthly: "Monthly",
      AddProperty_PaymentDetails_EWA: "EWA",
      AddProperty_PaymentDetails_Inclusive: "Inclusive ",
      AddProperty_PaymentDetails_Partial: "Includive w Partial ",
      AddProperty_PaymentDetails_Exclusive: "Exclusive ",
      AddProperty_PaymentDetails_OtherPayments: "Other Payments",
      AddProperty_PaymentDetails_SelectAdvanceAmount: "Select Advance Amount",
      AddProperty_PaymentDetails_oneYearAdvance: "1 Year Advance",
      AddProperty_PaymentDetails_MonthAdvance: "Month Advance",
      AddProperty_PaymentDetails_ShouldPriceNegotiable:
        "Is the rent amount negotiable?",
      AddProperty_PaymentDetails_Amount: "Amount",
      AddProperty_PaymentDetails_SelectContractPeriod: "Select Contract period",
      AddProperty_PaymentDetails_GRACEPERIOD: "GRACE PERIOD",
      AddProperty_PaymentDetails_Month: "Month",
      AddProperty_PaymentDetails_oneYear: "1 Year",
      AddProperty_PaymentDetails_SecurityAmount: "Security amount",
      AddProperty_PaymentDetails_Amount_Refundable: "Amount (Refundable)",
      AddProperty_PaymentDetails_InvalidAmount: "Invalid Amount",
      AddProperty_PaymentDetails_AdministrationFee: "Administration Fee",
      AddProperty_PaymentDetails_ParkingFee: "Parking Fee",
      AddProperty_PaymentDetails_HousekeepingFee: "Housekeeping Fee",
      AddProperty_PaymentDetails_MaintenanceFee: "Maintenance Fee",
      AddProperty_PaymentDetails_Others: "Others",
      AddProperty_PaymentDetails_AddMore: "Add more",
      AddProperty_PaymentDetails_Text: "Text...",
      AddProperty_PropertyDetails_AreaSquareMeters: "Area Square meters",
      AddProperty_PropertyDetails_Bedrooms: "Bedrooms",
      AddProperty_PropertyDetails_Toilets: "Toilets",
      AddProperty_PropertyDetails_Furnishing: "Furnishing",
      AddProperty_PropertyDetails_BuildingFloors: "Building floors",
      AddProperty_PropertyDetails_FloorNumber: "Floor number",
      AddProperty_PropertyDetails_YearOfConstruction: "Year of construction",
      AddProperty_PropertyDetails_State: "City (Area)",
      AddProperty_PropertyDetails_NumberOfBalconies: "Number of balconies",
      AddProperty_PropertyDetails_Discription: "Description",
      AddProperty_PropertyDetails_EnterAreaProperty: "Enter area of property",
      AddProperty_PropertyDetails_Parking: "Parking",
      AddProperty_PropertyDetails_NotFurnished: "Not Furnished",
      AddProperty_PropertyDetails_PartiallyFurnished: "Partially Furnished",
      AddProperty_PropertyDetails_FullFurnished: "Full Furnished",
      AddProperty_PropertyDetails_FurnitureList: "Furniture List",
      AddProperty_PropertyDetails_FurnitureDescription: "Furniture Description",
      AddProperty_PropertyDetails_New: "New",
      AddProperty_PropertyDetails_CompletelyRefurbished:
        "Completely Refurbished",
      AddProperty_PropertyDetails_PartiallyRefurbished: "Partially Refurbished",
      AddProperty_Specifications_Amenities: "Amenities",
      AddProperty_Specifications_ShowAllAmenities: "Show all amenities",
      AddProperty_Specifications_HideAmenities: "Hide amenities",
      AddProperty_Specifications_Landmark: "Landmark",
      AddProperty_Specifications_AddLandmark: "Add Landmark",
      AddProperty_Gallery_YouCanUploadOnly:
        "You can upload only 10 images with minimum (300 x 930) dimension",
      AddProperty_Gallery_ImageName: "Image Name",
      AddProperty_Gallery_UploadAndSaveImage: "Upload & Save Image",
      AddProperty_Gallery_Note: "Note:",
      AddProperty_Gallery_UploadProof: "Upload proof of ownership document",
      AddProperty_Gallery_Max: "Max 5MB",
      AddProperty_Document_DocumentName: "Document Name",
      AddProperty_Document_DocumentType: "Document Type",
      AddProperty_Document_UploadAndSaveDocument: "Upload & Save Document",
      AddProperty_ApprovalPreview_GreatYourPropertyHasBeen:
        "Great! Your property has been successfully",
      AddProperty_ApprovalPreview_UpdatedForApproval: "updated For Approval",
      AddProperty_ApprovalPreview_GreatYourPropertyHas:
        "Great! Your property has been successfully added.",
      AddProperty_ApprovalPreview_GreatYourPropertyWill:
        "Great! your property will be approved within 48 working hours",
      AddProperty_ApprovalPreview_GreatYourPropertyUploaded:
        "Great! Your property is now uploaded",
      AddProperty_ApprovalPreview_GreatYourPropertyLinkBank:
        "Please link your bank account for approval",
      AddProperty_ApprovalPreview_submitForApproval: "Submit for approval.",
      AddProperty_ApprovalPreview_updated: "Updated.",
      AddProperty_ApprovalPreview_AddYourPropertyAgreement:
        "ADD YOUR PROPERTY AGREEMENT",
      AddProperty_ApprovalPreview_LinkYourBankAccount: "LINK YOUR BANK ACCOUNT",
      AddProperty_ApprovalPreview_MoreStepsRentYour:
        "Steps to rent your property and collect your rent easily.",
      AddProperty_ApprovalPreview_One: "One",
      AddProperty_ApprovalPreview_Two: "Two",
      AddProperty_ApprovalPreview_Few: "Few",
      // Login and Registration Page
      Phone: "Phone",
      Password: "Password",
      ForgotPassword: "Forgot Password?",
      ContinueGuest: "Continue as Guest",
      RegisterNow: "Register Now",
      SelectType: "Select Type",
      SelectTypeOfRole: "Select type of role you will perform",
      Continue: "Continue",
      SignUP: "SignUP",
      TellMeMore: "Hi, Tell me more about you",
      FullName: "Full Name",
      PhoneNumber: "Phone Number",
      Re_EnterPassword: "Re-Enter Password",
      Accept: "Accept ",
      Terms: "Terms",
      PrivacyPolicy: "Privacy Policy",
      And: "and",
      AlreadyHaveAnAccount: "Already have an account?",
      PasswordConfirmNotMatch: "Password Mismatch",
      Agent_Manager: "Company",
      TeamMember: "Team Member",
      EnterOneTimePassword: "Enter One Time Password (OTP)",
      OTP_HasBeenSent: "One time password (OTP) has been sent to your mobile",
      OTP_verification: "Please enter the same here for verification",
      OTPCode: "OTP Code",
      Re_sendOTP: "Re-send OTP!",
      DragAndDropOr: "Drag & Drop or ",
      SelectIdentity: "Select Identity",
      EnglishName: "English Name as on ID",
      ArabicName: "Arabic Name as on ID (if applicable)",
      AddProperty_: "Identity Number",
      IdentityNumber: "Identity Number",
      ExpiryDate: "Expiry date",
      Upload: "Upload",
      // Explore
      Explore_PropertiesAvailableForRent: "Properties available for rent",
      Explore_ExclExtraCharges: "Excl. Extra Charges",
      Explore_ChangeRequest: "Change Request",
      // Explore_BookNow: 'Book Now',
      Explore_BookNow: "Request Now",
      Explore_PlaceRequest: "Place Request",
      Explore_MoveInDate: "Move in Date",
      Explore_SelectContractPeriod: "Select Contract period",
      Explore_NoOfOccupancy: "No. Of Occupancy",
      Explore_Address_Location: "Address/Location",
      Explore_Landmark_NearbyLocation: "Landmark (Nearby Location)",
      Explore_AdministrationFees: "Administration Fees",
      Explore_ParkingFees: "Parking Fees",
      Explore_HousekeepingFees: "Housekeeping Fees",
      Explore_MaintenanceFees: "Maintenance Fees",
      Explore_OtherFees: "Other Fees",
      Explore_Contractperiod: "Contract period",
      Explore_GracePeriod: "Grace Period",
      Explore_SecurityAmount: "Security Amount",
      Explore_Review: "Review",
      Explore_Accuracy: "Accuracy",
      Explore_Communication: "Communication",
      Explore_Cleanliness: "Cleanliness",
      Explore_MonthlyPrice: "Monthly Price",
      Explore_RentAmountRange: "Rent Amount Range",
      Explore_MinBHD: "Min BHD",
      Explore_MaxBHD: "Max BHD",
      // Property Details: '',
      Property_OTPVerification: "OTP Verification",
      Property_PleaseTypeTheVerificationCode:
        "Please type the verification code",
      Property_: "",
      // Review & Rating
      ReviewRating_ReviewRating: "Review & Rating",
      ReviewRating_RATE: "RATE",
      ReviewRating_ShareYourFeedback: "Share your Feedback",
      ReviewRating_HowSatisfiedAreYou:
        "How would you rate your experience with the property?",
      ReviewRating_Perfect: "What do you like most?",
      // Profile
      Profile_AddCompany: "Add Company",
      Profile_EditCompany: "Edit Company",
      Profile_About: "About",
      Profile_Job: "Job",
      Profile_RegisteredAddress: "Registered Address",
      Profile_RegisterOn: "Register on",
      Profile_IdentityInformation: "Identity Information",
      Profile_Number: "Number",
      Profile_Passport: "Passport",
      Profile_ExpiryDates: "Expiry Dates",
      Profile_Profile: "Profile",
      Profile_User: "User",
      Profile_Username: "Username",
      Profile_Save: "Save",
      Profile_InvalidEmailId: "Invalid Email Id",
      // Invites
      Invites_Invites: "Invites",
      Invites_InviteDate: "Invite Date",
      Invites_: "",
      // Booking
      Booking_TenantFile: "Tenant File a",
      Booking_AcceptRequestBy: "Request Accepted by",
      Booking_SendingContract: "Sending Contract",
      Booking_step_stepThreeStepCompleted: "step stepThree stepCompleted",
      Booking_step_stepThreeInProgress: "step stepThree inProgress",
      Booking_step_stepThree: "step stepThree",
      Booking_step_stepFourStepCompleted: "step stepFour stepCompleted",
      Booking_step_stepFourInProgress: "step stepFour inProgress",
      Booking_step_stepFour: "step stepFour",
      Booking_LandlordSignThe: "Landlord signed the",
      Booking_contract: "contract",
      Booking_TenantMakeThe: "Tenant paid rent",
      Booking_AgreementPendingFromTenantSide: "Agreement pending with Tenant",
      Booking_AgreementPendingFromLandlordSide:
        "Agreement pending with Landlord",
      Booking_PaymentPendingFromTenantSide: "Tenant Payment Pending",
      Booking_ClickAcceptToProceedFurtherSteps:
        "Click accept and proceed with next steps",
      Booking_TenantSignature: "Tenant Signature",
      Booking_ClickHereToSign: "Click here to sign",
      Booking_LandlordSignature: "Landlord Signature",
      Booking_BeforeAcceptingTenantRequest:
        "Before accepting tenant request please make sure you’ve attached the right contract. If you need to change the contract, then click",
      Booking_Re_uploadAgreement: "Re-upload Agreement",
      Booking_AcceptAndSendAgreement: "Accept & Send Agreement",
      Booking_CancelTheRequest: "Cancel the Request",
      Booking_ClickCancel_If:
        "(Click cancel, if you want to cancel/delete request)",
      Booking_AgreementContract: "Agreement Contract",
      Booking_ClickHereToView: "Click here to View",
      Booking_AdministrativeFees: "Administrative Fees",
      // ManageProperty,
      ManageProperty_SearchProperty: "Search Property",
      ManageProperty_SearchPropertyByName: "Search property by name",
      ManageProperty_NameA_Z: "Name: A-Z",
      ManageProperty_NameZ_A: "Name: Z-A",
      ManageProperty_AddressA_Z: "Address: A-Z",
      ManageProperty_AddressZ_A: "Address: Z-A",
      ManageProperty_UpdateASSIGN: "Update ASSIGN",
      ManageProperty_Relevance: "Relevance",
      RequestAdded: "Request Added",
      QueriesAdded: "Queries Added",
      Home_SEARCHBYTYPE: "SEARCH BY TYPE",
      YouNeedToUploadYourIdentityCardsAndDocuments:
        "You need to upload your identity cards and documents.",
      UploadId: "Upload Id",
      LivingSince: "Living Since",
      VacateRequest: "Vacate Request",
      TransactionId: "Transaction Id",
      InvoiceDate: "Invoice Date",
      NoNotificationsFound: "No Notifications Found.",
      RequestResolved: "Request Resolved",
      AreYouSureYouWantToResolveTheRequest:
        "Are you sure you want to resolve the request?",
      ResolveNotes: "Resolve Notes",
      ReopenNotes: "Reopen Notes",
      Companies: "Companies",
      AddProperty_Information_CompanyName: "Company Name",
      Role: "Role",
      Mob_No: "Mob. No",
      AddProperty_ApprovalPreview_CongratsYourAgreementHasBeen:
        "Congrats! your agreement has been",
      AddProperty_ApprovalPreview_updatedSuccessfully: "updated successfully",
      No_Agreement_Added: "No Agreement Added",
      Upload_Agreement: "Upload Agreement",
      Booking_CancelAllRequest: "Cancel all Requests",
      Booking_CancelRequest: "Cancel Request",
      Are_You_Sure_You_Want_To_Cancel_All_Request:
        "Are You Sure, you want to cancel all requests",
      Are_You_Sure_You_Want_To_Cancel_The_Request:
        "Are You Sure, you want to cancel the request",
      About_Company: "About Company",
      Total_Properties: "Total Properties",
      Vacant_Properties_Listed: "Vacant Properties Listed",
      to: "to",
      from: "From",
      Town_City: "City/Town",
      CompanyProfile: "Company Profile",
      Property_Option_RenewContract: "Renew Contract",
      Property_Year: "Year",
      Invoice_Duration: "Duration",
      Payment_TableHeading_RentAmount: "Rent Amount",
      Click_to_Send_Reminder: "Click the button if this reminder completed.",
      Payment_Details_Invoicefrom: "Invoice From",
      EditPropertyNote:
        "Changing in property status will cause changing in payment and contract details. Kindly update the payment details to save the changes.",
      AddProperty_PaymentDetails_MonthsAdvance: "Months Advance",
      AddProperty_PaymentDetails_Months: "Months",
      AddProperty_ApprovalPreview_MoreStepRentYour:
        "step to rent your property, and collect your rent easily.",
      addIfApplicable: "(If Applicable)",
      InActiveTeamMember: "Are you sure, you want to Inactive Member?",
      ActiveTeamMember: "Are you sure, you want to Active Member?",
      Explore_PreviousContractPeriod: "Previous Contract period",
      Booking_NoContractAttached:
        "Before accepting tenant request make sure to attach the right contract. If you want to upload a contract click on",
      Dashboard_Chart_AwaitInvite: "Await Invitation",
      Dashboard_VacatePropertyRequestDashboard_VacatePropertyRequest:
        "Vacate Property Request",
      Listed: "Listed:",
      Properties: "Properties",
      Available_For_Rent: "Available for Rent:",

      Covid_Nav_Text:
        "Supporting you and your organization throughout COVID-19",
      Covid_Nav_Text_Find_More: "Find out more",
      Covid_Pledge:
        "We Pledge to support you and your organization throughout COVID-19",
      Covid_Proptech_Solution:
        "Our Unique PropTech solution is here to support your organizational business by providing digital technology for yourselves and tenants. It enables better communication with your tenants and promote online payments.",
      Covid_Get_In_Touch: "GET IN TOUCH",
      Covid_Buisness_Plan: "Our business continuity plan regarding COVID-19",
      Covid_Buisness_Description:
        "The pandemic has really changed our daily routine – the way we live and do business! We at Eglooz are doing everything we can to ensure the health and safety of people in Bahrain at this time of crisis. Our updated business continuity plan outlines how we pledge to support The Kingdom of Bahrain rental real-estate industry.",
      Covid_Buisness_Read_More: "Read More ->",
      Covid_Buisness_Measures:
        "As part of our precautionary measure, we have implemented the following measures:",
      Covid_Buisness_Measures_1:
        "From Sunday 14th March 2020, all Eglooz employees will work from home for the foreseeable future.",
      Covid_Buisness_Measures_2:
        "Eglooz office will remain open for those needing office facilities.",
      Covid_Buisness_Measures_3:
        "Our digital platform was built to work remotely, facilitating easy connect by audio/video calls, remote LIVE presentations, however we can still attend in person client meetings as required.",
      Covid_Buisness_Measures_4:
        "Cancelled all business meetings at office premises.",
      Covid_Buisness_Measures_5:
        "Postponed meetups and large gatherings in the interim.",
      Covid_Buisness_Measures_6: "",
      Covid_Buisness_Additions: "In addition, we already have:",
      Covid_Buisness_Additions_1:
        "Eglooz Happiness Centre available 24/7 online and keep supporting you no matter what happens.",
      Covid_Buisness_Additions_2:
        "Enabled additional services on AWS Cloud for high performance and high availability, ready to outlive the pandemic or any future possible circumstances.",
      Covid_Buisness_Additions_3:
        "Eglooz has made available its training materials online and could be made available on your mailbox upon request anytime.",
      Covid_Buisness_Help: "We’re here to help",
      Covid_Buisness_Help_1:
        "You can write your queries to happiness@eglooz.com and we would quickly respond to your queries.",
      Covid_Buisness_Help_2:
        "If you need our team’s support, please reach out to your Account Manager via email, phone or WhatsApp.",
      Covid_Buisness_Help_3:
        "We will prioritise your queries and supporting you 24/7 wherever possible.",
      Covid_Buisness_Help_4:
        "As part of our COVID-19 initiative, we’ve also launched an awareness marketing campaign to promote online payments among our tenants by introducing prizes on rent paid online through the Eglooz Platform. We’ve witnessed a great response by tenants and transactions have shot up to 260% against March volumes.",
      Covid_Buisness_Help_5:
        "We are also updating our people regularly through Instagram, LinkedIn, Facebook, Twitter - with the best advice on how to stay healthy and reinforcing hygiene and self-isolation practices. We have asked Eglooz employees to adhere to our detailed precautionary circulation standards incase of any unavoidable direct interaction with our customers.",
      Covid_Buisness_Help_6:
        "We conclude, Continuity and contact with our customers is vital and we are well set up to operate remotely if required and have ensured that anyone who is customer facing has access to tools so they remain contactable by all of our customers. If you have any queries, please reach out to your Eglooz contact at any time.",
      Covid_Buisness_Help_7: "",
      Covid_Digital_Tools: "Digital tools to take advantage",
      Covid_Digital_Tools_Description:
        "Eglooz has a range of services enabled - advertisement, smart contracts, online payments, auto-invoice and receipt generation, request maintenance, finance dashboards, send reminders etc. All its services is available online for free until May 2020.",
      Covid_Digital_Tools_Image_1: "Online Registration And Support",
      Covid_Digital_Tools_Image_2: "Online Training For Team Members",
      Covid_Digital_Tools_Image_3: "Dedicated Acoount Manager 24/7",
      Covid_Digital_Tools_Image_4: "Online Help & Call Center For Tenants",
      Covid_Digital_Tools_Contact: "Contact us",
      Covid_Contact_Us:
        "Contact Us and Start Your Digital Transformation Journey Now",
      Covid_Think_Wise: "Think Wise – Choose Eglooz!",

      Add_Tenant: "Add Tenant",
      YouCanUploadYourIdentityCardsAndDocuments:
        "You can upload tenant identity cards and documents.",
      Tenants_TableHeading_Delete: "Delete",
      No_Tenant_Found: "No Tenant Found",
      Are_You_Sure: "Are You Sure, You want to delete tenant?",
      Setting_Tab_ChangePhoneNumber: "Change Phone Number",
      VideoImage: "assets/img/video.png",
      Home_OurPride: "Our Pride",
      Home_SuccessPartners: "Success Partners",
      assign: "Assign",
      Payment_Option_EditInvoice: "Edit Invoice",
      Request_Tab_Maintenance_Cost: "Maintenance / Cost",
      Property_details_Rent_Eligibility: "Rent Eligibility / Month",
      Requests_AddForm_DoYouWantToShareCost:
        "Do you want to share this Maintenance with your tenant",
      AreYouSureYouWantToResolveTheMaintenance:
        "Are you sure you want to resolve the maintenance?",
      maintenance_price: "MAINTENANCE PRICE IN BHD",
      Price: "Price",
      Maintenance_Fees: "Maintenance Fee",
      Maintenance_RequestDetails: "Maintenance Request Details",
      Resolved_Images: "Resolved Images",

      Rent_Interval: "Rent Interval",
      Additional_Fees: "Additional Fees",
      Managed_By: "Managed By",
      Due: "Due",
      Rent_Invoice: "RENT INVOICE",
      Rent_Eligibility: "Rent Eligibility",
      Rent_For: "Rent for (Month)",
      Total_Amount: "Total Amount",
      Eglooz_Platform: "Eglooz Platform",
      Paid_By_Cheque: "Paid By Cheque",
      Cheque_No: "Cheque No.",
      Transaction_No: "Transaction No.",
      Pay_To: "Pay To",
      Account_Name: "Account Name",
      Bank_Name: "Bank Name",
      IBAN_No: "IBAN No",
      Account_No: "Account No",
      Powered_By: "Powered By",
      paidByNote: "If Tenant Paid by Cash/Cheque",
      onlyDebitCard: "Only Debit Card (Benefit)",

      No: "No",
      Yes: "Yes",
      Submit_Id: "Submit ID",
      Complete_Images: "Completed Images",
      Complete_Notes: "Completed Notes",
      Reports_Expense_Card: "Expense Card",
      Reports_Rent_Card: "Rent Card",
      Reports_Vacant_List: "Vacant List",
      Reports_Not_Paid_Card: "Not Paid Card",
      Reports_Property_Statement: "Property Statement",
      Reports_Withdrawal: "Withdrawal",
      Reports_Withdrawals_Report: "Withdrawals Report",
      Reports_To_Be_Renewed: "To Be Renewed",
      Select_City: "Select City",
      Select_Months: "Select Months",
      Issuing_Date: "Issuing Date",
      Total_Expenses: "Total Expenses",
      Maintenance_Status: "Maintenance Status",
      Reports_Printed_By: "Reports Printed By",
      Total_Due_Rent: "Total Due Rent",
      Rent: "Rent",
      Invoice_Update: "Invoice Update",
      Tenant_Contact: "Tenant Contact",
      Rent_Period: "Rent Period",
      Invoice_Status: "Invoice Status",
      Total_Income: "Total Income",
      Net_Income: "Net Income",
      Income: "Income",
      Expense: "Expense",
      Pay_Date: "Pay Date",
      Payment_Method: "Payment Method",
      Rent_late: "Rent Late",
      Team_Assigned: "Team Assigned",
      Rent_Payment_Date: "Rent Payment Date",
      Transfer_Amount: "Transfer Amount",
      Transfer_Date: "Transfer Date",
      System_Reference: "System Reference",
      Iban: "Iban",
      Property_Type: "Property Type",

      Contract_Expiry_Date: "Contract Expire Date",
      Expected_Income: "Expected Income",
      Actual_Rental_Income: "Actual Rental Income",
      Total_Rent_Recieved: "Total Rent Received",
      Rent_Date: "Rent Date",
      Owner_Details: "Owner Details",
      Collected: "Collected",
      Cash: "Cash",
      Transfer: "Transfer",
      Reports_Month: "Month",
      Added_By: "Added In",
      Password_Updated: "Password Updated",
      Last_Login: "Last Login",

      Offline_Payment: "Offline Payment",
      Paid_Offline: "Paid Offline",
      Partial_Amount_Paid: "Partially Amount Paid",
      On: "On",
      TransId: "TransID",
      Cash_Mode: "Cash Mode",
      Partially_Paid: "Partially Paid",
      Bank_Transfer: "Bank Transfer",
      Fully_Paid: "Fully Paid",
      Offline_Payment_Cheque_No: "Cheque No.",
      Offline_Payment_From: "From",
      Offline_Payment_To: "To",
      Payment_Mode: "Payment Mode",
      Payment_Type: "Payment Type",
      Full: "Full",
      Partial: "Partial",
      Cheque: "Cheque",
      Cheque_Number: "Cheque Number",
      Cheque_Image: "Cheque Image",
      Offline_Date: "Date",
      Maintenance_Price_Notes: "Enter Maintenance price and notes",
      Maintenance_Price: "Maintenance price in BHD",

      Resolved_Complete_Reminder: "Are you sure you want to complete reminder?",

      Price_In_BHD: "Price in BHD",
      Reminder_Details: "Reminder Details",
      Share_With_Tenants: "Share With tenant?",

      Accept_Request_By: "Accept Request By",
      Sending_Contract: "Sending Contract",
      Tenant_Sign_The: "Tenant Sign The",
      Contract: "Contract",
      Submission: "Request",
      Vat_Exempted: "VAT Exempted",
      Vat_Number: "VAT Number",
      Units: "Units",
      Pending_Invoice: "Pending Invoice",
      Payment_DuePayments: "Due Payments",
      Privacy: "Privacy",
      phone: "Phone",
      Id_Number: "Id Number",
      contact: "Contact",
      ID: "ID",
      Upload_Documents: "Upload Documents",
      Add_Invoice: "Add Invoice",
      Add_Rent_Invoice: "Add Advance Invoice",
      Payment_CancelledPayments: "Cancelled Payments",
      SideNav_Rent_Invoice: "Rent Invoice",
      Payment_Cancel_Invoice: "Cancel Invoice",

      //Maintenance
      Maintenance_Assign_To: "Assigned To",
      Maintenance_Assign_Success: "Assigned Successfully",
      Complete: "Complete",
      Maintenance_Add_Notes: "Add Notes",
      Requests_TableHeading_DateTime: "Date and Time",
      Maintenance_Ticket_No: "Ticket No.",
      Maintenance_Submit_Date: "Submit Date",
      Maintenance_Resolve_Date: "Resolve Date",
      Requests_AddForm_DoYouWantToShareMaintenance: "Share with your tenant",
      Maintenance_Notes: "Maintenance Notes",
      Maintenance_Cost: "Cost",
      phoneType: 'Phone Type',
      language: 'Language',
      tenant_Type: 'Tenant Type',
      Maintenance_Me: 'Me',
      Maintenance_Vat_Percentage: 'VAT %',
      Maintenance_My_Notes: 'My Notes',
      Maintenance_Tenant_Notes: 'Tenant Notes',
      Maintenance_Photo_Video: 'Photo / Video',
      Maintenance_Photo_Video_Message: 'You can upload 5 images or 20MB video',
      Maintenance_Add_Cost_To: 'Ádd this cost to:',
      Maintenance_Next_Month_Rent_Invoice: 'Next month rent invoice',
      Maintenance_Generate_Immediate_Invoice: 'Generate immediate invoice',
      Maintenance_Charge_Cost: 'Charge this maintenance cost',
      Maintenance_resolving_details: 'Maintenance resolving details',

      //Add Other Invoice
      Otherinvoice_Add_Invoice: 'Add Other Invoice',
      Otherinvoice_Invoice_Entry: 'Invoice Entry',
      Otherinvoice_Unit_Amount: 'Unit Amount',
      Otherinvoice_Quantity: 'Quantity',
      Otherinvoice_Add_Entry: 'Add Entry',
      Otherinvoice_Vat_Percentage: 'Enter Vat Percentage',
      Otherinvoice_MyNotes: 'My Notes',
      Otherinvoice_TenantNotes: 'Tenant Notes',
      Otherinvoice_Cancelled_Invoices: 'Cancelled Invoices',
      Otherinvoice_Other_Fee_Invoiced: 'Other Fee Invoiced',
      Otherinvoice_Other_Fee_Received: 'Other Fee Received',
      Other_Invoices: "Other Invoices",
      Otherinvoice_Ticket_Ref: "Ticket Ref.",
      Otherinvoice_Unit_Price: 'Unit Price',
      Otherinvoice_Invoice_Charges: 'Invoice Charges',
      Otherinvoice_Other_Invoice: "Other Invoice",
      Otherinvoice_Vat_Invoice: "Tax Invoice",
      Otherinvoice_Total_With_Vat: "Total with VAT",
      Property_details_Call_For_Price: 'Call For Price',

      //roles
      to_be_renewed: 'To be renewed report',
      property_statement: 'Property statement report',
      withdrawal: 'Withdrawal report',
      not_paid_card: 'Not paid card report',
      vacant_card: 'Vacant list report',
      rent_card: 'Rent card report',
      expense_card: 'Expense card report',

      //offline-payments
      Rent_invoices: 'Rent Invoices',
      Payment_To_Confirm: 'Payment To Confirm',
      Payment_Rejected: 'Payment Rejected',
      Payment_Confirmed: 'Payment Confirmed',
      Payment_Confirmation: 'Payment Confirmation',
      Method_Attachments: 'Method/Attachments',
      Rent_Invoice_Confirmation: 'Rent Invoice Confirmation',
      Offline_invoice_Accept_Payment: 'Accept Payment',
      Offline_invoice_Reject_Payment: 'Reject Payment',
      Offline_Paid_By: 'Paid By',
      Offline_Payment_Confirmation: 'Rent Payment Confirmation',
      Attachment: 'Attachment',
      Submitted_Payment_Requests: 'Submitted Payment Requests',
      Amount_Paid: 'Amount Paid',
      Reject_Date: 'Reject Date',

      //roles
      Add_New_Porperty: 'Add New Property',
      Add_Unit_On_Existing_Building: 'Add Unit on Existing Building',
      Property_Name_In_Arabic: 'Property Name in Arabic',
      Requests_TableHeading_SubjectAr: 'Subject In Arabic',
      Requests_TableHeading_DescriptionAr: 'Description In Arabic',
      Requests_TableHeading_Furniture_List: 'Furniture List',
      Requests_TableHeading_Furniture_List_Arabic: 'Furniture List In Arabic',
      Requests_TableHeading_Area: 'Area',
      Requests_TableHeading_Governate: 'Governate',
      Requests_TableHeading_Road_Way_Number: 'Road / Way Number or name',
      AddProperty_PaymentDetails_Electricity_Meter: "Electricity Meter No.",
      AddProperty_PaymentDetails_Water_Meter: "Water Meter No.",
      Are_You_Sure_Request: "Are You Sure, You want to delete this request?",
      Property_Skip: 'Skip',
      Dashboard_Total_Unit: 'Total Units :',
      Dashboard_Net_Income: 'Net Income :',
      Dashboard_Expected_Income: 'Expected Income',
      Dashboard_Actual_Income: 'Actual Income',
      Dashboard_Expenses: 'Expenses',
      Address_Area_Name: 'Area Name',
      AddProperty_PropertyDetails_Building_Status: 'Building Status',
      Maintenance_Enter_Notes_Photo: 'Enter Maintenance note and photo',
      Maintenance_Question: 'Do You want to share this maintenance note with your tenant',
      Maintenance_Upload_Three_Image: 'You can upload up to 3 images',
      Add_Tenant_Issue_Date: 'Issue Date',
      Add_Tenant_Sr_No: 'Sr No',
      Add_Tenant_Due_Date: 'Due Date',
      Add_Tenant_Duration: 'Duration',
      Send: 'Ok',
      Days: 'Days',
      Request_Date: 'Request Date',
      Confirmation_Date: 'Confirmation Date',


      //Edit Profile
      Edit_Profile_Company_Name: 'Company Name',
      Edit_Profile_Company_Address: 'Company Address',
      Edit_Profile_Commercial_Registration_Number: 'COMMERCIAL REGISTRATION (CR) NUMBER',
      Edit_Profile_Authorized_Person_Name: 'AUTHORIZED PERSON NAME',
      Edit_Profile_Authorized_Person_ID: 'AUTHORIZED PERSON ID',
      Edit_Profile_Authorized_Person_Contact: 'AUTHORIZED PERSON CONTACT',
      Edit_Profile_About_The_Company: 'ABOUT THE COMPANY',
      Edit_Profile_Vat: 'VAT',
      Edit_Profile_Vat_No: 'VAT No.',
      Edit_Profile_Company_Logo: 'Company Logo',
      Edit_Profile_Add_Company_Documents: 'Add Company Documents',
      Edit_Profile_Done: 'Done',
      //Add Advance Invoice
      Advance_Invoice_Email: 'Email',
      Advance_Invoice_Tenant_Name: 'Tenant Name',
      Advance_Invoice_Move_In_Date: 'Move In Date',
      Advance_Invoice_Phone: 'Phone',
      Advance_Invoice_No_Of_Intervals: 'Number of Intervals',
      Advance_Invoice_Rent_Period: 'Rent Period',
      Advance_Invoice_Calculate: 'Calculate',
      Advance_Invoice_Terms_Cheeckbox: 'THIS INVOICE WILL BE GENERATED IMMEDIATELY AND WILL BE CANCELLED IF IT IS NOT PAID BEFORE NORMAL NEXT INVOICE GENERATION DATE',
      Advance_Invoice_Generate: 'Generate',
      Advance_Invoice_Preview: 'Preview',
      //Maintenance
      Maintenance_Details_Notes: 'Notes',
      Maintenance_Details_Resolved: 'Resolved',
      Maintenance_Details_Cost: 'Cost:',
      Maintenance_Details_Total: 'Total:',
      Maintenance_Details_VAT: 'VAT:',
      Maintenance_Details_Reopen: 'Reopen',
      Property_Details_Building_Status: 'Building Status',
      Other_Invoice_Sr_No: 'S/N',
      //Edit Profile
      Edit_Profile_Donot_Show_Phone: "Don't show my Phone",
      Edit_Profile_Donot_Show_Name: "Don't show my Name",
      Edit_Profile_Donot_Show_Chat: "Don't Recieve Chat",
      Edit_Profile_Logo_Image: 'Logo Image',
      Phone_To_Show_Explore: 'PHONE NO. TO DISPLAY IN EXPLORE LIST',
      Edit_Profile_Note: 'Note :',
      Edit_Profile_Note_Text: 'If you are an agent, you need to upload commercial registration certificate as well',
      //Edit Invoice
      Edit_Invoice_Give_Discount: 'Give Discount',
      Edit_Invoice_Discount_Note: 'Discount Note',
      Edit_Invoice_Add_Fees: 'Add Fees',
      Edit_Invoice_Fee_Note: 'Fee Note',
      Edit_Invoice_Add_Note: 'Add Note',
      Edit_Invoice_Please_Remove_Note: 'Please Remove Note',
      //Maintenane
      Request_Received: 'Received',
      Request_Submitted: 'Submitted',
      Maintenance_Type_Received: 'Received',
      Maintenance_Type_Ongoing: 'Ongoing',
      Maintenance_Type_Completed: 'Completed',
      Maintenance_Delete_Maintenance: 'Delete Maintenance',
      //  View Property
      View_Property_Sort_Building_Name: 'Unit Number',
      View_Property_Sort_Unit_Number: 'Building Name',
      View_Property_Sort_Ascending: 'Ascending',
      View_Property_Sort_Desending: 'Desending',
      Property_Delete: 'Delete',
      Property_Deleted_Successfully: 'Deleted Successfully',
      Property_Deleted_Properties: 'Archieved Properties',
      Property_Deleted: 'Deleted',
      Maintenance_Delete_Image: 'Delete Images',
      Maintenance_Delete_Reason: 'Delete Reason',
      Maintenance_Rejection_Note: 'Rejection Notes',
      Maintenance_Update_Cost: 'Update Cost',
      Maintenance_Generated_From: 'Generated From',
      Property_360_View: '360 Degree View',
      Maintenance_Date: 'Maintenance Date',
      Invoice_Tenant_Comment: 'Tenant Comment',
      Invoice_Reject_Notes: 'Reject Notes',
      Invoice_Reject_Rent_Payment: 'Reject Rent Payment',
      Invoice_Payment_Against_Invoice: 'Payment against Invoice No.',
      Invoice_Rejection_Commnet: 'Rejection Comment',
      Home_News: 'News',
      Partners_Heading: 'EGLOOZ PropTech platform signs a joint cooperation agreement with SADAD',
      Partners_Date: 'Manama, 01-09-2021',
      Partners_Joint_Agreement: 'EGLOOZ, the first Bahraini PropTech platform, has signed a joint cooperation agreement with SADAD,',
      Partners_Joint_Agreement_Link: 'to enable online rental collection',
      Partners_Joint_Agreement_Link_After: 'residential and commercial properties at ease.',
      Partners_Agreement_Result: `In this regard, Dr. Zakariya Ahmed Hejres, Chairman of the Board of Directors of EGLOOZ, said, "This agreement is the result of fruitful efforts between EGLOOZ platform and SADAD with the aim of supporting innovation and digital transformation in the real estate sector, as this service will remove the burden of manual, lethargic tasks and further automate to help reduce expenses, time and effort; ensuring highest quality and effective services to clients". Dr. Zakariya added, "We are pleased to sign this agreement in line with our strategic direction to grow within the FinTech ecosystem and work with various authorities and focus on national initiatives in line with the economic vision of The Kingdom and support the national plan for digitizing the real estate sector."`,
      Partners_Board_Directors: 'Dr. Mohammed Refaat Al-Kashef , a member of the Board of Directors of SADAD Bahrain, said, “This agreement aims to strengthen the cooperation of frameworks between SADAD and EGLOOZ; support the rise of PropTech and enablement of electronic payment services in addition to supporting Financial transfer operations and automate the mechanism of payment settlements.',
      Partners_CEO: 'Mr. Esam Al-Quradhi, Founder and CEO of EGLOOZ, further added, “Our partnership with SADAD comes within our goals to blend our PropTech solution along with financial inclusion and enhance the digital experience to serve clients in the real estate sector and to provide them with simpler and faster experience. This agreement will greatly contribute to stimulating the increase in electronic payments for real estate rental payments. ".',
      Partners_Eglooz_Partners: 'It is worth noting that the Eglooz platform offers an innovative concept in real estate technology, as it provides a safe and easy platform for real estate management and rent collection through the smart control panel and the digital rental book. Social media, instant communication between the property manager and the tenant, maintenance management and follow-up, sending notifications, signing lease contracts electronically, automatically renewing lease contracts, recording reminders, and extracting reports and statistics.',
      Partners_Our_Payment_Partner: 'Our Payment Partner',
      Tenants_Tab_Groups: 'Groups',
      Add_Tenant_Group: 'Add Group',
      EnterMainContactNumber: "Enter Main Contact Number",
      ContactPersonName: 'Contact Person Name',
      Group_Name: 'Group Name',
      Contact_Person: 'Contact Person',
      Contact_Number: 'Contact Number',
      Group_Properties: '#Properties',
      Add_Property_Tenant_Group: 'Add Property/Tenant To Group',
      Contact_Details: 'Contact Details',
      Advance: 'Advance',
      Living_Tenant_Details: 'Living Tenant Details',
      Selected_Group: 'Select Group',
      Property_Detail: 'Property Detail',
      Create_Invoice: 'Generate the first invoice of this contract (Separately)',
      Add_Invoice_Contract: 'Add invoice of this contract to the Next invoice of this group',
      Are_You_Sure_Group: 'Are you sure? You want to delete group',
      Group_Details: 'Group Details',
      List_Prop_Under_Tenant: 'List of Properties Under Group ',
      Living_Tenant_Name: 'Living Tenant Name',
      Main_Contact_Number: 'Main Contact',
      Name: 'Name',
      Contact_Person_Number: 'Contact Person Mobile',
      Main_Contact_No: 'Main Contact No.',
      Other_Description: 'Other Description',
      Group_Invoice: 'Date of Generating/Sending the Group Invoice',
      Identity_Details: 'Identity Details',
      EnglishNameIdentity: "English Name as on IDENTITY",
      ArabicNameIdentity: "Arabic Name as on IDENTITY (if applicable)",
      Contract_Details: 'Contract Details',
      Share_With_Living_Tenant: 'Share the invoice with the Living Tenant',
      Are_You_Sure_Property: "Are You Sure, You want to delete property?",
      Edit_Invoice_Date: 'Edit Invoice Date',
      Change_Invoice_Date: 'Change Invoice Date',
      Enter_Date_Between: 'Enter a day between (1-28)',
      User_Language: 'User Language',
      View_Group_Invoice: 'View Group Invoice',
      Home_ViewGroupDetails: 'View Group Details',
      Are_You_Sure_Invoice: "Are You Sure, You want to delete invoice?",
      Select_Bank_Account: 'Select Bank Account',
      Linked_Bank_Account: 'Linked Bank Account',
      building: 'Building',
      unit: 'Unit',
      tenant: 'Tenant',
      Paid: 'Paid',
      Download_Group_Invoice: 'Download Group Invoice',
      Add_Past_Invoice: 'Add Past Invoice',
      Soft_Delete: 'Soft Delete',
      recievePaymentConfirmation: 'Receive Payment Confirmation',
      delete: 'Delete',
      Accounts: 'Accounts',
      Accounts_Of_Tenants: 'Accounts of Tenants',
      Contracts: 'Contracts',
      contracts: 'Contracts',
      List_Contracts: 'List of Contracts',
      Contract_Start_Date: 'Contract Start Date',
      Invoice_Day: 'Invoice Day',
      Transaction_Date: 'Transaction Date',
      Commision: 'Commision',
      Reference: 'Reference',
      Mode_Of_Payment: 'Mode of Payment',
      viewList: 'View List',
      viewAccounts: 'View Accounts',
      add_rent_invoice: 'Add Rent Invoice',
      Reciepts: 'Receipts',
      receipts: 'Receipts',
      Payment_RecieptNo: 'Receipt No',
      Payment_RecieptId: 'Receipt ID',
      Payment_Receipt_Details: 'Receipt Deatils',
      Payment_Option_ViewReceipt: 'VIew Receipt',
      Payment_Option_DownloadReceipt: 'Download Receipt',
      Payment_Date: "Payment Date",
      Outstanding_Payments: 'Outstanding Payments',
      Advance_Amounts: 'Advance Amounts',
      Date_Of_Last_Payments: 'Date Of Last Payments',
      Equipments: 'Assets',
      Cost_Of_Equipments: 'Total Number of items',
      Current_Value_Of_Equipments: 'Current value of assets',
      Records: 'Records',
      Previous: 'Previous',
      Invoice_Generate_Now: 'Do you want to issue the invoice now?',
      Are_You_Sure_Cancel_Invoice: 'Are you sure? You want to cancel invoice',
      Add_New: 'Add New',
      Download_List: 'Download List',
      Add_New_Equipment: 'Add New Asset',
      inventoryId: 'Inventory ID',
      category: 'Category',
      subCategory: 'Sub Category',
      make: 'Make',
      modelNumber: 'Model Number',
      serialNumber: 'Serial Number',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      color: 'Color',
      condition: 'Condition',
      price: 'Price',
      deprecation: 'Deprication',
      dateOfPurchase: 'Date of Purchase',
      warrantyExpireDate: 'Warrenty Expire Date',
      purchasedFrom: 'Purchased From',
      vendorContact: 'Vendor Contact',
      isLiveTimeWarranty: 'Live Time Warrenty',
      details: 'Details',
      Add_Pic_Pdf_Warrenty: 'Add Picture/PDF of item/Warrenty',
      equipmentId: 'Asset ID',
      warranty: 'Warranty',
      repeat: 'Repeat',
      available: 'Available',
      addCategory: 'Add Category',
      addSubCategory: 'Add Sub Category',
      addCondition: 'Add Condition',
      occupancyRate: 'Occupancy Rate',
      nameEn: 'Name in English',
      nameAr: 'Name in Arabic',
      depreciation: 'Depreciation',
      listOfEquipments: 'List of Assets',
      equipmentList: 'Asset List',
      Added_Date: 'Added Date',
      Deleted_Images: 'Deleted Images',
      basicInformation: 'Basic Information',
      purchaseInformation: 'Purchase Information',
      equipmentDetails: 'Asset Details',
      editEquipment: 'Edit Asset',
      back: 'Back',
      Pic_Pdf_Warrenty: 'Picture/PDF of item/Warrenty',
      equipment: 'Asset',
      numberOfMonths: 'Number of Months',
      Tenant_Outstanding_Other_Payment: 'Outstanding Other Payment',
      Payments_Due_Payments: 'Due Rent Payments',
      Payments_Other_Payments: 'Other Payments',
      Outstanding_Invoice_Payments: 'Outstanding Invoiced Payments',
      Direct_Vacate: 'Direct Vacate',
      identity: 'Identity',
      Select_Start_Date: 'Select Start Date',
      Enter_Number_Of_Months: 'Enter Number Of Months',
      Adjust_Amount: '(You can adjust this amount manually)',
      Outstanding_Rent: 'Outstanding Rent',
      Tenant_Outstanding_Other_Invoice: 'Outstanding Other Invoice',
      Contract_Ends_In: 'Contract Ends In',
      Update_Tenant_Information: 'Update Tenant Information',
      Update_Tenant_Info: 'Any info you will change it will be updated everywhere',
      Update_Info: 'You can adjust this amount by editing the invoice',
      Expected_Life_Time: 'Expected Life Time',
      Expected_Life_Time_Placeholder: 'Please enter expected life in years',
      Purchase_Date: 'Purchase Date',
      Life_Time_In_Years: 'Life time in years',
      Item_In_Life: 'Item In Life',
      Contract_End_Date: 'Contract End Date',
      Charges: 'Charges',
      legal_Case_Start_Date: 'Legal Case Start Date',
      Lawyer_Office: 'Lawyer Office',
      Mobile_Contact: 'Mobile Contact',
      Email_Contact: 'Email Contact',
      Legal_Heading: 'Moving Tenant to Legal Case',
      Legal_Info: 'Moving Tenant to Legal Case means all Invoices of this Tenant will be moved from the Rent Book and it will be available in the Legal Page.',
      Send_To_Legal: 'Send To Legal',
      Legal_Cases_List: 'Legal Cases List',
      Legal_Invoices: 'Legal Invoices',
      Date_Of_Moving: 'Date of Moving',
      Total_Outstanding: 'Total OutStanding',
      Lawyer_Mobile: 'Lawyer Mobile',
      Update_Lawyer: 'Update Lawyer',
      Move_Tenant_Back: 'Move Tenant Back',
      Move: 'Move',
      Legal: 'Legal',
      Lawyer: 'Lawyer',
      AreYouSureYouWantToMoveCase: "Are you sure you want to move case?",
      tenantName: "Tenant Name as on ID",
      Update_Legal: 'Update legal Information',
      propertyOverview: 'Property Overview',
      Dashboard_Onboard: 'Onboard',
      Occupied_Units: 'Occupied Units',
      Vacant_Units: 'Vacant Units',
      Legal_Overview: 'Legal Overview',
      Legal_Cases:'Legal Cases',
      Invoices_In_Legal: 'Invoices in Legal',
      Legal_Number: 'Legal Case Number',
      Occupancy_rate: 'Occupancy Rate',
      Block_Number: 'Unit Number',
      Search_Option: 'Search Option',
      Enter_Unit_No: 'Enter Unit No',
      Current_Phone_Number: 'Current Phone Number',
      New_Phone_Number: 'New Phone Number',
    
    },
  },
};
