import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { TranslateService } from '../../shared/lang/translate.service';
import { HttpParams } from '@angular/common/http';
import { json } from 'body-parser';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { InternalServiceService } from 'src/app/internal/internal-service.service';

declare var $: any;

@Component({
    selector: 'app-manager-detail',
    templateUrl: './manager-detail.component.html',
    styleUrls: ['./manager-detail.component.css']
})
export class ManagerDetailComponent implements OnInit, OnDestroy {
    initiateRating = 0;
    showPhoneNumber: boolean = false;
    id: string;
    allData: any = [];
    pagination = { perPage: 10, page: 1, count: 0, max: 0, init: 1 };
    ratingData: any = [];
    link: any;
    sub: Subscription;
    show: boolean = false;
    viewTeam: boolean = false;
    teamClass = "team-members";

    constructor(private router: Router, public sanitizer: DomSanitizer, private route: ActivatedRoute,
        public translate: TranslateService,
        public admin: AdminService,
        public internalService:InternalServiceService) {
        this.id = route.snapshot.paramMap.get('id');

        this.sub = this.route.params.subscribe(params => {
            if (params.id) {
                this.getList();
            }
        });

        this.sub = this.admin.functionState.subscribe(data => {
            this.getList();
        });

    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    showNumber() {
        if (localStorage.getItem('adminToken')) {
            this.showPhoneNumber = true;
            this.link = "'tel:'+" + this.allData.data.callingCode + this.allData.data.phoneNumber;
        } else {
            this.link = this.sanitizer.bypassSecurityTrustResourceUrl('javascript:void(0);');
            this.showPhoneNumber = false;
            this.admin.toast('Please Login First', '', 'warning')
        }
    }


    ngOnInit() {
        this.getList();
    }

    getList() {
        const data = {
            userId: this.id,
            // page: this.pagination.page,
            // perPage: this.pagination.perPage,
        }
        this.admin.getData(apiUrl._getCompanyProfile, data).subscribe(res => {
            this.allData = res;
            this.show = true;
            // console.log(this.allData);
            if (this.allData.data.assignedTeamMember.length > 2) {
                this.teamClass = "team-members scroll-hidden";
            }
            // this.pagination.count = success.data.count;
            this.pagination.count = res.data.linkedProperty;
            // this.pagination.max = this.admin.paginationMaxValue(this.pagination.count, this.pagination.perPage);
        })
    }

    viewAll() {
        this.viewTeam = true;
        this.teamClass = "team-members scroll";
    }

    getReviewList() {
        let params = new HttpParams();
        params = params.append('userId', this.route.snapshot.paramMap.get('id'));
        params = params.append('page', JSON.stringify(this.pagination.page));
        params = params.append('perPage', JSON.stringify(this.pagination.perPage));

        this.admin.getData(apiUrl._reviewListing, params).subscribe(res => {
            this.ratingData = res.data.listing;
            console.log(res);
        });
    }

    readMore() {
        $('#readModal').show();
    }

    closeModal() {
        $('#readModal').hide();
    }


    showModal(type) {
        if (type) {
            this.allData.data.showData = true;
        }
        else {
            this.allData.data.showData = false;
        }
        $('#phoneModal').modal('show');
    }


    chat(id) {
        console.log(id);
        if (!localStorage.getItem('adminToken')) {
            this.admin.toast('Please Login First', '', 'warning')
        } else {
            if (localStorage.getItem('userType') == 'TENANT') {
                const data = {
                    'senderId': localStorage.getItem('tenantId'),
                    'receiverId': id,
                };
                this.admin.socket.emit('initiateChat', data);
                this.admin.chat = id;
                this.router.navigate(['tenants/chat']);
            } else {
                const data = {
                    'senderId': localStorage.getItem('landLordId'),
                    'receiverId': id,
                };
                this.admin.socket.emit('initiateChat', data);
                this.admin.chat = id;
                this.router.navigate(['landlord/chat']);
            }
            // this.router.navigate(['tenants/chat']);

        }
    }
}
