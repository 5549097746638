import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {TranslateService} from "../../shared/lang/translate.service";

@Component({
    selector: 'app-new-main-header',
    templateUrl: './new-main-header.component.html',
    styleUrls: ['./new-main-header.component.css']
})
export class NewMainHeaderComponent implements OnInit {

    temp2: boolean = true;
    image: any;

    constructor(private router: Router, public admin: AdminService, public translate: TranslateService) {
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp2 = false;
        }
        if (localStorage.getItem('profileImage')) {
            this.image = JSON.parse(localStorage.getItem('profileImage'));
        }

    }

    ngOnInit() {
    }


    check() {
        let type = localStorage.getItem("userType");
        this.router.navigate([type == 'LANDLORD' || type == 'MANAGER' || type == 'TEAM_MEMBER' ? '/landlord' : '/tenants']);
    }

    changeLanguage(lang) {
        if (this.translate.lang_code !== lang) {
            this.admin.lang = lang;
            localStorage.setItem('language', lang);
            window.location.reload();
        }
    }

}
