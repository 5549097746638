import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { LoginComponent } from './external/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { AuthGuard } from './services/auth.guard';
import { MainComponent } from './external/main/main.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './external/home/home.component';
import { Auth1Guard } from './services/auth1.guard';
import { ListComponent } from './external/list/list.component';
import { PropertyViewComponent } from './external/propertyView.component';
import { AboutUsComponent } from './external/about-us/about-us.component';
import { ContactUsComponent } from './external/contact-us/contact-us.component';
import { OtpComponent } from './external/otp/otp.component';
import { SignupComponent } from './external/signup/signup.component';
import { UploadDocumentComponent } from './external/upload-document/upload-document.component';
import { SetPasswordComponent } from './external/set-password/set-password.component';
import { ForgotPasswordComponent } from './external/forgot-password/forgot-password.component';
import { AgreementViewComponent } from './common/agreement-view/agreement-view.component';
import { HeaderComponent } from './external/header/header.component';
import { ManagerListComponent } from './external/manager-list/manager-list.component';
import { ManagerDetailComponent } from './external/manager-detail/manager-detail.component';
import { LandlordManagerComponent } from './external/landlord-manager/landlord-manager.component';
import { TenantComponent } from './external/tenant/tenant.component';
import { TermsAndConditionsComponent } from './external/terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './external/faq/faq.component';
import { PaymentStatusComponent } from './external/payment-status/payment-status.component';
import { PrivacyPolicyComponent } from './external/privacy-policy/privacy-policy.component';

import { LanguageGuard } from './services/language.guard';
import { SupportComponent } from './external/support/support.component';
import { NeedHelpComponent } from './external/need-help/need-help.component';
import { InvitationTestingComponent } from './common/invitation-testing/invitation-testing.component';
import { CovidHelpComponent } from './external/covid-help/covid-help.component';
import { BuisnessPlanComponent } from './external/covid-help/buisness-plan/buisness-plan.component';
import { RentInvoiceLinkComponent } from './external/rent-invoice-link/rent-invoice-link.component';
import { UploadCompanyDocumentsComponent } from './external/upload-company-documents/upload-company-documents.component';
import { LinkComponent } from './external/link/link.component';
import { PartnersComponent } from './external/partners/partners.component';

const routes: Routes = [
    // { path: '', component:AppComponent},
    // ,children:[
    //   { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '', component: HomeComponent, pathMatch: 'full' },

    { path: 'invite-flow', component: InvitationTestingComponent, pathMatch: 'full' },
    { path: 'link', component: LinkComponent },

    { path: 'home', component: HomeComponent },
    { path: 'partners', component: PartnersComponent },
    { path: 'together-against-covid19', component: CovidHelpComponent },
    { path: 'buisness-plan', component: BuisnessPlanComponent },
    { path: 'contact/:language', component: SupportComponent },
    { path: 'need-help/:language', component: NeedHelpComponent },
    // { path: 'i/:id', component: RentInvoiceLinkComponent },

    // { path: '', component: HeaderComponent, children:[]},
    { path: 'login', component: LoginComponent },
    // { path: 'verified', component: OtpComponent },
    {
        path: 'signUp', component: MainComponent, children: [
            { path: '', component: SignupComponent },
            { path: 'verified', component: OtpComponent },
            { path: 'uploadDocument', component: UploadDocumentComponent },
            { path: 'upload-company-documents', component: UploadCompanyDocumentsComponent },
        ]
    },
    // { path: 'uploadDocument', component: UploadDocumentComponent },
    {
        path: 'forgotPassword', component: MainComponent, children: [
            { path: '', component: ForgotPasswordComponent },
            { path: 'verified', component: OtpComponent },
            { path: 'changePassword', component: SetPasswordComponent },
        ]
    },
    // { path: 'contactUs', component: AboutUsComponent, data:{text:'contactUs',value:'2'} },
    { path: 'contactUs', component: ContactUsComponent, data: { text: 'contactUs', value: '3' } },
    {
        path: 'contactUs/:language',
        canActivate: [LanguageGuard],
        component: ContactUsComponent,
        data: { text: 'contactUs', value: '3' }
    },
    { path: 'aboutUs', component: AboutUsComponent, data: { text: 'aboutUs', value: '3' } },
    {
        path: 'aboutUs/:language',
        canActivate: [LanguageGuard],
        component: AboutUsComponent,
        data: { text: 'aboutUs', value: '3' }
    },
    { path: 'agreementView/:propertyId/:agreementId', component: AgreementViewComponent },
    // { path: 'agreementView/:propertyId/:agreementId/:tenantId', component: AgreementViewComponent},
    { path: 'propertyView', component: PropertyViewComponent },
    { path: 'managerList', component: ManagerListComponent },
    { path: 'howItWork', component: LandlordManagerComponent },
    { path: 'tenant', component: TenantComponent },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        data: { text: 'terms-and-conditions', value: '1' }
    },
    {
        path: 'terms-and-conditions/:language', canActivate: [LanguageGuard],
        component: TermsAndConditionsComponent,
        data: { text: 'terms-and-conditions', value: '1' }
    },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { text: 'Privacy Policy', value: '6' } },
    {
        path: 'privacy-policy/:language',
        canActivate: [LanguageGuard],
        component: PrivacyPolicyComponent,
        data: { text: 'Privacy Policy', value: '6' }
    },
    { path: 'faq', component: FaqComponent, data: { text: 'faq', value: '3' } },
    { path: 'managerDetail/:id', component: ManagerDetailComponent },
    { path: 'payment-status', component: PaymentStatusComponent },
    { path: 'explore', loadChildren: './explore/explore.module#ExploreModule' },
    // ]},
    // {path: 'terms-and-conditions/en', component: ListComponent, data: {text: 'terms&Condition', value: '1', language: 'en'}},
    // {path: 'help-and-suports/en', component: ListComponent, data: {text: 'help&Support', value: '5', language: 'en'}},
    // {path: 'about-us/en', component: ListComponent, data: {text: 'aboutUs', value: '3', language: 'en'}},
    // {path: 'contact-us/en', component: ListComponent, data: {text: 'contactUs', value: '2', language: 'en'}},
    // {path: 'terms-and-conditions/ar', component: ListComponent, data: {text: 'terms&Condition', value: '1', language: 'ar'}},
    // {path: 'help-and-suports/ar', component: ListComponent, data: {text: 'help&Support', value: '5', language: 'ar'}},
    // {path: 'about-us/ar', component: ListComponent, data: {text: 'aboutUs', value: '3', language: 'ar'}},
    // {path: 'contact-us/ar', component: ListComponent, data: {text: 'contactUs', value: '2', language: 'ar'}},
    { path: 'landlord', canActivate: [Auth1Guard], loadChildren: './internal/internal.module#InternalModule' },
    // { path: 'business', loadChildren: './business/business.module#BusinessModule' },
    { path: 'tenants', canActivate: [AuthGuard], loadChildren: './tenants/tenants.module#TenantsModule' },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled',
        })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
