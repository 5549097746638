import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscriber } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-expense-card',
  templateUrl: './expense-card.component.html',
  styleUrls: ['./expense-card.component.css']
})
export class ExpenseCardComponent implements OnInit, OnDestroy {
  totalExpenses: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter: any;
  date: Date = new Date();
  @Input() pagination: any;
  subscription = new Subscriber();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    public translate:TranslateService,
    public admin: AdminService) { }

  ngOnInit() {
    if (this.model.length) {
      if (this.model.listing.length) {
        this.model.listing.forEach(element => {
          if (element.expenseData) {
            this.totalExpenses = this.totalExpenses + element.expenseData.amount;
          }
        });
      }
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setTotalExpensesRent(model) {
    let rent = 0;
    if (Object.keys(model).length) {
      if (model.listing.length) {
        model.listing.forEach(element => {
          if (element.expenseData) {
            rent = rent + element.expenseData.amount;
          }
        });
      }
    }
    return rent;
  }



  getAmount(data) {
    let Amount = 0;
    if(data){
      if (data.commentAmount) {
        if (data.commentAmount.length) {
          data.commentAmount.forEach(element => {
            if (element.amount) {
              Amount = Amount + element.amount;
            }
          })
        }
      }
      if (Amount > data.amount) {
        return Amount;
      } else {
        return data.amount;
      }      
    }
  }
}
