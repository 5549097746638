import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from "../../shared/lang/translate.service";
import { apiUrl } from '../../global/global';
import { filter } from 'rxjs/operators';
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Subscriber, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-payment-filter',
    templateUrl: './payment-filter.component.html',
    styleUrls: ['./payment-filter.component.css']
})
export class PaymentFilterComponent implements OnInit, OnDestroy {

    @ViewChild('filter') showFilter: ElementRef;
    @ViewChild('filters') filters: ElementRef;
    @Input() type: any;
    date = new Date();
    filterStatus = {
        propertyName: '',
        tenantName: '',
        ownerName: '',
        propertyId: '',
        startDate: null,
        endDate: null,
        receiptNo: '',
        invoiceNo: '',
        unitNo: '',
        searchBy: 'ADDRESS'
    };
    maxDate: Date = new Date();

    minDate;
    maxDate1: Date;
    userSuggestions: any[] = [];
    propData: any[] = [];
    tenantsPropertyAddress: any = [];
    subscription = new Subscriber();
    userType: string = ''

    typeFilter: number;
    filterValue: any = {
        Payment_ReceivedPayments: 2,
        Payment_OverduePayments: 3,
        Payment_InvoicedPayments: 4,
        Payment_NotInvoicedPayments: 6,
        Payment_NextMonthInvoice: 7,
        Payment_NextMonthPayment: 8,
        Payment_CarryoverInvoice: 9,
        Payment_CarryoverPayment: 10,
    };

    @Output() messageEvent = new EventEmitter<string>();

    @Input()
    public set updateFilter(updateFilter: any) {

        // YOU CAN MODIFY THE hasStage HERE
        // BEFORE ASSIGNING TO mainPage
        this.filterStatus.propertyName = updateFilter.propertyName
        this.filterStatus.propertyId = 'true'
    }

    constructor(public admin: AdminService,
        public translate: TranslateService,
        private api: InternalServiceService,
        private sharedService: SharedService,
        private router: ActivatedRoute
    ) {
        this.userType = localStorage.getItem('userType')
        var lastDate1 = this.getLastDate(new Date().getFullYear(), (new Date().getMonth() + 1));
        this.maxDate = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + lastDate1);
        this.maxDate1 = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    }


    removeFilter(event) {
        console.log(event)
        if (event == 'property') {
            this.filterStatus = {
                propertyName: '',
                propertyId: '',
                tenantName: '',
                ownerName: '',
                startDate: this.filterStatus.startDate,
                endDate: this.filterStatus.endDate,
                invoiceNo: '',
                receiptNo: '',
                unitNo: '',
                searchBy: 'ADDRESS'
            };
            this.messageEvent.emit(JSON.stringify(this.filterStatus));
        } else {
            this.filterStatus = {
                propertyName: this.filterStatus.propertyName,
                propertyId: this.filterStatus.propertyId,
                tenantName: this.filterStatus.tenantName,
                ownerName: this.filterStatus.ownerName,
                startDate: null,
                endDate: null,
                invoiceNo: '',
                receiptNo: '',
                unitNo: '',
                searchBy: 'ADDRESS'
            };
        }
        this.messageEvent.emit(JSON.stringify(this.filterStatus));
    }


    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {

        this.subscription.add(this.sharedService.filter$.subscribe(res => {
            if (res) {
                this.clearAll();
            }
        }))

        this.admin.paymentFilterState.subscribe(data => {
            if (data) {
                this.showFilter.nativeElement.click();
            }
        });

        this.getTenantProp();


        this.subscription.add(this.router.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            if (params['status']) {
                const key = params['status'];
                this.typeFilter = this.filterValue[key];
            }

            console.log(this.typeFilter)
            if (this.typeFilter != 7 && this.typeFilter != 8 && this.typeFilter != 9 && this.typeFilter != 10) {
                var currentMonthDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + 1;
                this.minDate = new Date(currentMonthDate);
            } else {
                if (this.typeFilter != 7 && this.typeFilter != 8) {
                    var oldDate = ((new Date().getFullYear()) - (10)) + '-' + (new Date().getMonth() + 1) + '-' + 1;
                    console.log(oldDate)
                    this.minDate = new Date(oldDate);
                    var lastDate = this.getLastDate(new Date().getFullYear(), (new Date().getMonth() + 1));
                    this.maxDate = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + lastDate);
                    this.maxDate1 = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + lastDate);
                } else {
                    var nextMonthDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 2) + '-' + 1;
                    this.minDate = new Date(nextMonthDate);
                    var lastDate = this.getLastDate(new Date().getFullYear(), (new Date().getMonth() + 1));
                    this.maxDate = new Date(new Date().getFullYear() + '-' + (((new Date().getMonth() + 2) < 12) ? (new Date().getMonth() + 2) : (1)) + '-' + lastDate);
                    this.maxDate1 = new Date(new Date().getFullYear() + '-' + (((new Date().getMonth() + 2) < 12) ? (new Date().getMonth() + 2) : (1)) + '-' + lastDate);
                }
            }

            if(this.type === 'reciept') {
                    this.minDate = null
            }
        }));
    }

    getLastDate(y, m) {
        return new Date(y, m + 1, 0).getDate();
    }

    clearAll() {
        this.filterStatus = {
            propertyName: '',
            propertyId: '',
            tenantName: '',
            ownerName: '',
            startDate: null,
            endDate: null,
            invoiceNo: '',
            receiptNo: '',
            unitNo: '',
            searchBy: 'ADDRESS'
        };
        // startDate: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
        // endDate: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
        this.messageEvent.emit(JSON.stringify(this.filterStatus));
    }


    onSearch(e) {

    }

    getTenantProp() {

        let url = apiUrl._getCurrentTenantPropertyList

        if (this.userType === 'TENANT') {
            url = apiUrl._getTenantsProperty + '?inProgress=3'
        }

        this.api.getList({}, url).subscribe(success => {

            const resData = success.data.property;

            for (const item of resData) {
                const index = this.propData.findIndex(property => property.propName.toLowerCase() == item.propertyName.toLowerCase());
                if (index != -1) {
                    const address = { _id: item._id, userId: item.userId, address: item.address };
                    this.propData[index].propertyData.push(address);
                } else {
                    const data = {
                        propName: item.propertyName,
                        propertyData: [{ _id: item._id, userId: item.userId, address: item.address }]
                    };
                    this.propData.push(data);
                }
            }
        });
    }

    selectProperty(event) {
        const index = this.propData.findIndex(x => x.propName === event.target.value);
        this.tenantsPropertyAddress = [];
        this.tenantsPropertyAddress = this.propData[index].propertyData;
    }

    selectAddress(event) {
        const index = this.tenantsPropertyAddress.findIndex(x => x._id === event.target.value);
        const data = this.tenantsPropertyAddress[index];
        // this.userForm.patchValue({
        //     ownerId: data.userId,
        //     propertyId: data._id
        // });
    }


    // filterDate () {
    //   if (+new Date(this.filterStatus.startDate) < +new Date(this.filterStatus.endDate)) {
    //     this.messageEvent.emit(JSON.stringify(this.filter));
    //   } else {
    //   }
    // }

    clearDate(key, value1, value2) {
        this.filterStatus[key] = new Date(this.date.getFullYear(), this.date.getMonth() + value1, value2);
        this.messageEvent.emit(JSON.stringify(this.filterStatus));
    }


    searchUsers(event) {
        const data: any = {
            page: 1,
            perPage: 100,
            type: 6,
        };

        if (event && event != '') {
            data.propertyName = event;
        }
        this.admin.getData(apiUrl._getAllProperties, data).subscribe(res => {
            console.log(res.data);
            this.userSuggestions = [];
            const dataList: any[] = res.data.property;
            dataList.forEach(item => {
                const obj: any = {};
                obj.id = item.property;
                obj.name = item.propertyName;
                this.userSuggestions.push(obj);
            });
        });
    }

    clickContinue() {
        if (this.filterStatus['startDate'] != null || this.filterStatus['endDate'] != null) {
            if (this.filterStatus['startDate'] == null || this.filterStatus['endDate'] == null) {
                return false;
            }
        }
        let request = Object.assign({}, this.filterStatus);
        if (request.propertyId) {
            if (request.propertyId == 'true') {
                console.log('test');
                request['all'] = true
            } else {
                this.tenantsPropertyAddress.forEach(element => {
                    if (element._id == request.propertyId) {
                        request['address'] = element.address;
                    }
                });
            }
        }
        if (request.propertyName['name']) {
            request.propertyName = request.propertyName['name'];
        }

        this.filters.nativeElement.click();
        console.log(request);
        this.messageEvent.emit(JSON.stringify(request));
    }
}
