import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';

@Component({
  selector: 'app-mark-fav',
  templateUrl: './mark-fav.component.html',
  styleUrls: ['./mark-fav.component.css']
})
export class MarkFavComponent implements OnInit {

  @Input() key: any;
  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

  markProperty() {
    this.admin.showLoader = false;
    let data = {
      property: this.key.property,
      isMarkingFavourite: !this.key.favourite
    }
    this.admin.postData(apiUrl._markFavUnFavProperty,data).subscribe( res => {
      this.key.favourite = !this.key.favourite;
      // this.messageEvent.emit(JSON.stringify({index:i,status:'fav',value:!this.key.favourite}))
    },err => {
      this.key.favourite = this.key.favourite;
      // this.messageEvent.emit(JSON.stringify({index:i,status:'fav',value:this.key.favourite}))
    });
  }


}
