import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import {TranslateService} from '../../shared/lang/translate.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

  constructor(public translate: TranslateService) {}

  ngOnInit() {
  }

}
