import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {permission} from '../shared/models/general.model';
import {AdminService} from './admin.service';
import {TranslateService} from "../shared/lang/translate.service";

@Injectable({
    providedIn: 'root'
})
export class LanguageGuard implements CanActivate {
    constructor(
        private router: Router,
        private translate: TranslateService,
        private admin: AdminService,
    ) {

    }


    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot) {
        console.log(activatedRouteSnapshot.params);

        const data: any = activatedRouteSnapshot.params;
        if (data.language) {
            if (this.translate.lang_code !== data.language) {
                this.admin.lang = data.language;
                localStorage.setItem('language', data.language);
                setTimeout(() => {
                    window.location.reload();
                }, 200);
                return true;
            } else {
                return true;
            }
        }
    }
}
