import { Component, OnInit } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-agreement-view',
  templateUrl: './agreement-view.component.html',
  styleUrls: ['./agreement-view.component.css']
})
export class AgreementViewComponent implements OnInit {

  propertyId: string;
  agreementId: string;
  template: string = ''
  filterValue: string = '';
  tenantId: string;

  constructor(activeRoute: ActivatedRoute, private router: Router, public admin: AdminService) {
    this.propertyId = activeRoute.snapshot.paramMap.get('propertyId');
    this.agreementId = activeRoute.snapshot.paramMap.get('agreementId');
    this.tenantId = activeRoute.snapshot.paramMap.get('tenantId');
    this.filterValue = activeRoute.snapshot.data.title
  }

  ngOnInit() {
    this.getAgreement();
    this.getPdfAgreement();
  }


  getPdfAgreement() {
    const data = {
      property: this.propertyId,
      agreement: this.agreementId,
      isPdf: true,
    };
    this.admin.getData(apiUrl._getAgreement, data).subscribe(success => {
      console.log(success.data);

    });
  }

  getAgreement() {
    const data = {
      property: this.propertyId,
      agreement: this.agreementId,
    };
    // if(this.tenantId) {
    //   data['tenantId'] = this.tenantId;
    // }
    this.admin.getData(apiUrl._getAgreement, data).subscribe(success => {
      this.template = success.data.html;
    });
  }
  // getList() {
  //   let data ={
  //     id:this.id,
  //     type:this.filterValue.replace(' ','_')
  //   }
  //   this.admin.getData(apiUrl._getAgreement,data)
  //     .subscribe(res => {
  //       let data = res.data.serviceType[0];
  //       // this.userForm.patchValue({type:data['type']});
  //       if(data['template']) {
  //         this.template = data['template'];
  //       }
  //     }
  //   );
  // }
}
