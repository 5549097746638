import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    modalRef: BsModalRef;
    status: string = 'login';
    image: any;
    @ViewChild('login') login: any;
    temp: Boolean = true;
    sub;

    constructor(private modalService: BsModalService, private router: Router, public admin: AdminService, private route: ActivatedRoute) {
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp = false;
        }
    }

    ngOnInit() {
        this.admin.loginState.subscribe(data => {
            this.temp = data;
        })
        this.sub = this.route.queryParams.subscribe(params => {
            if (params['status']) {
                if (localStorage.getItem('adminToken')) {
                    this.router.navigate(['/']);
                } else {
                    setTimeout(() => {
                        this.receiveMessage(params['status']);
                    }, 1000);
                }
            }
        });

        if (localStorage.getItem('profileImage')) {
            this.image = JSON.parse(localStorage.getItem('profileImage'));
        }
    }

    receiveMessage(event) {

        if (event === 'close') {
            this.login.hide();
            this.status = 'login';
            localStorage.setItem('userDocument', 'false');
            localStorage.removeItem('adminToken');
        } else if (event === 'closeUpload') {
            this.login.hide();
            localStorage.setItem('userDocument', 'true');
            this.status = 'login';
            this.router.navigate(['']);
        } else {
            this.status = event;
            this.login.show();
        }
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    check() {
        let type = localStorage.getItem("userType");
        this.router.navigate([type == 'LANDLORD' || type == 'MANAGER' || type == 'TEAM_MEMBER' ? '/landlord' : '/tenants']);
    }

    setLanguage(status) {
        this.admin.lang = status;
        localStorage.setItem('language', status);
        localStorage.setItem('lang', status);
    }

}
