import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { TranslateService } from "../../shared/lang/translate.service";
import { stat } from 'fs';

@Component({
    selector: 'app-vacate-view',
    templateUrl: './vacate-view.component.html',
    styleUrls: ['./vacate-view.component.css']
})
export class VacateViewComponent implements OnInit {

    // @Input() allData: any;
    // @Input() typeFilter: any;
    // @Input() status: string;
    data: any = {};
    check: any = { value: false, text: '' };
    rejectReason: string = '';
    @ViewChild('close1') close1: ElementRef;
    @ViewChild('vdCheckShow') vdCheckShow: ElementRef;
    @Output() messageEvent = new EventEmitter<string>();
    allData: any[] = [];
    pagination: any = { perPage: 10, page: 1, count: 0, max: 0, init: 1 };
    subscription: Subscription;
    status: string = 'receiverId';
    paymentData: any;
    typeFilter: number;
    vacateId: any;
    filterValue: any = {
        sent: 1,
        received: 2,
    };
    sub;
    routeValue: string;
    userType: any;
    userId: any;
    show;


    constructor(private router: Router, private route: ActivatedRoute, public admin: AdminService, public translate: TranslateService) {
        this.routeValue = route.snapshot.data.link;
        this.userType = localStorage.getItem('userType');
        this.userId = localStorage.getItem('userId');
    }

    ngOnInit() {
        this.subscription = this.admin.functionState.subscribe(data => {
            this.pagination.page = data;
            this.getRequest();
        });
        this.sub = this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            if (params['status']) {
                this.typeFilter = this.filterValue[params['status']];
                this.pagination.page = 1;
            } else {
                this.typeFilter = this.filterValue['sent'];
            }
            this.status = this.typeFilter === 1 ? 'receiverId' : 'senderId'
            this.getRequest();
        });

        window.scrollTo(0, 0);
    }

    checkTeam(data) {
        let status = false;
        if (data.assignedTeamMember.length) {
            data.assignedTeamMember.forEach(element => {
                console.log(element);
                if (element == this.userId) {
                    status=true;
                }
            });
        }

        return status;
    }


    getRequest() {
        const data = {
            page: this.pagination.page,
            perPage: '10',
            type: this.typeFilter
        };
        console.log(data);
        this.admin.getData(apiUrl._getVacateRequest, data).subscribe(success => {
            this.allData = success.data.listing;
            this.pagination.count = success.data.count;
            this.pagination.max = this.admin.paginationMaxValue(this.pagination.count, this.pagination.perPage);
        });
    }

    makePayment() {
        const data = {
            vacateId: this.vacateId
        };
        this.admin.putData(apiUrl._paidVacantRequest, data).subscribe(success => {
            window.open(success.data.paymentUrl, '_self');
            this.getRequest();
        });
    }

    // signDoc(){
    //   if(this.userType != 'TEAM_MEMBER'){
    //     this.submit(true)
    //   }
    //   else {
    //     swal({
    //           title: 'You dont have access for this action',
    //           type: 'warning',
    //           showCancelButton: false,
    //           showConfirmButton: true,
    //           cancelButtonColor: '#ed5565',
    //         }).then((result) => {
    //           if (result.value) {
    //             // this.admin.logout();
    //           }
    //         })
    //   }
    // }

    multiplyFun(x, y) {
        return parseInt(x) * parseInt(y);
    }

    changFilter(status) {
        if (this.typeFilter != this.filterValue[status]) {
            this.router.navigate(['/' + this.routeValue + '/vacateRequest'], { queryParams: { status: status } });
        }
    }

    openInvitation(data) {
        this.paymentData = data;
        this.vacateId = data._id;
    }


    submit(x, status) {

        if (x) {
            this.data = x;
        }
        console.log(this.data);
        if (this.admin.control.vacate.canAcceptOrReject) {
            let data = {
                vacateId: this.data._id
            }
            if (!status) {
                data['rejectReason'] = this.rejectReason
            } else {
                if (this.rejectReason != '') {
                    data['advancePeriod'] = this.rejectReason
                }
            }
            let url = status ? apiUrl._acceptVacantRequest : apiUrl._rejectVacantRequest;
            this.admin.putData(url, data).subscribe(success => {
                this.check.text = this.rejectReason;
                this.rejectReason = '';
                this.check.value = status;
                this.getRequest();
                this.close1.nativeElement.click();
                this.vdCheckShow.nativeElement.click();
                this.messageEvent.emit('true');
            });
        } else {
            swal({
                title: 'You dont have access for this action',
                type: 'warning',
                showCancelButton: false,
                showConfirmButton: true,
                cancelButtonColor: '#ed5565',
            }).then((result) => {
                if (result.value) {
                    // this.admin.logout();
                }
            });
        }


    }

    checkABC(x) {
        this.data = x;

        console.log(this.data);

        // this.show = 'pending';
        this.rejectReason = ''
    }

}
