import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { apiUrl } from '../../global/global';
import { AdminService } from '../../services/admin.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ExternalService } from '../external.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "../../shared/lang/translate.service";
import { Subscriber } from 'rxjs';

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.css']
})

export class OtpComponent implements OnInit {
    showError = false;
    userForm: FormGroup;
    @Output() messageEvent = new EventEmitter<string>();

    changeNumber: Boolean = false;
    subscription = new Subscriber();
    constructor(
        public admin: AdminService,
        private api: ExternalService,
        private router: Router,
        private fb: FormBuilder,
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute
    ) {
        const data = JSON.parse(localStorage.getItem('OTPData'));
        console.log(data);


        this.api.otpId = data.id;
        this.PhoneNumber = data.phoneNumber.substr(data.phoneNumber.length - 4);
        this.userForm = this.fb.group({
            otp: ['', Validators.required],
        });


        this.subscription.add(this.activatedRoute.queryParams.subscribe((params) => {
            if (params.changeNumber) {
                this.changeNumber = true;
                if (!this.userForm.get('password')) {
                    this.userForm.registerControl('password', new FormControl('', [Validators.compose([Validators.required])]));
                }
            } else {
                if (this.userForm.get('password')) {
                    this.userForm.removeControl('password');
                }
            }
        }))

    }

    PhoneNumber: string = '';

    ngOnInit() {


    }

    register() {
        this.api.submitForm(this.api.registerData, apiUrl._registerUser)
            .subscribe(res => {
                this.admin.toast('Sign up Successfull!', '', 'success');
                localStorage.setItem('adminToken', res.data.accessToken);
                localStorage.setItem('loginData', JSON.stringify(res.data));
                localStorage.setItem('userType', res.data.userType);
                localStorage.setItem('userDocument', 'false');
                localStorage.setItem('profileImage', JSON.stringify(res.data.image));
                this.api.userType = res.data.userType;
                this.router.navigate(['/signUp/uploadDocument']);
                localStorage.removeItem('OTPData')
            });
    }


    submitChangedPhoneNumber(fd) {
        const data = {
            'otp': this.userForm.value.otp,
            'password': this.userForm.value.password
        };
        this.api.putData(data, apiUrl._editPhoneVerify).subscribe(res => {
            this.admin.toast('Number Changed successfully', '', 'success');
        });
    }

    onSubmit(form) {
        if (form.valid) {
            if (this.changeNumber) {
                this.submitChangedPhoneNumber(form)
            }
            else {
                const data = {
                    'otpId': this.api.otpId,
                    'otp': this.userForm.value.otp
                };
                this.api.submitForm(data, apiUrl._verifyOtp).subscribe(res => {
                    if (this.router.url === '/forgotPassword/verified') {
                        this.router.navigate(['/forgotPassword/changePassword']);
                    } else {
                        this.register();
                    }
                });
            }
        } else {
            this.showError = true;
            setTimeout(() => {
                this.showError = false;
            }, 5000);
            return;
        }
    }

    keytab(event) {
        let element = event.srcElement.nextElementSibling; // get the sibling element
        if (element == null) {
            return;
        } else {
            element.focus();   // focus if not null
        }
    }

    resendOtp() {
        const getData = JSON.parse(localStorage.getItem('OTPData'));
        const data = {
            'fullNumber': getData.phoneNumber,
            'userType': getData.userType
        };

        this.api.submitForm(data, apiUrl._sendOtp)
            .subscribe(res => {
                console.log(res.data);
                this.api.otpId = res.data._id;

                this.admin.toast('OTP resend successfully', '', 'success');

                // this.api.redirectStatus = 'signup';
                // this.admin.showAlert('Sign up successfull', '');
                // this.messageEvent.emit('otp');
                // this.router.navigate(['/signUp/verified']);
            });
    }
}
