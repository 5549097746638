import { Component, OnInit, Input } from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-table-property-data',
  templateUrl: './table-property-data.component.html',
  styleUrls: ['./table-property-data.component.css']
})
export class TablePropertyDataComponent implements OnInit {
  @Input() allData: any;

  constructor(public admin: AdminService,) { }

  ngOnInit() {
  }

}
