import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-covid-help',
  templateUrl: './covid-help.component.html',
  styleUrls: ['./covid-help.component.css']
})
export class CovidHelpComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public router: Router,
  ) { }


  digitalTools = [
    { img: 'assets/images/img_1.png', description: this.translate.lang.Covid_Digital_Tools_Image_1, link: this.translate.lang.Covid_Digital_Tools_Contact },
    { img: 'assets/images/img_2.png', description: this.translate.lang.Covid_Digital_Tools_Image_2, link: this.translate.lang.Covid_Digital_Tools_Contact },
    { img: 'assets/images/img_3.png', description: this.translate.lang.Covid_Digital_Tools_Image_3, link: this.translate.lang.Covid_Digital_Tools_Contact },
    { img: 'assets/images/img_4.png', description: this.translate.lang.Covid_Digital_Tools_Image_4, link: this.translate.lang.Covid_Digital_Tools_Contact },
  ]

  ngOnInit() {
  }

}
