import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { TranslateService } from "../../shared/lang/translate.service";
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-booking-property',
  templateUrl: './booking-property.component.html',
  styleUrls: ['./booking-property.component.css']
})
export class BookingPropertyComponent implements OnInit {

  id: string;
  tenantId: string;
  allData: any = {};
  template = { template: '', name: '' };
  show: boolean;
  @ViewChild('accept') accept: ElementRef;
  userType;
  showPayment = true;
  public subscribe = new Subscriber();

  constructor(public admin: AdminService, private router: Router, private route: ActivatedRoute, public translate: TranslateService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.show = route.snapshot.data.show;
    this.userType = localStorage.getItem('userType');

    this.subscribe.add(this.admin.rentalSuccess$.subscribe(res => {
      if (Object.keys(res).length) {
        console.log(res);
        if(res.value){
          this.showPayment = false;
        }
      }
    }))
  }


  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    const data = {
      'requestId': this.id
    };
    this.admin.getData(apiUrl._getPropertyStatusDetails, data).subscribe(success => {
      this.allData = success.data;
            if (this.allData.isAccepted && this.allData.property.agreement.length && this.allData.tenantAgreementDone) {
        this.show = false
      }
    });
  }

  getPdf(x) {
    window.open(this.admin.apiEndpoint + x.image.original, '_blank');
  }

  signDoc() {
    if (this.userType != 'TEAM_MEMBER') {
      this.router.navigate(['/landlord/signAgreement/' + this.id]);
    }
    else {
      swal({
        title: 'You dont have access for this action',
        type: 'warning',
        showCancelButton: false,
        showConfirmButton: true,
        cancelButtonColor: '#ed5565',
      }).then((result) => {
        if (result.value) {
          // this.admin.logout();
        }
      })
    }
  }

  acceptRequest() {
    let temp = {
      requestId: this.allData._id,
      moveDate: (+new Date(this.allData.moveDate)),
      contractPeriod: this.allData.contractPeriod
    };
    this.admin.putData(apiUrl._acceptRequest, temp).subscribe(success => {

      this.getList();
      setTimeout(() => {
        this.accept.nativeElement.click();
        this.admin.showAlert('Request Accepted', '')
      }, 500);
    });
  }

}
