import { Component, OnInit } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../shared/lang/translate.service';
import { InternalServiceService } from '../../internal/internal-service.service';
import { Subscriber } from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    userDetail: any = {};
    routeValue: string;
    typeData: any[] = [];

    id: string;
    status = false;
    userId: any = localStorage.getItem('userId');
    USERTYPE: any = localStorage.getItem('userType');
    private subscription = new Subscriber();

    propertyId;
    property;
    constructor(
        public admin: AdminService,
        private route: ActivatedRoute,
        private api: InternalServiceService,
        private router: Router,
        public translate: TranslateService
    ) {
        this.routeValue = route.snapshot.data.link;
        this.status = route.snapshot.data.status;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getDetail();
        window.scrollTo(0, 0);
        this.getProperty();

        this.subscription.add(this.route.queryParams.subscribe(params => {
            this.propertyId = params.id
        }))

    }

    getDetail() {
        const data = {
            'userId': this.id
        };
        this.admin.getData(apiUrl._getUserDetail, data).subscribe(success => {
            console.log(success.data);
            this.userDetail = success.data;
            this.property = this.userDetail.property.find(x => x._id == this.propertyId);
        });
    }

    getPdf(data) {
        console.log(data)
        window.open(this.admin.apiEndpoint + data.original, '_blank');
    }



    getProperty() {
        this.admin.showLoader = false;
        this.api.getList({ type: 'Identity' }, apiUrl._getPropertyType).subscribe(success => {
            if (success.data.propertyType[0])
                this.typeData = success.data.propertyType[0].propertyData;
        });
    }

    emitChat() {
        console.log(this.id);

        const data = {
            'senderId': localStorage.getItem('landLordId'),
            'receiverId': this.id,
        };
        this.admin.socket.emit('initiateChat', data);
        this.admin.chat = this.id;
        this.router.navigate(['landlord/chat']);
    }


}
