import { Component, OnInit, Input } from '@angular/core';
import { Subscriber } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-property-statement',
  templateUrl: './property-statement.component.html',
  styleUrls: ['./property-statement.component.css']
})
export class PropertyStatementComponent implements OnInit {
  totalRent: number = 0;
  totalOccupiedRent: number = 0;
  totalCollectedRent: number = 0;
  totalExpenses: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter: any;
  @Input() pagination: any;
  date: Date = new Date();

  subscription = new Subscriber();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public admin: AdminService,
    public translate:TranslateService,
  ) { }

  ngOnInit() {
  }

  calculateTotalIncome(data) {
    let count = 0;
    if (data.listing) {
      for (let list of data.listing) {
        if (list.invoiceData) {
          if (list.invoiceData.totalAmount) {
            count = count + list.invoiceData.totalAmount;
          }
        }
      }
    }
    return count;
  }

  calculateTotalExpense(data) {
    let count = 0;
    if (data.listing) {
      for (let list of data.listing) {
        if (list.expenseData) {
          if (list.expenseData.amount) {
            count = count + list.expenseData.amount;
          }
        }
      }
    }
    return count;
  }

  calculateNetIncome(data) {
    let count = 0;
    let expense = 0;
    let total = 0;
    if (data.listing) {
      for (let list of data.listing) {
        if (list.expenseData) {
          if (list.expenseData.amount) {
            expense = expense + list.expenseData.amount;
          }
        }
      }
      count = data.invoiceIncome - expense;
    }
    return count;
  }

  checkDate(data) {
    let value = this.translate.lang.Requests_AddForm_No;
    if (data) {
      if(data.flag==2){
        let y = +new Date();
        if (+new Date(data.paidDate) > y) {
          value = this.translate.lang.Requests_AddForm_Yes
        }
      }
    }
    return value; 
  }

}