import {Injectable, LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';



import {locale as en} from './app.en';
import {locale as ar} from './app.ar';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TranslateService {

    lang_code: any;
    lang: any = {};
    offset = '0';

    languages: any = [
        {code: 'en', name: 'English', lang: en},
        {code: 'ar', name: 'عربى', lang: ar},
    ];

    constructor(private activatedRoute:ActivatedRoute,private router:Router) {

        this.activatedRoute.params.subscribe(params => {
            console.log(params);

            if (params.language) {
                
                localStorage.setItem('language', params.language);
            }
        })


        this.lang_code = localStorage.getItem('language') ? localStorage.getItem('language') : 'en' ;
        if (this.lang_code === 'en' ) {
            console.log('local En');
            this.setLanguage({code: 'en', name: 'English', lang: en});

        }

        if (this.lang_code === 'ar' ) {
            console.log('local Ar');
            this.setLanguage({code: 'ar', name: 'عربى', lang: ar});

        }


    }

    setLanguage(item) {
        document.getElementsByTagName('html')[0].setAttribute('lang', item.code);
        this.lang_code = item.code;
        localStorage.setItem('lang', this.lang_code);
        this.languages.forEach( lang => {
            if (lang.code === this.lang_code) {
                this.lang = lang.lang.data.app;
            }
        });
    }


}
