import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-manager-list-view',
  templateUrl: './manager-list-view.component.html',
  styleUrls: ['./manager-list-view.component.css']
})
export class ManagerListViewComponent implements OnInit {

  @Input() allData: any;
  @Input() showCount:any;
  constructor(public admin: AdminService,
    public translate:TranslateService) { }

  ngOnInit() {
  }

}
