import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { ExternalService } from '../external.service';
import { apiUrl } from "../../global/global";
import { Router } from '@angular/router';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  showError = false;
  userForm: FormGroup;
  @Output() messageEvent = new EventEmitter<string>();

  otpData;

  constructor(public admin: AdminService,
    private api: ExternalService,
    private router: Router,
    private fb: FormBuilder) {
    const data = JSON.parse(localStorage.getItem('OTPData'));
    this.otpData = data
    this.userForm = this.fb.group({
      // languageType: ['EN'],
      fullNumber: [data.phoneNumber, Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      deviceType: ['WEB'],
      deviceToken: ['dfdfdfdf']
    }, { validator: this.validateIsSpouse });

  }

  validateIsSpouse(group: FormGroup) {
    let to = group.get('password').value;
    let from = group.get('confirmPassword').value;
    if (to != from) {
      return group.controls['confirmPassword'].setErrors({ min: true });
    }
    return null;
  }

  ngOnInit() {

  }

  sendMessage(status) {
    this.messageEvent.emit(status);
  }

  onSubmit(form) {
    if (form.valid) {
      const data = {};
      for (const x in this.userForm.controls) {
        data[x] = form.value[x];
      }
      if (this.otpData.userType) {
        data['userType'] = this.otpData.userType
      }

      delete data['confirmPassword'];
      this.api.submitForm(data, apiUrl._setPassword)
        .subscribe(res => {
          this.admin.toast('Password Reset Successfull!', '', 'success');
          this.router.navigate(['/login']);
        });
    } else {
      console.log(this.userForm)
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
      return;
    }
  }


}
