import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';

@Component({
  selector: 'app-add-broker-request',
  templateUrl: './add-broker-request.component.html',
  styleUrls: ['./add-broker-request.component.css']
})
export class AddBrokerRequestComponent implements OnInit {

  @Input() public propData: any = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  inItOpt: any = { initialCountry: 'bh' };
  selectedCar: number;
  cityData: any = []
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  constructor(public translate: TranslateService, private fb: FormBuilder, public activeModal: NgbActiveModal, public admin: AdminService) { }

  ngOnInit() {
    this.initForm()
    this.getCity()
  }

  getCity() {
    this.admin.showLoader = false;
    this.admin.getData(apiUrl._getPropertyType, { type: 'City' }).subscribe(success => {
      this.cityData = success.data.propertyType[0].propertyData;
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: [''],
      organization: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      preferedAreas: ['', [Validators.required]],
      callingCode: ['+973']
    })
  }

  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      return false;
    }
  }

  hasError(event) {
  }

  onCountryChange(event) {
    this.form.patchValue({
      callingCode: '+' + event.dialCode,
    });
  }

  getNumber(event) {
    const numArray = event.split(this.form.value.callingCode);
    this.form.patchValue({
      phoneNumber: numArray[1]
    });
  }

  telInputObject(event) {
    console.log(event);
  }

  onSubmit(): void {
    if (this.form.valid) {
      console.log(this.form)
      const request = { ... this.form.value }
      request.preferedAreas = request.preferedAreas
      this.admin.postData(apiUrl._brokerRequests, request).subscribe(res => {
        this.admin.showAlert('Success', 'Request sent successfully')
        this.activeModal.close('cross click')
      })
    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched({ onlySelf: true })
      });
    }
  }
}
