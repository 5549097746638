import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { permission } from '../shared/models/general.model';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,public admin:AdminService){}
  canActivate()
  {
    let token = localStorage.getItem("adminToken");
    let document1 = localStorage.getItem("userDocument");
    let userType = localStorage.getItem("userType");
    
    if (token && document1 == 'true' && userType == 'TENANT') {
      return true;
    } else if(userType == 'LANDLORD' || userType == 'MANAGER' || userType == 'TEAM_MEMBER'){
      this.router.navigate(['/']);
      return false;  
    } else {
      this.admin.logout();
      return false;  
    }
  }
}
