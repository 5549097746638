import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscriber } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-rent-card',
  templateUrl: './rent-card.component.html',
  styleUrls: ['./rent-card.component.css']
})
export class RentCardComponent implements OnInit, OnDestroy {
  totalRent: number = 0;
  totalOccupiedRent: number = 0;
  totalCollectedRent: number = 0;
  totalExpenses: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter: any;
  @Input() pagination: any;
  @Input() selectedMonths: any;
  @Input() year: any;

  date: Date = new Date();

  subscription = new Subscriber();
  months = [
    { id: 0, name: 'January', disabled: false },
    { id: 1, name: 'Feburary', disabled: false },
    { id: 2, name: 'March', disabled: false },
    { id: 3, name: 'April', disabled: false },
    { id: 4, name: 'May', disabled: false },
    { id: 5, name: 'June', disabled: false },
    { id: 6, name: 'July', disabled: false },
    { id: 7, name: 'August', disabled: false },
    { id: 8, name: 'September', disabled: false },
    { id: 9, name: 'October', disabled: false },
    { id: 10, name: 'November', disabled: false },
    { id: 11, name: 'December', disabled: false },
  ];


  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public admin: AdminService) { }

  ngOnInit() {
  }

  matchMonth(month) {
    let name = ''
    this.months.forEach(element => {
      if (element.id == month) {
        name = element.name;
      }
    });
    return name;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
