import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as CONSTANT from '../../services/constants';
import { loginModel } from '../../shared/models/login-model';
import { AdminService } from '../../services/admin.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
import { ExternalService } from '../external.service';
import { TranslateService } from "../../shared/lang/translate.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    showError = false;
    public loading = false;
    password: string = 'password';
    preferredCountries = ['in', 'us'];
    allData: any[] = [];
    propertyId;
    userId;
    type = { landlord: false, team: false, tenants: false, manager: false }

    @ViewChild('openType') openType: ElementRef;
    @ViewChild('closeType') closeType: ElementRef;
    @Output() messageEvent = new EventEmitter<string>();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public admin: AdminService,
        private api: ExternalService,
        public translate: TranslateService
    ) {
        //
        this.form = this.formBuilder.group({
            phoneNumber: [loginModel['phoneNumber'], Validators.required],
            languageType: ['EN'],
            callingCode: ['+973', Validators.required],
            password: [loginModel['password'], Validators.required],
            deviceType: ['WEB', Validators.required],
            deviceToken: ['sdsdsdsdsd', Validators.required],
        });
    }

    ngOnInit() {
        this.propertyId = localStorage.getItem('propertyId');
    }

    keyPress2(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.form.value.phoneNumber = '';
            return false;
        }
    }

    signUp(value) {
        this.router.navigate(['/forgotPassowrd']);
    }

    sendMessage(status) {
        this.messageEvent.emit(status);
    }

    loginFun(status, event) {
        // for(let x in this.form.controls) {
        //   if(typeof this.form.value[x] == 'string') {
        //     this.form.patchValue({[x]: this.form.value[x].trim()})
        //   }
        // }

        if (this.form.valid) {
            // if(this.propertyId){
            //   this.router.navigate(['/explore/propertyView/'+this.propertyId])
            // }

            this.form.value.language = localStorage.getItem('lang');
            const data = {};
            for (let x in this.form.controls) {
                data[x] = this.form.value[x];
            }
            if (status) {
                this.closeType.nativeElement.click();
                data['userType'] = event.target.value;
            }
            this.admin.postData(apiUrl._userLogin, data)
                .subscribe(res => {
                    this.userId = res.data._id;
                    localStorage.setItem('userId', this.userId);
                    if (res.data.roleAccounts) {
                        this.allData = res.data.roleAccounts;
                        for (let x of res.data.roleAccounts) {
                            if (x.userType == 'TENANT') {
                                this.type.tenants = true;
                            } else if (x.userType == 'LANDLORD') {
                                this.type.landlord = true;
                            } else if (x.userType == 'MANAGER') {
                                this.type.manager = true;
                            } else if (x.userType == 'TEAM_MEMBER') {
                                this.type.team = true;
                            }
                        }
                        this.openType.nativeElement.click();
                    } else {
                        this.checkData(res);
                    }
                });

        } else {
            this.showError = true;
        }
    }

    changeAccount(event) {
    }

    checkData(res) {
        localStorage.setItem('loginData', JSON.stringify(res.data));
        this.admin.toast('Login Successfull!', '', 'success');
        localStorage.setItem('userType', res.data.userType);
        localStorage.setItem('adminToken', res.data.accessToken);
        if (res.data.userType == 'LANDLORD' || res.data.userType == 'MANAGER' || res.data.userType == 'TEAM_MEMBER') {
            localStorage.setItem('landLordId', res.data._id);
        } else {
            localStorage.setItem('tenantId', res.data._id);
        }

        localStorage.setItem('profileImage', JSON.stringify(res.data.image));
        if (res.data.userType == 'TEAM_MEMBER' && !this.propertyId) {
            this.router.navigate(['/landlord']);
            localStorage.setItem('userDocument', 'true');
            localStorage.setItem('control', JSON.stringify(res.data.role.accessControle));
        } else if (res.data.userType == 'TEAM_MEMBER' && this.propertyId) {
            this.router.navigate(['/explore/propertyView/' + this.propertyId]);
        }
        // else if (res.data.userType == 'MANAGER' && !res.data.companyProfile.document.length) {
        //     localStorage.setItem('userDocument', 'false');
        //     this.api.userType = res.data.userType;
        //     this.router.navigate(['/signUp/uploadDocument']);
        // }
        else {
            if (res.data.isDocumentUploaded) {
                if (this.admin.path.flag) {
                    if (this.admin.path.type == res.data.userType) {
                        const loginData = JSON.parse(localStorage.getItem('loginData'));
                        const str = loginData.fullNumber;
                        const res = str.replace("+", " ");
                        if (this.admin.path.status.phoneNumber && this.admin.path.status.phoneNumber == res) {
                            let contractPeriod = this.admin.path.status.contractPeriod
                            let inviteId = this.admin.path.status.inviteId
                            let path = this.admin.path.value;
                            this.admin.path.status = {};
                            this.admin.path.value = '';
                            this.admin.path.flag = false;
                            this.admin.path.type = '';

                            this.router.navigate([path], {
                                queryParams: {
                                    contractPeriod: contractPeriod,
                                    inviteId: inviteId
                                }
                            });
                        } else {
                            let path = this.admin.path.value;
                            this.admin.path.status = {};
                            this.admin.path.value = '';
                            this.admin.path.flag = false;
                            this.admin.path.type = '';
                            this.router.navigate([path]);
                        }
                    } else {
                        this.router.navigate([res.data.userType == 'LANDLORD' || res.data.userType == 'MANAGER' ? '/landlord' : '/tenants']);
                    }
                } else {
                    this.router.navigate([res.data.userType == 'LANDLORD' || res.data.userType == 'MANAGER' ? '/landlord' : '/tenants']);
                }
                localStorage.setItem('userDocument', 'true');
                if (res.data.userType == 'TENANT' && this.propertyId) {
                    this.router.navigate(['/explore/propertyView/' + this.propertyId]);
                } else if (res.data.userType == 'LANDLORD' && this.propertyId) {
                    this.router.navigate(['/explore/propertyView/' + this.propertyId]);
                } else {

                }
            } else {
                localStorage.setItem('userDocument', 'false');
                this.api.userType = res.data.userType;
                this.router.navigate(['/signUp/uploadDocument']);
            }
        }
    }

    getNumber(event) {
        const numArray = event.split(this.form.value.callingCode);
        this.form.patchValue({
            phoneNumber: numArray[1]
        });
    }

    hasError(event) {
    }

    onCountryChange(event) {
        this.form.patchValue({
            callingCode: '+' + event.dialCode,
            // phoneNumber: ''
        });
    }

    telInputObject(event) {
    }

}
