import { Component, OnInit } from '@angular/core';
import { apiUrl } from '../../global/global';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  id: String;
  value: String;
  allData={};
  flag: Boolean = true;
  text: String;
  array=[{name:'terms-and-conditions',value:'Terms and Conditions'},{name:'contact-us',value:'Contact Us'},{name:'how-it-works',value:'How It Works'},{name:'about-us',value:'AboutUs'},{name:'faqs',value:'FAQs'}];
  
  constructor(private activeRoute:ActivatedRoute, public admin:AdminService, private router:Router)
  {
    this.text = activeRoute.snapshot.data.text;    
    this.value = activeRoute.snapshot.data.value;    
    this.id = activeRoute.snapshot.data.language;
    this.getList();
  }

  ngOnInit() {
  }

  getList() {
    let data = {
      type: this.value
    }
    this.admin.getData(apiUrl._getWebViewList,data)
      .subscribe(res => {
        let temp = res.data.template;
        if(temp.length) {
          this.flag = true;
          this.allData = this.id == 'en'?temp[0]:temp[1];  
        } else {
          this.flag = false;
        }
      }
    );
  }

}
