import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { Router } from '@angular/router';
import { TranslateService } from "../../shared/lang/translate.service";

@Component({
    selector: 'app-rental-invoice',
    templateUrl: './rental-invoice.component.html',
    styleUrls: ['./rental-invoice.component.css']
})
export class RentalInvoiceComponent implements OnInit {

    @Input() allData: any;
    @ViewChild('showRental') showRental: ElementRef;
    @ViewChild('rentalInvoice') rentalInvoice: ElementRef;

    constructor(public admin: AdminService, private router: Router, public translate: TranslateService) {
    }

    ngOnInit() {
        this.admin.rentalState.subscribe(data => {
            if (data) {
                this.showRental.nativeElement.click();
            }
        });
    }

    totalRent() {
        let total = 0;
        if (this.allData['property']) {
            total += this.allData['property']['rentAmount'];

            total += this.allData['property']['securityAmount']

            if (this.allData['property']['selectOtherPayment']) {
                total += this.allData['property']['parkingAmount'];
                total += this.allData['property']['maintenanceAmount'];
                total += this.allData['property']['housekeepingAmount'];
                total += this.allData['property']['administrationAmount'];
                if (this.allData['property']['otherPayments'].length) {
                    for (let x of this.allData['property']['otherPayments']) {
                        total += x.value;
                    }
                }
            }
            // if(this.allData['property']['ewaType'] == 2) {
            //     total += this.allData['property']['ewaAmount'];
            // }
            if (this.allData['property']['selectAdvancePeriod']) {
                total = total * this.allData['property']['advanceRentPeriod'];
            }
        }

        return this.allData['property'] ? this.allData['property']['invoiceInterval'] * total : 0;
    }

    paymentComplete() {
        console.log(JSON.stringify(this.allData));
        const securityAmount = (this.allData.property.securityAmountRequired ? this.allData.property.securityAmount : 0);
        const totalAmount = this.totalRent();
        const data = {
            requestId: this.allData['requestId'],
            totalAmount: totalAmount + securityAmount
        }
        this.admin.putData(apiUrl._makePayment, data).subscribe(success => {
            this.rentalInvoice.nativeElement.click();
            window.open(success.data.paymentUrl, '_self');
            this.admin.rentalSuccess.next({ value: true });
            // this.admin.toast('Payment Complete', '', 'success');
            // this.router.navigate(['/tenants/propertyDetail/' + this.allData['property']['_id']]);
        });
    }
}
