import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, CommonModule, registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { InterceptorService } from './services/interceptor.service';
import { LightboxModule } from 'ngx-lightbox';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { LoaderComponent } from './common/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { HeaderComponent } from './external/header/header.component';
// import { FooterComponent } from './external/footer/footer.component';
import { MainComponent } from './external/main/main.component';
import { HomeComponent } from './external/home/home.component';
import { ModalModule } from 'ngx-bootstrap';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { ListComponent } from './external/list/list.component';

import { PropertyViewComponent } from './external/propertyView.component';
import { AboutUsComponent } from './external/about-us/about-us.component';
import { ContactUsComponent } from './external/contact-us/contact-us.component';
import { FaqComponent } from './external/faq/faq.component';
import { RentPropertyComponent } from './external/rent-property/rent-property.component';
import { AgreementViewComponent } from './common/agreement-view/agreement-view.component';
import { PropertiesViewComponent } from './external/properties-view/properties-view.component';
import { ManagerListViewComponent } from './external/manager-list-view/manager-list-view.component';
import { ManagerListComponent } from './external/manager-list/manager-list.component';
import { MainHeaderComponent } from './external/main-header/main-header.component';
import { ManagerDetailComponent } from './external/manager-detail/manager-detail.component';
import { LandlordManagerComponent } from './external/landlord-manager/landlord-manager.component';
import { TermsAndConditionsComponent } from './external/terms-and-conditions/terms-and-conditions.component';
import { TenantComponent } from './external/tenant/tenant.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NewMainHeaderComponent } from './external/new-main-header/new-main-header.component';
import { PrivacyPolicyComponent } from './external/privacy-policy/privacy-policy.component';

import localeAr from '@angular/common/locales/ar-BH';
import localEs from '@angular/common/locales/es-US';
import { DatePipe } from '@angular/common';

import localeEsExtra from '@angular/common/locales/extra/es-US';
import localeArExtra from '@angular/common/locales/extra/ar-BH';
import { ActivatedRouteSnapshot } from "@angular/router";
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { InvitationTestingComponent } from './common/invitation-testing/invitation-testing.component';
import { AutoCompleteModule } from 'primeng/primeng';
import { CovidHelpComponent } from './external/covid-help/covid-help.component';
import { BuisnessPlanComponent } from './external/covid-help/buisness-plan/buisness-plan.component';
import { RentInvoiceLinkComponent } from './external/rent-invoice-link/rent-invoice-link.component';
import { UploadCompanyDocumentsComponent } from './external/upload-company-documents/upload-company-documents.component';
import { LinkComponent } from './external/link/link.component';
import { PartnersComponent } from './external/partners/partners.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

registerLocaleData(localEs, 'es-US', localeEsExtra);
registerLocaleData(localeAr, 'ar', localeArExtra);


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        PageNotFoundComponent,
        HeaderComponent,
        // FooterComponent,
        MainComponent,
        HomeComponent,
        ListComponent,
        PropertyViewComponent,
        AboutUsComponent,
        ContactUsComponent,
        FaqComponent,
        SafeHtmlPipe,
        RentPropertyComponent,
        AgreementViewComponent,
        PropertiesViewComponent,
        ManagerListViewComponent,
        ManagerListComponent,
        MainHeaderComponent,
        ManagerDetailComponent,
        LandlordManagerComponent,
        TermsAndConditionsComponent,
        TenantComponent,
        NewMainHeaderComponent,
        PrivacyPolicyComponent,
        InvitationTestingComponent,
        CovidHelpComponent,
        BuisnessPlanComponent,
        RentInvoiceLinkComponent,
        UploadCompanyDocumentsComponent,
        LinkComponent,
        PartnersComponent,
    ],
    imports: [
        NgSelectModule,
        AutoCompleteModule,
        SharedModuleModule,
        McBreadcrumbsModule.forRoot(),
        BrowserModule,
        NgxSpinnerModule,
        Ng2TelInputModule,
        ModalModule.forRoot(),
        AppRoutingModule,
        NgbModalModule,
        SharedModuleModule,
        HttpModule,
        HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production
        }),
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule.forRoot(),
        CommonModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        LightboxModule,
        AngularFontAwesomeModule,
        SweetAlert2Module.forRoot({
            buttonsStyling: false,
            customClass: 'modal-content',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn'
        }),
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ],
    providers: [AsyncPipe, {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptorService,
        multi: true
    }, NgxSpinnerModule,
        SafeHtmlPipe,
        NgbActiveModal,
        DatePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
