// export const API_END_POINT = 'http://192.168.102.123:8000';
// export const API_URL = 'http://192.168.102.123:8000';
// export const API_URL = 'http://45.232.252.37:8000'; // dev
// export const API_END_POINT = 'http://45.232.252.37:8000'; // dev
// export const API_URL = 'http://45.232.252.44:8000'; // local
// export const API_END_POINT = 'http://45.232.252.44:8000'; // local

// locale
// export const API_END_POINT = 'https://nd.eglooz.com/common/getImage/';
// export const API_URL = 'http://192.168.102.123:8000';
// export const API_FILE_END_POINT = 'https://tstnd.eglooz.com/common/getFile/';
// export const WEB_URL = 'https://tst.eglooz.com/';

// beta live
export const API_END_POINT = 'https://nd.eglooz.com/common/getImage/';
export const API_URL = 'https://nd.eglooz.com';
export const API_FILE_END_POINT = 'https://nd.eglooz.com/common/getFile/';
export const WEB_URL = 'https://eglooz.com/';

// stage live
// export const API_END_POINT = 'https://tstnd.eglooz.com/common/getImage/';
// export const API_URL = 'https://tstnd.eglooz.com';
// export const API_FILE_END_POINT = 'https://tstnd.eglooz.com/common/getFile/';
// export const WEB_URL = 'https://tst.eglooz.com/';

// export const API_END_POINT = 'http://192.168.103.20:8000/common/getImage/';
// export const API_URL = 'http://192.168.103.20:8000';

// export const API_FILE_END_POINT = 'http://192.168.103.20:8000/common/getFile/';
// export const WEB_URL = 'http://192.168.103.20:8000';
// Local live
// export const API_URL = 'http://192.168.102.123:8000'; // local
// export const API_END_POINT = 'http://192.168.102.123:8000'; // local
// export const API_URL = 'http://192.168.102.109:8000'; // local
// export const API_END_POINT ='http://192.168.102.109:8000'; // local
// export const WEB_URL = 'https://tst.eglooz.com/';