import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { apiUrl } from 'src/app/global/global';
import { Subscriber } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DATE_CONVERTER_TYPE, SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-add-payment-receipt',
  templateUrl: './add-payment-receipt.component.html',
  styleUrls: ['./add-payment-receipt.component.css']
})
export class AddPaymentReceiptComponent implements OnInit {

  form: FormGroup
  date = new Date()
  paymentModes: any = []
  @Input() public invoiceData: any
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  max = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate(),
    this.date.getHours(),
    this.date.getMinutes() + 1,
    59
  );
  private subscription = new Subscriber()
  constructor(public translate: TranslateService, private _fb: FormBuilder, public admin: AdminService, public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private api: InternalServiceService, private activatedRoute: ActivatedRoute,
  ) {
    this.getPaymentMethods()
  }

  ngOnInit() {
    this.form = this._fb.group({
      paymentMode: ['', [Validators.required]],
      tenantPaidDate: [new Date(), [Validators.required]],
      amount: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      type: [''],
      image: new FormControl([]),
      invoiceId: ['', [Validators.required]]
    })
    this.form.get('invoiceId').patchValue(this.invoiceData['invoiceId'])
    this.form.get('amount').patchValue(this.invoiceData['totalAmount'])
    // this.form.get('amount').disable()
  }

  public getPaymentMethods(): void {
    const request = {
      type: 'Payment_Options'
    }
    this.subscription.add(this.admin.getData(apiUrl._getPropertyType, request).subscribe(res => {
      this.paymentModes = res.data.propertyType.length ? res.data.propertyType[0].propertyData : []
    }))
  }

  public onPaymentType(e: any): void {

  }

  public numberWithCommas(x) {
    if (x || x == 0) {
      if (typeof x == 'number') {
        x = x.toFixed(3);
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      } else {
        return x;
      }
    } else {
      return x;
    }
  }

  public upload(event): void {
    if (event.target.files && event.target.files.length) {
      const temp = event.target.files[0].type;
      if (temp == 'image/jpeg' || temp == 'application/pdf' || temp == 'image/png' || temp == 'image/jpg' || temp == 'image/pdf') {
        const file = event.target.files[0];
        this.api.uploadImage(file, file.name.split('.').pop() == 'pdf' ? 'pdf' : 'img').subscribe(success => {
          this.form.get('image').value.push({
            original: success.data.original,
            thumbnail: success.data.thumbnail,
            type: success.data.type,
          })
        });
      } else {
        this.admin.errorAlert('Invalid format!', '');
      }
    }
  }

  public removeImage(i): void {
    this.form.value.image.splice(i, 1);
  }

  public onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      const request = { ...this.form.value }
      delete request.type
      request.tenantPaidDate = +new Date(request.tenantPaidDate)
      const dd: Date = new Date(request.tenantPaidDate);
      dd.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
      request.tenantPaidDate = +new Date(dd);
      this.subscription.add(this.admin.postData(apiUrl._tenantPaymentRequest, request).subscribe(res => {
        this.admin.successAlert('Thank you for submitting your payment documents', 'Your Payment document against your invoice is submitted Successfully, shortly the leaser will revise it and notify you.')
        this.activeModal.close('cross click')
      }))
    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

}
