import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import * as moment from 'moment';
import { TranslateService } from "../../shared/lang/translate.service";
import { stat } from 'fs';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
    @Input() notificationData: any[];
    @Input() count: number;
    @Input() routerValue: string;

    constructor(private route: Router, public admin: AdminService, public translate: TranslateService) {
    }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications() {
        const data = {};
        this.admin.showLoader = false;
        this.admin.getData(apiUrl._getNotification, data).subscribe(success => {
            this.notificationData = success.data;
            console.log(this.notificationData);
            this.count = 0;
            this.notificationData.forEach(col => {
                // console.log(moment().calendar(col.createdAt), '=============' , new Date(col.createdAt));
                // console.log(moment().calendar(col.createdAt, {
                //     sameDay: '[Today]',
                //     nextDay: '[Tomorrow]',
                //     nextWeek: 'dddd',
                //     lastDay: '[Yesterday]',
                //     lastWeek: '[Last] dddd',
                //     sameElse: 'DD/MM/YYYY'
                // }));
                col.timing = new Date(col.createdAt).getTime();
                // col.timing = moment().calendar(col.createdAt);
            });
        });
    }

    routeNavigation(status) {

        console.log(status);

        switch (status.type) {
            // Done
            case 'REQUEST':
                this.route.navigate(['/' + this.routerValue + '/request/' + status.propertyId._id]);
                break;
            // Done
            case 'ACCEPT_REQUEST':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'REJECT_AGREEMENT':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'SIGN_AGREEMENT_LANDLORD':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'PROPERTY_APPROVED':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            // case 'SIGN_AGREEMENT_TENANT' :
            //   // router.navigate(['']);
            //   break;
            case 'REPLIED_COMMENT':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'RESEND_AGREEMENT':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'PAYMENT_DONE':
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'ACCEPT_INVITATION':
                // router.navigate(['']);
                this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                break;
            case 'INVITE_USER':
                // router.navigate(['']);
                if (status.assignedTeamMember.length == 0) {
                    this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id], { queryParams: { invite: true } });
                } else {
                    this.route.navigate(['/' + this.routerValue + '/propertyDetail/' + status.propertyId._id]);
                }
                break;
            case 'OUERIES':
                this.route.navigate(['/' + this.routerValue + '/requests/' + status.requestId]);
                break;

            case 'MAINTENANCE':
                this.route.navigate(['/' + this.routerValue + '/requests/' + status.requestId]);
                break;

            case 'RESOLVED_QUERY_MAINTENANCE':
                this.route.navigate(['/tenants/requests/' + status.requestId]);
                break;

            case 'REMAINDER':
                if (localStorage.getItem('userType') != 'TENANT') {
                    this.route.navigate(['/' + this.routerValue + '/request/maintenanceReminder']);
                } else {
                    this.route.navigate(['/' + this.routerValue + '/request/'], { queryParams: { status: 'maintenanceReminder' } });
                }
                break;
            case 'INVOICE':
                this.route.navigate(['/' + this.routerValue + '/payment']);
                break;
            // case 'ACCOUNT_APPROVED' :
            //   // router.navigate(['']);
            //   break;
            // case 'ACCOUNT_UNAPPROVED' :
            //   // router.navigate(['']);
            //   break;
            case 'VACATE_REQUEST_TENANT':
                //   this.route.navigate(['/'+this.routerValue+'/vacateRequest']);
                this.route.navigate(['/' + this.routerValue + '/vacateRequest'], { queryParams: { status: 'received' } });
                break;
            case 'VACATE_REQUEST_REJECT_LANDLORD':
                //   this.route.navigate(['/'+this.routerValue+'/vacateRequest']);
                this.route.navigate(['/' + this.routerValue + '/vacateRequest'], { queryParams: { status: 'sent' } });
                break;
            case 'VACATE_REQUEST_ACCEPT_LANDLORD':
                //   this.route.navigate(['/'+this.routerValue+'/vacateRequest']);
                this.route.navigate(['/' + this.routerValue + '/vacateRequest'], { queryParams: { status: 'sent' } });
                // router.navigate(['']);
                break;
            // case 'ACCOUNT_BLOCK' :
            //   // router.navigate(['']);
            //   break;
            // case 'ACCOUNT_DELETED' :
            //   // router.navigate(['']);
            //   break;
        }
    }
}
