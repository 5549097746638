import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../shared/lang/translate.service';

@Component({
  selector: 'app-landlord-manager',
  templateUrl: './landlord-manager.component.html',
  styleUrls: ['./landlord-manager.component.css']
})
export class LandlordManagerComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
