import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import swal from 'sweetalert2';
import { TranslateService } from '../../shared/lang/translate.service';
import { ExternalService } from '../external.service';
import { Router } from "@angular/router";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   
    constructor(
        public admin: AdminService,
        public translate: TranslateService,
        private api: ExternalService,
        private router: Router
    ) {
    }

    ngOnInit() {

    }


    // telInputObject(event) {
    //     this.event = event;
    // }






    changeProertyType(item) {
        this.router.navigate(['/explore/property'], {
            queryParams: {
                typeValue: item.type === 'Residential' ? 1 : 2,
                propertyType: item._id
            }
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    /*
    routerLink="/explore/property"
    [queryParams]="{typeValue:item.type =='Residential'? 1 : 2, propertyType:item._id}"
    */

}
