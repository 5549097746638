import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css']
})
export class RequestViewComponent implements OnInit {
  @Input() allData: any;
  @Input() status: any;
  @Input() routeValue: any;

  constructor(public admin:AdminService) { }

  ngOnInit() {
  }

}
