import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '../../shared/lang/translate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { Subscription, Subscriber } from 'rxjs';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit, OnDestroy {
  @Input() allData: any;
  @Input() status: boolean;
  @Input() count: number;
  @Input() type: number;

  model: any = [];
  rate = 0;
  subscription = new Subscriber();
  constructor(
    public translate: TranslateService,
    public admin: AdminService, private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  

  ngOnInit() {
    this.getRatingPoints();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getRatingPoints() {
    console.log(this.allData + 'Data');


    if (this.allData.length == 0) {
      return false;
    }

    let id = this.allData._id ? this.allData._id : this.allData.property;

    if (!id) {
      return false;
    }


    let data = {
      id: id,
      type: this.type
    }

    if (this.type == 2) {
      data.id = this.allData.userId;
    }


    this.subscription.add(this.admin.getData(apiUrl._getRatingPoints, data).subscribe(success => {
      this.model = success.data;
    }));
  }

}
