import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { ExternalService } from 'src/app/external/external.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as CONSTANTS from '../../services/constants';
import { apiUrl } from 'src/app/global/global';
import { SharedService } from 'src/app/shared/shared.service';
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { DATE_CONVERTER_TYPE } from "../../shared/shared.service";


@Component({
  selector: 'app-invitation-testing',
  templateUrl: './invitation-testing.component.html',
  styleUrls: ['./invitation-testing.component.css']
})
export class InvitationTestingComponent implements OnInit {
  inviteForm: FormGroup;
  dryRunModel: any = []
  dryRunForm: FormGroup;
  showError: boolean = false;
  showError2: boolean = false;
  propertyId = '';
  model: any = [];
  showOption = false;
  response: boolean = false;
  date: Date = new Date();
  constructor(
    public admin: AdminService,
    public translate: TranslateService,
    private api: ExternalService,
    private internalApi: InternalServiceService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {

    this.inviteForm = this.fb.group({
      moveDate: [new Date(), Validators.required],
      contractPeriod: [12, Validators.required],
      inviteDate: [new Date(), Validators.required],
      // inviteAcceptDate: [new Date(), Validators.required],
      invoiceGenerate: [false]
    });

    this.dryRunForm = this.fb.group({
      date: [new Date(), Validators.required],
    })

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.propertyId = params.id;
      }
    })
  }


  submitDryRun(form) {
    form.value['date'] = this.setDate(form.value.date);
    if (form.valid) {
      this.admin.getData(apiUrl._dryRunGetInvoices, form.value).subscribe(success => {
        this.dryRunModel = success.data;
        this.admin.toast('Submitted Successfully', '', 'success');
      });
    } else {
      console.log(form)
      this.showError2 = true;
      setTimeout(() => {
        this.showError2 = false;
      }, 5000);
      return;
    }
  }


  setDate(date) {
    const dd: Date = new Date(date);
    dd.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
    return this.sharedService.getUtcDateOrTime(dd, DATE_CONVERTER_TYPE.dateTime);
  }

  submitInvite(form) {
    if (form.valid) {
      form.value['moveDate'] = this.setDate(form.value.moveDate);
      form.value['inviteDate'] = this.setDate(form.value.inviteDate);
      // form.value['inviteAcceptDate'] = this.setDate(form.value.inviteAcceptDate);

      if (this.response) {
        if (form.value.moveDate > new Date().getTime()) {
          // form.value['invoiceGenerate'] = true;
        }
      } else {
        delete form.value.invoiceGenerate;
      }

      let x = this.compareDates(form.value);
      if (!x) {
        return x;
      }

      // if (this.propertyId) {
      //   form.value['property'] = this.propertyId;
      // }

      this.admin.postData(apiUrl._dryRun, form.value).subscribe(success => {

        this.model = success.data;
        this.admin.toast('Invitation Send', '', 'success');

        if (success.data.confirmation) {
          this.response = true;
        }
        // form.value.invoiceGenerate = false;

        this.inviteForm.patchValue({
          invoiceGenerate: form.value.invoiceGenerate
        })

      });
    } else {
      console.log(form)
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
      return;
    }
  }


  initForm() {
    this.model = [];
    this.inviteForm.patchValue({
      moveDate: new Date(),
      contractPeriod: 12,
      invoiceGenerate: false,
      inviteDate: new Date(),
      // inviteAcceptDate: new Date(),
    });

    this.response = false;
  }


  initDryRunForm() {
    this.model = [];
    this.dryRunForm.patchValue({
      date: new Date(),
    });
    this.dryRunModel = [];
  }


  compareDates(fd) {
    // if (fd.inviteDate > fd.inviteAcceptDate) {
    //   this.admin.errorAlert('Invite Accept Date must be greater than Invite Date', '')
    //   return false;
    // }
    return true;
  }

  checkDate(event) {
    this.response = false;
    console.log(event);
    const date: Date = new Date();
    date.setHours(0, 0, 0);
    const formDate: Date = new Date(event);
    formDate.setHours(23, 59, 59);
    this.showOption = formDate.getTime() < date.getTime();
    // this.showOption = false;
  }

  checkRaisedDate(event) {
    console.log(event);
    const date: Date = new Date();
    date.setHours(0, 0, 0);
    const formDate: Date = new Date(event);
    formDate.setHours(23, 59, 59);
    this.showOption = formDate.getTime() < date.getTime();
  }


}
