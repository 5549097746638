export const locale = {
  lang: "ar",
  data: {
    app: {
      Search: "بحث",
      Residential: "سكني",
      Commercial: "تجاري",
      ViewAll: "إظهار الكل",
      Date: "التاريخ",
      Type: "النوع",
      Property: "العقار",
      All: "الجميع",
      Action: "المهام",
      Bank: "البنك",
      NoData: "لا توجد بيانات",
      Status: "الحالة ",
      Sent: "تم الإرسال",
      Received: "تم الاستلام",
      AddProperty: "أضف عقاراً",
      DragAndDrop: "اسحب وضع ",
      UploadManually: "تحميل فردي",
      EnterPhoneNumber: "أدخل رقم الهاتف",
      Submit: "أرسل",
      LogIn: "الدخول",
      LogOut: "الخروج",
      LogOutMsg: "هل أنت متأكد من رغبتك بالخروج؟",
      Month: "شهرياً",
      Status_Pending: "قيد الانتظار",
      Status_Resolved: "تم حلها",
      Status_Reopen: "إعادة فتح",
      Status_Cancelled: "ملغي",
      Status_Completed: "منجز",
      Cancel: "إلغاء",
      Next: "التالي",
      startDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      Email: "البريد الإلكتروني",
      Required: "مطلوب",
      ClearAll: "مسح الكل",
      Year: "السنة",
      BHD: "د.ب",
      More: "أكثر",
      Bath: "حمام",
      Bed: "سرير",
      sqft: "متر مربع",
      OK: "حسناً",
      BHK: "غرفة نوم",
      Favourite: "مفضلة",
      //roles
      rate: "قيّم",
      // Header
      Header_Explore: "تصفّح",
      Header_Landlord_Manager: "المالك/المدير",
      Header_Tenant: "حساب المستأجر",
      Header_Contact_Us: "تواصل معنا",
      Header_RegisterNow: "سجّل الآن",
      Header_MyProfile: "حسابي الشخصي",
      Header_Dashboard: "لوحة التحكم",
      Header_SignOut: "خروج",
      WithEgloozSince: "مسجل منذ",
      // Home
      Home_FindTheHomeYouLove: "ابحث عن منزلك الذي تحب.",
      Home_Place_EnterAddress: "أدخل العنوان",
      Home_Place_Address: "عنوان",
      Home_Place_Min: "أقل",
      Home_Place_Max: "أعلى",
      Home_SearchHome: "ابحث عن منزلك",
      Home_HowItWorks: "كيف يعمل؟",
      Home_DiscoverHowToImproveYourRealestateExperience:
        "اكتشف كيف تحسن من تجربتك العقارية",
      Home_Landlord: "المالك",
      Home_Landlord_Text: [
        "تحصيل الإيجار أون لاين",
        "تسويق العقار",
        "اختيار المستأجر الأنسب",
        "التواصل مع المستأجرين وفريق العمل",
        "والمزيد من الخدمات....",
      ],
      Home_PropertyManager: "مدير العقار",
      Home_PropertyManager_Text: [
        "إدارة ومتابعة الإيجار",
        "إنشاء فريق العمل",
        "إدارة دفتر العقار",
        "طلبات الصيانة",
        "والمزيد....",
      ],
      Home_Tenant: "المستأجر",
      Home_Tenant_Text: [
        "دفع الايجار أون لاين",
        "البحث عن منزل أو مكتب جديد",
        "التواصل مع المالك/مدير العقار",
        "تنظيم المعاملات العقارية",
        "وأكثر بكثير ....",
      ],
      Home_ViewVideo: "شاهد الفيديو",
      Home_LearnMore: "المزيد",
      Home_FindYourDreamProperty: "ابحث عن العقار الذي تحلم به",
      Home_MostPopularProperties: "أكثر العقارات شعبيةً",
      Home_FindARentProperties: "اكتشف عقارات للإيجار في البحرين!",
      Home_SearchNewProperty: "ابحث عن عقار للإيجار",
      Home_NewlyAddedProperties: "عقارات أضيفت حديثاً",
      Home_ExploreAllCategoriesOfProperties: "تصفح جميع فئات العقارات",
      Home_ExploreAllTypesOfProperty: "تصفح جميع أنواع العقارات",
      Home_JoinUsNow: "انضم إلينا الآن!",
      Home_LiveTheNewLevel: "عش التجربة العقارية بمفهوم جديد",
      Home_OurBestAgentTeam: "أفضل المدراء العقاريين",
      Home_Eglooz_Agent_Manager: "شركائنا لإدارة العقار",
      Home_ViewDetails: "إظهار التفاصيل",
      Home_OurClientTestimonial: "ماذا قالوا عنا",
      Home_NeedHelpGettingStarted: "هل أنت بحاجة للمساعدة؟",
      Home_OurProfessionalsWouldLoveToAssistYou:
        "فريق العمل المحترف جاهزين لمساعدتكم.",
      Home_WhatPeopleSay: "ما قيل عن Eglooz",
      Home_PlaceYourPhoneNumberHere: "اكتب رقم هاتفك هنا...",
      Home_ContactOurHappyUserCenter: "تواصل مع مركز سعادة العملاء",
      Home_EnterYourName: "أدخل اسمك...",
      Home_EnterYourEmailAddress: "أدخل بريدك الإلكتروني...",
      Home_WriteYourMessageHere: "اكتب رسالتك هنا...",
      Home_SearchByCity: "البحث بالمدينة",
      Home_COMPANY: "الشركة ",
      Home_InstallApp: "حمل التطبيق",
      Home_ForLandlord: "للملاك",
      Home_ForManager: "لمدراء العقار",
      Home_ForTenant: "للمستأجرين",
      Home_AboutUs: "نبذة عنا",
      Home_ContactUs: "تواصل معنا",
      Home_FAQ: "الأسئلة المتكررة",
      Home_TermsAndConditions: "الشروط والأحكام",
      Home_DownloadFromAppStore: "التحميل من متجر أبل",
      Home_DownloadFromPlayStore: "التحميل من متجر جوجل",
      Home_FOLLOWUSON: "تابعنا",
      // Landlord/Manager
      HowItWork_Title: "اكتشف Eglooz",
      HowItWork_SubTitle: "تجربة الإيجار الذكية",
      HowItWork_Text:
        "منصة إلكترونية متقدمة لعرض، بحث، تأجير ،و تحصيل الإيجار و إدارة العقارات الخاصة بك",
      HowItWork_HowItWorks: "كيف يعمل",
      HowItWork_LearnHowItWorks: "جعلناه سهلاً للملاك ومدراء العقار",
      HowItWork_Step1_Title:
        "منصة محمية وآمنه، ومعتمدة لقواعد معرفة المستأجر (KYT)",
      HowItWork_Step1_Text:
        "أوجدت Eglooz قواعد (KYT) (تعرف على مستأجرك) في خطوتها لبناء منصة ترحب بالعملاء الحقيقيين فقط، للتمكّن من خدمة العملاء بشكل أفضل وإدارة المخاطر بحكمة أكبر.",
      HowItWork_Step2_Title: "منصة إلكترونية مجانية لإدارة العقار",
      HowItWork_Step2_Text:
        "تساعد Eglooz ملاك ومدراء العقار والمستأجرين على إدارة عقاراتهم بكل يسر وسهولة وذلك باستخدام أنظمة متطورة وتقنيات حديثة.",
      HowItWork_Step3_Title: "أداة فعّالة ومجانية لتسويق العقار",
      HowItWork_Step3_Text:
        "لا مزيد من إضاعة الوقت على المستأجرين الوهميين\n" +
        " Eglooz تمكنك من الوصول لآلاف من المستأجرين المحتملين والحقيقيين.",
      HowItWork_Step4_Title: 'قاعدة " 3 S\'s" لتحصيل إيجار العقارات',
      HowItWork_Step4_Text:
        'تجعل Eglooz تحصيل عملية تحصيل الإيجارات سهلة وممتعة وذلك باعتمادها على قاعدة "3S\'s" “Smart Simple Secure” (ذكي، بسيط، آمن). Eglooz تهتم بدفتر إيجاراتك، إصدار فواتير االإيجار تلقائياً والتذكير بها إلكترونياً، بالإضافة لعدد من الخدمات المالية الأخرى.',
      HowItWork_Step5_Title: "تواصل الملاك ومدراء العقار مع المستأجرين",
      HowItWork_Step5_Text:
        "تمكن Eglooz الملاك، ومدراء العقار والمستأجرين من التواصل عبر نظام دردشة آمن، وإرسال الإشعارات التلقائية، ورسائل البريد الإلكتروني للتذكير، والاتصال المباشر. نحن متصلون إلى الأبد",
      HowItWork_Step6_Title: "أتم صفقتك بنجاح وسرعة وفعالية",
      HowItWork_Step6_Text:
        "مع خدمة العقد الذكي من Eglooz و خدمة تعرف على مستأجرك (KYT)، تستطيع اختيار مستأجرك القادم بيسر، وتوقيع عقد إيجارك عبر الانترنت وتحصيل الدفعة الأولى مباشرة.",
      HowItWork_Step7_Title: "إدارة الطلبات والصيانة",
      HowItWork_Step7_Text:
        "دائماً Eglooz بالقرب منك لنتأكد من حصولك على تجربة ناجحة ومختلفة، تابع طلبات الصيانة عبر الانترنت بسهولة، وتتبع إنجازها، وإنشاء التذكير الذي ترغب به.",
      HowItWork_Step8_Title: "كون فريق عملك وتابعه",
      HowItWork_Step8_Text:
        "أصبحت إدارة فريق العمل سهلة. فقط قم بتكوين فريقك والصلاحيات المخولة لهم، وتتبع الإنجازات.",
      HowItWork_Step9_Title: "التقارير و الاحصائيات",
      HowItWork_Step9_Text:
        "نجاحك هو مكافأتنا من خلال لوحة التحكم الإحصائية المتقدمة ودفتر الإيجار الذكي، استمتع بتقارير محدثة وشاملة",
      HowItWork_Step10_Title: "تجربة المستخدم",
      HowItWork_Step10_Text:
        'ابتسامتك هي قوتنا! نحن نسعى لتقديم تجربة فريدة ومميزة ننقل معها سوق العقار إلى مستوى آخر. نساهم في تطوير خدمات التكنولوجيا العقارية "PropTech" في البحرين - مهمتنا! نحن نسمعك .. وهنا من أجلك!',
      HowItWork_End_Text:
        "لمزيد من المعلومات نرجو التسجيل في Eglooz عن طريق موقعنا الإلكتروني أو تحميل تطبيق الهاتف الذكي",
      // Tenant Page
      TenantPage_title_text: "اكتشف Eglooz",
      TenantPage_LearnHowItWorks: "تعرّف أكثر، كمستأجر",
      TenantPage_Step1_Title: "ابحث، احجز واستأجر عقارك التالي",
      TenantPage_Step1_Text:
        "حدد الموقع والعقار المفضل لك، وبكل سهولة قدم طلبك لحجزه واستئجاره، واغتنم الفرصة!",
      TenantPage_Step2_Title: "بطاقتك الائتمانية هي نقدك",
      TenantPage_Step2_Text:
        "لا لمزيد من هدر الوقت أمام أجهزة الصراف الآلي أو التعامل مع الدفع النقدي. ادفع إيجارك ببساطة باستخدام بطاقتك الائتمانية عبر بوابة بنفت للدفع الإلكتروني.",
      TenantPage_Step3_Title: "التنبيهات والإشعارات فورية و دقيقة",
      TenantPage_Step3_Text: "جميع الإشعارات فورية و مباشرو و في الوقت الفعلي",
      TenantPage_Step4_Title: "انشئ ملفك الإيجاري الجيد",
      TenantPage_Step4_Text:
        "بالحفاظ على تاريخك الإيجاري الجيد ومشاركته المالك (المؤجر) فإنه يسهل عملية إستئجارك لعقارك القادم.",
      TenantPage_Step5_Title: "المعاملات المالية",
      TenantPage_Step5_Text:
        "عرض وتحميل السجلات الخاصة بك للمدفوعات القادمة ، الماضية والمتأخرة.",
      TenantPage_Step6_Title: "سهولة التواصل",
      TenantPage_Step6_Text:
        "تحدث مع المالك أو مدير العقار في أي وقت، لطلب الصيانة ولأي استفسارات أخرى.",
      TenantPage_Step7_Title: "التقييم ومشاركة الرأي",
      TenantPage_Step7_Text:
        "شارك رأيك وتقييمك للعقار الذي استأجرته، وتصفّح أراء وتقييمات المستأجرين الآخرين.",
      // Side Nav bar
      SideNav_Dashboard: "لوحة التحكم",
      SideNav_Properties: "العقارات ",
      SideNav_Payment: "المدفوعات",
      SideNav_Bank: "البنوك",
      SideNav_Withdrawals: "المبالغ المحوّلة",
      SideNav_Tenants: "المستأجرين",
      SideNav_Requests: "الطلبات",
      SideNav_VacateRequests: "طلبات الإخلاء",
      SideNav_Team: "فرق العمل",
      SideNav_ManageRole: "إدارة المهام",
      SideNav_Chat: "المحادثات",
      SideNav_OtherReports: "تقارير أخرى",
      SideNav_Setting: "الضبط",
      SideNav_Logout: "الخروج",
      SideNav_Rate: "قيّم",
      Reviews: "تعليقات",
      Download_Pdf: "تحميل ملف PDF",

      // Dashboard
      Dashboard_RentBook: "دفتر الإيجار",
      Dashboard_Chips_Invoiced: "مفوتر",
      Dashboard_Chips_Overdue: "متأخرات",
      Dashboard_Chips_NotInvoiced: "غير مفوتر",
      Dashboard_Transaction_Received: "التحويلات (المستلمة)",
      Dashboard_NextMonthInvoice: "فواتير الشهر القادم",
      Dashboard_CarryoverInvoice: "الفواتير المتأخرة",
      Dashboard_NextMonthPayment: "مدفوعات الشهر القادم",
      Dashboard_CarryoverPayment: "المدفوعات السابقة",
      Dashboard_TotalAndAccruedPaymentsGraph:
        "شكل بياني لمجموع المستحقات والمدفوعات",
      Dashboard_MonthlyFullPayments:
        "نظرة شاملة للمدفوعات، هذه البيانات تحدث شهرياً",
      Dashboard_TotalProperties: "جميع العقارات",
      Dashboard_TotalRequests: "إجمالي الطلبات",
      Dashboard_ApprovalPending: "الحساب تحت المراجعة",
      Dashboard_Incomplete: "غير مكتمل",
      Dashboard_Inactive: "غير مفعل",
      Dashboard_Resolved: "تم حلها",
      Dashboard_OccupiedProperty: "عقارات مؤجرة",
      Dashboard_VacantProperty: "عقارات فارغة",
      Dashboard_BookedProperty: "عقارات محجوزة",
      Dashboard_QueriesRequest: "الطلبات العامة",
      Dashboard_AwaitInvitation: "بانتظار الدعوة",
      Dashboard_MaintenanceRequest: "طلبات الصيانة",
      Dashboard_BookingRequest: "طلبات الحجز",
      Dashboard_TenantRequest: "طلبات المستأجر",
      Dashboard_ManageYourNewTenants:
        "إدارة الطلبات.. طلبات التأجير، طلبات الصيانة و الطلبات العامة",
      Dashboard_Chart_Occupied: "مؤجرة",
      Dashboard_Chart_Vacant: "غير مؤجرة",
      Dashboard_Chart_Booked: "محجوزة",
      Dashboard_Chart_AwaitInvite: "بانتظار الدعوة",
      Dashboard_TableHeading_Property: "العقار",
      Dashboard_TableHeading_TenantName: "اسم المستأجر",
      Dashboard_TableHeading_PhoneNo: "رقم الهاتف",
      Dashboard_TableHeading_Recurrence: "التكرار",
      Dashboard_TableHeading_Status: "الحالة",
      Dashboard_MyReminder: "تذكيراتي",
      Dashboard_ManageYourPropertyMaintenanceReminder: "إدارة التذكيرات",
      Dashboard_TenantsList: "قائمة المستأجرين",
      Dashboard_TeamsList: "قائمة فريق العمل",
      Dashboard_ViewManageTenants: "إدارة المستأجرين",
      Dashboard_ViewManageTeams: "إدارة فريق العمل",
      Dashboard_Accured: "المستحق",
      Dashboard_Recieved: "المستلم",
      Dashboard_AwaitInvite: "بانتظار الدعوة",
      Dashboard_PaymentAndHistory: "المدفوعات السابقة",
      Dashboard_History: "السابق",
      Dashboard_CurrentOccupiedProperty: "العقارات المستأجرة حالياً",
      Dashboard_PendingAgreement: "بانتظار العقد",
      Dashboard_VacatePropertyRequestDashboard_VacatePropertyRequest:
        "طلب إخلاء العقار",
      Dashboard_PropertyInvitations: "دعوات للعقار",
      Dashboard_MaintainceAndQuery: "طلبات الصيانة والطلبات العامة",
      Dashboard_MaintenanceReminder: "التذكيرات",
      Dashboard_PaymentsGraph: "شكل بياني للمدفوعات",
      Dashboard_Request_QueryAndMaintenance: "الطلبات (العامة & الصيانة)",
      Dashboard_ManageYourMaintenanceQuery: "إدارة طلباتك للصيانة",
      Dashboard_TableHading_Owner: "المالك ",
      Dashboard_TableHading_MAINTAINENCE: "الصيانة",
      Dashboard_TableHading_REMAINDER: "تذكير",
      Dashboard_ReceivedTotalPayments: "المدفوعات الكلية المستلمة",
      Dashboard_TableOption_EveryMonth: "شھریا",
      Dashboard_TableOption_Quarterly: " (ربع سنوي (3شھور",
      Dashboard_TableOption_HalfYearly: " (نصف سنوي (6شھور",
      Dashboard_TableOption_Yearly: "سنوي",
      Dashboard_TableOption_None: "لا یوجد تكرار",
      Dashboard_PastProperty: "العقارات السابقة",
      Property_Tab_Occupied: "مؤجرة",
      Property_Tab_Booked: "محجوزة",
      Property_Tab_Vacant: "فارغة",
      Property_Tab_Pending: "تحت المراجعة",
      Property_Tab_Incomplete: "غير مكتملة",
      Property_Tab_AwaitInvitation: "بانتظار دعوة",
      Property_Tab_Inactive: "غير مفعل",
      Property_Option_EditProperty: "تعديل العقار",
      Property_Option_Agreement: "العقد",
      Property_Option_BankAccount: "الحساب البنكي",
      Property_Option_PropertyStatus: "حالة العقار",
      Property_Option_View_Change_Edit_Bank: "إظهار/ تعديل الحساب البنكي",
      Property_Option_View_ChangeEditAgreement: "إظهار/ تعديل العقد",
      Property_Option_FeatureProperty: "ميّز عقارك",
      Property_Option_VacateRequest: "طلب إخلاء",
      Property_MyCurrentRentals: "عقاراتي المستأجرة",
      Property_PreviousTenants: "المستأجرين السابقين",
      Property_CurrentTenant: "المستأجرين الحاليين",
      Property_NoTenantInformationAvailable: "لا يوجد معلومات عن المستأجر",
      Property_InviteTenants: "دعوة مستأجر",
      // Property_Note_ToSendInvitations
      Property_MoveInDate: "تاريخ الانتقال",
      Property_MoveDate: "تاريخ الانتقال",
      Property_DoYouWantGenerateInvoice: "هل ترغب في إصدار فاتورة لفترة الإيجار الحالية؟",
      Property_ContractPeriod: "فترة العقد",
      Property_SendInvite: "أرسل الدعوة",
      Property_Months: "شهور",
      Property_ContractFinishIn: "ينتهي العقد في",
      Property_since: "منذ",
      Property_NoAmenities: "لا وسائل راحة",
      Property_NumberOfParking: "عدد المواقف",
      Property_Bathroom: "الحمامات",
      Property_Bedroom: "الغرف",
      Property_Overview: "عام ",
      Payment_ReceivedPayments: "المدفوعات المستلمة",
      Payment_InvoicedPayments: "المدفوعات المفوترة",
      Payment_OverduePayments: "المدفوعات المتأخرة",
      Payment_NotInvoicedPayments: "المدفوعات غير المفوترة",
      Payment_NextMonthInvoice: "فواتير الشهر القادم",
      Payment_NextMonthPayment: "مدفوعات الشهر القادم",
      Payment_CarryoverInvoice: "الفواتير المتأخرة ",
      Payment_CarryoverPayment: "المدفوعات المستلمة سابقاً",
      Payment_Received: "مستلم",
      Payment_TableHeading_TenantName: "اسم المستأجر",
      Payment_TableHeading_PaidOn: "دفع في",
      Payment_TableHeading_DueDate: "تاريخ الاستحقاق",
      Payment_TableHeading_RentCharges: "قيمة الإيجار",
      Payment_TableHeading_TransactionId: "رقم العملية",
      Payment_TableHeading_InvoiceNo: "رقم الفاتورة",
      Payment_TableHeading_SendReminder: "أرسل تذكير",
      Payment_Option_Select: "إختار",
      Payment_Option_SendInvoiceReminder: "أرسل تذكير بالفاتورة",
      Payment_Option_ViewInvoice: "إظهار الفاتورة",
      Payment_Option_DownloadInvoice: "تحميل الفاتورة",
      Payment_InvoiceNo: "رقم الفاتورة",
      Payment_Status_Paid: "دفعت",
      Payment_Status_NotPaid: "لم تدفع",
      Payment_Status_Due: "مستحقة",
      Payment_Details_From: "من",
      Payment_Details_To: "إلى",
      Payment_Details_SecurityFees: "رسوم التأمين",
      Payment_Details_GrandTotal: "المجموع الكلي",
      Payment_Details_PayNow: "ادفع الآن",
      Payment_Details_PaidByCash: "دفعت نقداً",
      Payment_Details_Poweredby: "Powered by",
      Payment_Details_PaidByCheque: " دفعت بالشيك",
      Payment_Details_InvoiceDetails: "تفاصيل الفاتورة ",
      Payment_Details_Invoiceof: "فاتورة شهر",
      Payment_Details_IssueDate: "تاريخ الاصدار",
      // Bank
      Bank_AccountName: "اسم الحساب",
      Bank_AccountNumber: "رقم الحساب",
      Bank_IBANnumber: "رقم IBAN",
      Bank_AddBankAccount: "أضف حساب بنكي",
      Bank_Update: "تحديث",
      Bank_Not_Approved: 'لم يتم مراجعته بعد',
      Add_Bank_BankInformation: "المعلومات البنكية",
      Add_Bank_BankName: "اسم البنك",
      Add_Bank_BranchName: "اسم الفرع",
      Add_Bank_SwiftCode: "سويفت كود",
      Add_Bank_BankLetter_HeaderOfBankStatement:
        "رسالة البنك/ مقدمة كشف الحساب",
      Add_Bank_YourAccountPhoto:
        "*صورة رسالة البنك أو كشف الحساب تستخدم للتأكد من البيانات المدخلة وتفادي أي أخطاء في الإدخال.",
      Withdrawals_AccruedPayment: "المبالغ المستحقة",
      Withdrawals_ReceivedPayments: "المبالغ المستلمة",
      Withdrawals_Withdrawals: "المبالغ المحوّلة",
      Withdrawals_PropertyName: "اسم العقار",
      Withdrawals_DueDate: "مستحقة قبل",
      Withdrawals_RentCharges: "قيمة الإيجار",
      Withdrawals_Fees: "الرسوم",
      Withdrawals_YouReceived: "المبلغ المستحق النهائي",
      Withdrawals_InvoiceAmount: "مبلغ الفاتورة",
      Tenants: "المستأجرين",
      Tenants_Tab_ListOfTenants: "قائمة المستأجرين",
      Tenants_Tab_PastTenants: "المستأجرين السابقين",
      Tenants_TableHeading_TenantName: "اسم المستأجر",
      Tenants_TableHeading_Address: "العنوان",
      Tenants_TableHeading_PhoneNo: "رقم الهاتف",
      Tenants_TableHeading_Mail: "الإيميل",
      Tenants_TableHeading_Since: "منذ",
      Tenants_TableHeading_Chat: "محادثة",
      Tenants_TableHeading_Rating: "تقييم",
      Requests_Requests: "الطلبات",
      Requests_DateRange: "نطاق التاريخ",
      Requests_selectDateRange: "اختر نطاق التاريخ",
      Requests_ApplyFilter: "التصفية",
      Requests_ClearFilter: "مسح التصفية",
      Requests_Tab_BookingRequest: "طلبات الحجز",
      Requests_Tab_Maintenance: "الصيانة",
      Requests_Tab_Queries: "طلبات عامة",
      Requests_Tab_MaintenanceReminder: "التذكيرات",
      Requests_TableHeading_Subject: "العنوان",
      Requests_TableHeading_Property: "العقار",
      Requests_TableHeading_DateTime: "التاريخ و الوقت",
      Requests_TableHeading_SubmittedBy: "مقدم طلب الصیانة",
      Requests_TableHeading_ShareWith: "شارك",
      Requests_Add_PlaceRequest: "قدّم طلب صيانة",
      Requests_Add_PlaceQuery: "قدّم طلب عام",
      Requests_Add_AddReminder: "إضافة تذكير",
      Requests_AddForm_SelectProperty: "اختر العقار",
      Requests_AddForm_SelectAddress: "اختر العنوان",
      Requests_AddForm_Subject: "الموضوع",
      Requests_AddForm_Importance: "الأهمية",
      Requests_AddForm_Recurrence: "التكرار",
      Requests_AddForm_RecurrenceDate: "تاريخ التكرار",
      Requests_AddForm_Description: "الوصف",
      Requests_AddForm_Photo: "الصور",
      Requests_AddForm_DoYouWantToShareReminder:
        "هل ترغب في مشاركة هذا التذكير مع المستأجرين؟",
      Requests_AddForm_Yes: "نعم",
      Requests_AddForm_No: "لا",
      Requests_PerMonth: "شهرياً",
      Requests_AddForm_YouCanUploadOnlyFiveImages: "تستطيع إرفاق 5 صور",
      Requests_RequestDetails: "تفاصيل الطلب",
      Requests_Photos: "الصور",
      Requests_ClickTheButtonIfThisRequestResolved:
        "الرجاء الضغط على الزر في حال تمّ حل هذا الطلب",
      Requests_ClickTheButtonIfThisReopenMaintenanceRequest:
        "الرجاء الضغط على الزر في حال رغبتك في إعادة فتح الطلب",
      Requests_Resolve: "تم حلّها",
      Requests_Notes: "ملاحظات",
      Requests_AreYouSureYouWantToReopenTheRequest:
        "هل أنت متاكد من رغبتك في إعادة فتح الطلب؟",
      Requests_RequestSent: "تم إرسال الطلب",
      Requests_PaymentPending: "بانتظار الدفع",
      Requests_Reject: "رفض",
      Requests_Reason: "الأسباب",
      // Vacate Requests
      VacateRequest_Tab_Sent: "مٌرسل",
      VacateRequest_Tab_Received: "مُستلم",
      VacateRequest_VacateDate: "تاريخ الإخلاء",
      VacateRequest_VacateDetails: "تفاصيل الإخلاء",
      VacateRequest_RemainingPeriod: "الفترة المتبقية",
      VacateRequest_ReasonVacateProperty: "أسباب إخلاء العقار",
      VacateRequest_UploadedDocument: "رفع المستندات",
      VacateRequest_PaymentStatus_PaymentPending: "بانتظار الدفع",
      VacateRequest_PaymentStatus_Accept: "موافق",
      VacateRequest_PaymentStatus_RequestAccepted: "تمت الموافقة على الطلب",
      VacateRequest_PaymentStatus_Rejected: "مرفوض",
      VacateRequest_PaymentStatus_Accepted: "تمت الموافقة",
      VacateRequest_AdvanceRentAmount: "قيمة مقدم الإيجار",
      VacateRequest_Pay: "دفع",
      VacateRequest_MakePayment: "ادفع",
      VacateRequest_PaymentDetails: "تفاصيل الدفع",
      VacateRequest_MonthlyRent: "الإيجار الشهري",
      VacateRequest_AdvanceRent: "مقدم الإيجار",
      VacateRequest_TotalRent: "الإيجار الكلي",
      VacateRequest_Total: "المجموع",
      VacateRequest_NoRequests: "لا يوجد طلبات",
      //cookies
      cookies:
        "نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك ولتحسين الوظائف وموقعنا على الإنترنت. من خلال الاستمرار في زيارة هذا الموقع، فإنك توافق على استخدامنا لملفات تعريف الارتباط. ",
      // Team
      Team_Team: "الفريق",
      Team_AddTeam: "إضافة عضو",
      Team_EditTeam: "تعديل الأعضاء",
      Team_TableHeading_Name: "الاسم",
      Team_TableHeading_RoleName: "اسم الصلاحية",
      Team_Option_Edit: "تعديل",
      Team_Option_ActiveStatus: "فعّال",
      Team_Option_ManageProperty: "إدارة العقار",
      Team_Option_Delete: "حذف",
      Team_AddForm_EnterFullName: "أدخل الاسم كاملاً",
      Team_AddForm_EnterEmailId: "أدخل البريد الإلكتروني",
      Team_AddForm_RoleType: "نوع الصلاحية",
      Team_TeamControl: "التحكم بالفريق ",
      Team_CreatedRole: "الصلاحيات المضافة",
      Team_AddNewRole: "إضافة صلاحية",
      properties: "العقارات",
      invoice: "الفواتير",
      request: "الطلبات",
      vacate: "طلبات الإخلاء",
      tenants: "المستأجرين",
      withdrawals: "المبالغ المحوّلة",
      team: "الفريق",
      bank: "البنوك",
      reports: "التقارير",
      view: "إظهار",

      export: "تحميل",
      recieveAsCash: "استُلم نقداً",
      markResolve: "تأشير بتم الحل",
      canAcceptOrReject: "القبول أو الرفض",
      doVacateRequest: "تقديم طلب إخلاء",
      add: "إضافة",
      edit: "تعديل",
      Team_EnterRoleName: "أدخل اسم الصلاحية",
      //    chat
      Chat_MessageBoard: "رسالة عامة",
      Chat_SearchForPerson: "البحث عن شخص",
      Chat_Offline: "غير متصل",
      Chat_Online: "متصل",
      Chat_TypeYourMessageHere: "اكتب رسالتك هنا",
      // Reports
      Reports_Tab_CollectedPayments: "المدفوعات المستلمة",
      Reports_Tab_LatePayment: "المدفوعات المتأخرة",
      Reports_TableHeading_PropertyName: "اسم العقار",
      Reports_TableHeading_Address: "العنوان",
      Reports_TableHeading_PaidOn: "دفع بتاريخ",
      Reports_TableHeading_RentCharges: "قيمة الإيجار",
      Reports_Options_Download_CSV: "تحميل ملف CSV",
      Reports_Options_GenerateReport: "إظهار التقارير",
      // Setting
      Setting_Tab_ChangePassword: "تغيير كلمة السر",
      Setting_Tab_ChangePassword_CurrentPassword: "كلمة السر الحالية",
      Setting_Tab_ChangePassword_NewPassword: "كلمة السر الجديدة",
      Setting_Tab_ChangePassword_ConfirmPassword: "تأكيد كلمة السر",
      Setting_Tab_ContactUs: "تواصل معنا",
      Setting_Tab_TermsAndConditions: "الشروط والأحكام",
      // Add Property
      InvoiceDetails: "تفاصيل الفاتورة/الفواتير الأولى",
      PreviewInvoice: "‎مشاهدة الفاتورة",
      AddProperty_AddProperty: "أضف عقار",
      AddProperty_MyProperties: "عقاراتي",
      AddProperty_PleaseNote: "الرجاء الملاحظة",
      AddProperty_PText:
        "لاستكمال إضافة العقار، نرجو منك إرفاق وثيقة تثبت امتلاكك أو أحقيتك لإدارة للعقار المضاف،",
      AddProperty_Deed: "وثيقة الملكية",
      AddProperty_AddressCard: "بطاقة العنوان",
      AddProperty_ElectricityBill: "فاتورة الكهرباء",
      AddProperty_AnyOtherDocumnets: "أخرى",
      AddProperty_DontRemindThis: "لا تذكرني مرة أخرى",
      AddProperty_Continue: "المواصلة",
      AddProperty_AddPropertyManually: "إضافة العقار فردياً",
      AddProperty_UploadFromCSV: "إضافة العقار برفع ملف CSV",
      AddProperty_Etemplate: "نموذج Eglooz",
      AddProperty_RecentAgreement: "العقود المستخدمة مؤخراً",
      AddProperty_OwnAgreement: "حمل العقود الخاصة بك",
      AddProperty_Submit: "أرسل",
      AddProperty_Filter: "تصفية",
      AddProperty_NEXTSTEP: "الخطوة التالية",
      AddProperty_SortBy: "ترتيب حسب",
      AddProperty_Tab_Information: "معلومات أساسية",
      AddProperty_Tab_Location: "العنوان والموقع",
      AddProperty_Tab_PaymentDetails: "تفاصيل المدفوعات",
      AddProperty_Tab_PropertyDetails: "تفاصيل العقار",
      AddProperty_Tab_Specifications: "الخصائص",
      AddProperty_Tab_Gallery: "الاستوديو",
      AddProperty_Tab_Documents: "المستندات",
      AddProperty_Information_OwnerName: "اسم المالك",
      AddProperty_Information_PHONE: "الهاتف",
      AddProperty_Information_PropertyName: "اسم العقار",
      AddProperty_Information_SelectType: "النوع",
      AddProperty_Information_PropertyType: "نوع العقار",
      AddProperty_Information_PropertyStatus: "حالة العقار",
      AddProperty_Location_UnitNo: "رقم الوحدة",
      AddProperty_Location_BuildingNo: "رقم المبنى",
      AddProperty_Location_RoadName: "رقم / اسم الشارع",
      AddProperty_Location_BlockNo: "رقم المجمع",
      AddProperty_Location_City: "المدينة",
      AddProperty_Location_Country: "الدولة",
      AddProperty_Location_Location: "الموقع",
      AddProperty_PaymentDetails_RentAmount: "قيمة الإيجار",
      AddProperty_PaymentDetails_Monthly: "شهرياً",
      AddProperty_PaymentDetails_EWA: "الكهرباء والماء",
      AddProperty_PaymentDetails_Inclusive: "شاملة",
      AddProperty_PaymentDetails_Partial: "شامل مع جزئي",
      AddProperty_PaymentDetails_Exclusive: "غير شاملة",
      AddProperty_PaymentDetails_OtherPayments: "مدفوعات أخرى",
      AddProperty_PaymentDetails_SelectAdvanceAmount: "اختر مبلغ المقدم",
      AddProperty_PaymentDetails_oneYearAdvance: "سنة مقدم",
      AddProperty_PaymentDetails_MonthAdvance: "شهر مقدم",
      AddProperty_PaymentDetails_ShouldPriceNegotiable:
        "هل مبلغ الإيجار قابل للتفاوض؟",
      AddProperty_PaymentDetails_Amount: "المبلغ",
      AddProperty_PaymentDetails_SelectContractPeriod: "اختر فترة العقد",
      AddProperty_PaymentDetails_GRACEPERIOD: "فترة السماح",
      AddProperty_PaymentDetails_Month: "شهر",
      AddProperty_PaymentDetails_oneYear: "سنة",
      AddProperty_PaymentDetails_SecurityAmount: "مبلغ التأمين",
      AddProperty_PaymentDetails_Amount_Refundable: "المبلغ (مسترد)",
      AddProperty_PaymentDetails_InvalidAmount: "المبلغ غير صحيح",
      AddProperty_PaymentDetails_AdministrationFee: "رسوم الإدارة",
      AddProperty_PaymentDetails_ParkingFee: "رسوم المواقف",
      AddProperty_PaymentDetails_HousekeepingFee: "رسوم النظافة",
      AddProperty_PaymentDetails_MaintenanceFee: "رسوم الصيانة",
      AddProperty_PaymentDetails_Others: "أخرى",
      AddProperty_PaymentDetails_AddMore: "أضف أكثر ",
      AddProperty_PaymentDetails_Text: "النص",
      AddProperty_PropertyDetails_AreaSquareMeters: "المساحة (متر مربع)",
      AddProperty_PropertyDetails_Bedrooms: "الغرف",
      AddProperty_PropertyDetails_Toilets: "الحمامات",
      AddProperty_PropertyDetails_Furnishing: "الفرش",
      AddProperty_PropertyDetails_BuildingFloors: "عدد الطوابق ",
      AddProperty_PropertyDetails_FloorNumber: "الطابق",
      AddProperty_PropertyDetails_YearOfConstruction: "سنة البناء",
      AddProperty_PropertyDetails_State: "المدينة (المنطقة)",
      AddProperty_PropertyDetails_NumberOfBalconies: "البلكونات",
      AddProperty_PropertyDetails_Discription: "الوصف",
      AddProperty_PropertyDetails_EnterAreaProperty: "مساحة العقار",
      AddProperty_PropertyDetails_Parking: "المواقف",
      AddProperty_PropertyDetails_NotFurnished: "غير مفروشة",
      AddProperty_PropertyDetails_PartiallyFurnished: "نصف مفروشة ",
      AddProperty_PropertyDetails_FullFurnished: "مفروشة",
      AddProperty_PropertyDetails_FurnitureList: "قائمة الفرش",
      AddProperty_PropertyDetails_FurnitureDescription: "وصف الفرش",
      AddProperty_PropertyDetails_New: "جديد",
      AddProperty_PropertyDetails_CompletelyRefurbished: "مجددة بالكامل",
      AddProperty_PropertyDetails_PartiallyRefurbished: "مجددة جزئيا",
      AddProperty_Specifications_Amenities: "وسائل الراحة",
      AddProperty_Specifications_ShowAllAmenities: "إظهار الكل",
      AddProperty_Specifications_HideAmenities: "أقل",
      AddProperty_Specifications_Landmark: "المعالم (بالقرب من)",
      AddProperty_Specifications_AddLandmark: "أضف معلم",
      AddProperty_Gallery_YouCanUploadOnly:
        "تستطيع إضافة 10 صور، أقل أبعاد (300X390)",
      AddProperty_Gallery_ImageName: "اسم الصورة",
      AddProperty_Gallery_UploadAndSaveImage: "رفع وحفظ الصورة",
      AddProperty_Gallery_Note: "ملاحظة :",
      AddProperty_Gallery_UploadProof: "رفع مستندات إثبات الملكية",
      AddProperty_Gallery_Max: "الحد الاعلى 5MB",
      AddProperty_Document_DocumentName: "اسم المستند",
      AddProperty_Document_DocumentType: "نوع المستند",
      AddProperty_Document_UploadAndSaveDocument: "رفع وحفظ المستند",
      AddProperty_ApprovalPreview_GreatYourPropertyHas:
        "تهانينا! تم تحديث عقارك",
      AddProperty_ApprovalPreview_GreatYourPropertyWill:
        "تهانينا! تم تقديم عقارك",
      AddProperty_ApprovalPreview_GreatYourPropertyLinkBank:
        "يرجى ربط حسابك المصرفي للموافقة",
      AddProperty_ApprovalPreview_GreatYourPropertyUploaded:
        "تهانينا! تم إضافة العقار الخاص بك",
      AddProperty_ApprovalPreview_updated: "بنجاح",
      AddProperty_ApprovalPreview_AddYourPropertyAgreement:
        "أضف عقد إيجار لعقارك",
      AddProperty_ApprovalPreview_submitForApproval: "للمراجعة",
      AddProperty_ApprovalPreview_LinkYourBankAccount: "اربط حسابك البنكي",
      AddProperty_ApprovalPreview_MoreStepsRentYour:
        "تبقّت خطوتين لتأجير عقارك و البدء بتحصيل الإيجار عبر الانترنت.",
      AddProperty_ApprovalPreview_One: "",
      AddProperty_ApprovalPreview_Few: "",
      // AddProperty_ApprovalPreview_Two : 'اثنان',
      // Login and Registration  Page
      Phone: "الهاتف",
      Password: "الرقم السري",
      ForgotPassword: "نسيت الرقم السري؟",
      ContinueGuest: "المواصلة كزائر",
      RegisterNow: "سجل الآن",
      SelectType: "اختار النوع",
      SelectTypeOfRole: "اختر نوع الحساب",
      Continue: "المواصلة",
      SignUP: "سجل الآن",
      TellMeMore: "مرحباً، أخبرنا أكثر عنك",
      FullName: "الاسم الكامل",
      PhoneNumber: "رقم الهاتف",
      Re_EnterPassword: "إعادة إدخال الرقم السري",
      Accept: "موافق",
      Terms: "الشروط",
      PrivacyPolicy: "الخصوصية",
      And: "و",
      AlreadyHaveAnAccount: "أمتلك حساباً",
      PasswordConfirmNotMatch: "الرقم السري وتأكيد الرقم السري لا يتطابقان",
      Agent_Manager: "المدير",
      TeamMember: "عضو فريق",
      EnterOneTimePassword: "أدخل رمز التحقق",
      OTP_HasBeenSent: "لقد تم إرسال رمز التحقق لهاتفك",
      OTP_verification: "رجاءً قم بإدخال الرمز هنا للتحقق",
      OTPCode: "رمز التحقق",
      Re_sendOTP: "إعادة إرسال رمز التحقق!",
      DragAndDropOr: "اسحب وضع",
      SelectIdentity: "اختر نوع الهوية",
      EnglishName: "الاسم كما يظهر في الهوية (إنجليزي)",
      ArabicName: "الاسم كما يظهر في الهوية (عربي)",
      AddProperty_: "رقم الهوية",
      IdentityNumber: "رقم الهوية",
      ExpiryDate: "تاريخ الانتهاء",
      Upload: "رفع",
      // Explore
      Explore_PropertiesAvailableForRent: "عقارات متاحة للإيجار",
      Explore_ExclExtraCharges: "باستثناء الرسوم الأخرى",
      Explore_ChangeRequest: "تغيير الطلب",
      // Explore_BookNow: 'احجز الآن',
      Explore_BookNow: "اطلب الآن",
      Explore_PlaceRequest: "قدّم طلبك",
      Explore_MoveInDate: "تاريخ الانتقال",
      Explore_SelectContractPeriod: "اختر فترة العقد",
      Explore_NoOfOccupancy: "عدد الساكنين",
      Explore_Address_Location: "العنوان/ الموقع",
      Explore_Landmark_NearbyLocation: "المعالم (بالقرب من)",
      Explore_AdministrationFees: "رسوم الإدارة",
      Explore_ParkingFees: "رسوم المواقف",
      Explore_HousekeepingFees: "رسوم النظافة",
      Explore_MaintenanceFees: "رسوم الصيانة",
      Explore_OtherFees: "رسوم أخرى",
      Explore_Contractperiod: "فترة العقد",
      Explore_GracePeriod: "فترة السماح",
      Explore_SecurityAmount: "مبلغ التأمين",
      Explore_Review: "التعليقات",
      Explore_Accuracy: "الدقة",
      Explore_Communication: "التواصل ",
      Explore_Cleanliness: "النظافة",
      Explore_MonthlyPrice: "الإيجار الشهري",
      Explore_RentAmountRange: "مبلغ الإيجار",
      Explore_MinBHD: "أقل من د.ب",
      Explore_MaxBHD: "أكثر من د.ب",
      // Property Details
      Property_OTPVerification: "رمز التحقق",
      Property_PleaseTypeTheVerificationCode: "رجاءً قم بإدخال رمز  التحقق",
      // Reiew & Rating
      ReviewRating_ReviewRating: "التقييم والتعليقات",
      ReviewRating_RATE: "التقييم",
      ReviewRating_ShareYourFeedback: "شارك رأيك؟",
      ReviewRating_HowSatisfiedAreYou: "ما مدى رضائك عن المالك؟",
      ReviewRating_Perfect: "ماهو أفضل شي أحببته؟",
      Profile_AddCompany: "أضف شركتك",
      Profile_EditCompany: "تعديل الشركة",
      Profile_About: "عن ",
      Profile_Job: "المهنة",
      Profile_RegisteredAddress: "العنوان",
      Profile_RegisterOn: "عضو منذ",
      Profile_IdentityInformation: "معلومات الهوية",
      Profile_Number: "الرقم ",
      Profile_Passport: "الجواز",
      Profile_ExpiryDates: "تاريخ النتهاء",
      Profile_Profile: "الملف الشخصي",
      Profile_User: "المستخدم",
      Profile_Username: "اسم المستخدم",
      Profile_Save: "حفظ",
      Profile_InvalidEmailId: "الإيميل غير صحيح",
      // Invites
      Invites_Invites: "الدعوات",
      Invites_InviteDate: "تاريخ الدعوة",
      // Booking
      Booking_TenantFile: "تقدیم الطلب من",
      Booking_AcceptRequestBy: "تمت الموافقة على الطلب بواسطة",
      Booking_SendingContract: "تم توقيع العقد",
      Booking_LandlordSignThe: "المالك وقع ",
      Booking_contract: "العقد",
      Booking_TenantMakeThe: "المستأجر قام ب ",
      Booking_AgreementPendingFromTenantSide: "العقد بانتظار توقيع المستأجر",
      Booking_AgreementPendingFromLandlordSide: "العقد بانتظار توقيع المالك",
      Booking_PaymentPendingFromTenantSide: "بانتظار الدفع من قبل المستأجر",
      Booking_ClickAcceptToProceedFurtherSteps:
        "اضغط على موافق للإنتقال للخطوات التالية",
      Booking_TenantSignature: "توقيع المستأجر",
      Booking_ClickHereToSign: "اضغط هنا للتوقيع",
      Booking_LandlordSignature: "توقيع المالك",
      Booking_BeforeAcceptingTenantRequest:
        "قبل موافقتك على طلب المستأجر تأكد من إرفاقك للعقد الصحيح، عند حاجتك لتغير العقد اضغط على إعادة إرفاق العقد",
      Booking_Re_uploadAgreement: "إعادة إرفاق العقد",
      Booking_AcceptAndSendAgreement: "الموافقة وإرسال العقد",
      Booking_CancelTheRequest: "إلغاء الطلب",
      Booking_ClickCancel_If: "(اضغط على إلغاء، لحذف أو إلغاء الطلب)",
      Booking_AgreementContract: "العقد",
      Booking_ClickHereToView: "اضغط هنا لمشاهدة العقد",
      Booking_AdministrativeFees: "رسوم الإدارة",
      // ManageProperty,
      ManageProperty_SearchProperty: "البحث عن عقار",
      ManageProperty_SearchPropertyByName: "البحث باستخدام الاسم",
      ManageProperty_NameA_Z: "الأسماء: أ - ي",
      ManageProperty_NameZ_A: "الأسماء: ي - أ",
      ManageProperty_AddressA_Z: "العنوان:أ-ي",
      ManageProperty_AddressZ_A: "العنوان:ي-أ",
      ManageProperty_UpdateASSIGN: "تحديث ",
      ManageProperty_Relevance: "ملائمة",
      RequestAdded: "تم إضافة الطلب",
      QueriesAdded: "تم إضافة طلبات عامة",
      Home_SEARCHBYTYPE: "البحث حسب النوع ",
      Home_AddPrivacyHere: "سياسة حماية الخصوصية",
      YouNeedToUploadYourIdentityCardsAndDocuments:
        "تحتاج إلى تحميل بطاقة الهوية والمستندات الخاصة بك.",
      UploadId: "تحميل الهوية",
      LivingSince: "مقيم منذ",
      VacateRequest: "طلبات الإخلاء",
      TransactionId: "رقم العملية",
      InvoiceDate: "تاريخ الفاتورة",
      NoNotificationsFound: "لا يوجد تنبيهات",
      RequestResolved: "تم حل الطلب",
      AreYouSureYouWantToResolveTheRequest: "هل أنت متأكد أنك تريد حل الطلب؟",
      ResolveNotes: "ملاحظات حل الطلب",
      ReopenNotes: "ملاحظات إعادة فتح الطلب",
      About_Company: "عن الشركة",
      Total_Properties: "إجمالي العقارات",
      Vacant_Properties_Listed: "عقارات شاغرة",
      Booking_CancelAllRequest: "إلغاء جميع الطلبات",
      Booking_CancelRequest: "إلغاء الطلب",
      Are_You_Sure_You_Want_To_Cancel_All_Request:
        "هل أنت متأكد من رغبتك في إلغاء جميع الطلبات؟",
      Are_You_Sure_You_Want_To_Cancel_The_Request:
        "هل أنت متأكد من رغبتك في إلغاء الطلب؟",
      No_Agreement_Added: "لم يتم إضافة اتفاقية",
      Upload_Agreement: "تحميل الاتفاقية",
      AddProperty_Information_CompanyName: "اسم الشركة",
      Payment_Details_Invoicefrom: "فاتورة من",
      Role: "الصلاحية",
      Mob_No: "رقم الهاتف",
      //    chat
      CompanyProfile: "ملف الشركة",
      // Add Property
      Invoice_Duration: "المدة الزمنية",
      AddProperty_ApprovalPreview_GreatYourPropertyHasBeen:
        "تهانينا! تم إضافة العقار الخاص بك",
      AddProperty_ApprovalPreview_UpdatedForApproval: "بنجاح",
      AddProperty_ApprovalPreview_CongratsYourAgreementHasBeen:
        "تهانينا! لقد تم تحديث اتفاقيتك",
      AddProperty_ApprovalPreview_updatedSuccessfully: "بنجاح",
      to: "إلى",
      from: "من عند",
      AddProperty_ApprovalPreview_MoreStepRentYour:
        "تبقّت خطوة واحدة فقط لتأجير عقارك و تحصيل الإيجار إلكترونياً و بسهولة",
      Property_Year: "سنة",
      Town_City: "المدينة",
      addIfApplicable: "(إن وجد)",
      AddProperty_PaymentDetails_MonthsAdvance: "شهور مقدم",
      AddProperty_PaymentDetails_Months: "شهور",
      Property_Option_RenewContract: "تجديد العقد",
      Payment_TableHeading_RentAmount: "مبلغ الإيجار",
      Click_to_Send_Reminder: "اضغط على الزر في حال تم الانتهاء من التذكير",
      EditPropertyNote:
        "إن تغيير حالة العقار سينتج تغييرات في التفاصيل المالية والعقد. يرجى تعديل التفاصيل المالية للحفظ",
      Explore_PreviousContractPeriod: "فترة العقد السابقة",
      Booking_NoContractAttached:
        "قبل قبول المستأجر تأكد من اختيارك للعقد الصحيح. إذا رغبت في رفع عقد اضغط على أيقونة تحميل العقد",
      InActiveTeamMember: "هل ترغب في إيقاف حساب العضو فعلاً؟",
      ActiveTeamMember: "هل ترغب في تفعيل حساب العضو فعلاً؟",
      Dashboard_CurrentMonth: "الشهر الحالي",
      Dashboard_VacatePropertyRequest: "طلب إخلاء",
      Dashboard_TableHading_QUERY: "الطلبات العامة",
      Property_Note_ToSendInvitations:
        "Note : To send invitations to multiple tenants, visit the website for bulk upload",
      Payment_PaymentsHistory: "المدفوعات السابقة",
      VacateRequest_Other: "أخرى",
      VacateRequest_Browse: "تصفح",
      VacateRequest_DragAndDropImageHere: "اسحب و ضع الصورة هنا",
      VacateRequest_Max5MB: "أعلى حجم للملفات 5 MB",
      Booking_step_stepThreeStepCompleted: "step stepThree stepCompleted",
      Booking_step_stepThreeInProgress: "step stepThree inProgress",
      Booking_step_stepThree: "step stepThree",
      Booking_step_stepFourStepCompleted: "step stepFour stepCompleted",
      Booking_step_stepFourInProgress: "step stepFour inProgress",
      Booking_step_stepFour: "step stepFour",
      Invites_: "",
      Listed: "مدرجة:",
      Properties: "عقارات",
      Available_For_Rent: "متاحة للإيجار:",

      Covid_Nav_Text: "نقدم الدعم لك و لمؤسستك خلال كوفيد – 19. ",
      Covid_Nav_Text_Find_More: "اكتشف المزيد",
      Covid_Pledge: "نتعهد بتقديم الدعم لك و لمؤسستك خلال كوفيد – 19",
      Covid_Proptech_Solution:
        "توفر لك منصتنا الفريدة في تكنولوجيا العقار الدعم المستمر لإدارة أعمالك العقارية من خلال توفير التكنولوجيا الرقمية لك وللمستأجرين. إنها تمكنك من التواصل بشكل أفضل مع المستأجرين وتشجيع الدفع عبر الإنترنت.",
      Covid_Get_In_Touch: "ابقَ على تواصل",
      Covid_Buisness_Plan: "خطتنا في استمرارية العمل خلال كوفيد – 19",
      Covid_Buisness_Description:
        "لقد غير الفايروس الروتين اليومي و الطريقة التي نعيش ونعمل بها! نحن في Eglooz نبذل قصارى جهدنا لضمان صحة وسلامة الأشخاص في البحرين خلال هذه الجائحة. تتضمن خطتنا في استمرارية العمل التعهد بدعم مجال تأجير العقارات في مملكة البحرين.",
      Covid_Buisness_Read_More: "اقرأ المزيد ->",
      Covid_Buisness_Measures:
        "كجزء من تدابيرنا الاحترازية، قمنا بتنفيذ الإجراءات التالية:",
      Covid_Buisness_Measures_1:
        "اعتبارًا من يوم الأحد 14 مارس 2020 ، سيعمل جميع موظفي Eglooz من المنزل و حتى إشعار آخر",
      Covid_Buisness_Measures_2:
        "سيبقى مكتب Eglooz مفتوحًا لمن هم بحاجة إلى ذلك",
      Covid_Buisness_Measures_3:
        "تم إنشاء منصتنا الرقمية للعمل عن بُعد، مما يسهل الاتصال و التواصل عن طريق المكالمات الصوتية / الفيديو ، والعروض التقديمية المباشرة عن بعد، ومع ذلك لا يزال بإمكاننا حضور اجتماعات العملاء شخصيًا إذا تطلب الأمر ذلك",
      Covid_Buisness_Measures_4: "إلغاء جميع اجتماعات العمل في المكتب",
      Covid_Buisness_Measures_5:
        "تأجيل اللقاءات والتجمعات الكبيرة في الفترة الحالية",
      Covid_Buisness_Measures_6:
        "تم وضع المنصة مسبقًا في مركز بيانات سحابة AWS في مملكة البحرين لتجنب أي انقطاع في الخدمات",
      Covid_Buisness_Additions: "بالإضافة إلى ذلك:",
      Covid_Buisness_Additions_1:
        "يقدم لك مركز سعادة العملاء الدعم المستمر عبر الإنترنت على مدار 24 ساعة طوال أيام الأسبوع بغض النظر عن الظروف في الوقت الراهن",
      Covid_Buisness_Additions_2:
        "توفير خدمات إضافية على سحابة AWS للارتقاء بالأداء إلى مستوى أعلى",
      Covid_Buisness_Additions_3:
        "قامت Eglooz بتوفير مواد التدريب الخاصة بها عبر الإنترنت ويمكن إرسالها إلى صندوق بريدك عند الطلب في أي وقت.",
      Covid_Buisness_Help: "نحن هنا للمساعدة",
      Covid_Buisness_Help_1:
        "يمكنك كتابة استفساراتك و إرسالها إلى happiness@eglooz.com وسيتم الرد عليها عاجلاً",
      Covid_Buisness_Help_2:
        "إذا كنت بحاجة إلى دعم فريقنا، فالرجاء التواصل مع مدير حسابك عبر البريد الإلكتروني أو الهاتف أو WhatsApp",
      Covid_Buisness_Help_3:
        "سنعطي الأولوية لاستفساراتك ونقدم لك الدعم على مدار الساعة طوال أيام الأسبوع حيثما أمكن ذلك",
      Covid_Buisness_Help_4:
        "كجزء من مبادرة كوفيد – 19، أطلقنا أيضًا حملة تسويق توعوية لتشجيع المستأجرين على الدفع عبر الإنترنت من خلال تقديم جوائز لمن يدفع عن طريق منصة Eglooz. لقد شهدنا استجابة كبيرة من قبل المستأجرين وارتفعت نسبة المعاملات البنكية لتصل إلى 260٪ مقارنةً بشهر مارس.",
      Covid_Buisness_Help_5:
        "نقوم أيضًا بإرشاد عملائنا بانتظام من خلال Instagram و LinkedIn و Facebook و Twitter - بأفضل النصائح حول كيفية الحفاظ على صحتهم وتعزيز ممارسات النظافة والعزل الذاتي. لقد طلبنا من موظفي Eglooz الالتزام بمعايير التواصل الوقائية الخاصة بنا في حال وجود أي تواصل لا يمكن تجنبه مع عملائنا.",
      Covid_Buisness_Help_6:
        "إن استمرارية التواصل مع عملائنا أمر مهم ونحن على أتم الاستعداد لتقديم الدعم عن بعد إذا لزم الأمر، ولقد تأكدنا من توفر جميع الأدوات المطلوبة لتواصل موظفينا مع العملاء و الاتصال بهم.",
      Covid_Buisness_Help_7:
        "إذا كان لديك أي استفسار ، يرجى التواصل مع جهة اتصال Eglooz في أي وقت",
      Covid_Digital_Tools: "أدوات رقمية للاستفادة منها",
      Covid_Digital_Tools_Description:
        "لدى Eglooz مجموعة من الخدمات المتوفرة  - الإعلان، العقود الذكية، الدفع عبر الإنترنت، إصدار الفواتير والإيصالات آليًا، طلب الصيانة، لوحة تحكم رقمية وإرسال التذكيرات والإشعارات، إلخ. جميع خدمات المنصة متاحة عبر الإنترنت مجانًا حتى مايو 2020.",
      Covid_Digital_Tools_Image_1: "التسجيل أونلاين",
      Covid_Digital_Tools_Image_2: "تدريب فرق العمل أونلاين",
      Covid_Digital_Tools_Image_3: "إدارة الحسابات 24 ساعة",
      Covid_Digital_Tools_Image_4: "مساعدة المستأجرين أونلاين",
      Covid_Digital_Tools_Contact: "تواصل معنا",
      Covid_Contact_Us: "اتصل بنا و ابدأ رحلتك للتحول الرقمي الآن",
      Covid_Think_Wise: "فكر بحكمة – اختر Eglooz!",

      Add_Tenant: "إضافة مستأجر",
      YouCanUploadYourIdentityCardsAndDocuments: "بإمكانك تحميل هوية المستأجر ",
      Tenants_TableHeading_Delete: "حذف",
      No_Tenant_Found: "لم يتم العثور على مستأجر",
      VideoImage: "assets/img/videoAr.png",
      Home_OurPride: "Our Pride",
      Home_SuccessPartners: "شركاء النجاح",
      assign: "تخويل",
      Payment_Option_EditInvoice: "تعديل الفاتورة",
      Request_Tab_Maintenance_Cost: "صيانة / تكلفة",
      Property_details_Rent_Eligibility: "فترة الإيجار / الشهر",
      Requests_AddForm_DoYouWantToShareCost:
        "هل تريد مشاركة هذه الصيانة / التكلفة مع مستأجرك؟",
      AreYouSureYouWantToResolveTheMaintenance:
        "هل أنت متأكد من أنك تريد حل الصيانة؟",
      maintenance_price: "مبلغ الصيانة د.ب",
      Price: "المبلغ",
      Maintenance_Fees: "السعر",
      Maintenance_RequestDetails: "تفاصيل طلب الصيانة",
      Resolved_Images: "صور الحل",
      Rent_Interval: "فترة الإيجار",
      Additional_Fees: "رسوم إضافية",
      Managed_By: "بإدارة",
      Due: "مستحقة",
      Rent_Invoice: "فاتورة إيجار",
      Rent_Eligibility: "فترة الإيجار",
      Total_Amount: "المجموع",
      Eglooz_Platform: "منصة إيجلوز",
      Paid_By_Cheque: "دفعت بالشيك",
      Cheque_No: "شيك رقم",
      Transaction_No: "رقم العملية",
      Pay_To: "ادفع لـ",
      Account_Name: "اسم الحساب",
      Bank_Name: "البنك",
      IBAN_No: "رقم الآيبان",
      Account_No: "رقم الحساب",
      Powered_By: "Powered By",
      paidByNote: "إذا دفع المستأجر نقداً أو بالشيك",
      onlyDebitCard: "البطاقات الإئتمانية فقط (بنفت)",

      No: "لا",
      Yes: "نعم",
      Rent_For: "الإيجار لـ (شهر)",
      Submit_Id: "إرفاق الهوية",
      Complete_Images: "صور الحل",
      Complete_Notes: "ملاحظات الحل",
      Reports_Expense_Card: "بطاقة المصروفات",
      Reports_Rent_Card: "بطاقة الإيجار",
      Reports_Vacant_List: "قائمة العقارات الشاغرة",
      Reports_Not_Paid_Card: "بطاقة - الغير مدفوعة",
      Reports_Property_Statement: "كشف العقار",
      Reports_Withdrawal: "المبالغ المحوّلة",
      Reports_Withdrawals_Report: "المبالغ المحوّلة",
      Reports_To_Be_Renewed: "التجديد",
      Select_City: "اختر المدينة",
      Select_Months: "اختر الشهور",
      Issuing_Date: "تاريخ الإصدار",
      Total_Expenses: "إجمالي المصروفات",
      Maintenance_Status: "حالة الطلب",
      Reports_Printed_By: "تمت الطباعة بواسطة",
      Total_Due_Rent: "إجمالي الإيجار المستحق",
      Rent: "الإيجار",
      Invoice_Update: "تحديث الفاتورة",
      Tenant_Contact: "رقم المستأجر	",
      Rent_Period: "فترة الإيجار",
      Invoice_Status: "حالة الفاتورة",
      Total_Income: "إجمالي الدخل",
      Net_Income: "الدخل الصافي",
      Income: "الدخل",
      Expense: "المصروفات",
      Pay_Date: "تاريخ الدفع",
      Payment_Method: "طريقة الدفع",
      Rent_late: "إيجار متأخر",
      Team_Assigned: "العضو المسؤول",
      Rent_Payment_Date: "تاريخ دفع الإيجار",
      Transfer_Amount: "المبلغ المحوّل",
      Transfer_Date: "تاريخ التحويل",
      System_Reference: "الرقم المرجعي",
      Iban: "الآيبان",
      Property_Type: "نوع العقار",

      Contract_Expiry_Date: "تاريخ انتهاء العقد",
      Expected_Income: "دخل الإيجار المتوقع",
      Actual_Rental_Income: "دخل الإيجار الفعلي",
      Total_Rent_Recieved: "إجمالي الإيجار المستلم",
      Rent_Date: "تاريخ الإيجار",
      Owner_Details: "اسم المالك",
      Collected: "الاستلام",
      Cash: "نقدي",
      Transfer: "تحويل",
      Reports_Month: "الشهر",
      On: "في",
      Partial_Amount_Paid: "دفع مبلغ جزئي",
      Cash_Mode: "نقدي",
      Fully_Paid: "دفعت كاملاً",
      Offline_Payment_Cheque_No: "رقم الشیك",
      Offline_Payment_From: "من",
      Offline_Payment_To: "إلى",
      Added_By: "أضيفت في",
      Password_Updated: "تحدیث للرقم السري",
      Last_Login: "آخر دخول",
      Offline_Payment: "مدفوعات الأوفلاین",
      Paid_Offline: "دفعت أوفلاین",
      Partially_Paid: "مدفوعة جزئیا",
      Bank_Transfer: "تحویل بنكي",
      Payment_Mode: "طریقة الدفع",
      Payment_Type: "كیفیة الدفع",
      Full: "كامل",
      Partial: "جزئي",
      Offline_Date: "التاریخ",
      Maintenance_Price_Notes: "أدخل مبلغ الصیانة والملاحظات",
      Maintenance_Price: "مبلغ الصیاتة د.ب",
      TransId: "رقم العملية",
      Cheque: "الشيك",
      Cheque_Number: "رقم الشيك",
      Cheque_Image: "صورة الشيك",

      Resolved_Complete_Reminder: "ھل تم الإنتھاء من التذكیر",
      Price_In_BHD: "المبلغ د.ب",
      Reminder_Details: "تفاصیل التذكیر",
      Share_With_Tenants: "المشاركة مع المستأجر",

      Accept_Request_By: "موافقة الطلب مع",
      Sending_Contract: " إرسال العقد",
      Tenant_Sign_The: "المستأجر یوقع",
      Contract: "العقد",
      Landlord_Sign_The: "المؤجر یوقع",
      Submission: "المستأجر",

      //Add Other Invoice
      Otherinvoice_Other_Invoice: "Other Invoice",
      Otherinvoice_Vat_Invoice: "Vat Invoices",
      Otherinvoice_Total_With_Vat: "Total with VAT",

      //roles
      Rent_invoices: 'Rent Invoices',
      Payment_To_Confirm: 'Payment To Confirm',
      Payment_Rejected: 'Payment Rejected',
      Payment_Confirmed: 'Payment Confirmed',
      Payment_Confirmation: 'Payment Confirmation',
      Method_Attachments: 'Method/Attachments',
      Rent_Invoice_Confirmation: 'Rent Invoice Confirmation',
      Offline_invoice_Accept_Payment: 'Accept Payment',
      Offline_invoice_Reject_Payment: 'Reject Payment',
      Offline_Paid_By: 'Paid By',
      Offline_Payment_Confirmation: 'Rent Payment Confirmation',
      Submitted_Payment_Requests: 'Submitted Payment Requests',
      Amount_Paid: 'Amount Paid',
      Reject_Date: 'Reject Date',

      Vat_Exempted: "معفى من ضريبة",
      Vat_Number: "رقم الضريبي",
      Units: "وحدة",
      Pending_Invoice: "الفواتير المستحقة",
      Payment_DuePayments: "المدفوعات المستحقة",
      Privacy: "خصوصية",
      phone: "الهاتف",
      Id_Number: "رقم الهوية",
      contact: "التواصل",
      ID: "الهوية",
      Upload_Documents: "إرفع المستند",
      SideNav_Tasks: "مهام",
      SideNav_MyDate: "التاريخ",
      Add_Invoice: "أضف فاتورة",
      Add_Rent_Invoice: "أضف فاتورة مقدماً",
      Payment_CancelledPayments: "المدفوعات الملغاة",
      SideNav_Rent_Invoice: "فاتورة الإيجار",
      Payment_Cancel_Invoice: "إلغاء الفاتورة",

      Maintenance_Assign_To: "تعين فريق عمل",
      Maintenance_Assign_Success: "تم التعين بنجاح",
      Complete: "إكتمال",
      Maintenance_Add_Notes: "أضف ملاحظات",
      Maintenance_Ticket_No: "رقم التذكرة.",
      Maintenance_Submit_Date: "تاريخ التقديم",
      Maintenance_Resolve_Date: "تاريخ الحل",
      Requests_AddForm_DoYouWantToShareMaintenance: "المشاركة مع المستأجر",
      Maintenance_Notes: "ملاحظات الصيانة",
      Maintenance_Cost: "التكلفة",
      phoneType: 'نوع التواصل',
      language: 'اللغة',
      tenant_Type: 'نوع المستأجر',
      Maintenance_Me: 'حسابي',
      Maintenance_Vat_Percentage: '% القيمة المضافة',
      Maintenance_My_Notes: 'ملاحظاتي',
      Maintenance_Tenant_Notes: 'ملاحظات المستأجر',
      Maintenance_Photo_Video: 'صور / فيديو',
      Maintenance_Photo_Video_Message: 'بإمكانك تحميل 5 صور أو 20 ميجا فيديو',
      Maintenance_Add_Cost_To: 'أضف هذه التكلفة إلى:',
      Maintenance_Next_Month_Rent_Invoice: 'فاتورة إيجار الشهر القادم',
      Maintenance_Generate_Immediate_Invoice: 'أصدر فاتورة فورية',
      Maintenance_Charge_Cost: 'أضف تكلفة الصيانة على',
      Maintenance_resolving_details: 'تفاصيل حل الصيانة',

      //Add Other Invoice
      Otherinvoice_Add_Invoice: 'إضافة فواتير أخرى',
      Otherinvoice_Invoice_Entry: 'مدخلات الفاتورة',
      Otherinvoice_Unit_Amount: 'مبلغ الوحدة',
      Otherinvoice_Quantity: 'الكمية',
      Otherinvoice_Add_Entry: 'إضافة المدخلات',
      Otherinvoice_Vat_Percentage: 'أدخل نسبة الضريبة المضافة',
      Otherinvoice_Cancelled_Invoices: 'الفواتير الملغاة',
      Otherinvoice_Other_Fee_Invoiced: 'فواتير أخرى مفوترة',
      Otherinvoice_Other_Fee_Received: 'قواتير أخرة مستلمة',
      Other_Invoices: "فواتير أخرى",
      Otherinvoice_Ticket_Ref: "رقم التذكرة",
      Otherinvoice_Unit_Price: 'شعر الوحدة',
      Otherinvoice_Invoice_Charges: 'مبلغ الفاتورة',
      Property_details_Call_For_Price: 'إتصل للسعر',

      //roles
      to_be_renewed: 'تقارير العقود القابلة للتجديد',
      property_statement: 'تقارير كشف حساب العقاري',
      withdrawal: 'تقرير المبالغ المحولة',
      not_paid_card: 'تقارير المدفوعات الغير المستلمة',
      vacant_card: 'تقارير الشاغر',
      rent_card: 'تقارير دفتر الإيجار',
      expense_card: 'تقرير دفتر المصاريف',
      Add_New_Porperty: 'أضف عقار جديد',
      Add_Unit_On_Existing_Building: 'أضف عقار على مبنى سابق',
      Property_Name_In_Arabic: 'إسم العقار بالعربي',
      Requests_TableHeading_SubjectAr: 'العنوان بالعربي',
      Requests_TableHeading_DescriptionAr: 'التفاصيل بالعربي',
      Requests_TableHeading_Furniture_List: 'ثائمة الفرش',
      Requests_TableHeading_Furniture_List_Arabic: 'قائمة الفرش بالعربي',
      Requests_TableHeading_Area: 'المنطقة',
      Requests_TableHeading_Road_Way_Number: 'اسم / رقم الطريق أو السكة',
      Requests_TableHeading_Governate: 'المحافظة',
      AddProperty_PaymentDetails_Electricity_Meter: "رقم حساب الكهرباء",
      AddProperty_PaymentDetails_Water_Meter: "رقم حساب الماء",
      Are_You_Sure_Request: "هل أنت متأكد، هل تريد حذف هذا الطلب؟",
      Property_Skip: 'تخطى',
      Dashboard_Total_Unit: 'عدد الوحدات',
      Dashboard_Net_Income: 'صافى الدخل',
      Dashboard_Expected_Income: 'الدخل المتوقع',
      Dashboard_Actual_Income: 'الدخل الفعلي',
      Dashboard_Expenses: 'المصاريف',
      Address_Area_Name: 'اسم المنطقة',
      AddProperty_PropertyDetails_Building_Status: 'حالة المبنى',
      Maintenance_Enter_Notes_Photo: 'أكتب ملاحظة وأضف صورة على هذا الطلب',
      Maintenance_Question: 'هل تريد مشاركة المستأجر هذه الملاحظات؟',
      Maintenance_Upload_Three_Image: 'يمكنك رفع حتى 3 صور',
      Add_Tenant_Issue_Date: 'تاريخ الإصدار',
      Add_Tenant_Sr_No: 'الرقم',
      Add_Tenant_Due_Date: 'مستحقة قبل',
      Add_Tenant_Duration: 'فترة الإيجار',
      Days: 'أيام',
      Send: 'إرسال',
      Request_Date: 'تاريخ الطلب',
      Confirmation_Date: 'تاريخ التأكيد',
      Edit_Profile_Company_Name: 'إسم الشركة',
      Edit_Profile_Company_Address: 'عنوان الشركة',
      Edit_Profile_Commercial_Registration_Number: 'رقم السجل التجاري',
      Edit_Profile_Authorized_Person_Name: 'إسم الشخص المخول',
      Edit_Profile_Authorized_Person_ID: 'رقم هوية الشخص المخول',
      Edit_Profile_Authorized_Person_Contact: 'رقم هاتف الشخص المخول',
      Edit_Profile_About_The_Company: 'عن الشركة',
      Edit_Profile_Vat: 'ضريبة القيمة المضافة',
      Edit_Profile_Vat_No: 'رقم ضريبة القيمة المضافة.',
      Edit_Profile_Company_Logo: 'شعار الشركة',
      Edit_Profile_Add_Company_Documents: 'إضافة مستندات الشركة',
      Edit_Profile_Done: 'تم',
      //Add Advance Invoice
      Advance_Invoice_Email: 'البريد الالكتروني',
      Advance_Invoice_Tenant_Name: 'إسم المستأجر',
      Advance_Invoice_Move_In_Date: 'تاريخ بداية العقد',
      Advance_Invoice_Phone: 'الهاتف',
      Advance_Invoice_No_Of_Intervals: 'دفع الإيجار كل',
      Advance_Invoice_Rent_Period: 'مدة الإيجار',
      Advance_Invoice_Calculate: 'إحسب',
      Advance_Invoice_Terms_Cheeckbox: 'سيتم إصدار هذه الفاتورة فوراً وسيتم إلغاؤها إذا لم يتم دفعها قبل إصدار الفاتورة القادمة آلياً',
      Advance_Invoice_Generate: 'اصدر',
      Advance_Invoice_Preview: 'معاينة',
      //Maintenance
      Maintenance_Details_Notes: 'ملاحظات',
      Maintenance_Details_Resolved: 'تم الحل',
      Maintenance_Details_Cost: 'التكلفة:',
      Maintenance_Details_Total: 'الإجمالي:',
      Maintenance_Details_VAT: 'ضريبة القيمة المضافة:',
      Maintenance_Details_Reopen: 'إعادة فتح',
      Property_Details_Building_Status: 'حالة العقار',
      Other_Invoice_Sr_No: 'الرقم',
      //Edit Profile
      Edit_Profile_Donot_Show_Phone: "لا تظهر رقمي",
      Edit_Profile_Donot_Show_Name: "لا تظهر أسمي",
      Edit_Profile_Donot_Show_Chat: "لا أستقبل المراسلات",
      Edit_Profile_Logo_Image: 'صورة الشعار',
      Phone_To_Show_Explore: 'رقم الهاتف المخصص للعرض في صفحة تصفح العقارات',
      Edit_Profile_Note: 'ملاحظة:',
      Edit_Profile_Note_Text: 'إذا كان حسابك حساب شركة، يرجى إرفاق صورة السجل التجاري وكذلك ترخيص العمل العقاري',
      //Edit Invoice
      Edit_Invoice_Give_Discount: 'إضافة خصم',
      Edit_Invoice_Discount_Note: 'ملاحظات الخصم',
      Edit_Invoice_Add_Fees: 'أضف الرسوم',
      Edit_Invoice_Fee_Note: 'ملاحظات الرسوم',
      Edit_Invoice_Add_Note: 'أضف الملاحظة',
      Edit_Invoice_Please_Remove_Note: 'الرجاء حذف خانة الملاحظة',
      //Maintenane
      Request_Received: 'المقدمة',
      Request_Submitted: 'المستلمة',
      Maintenance_Type_Received: 'تم الإستلام',
      Maintenance_Type_Ongoing: 'جاري التنفيذ',
      Maintenance_Type_Completed: 'مكتمل',
      Maintenance_Delete_Maintenance: 'Delete Maintenance',
      //  View Property
      View_Property_Sort_Building_Name: 'إسم العقار',
      View_Property_Sort_Unit_Number: 'رقم الوحدة',
      View_Property_Sort_Ascending: 'تصاعدي',
      View_Property_Sort_Desending: 'تنازلي',
      // Property
      Property_Delete: 'حذف',
      Property_Deleted_Successfully: 'تم الحذف بنجاح',
      Property_Deleted_Properties: 'العقارات المؤرشفة',
      Property_Deleted: 'محذوف',
      Maintenance_Delete_Image: 'حذف الصورة',
      Maintenance_Delete_Reason: 'سبب الحذف',
      Maintenance_Rejection_Note: 'سبب الرفض',
      Maintenance_Update_Cost: 'تحديث المبلغ',
      Maintenance_Generated_From: 'اضافها',
      Property_360_View: 'مشاهدة ٣٦٠ درجة',
      Maintenance_Date: 'تاريخ الصيانة',
      Invoice_Tenant_Comment: 'ملاحظات المستأجر',
      Invoice_Reject_Notes: 'ملاحظات الرفض',
      Tenants_Tab_Groups: 'المجموعات',
      Add_Tenant_Group: 'أضف مستأجر كمجموعة',
      EnterMainContactNumber: "ادخل رقم تواصل الشخص المسئول",
      ContactPersonName: 'اسم الشخص المسئول',
      Add_Property_Tenant_Group: 'أضف عقار/ مستأجر للمجموعة',
      Selected_Group: 'أختر المجموعة',
      Property_Detail: 'تفاصيل العقار',
      Create_Invoice: 'أصدر الفاتورة الأولى من هذا العقد (منفصلة)',
      Add_Invoice_Contract: 'Add invoice of this contract to the Next invoice of this group',

      Are_You_Sure_Group: 'هل أنت متأكد من رغبتك بحدف المجموعة؟',
      Group_Name: 'اسم المجموعة',
      Contact_Person: 'الشخص المسئول',
      Contact_Number: 'رقم التواصل',
      Group_Properties: 'عدد العقارات',
      Contact_Details: 'Contact Details',
      Advance: 'مقدم',
      Living_Tenant_Details: 'تفاصيل المستأجر المقيم',
      Group_Details: 'تفاصيل المجموعة',
      List_Prop_Under_Tenant: 'قائمة العقارات تحت المجموعة',
      Living_Tenant_Name: 'اسم المستأجر المقيم',
      Name: 'الإسم',
      Main_Contact_Number: 'رقم التواصل',
      Contact_Person_Number: 'رقم هاتف الشخص المسئول',
      Main_Contact_No: 'رقم الشخص المسئول',
      Other_Description: 'تفاصيل أخرى',
      Group_Invoice: 'تاريخ إصدار/إرسال فاتورة المجموعة، (1- 28)',
      Identity_Details: 'تفاصيل الهوية',
      EnglishNameIdentity: "الإسم باللغة الإنجليزية",
      ArabicNameIdentity: "الإسم باللغة العربية (حال توفره)",
      Contract_Details: 'تفاصيل العقد',
      Share_With_Living_Tenant: 'شارك الفاتورة الخاصة بهذا العقار مع المستأجر المقيم',
      Are_You_Sure_Property: "هل أنت متأكد من رغبتك بحذف هذا العقار؟",
      Edit_Invoice_Date: 'تعديل تاريخ الفاتورة',
      Change_Invoice_Date: 'تغيير تاريخ الفاتورة',
      Enter_Date_Between: 'إدخل يوم بين 1- 28',
      Invoice_Reject_Rent_Payment: 'Reject Rent Payment',
      Invoice_Payment_Against_Invoice: 'Payment against Invoice No.',
      Invoice_Rejection_Commnet: 'Rejection Comment',
      Home_News: 'News',
      Partners_Heading: 'منصة إيجلوز للتقنيات العقارية توقع إتفاقية تعاون مشترك مع سداد ',
      Partners_Date: 'المنامة – 01 سبتمر 2012',
      Partners_Joint_Agreement: 'وقعت منصة إيجلوز (Eglooz)، المنصة البحرينية الأولى للتقنيات العقارية إتفاقية تعاون مشترك مع سداد، وذلك لتمكين المؤجرين من ',
      Partners_Joint_Agreement_Link: 'من تحصيل الإيجارات العقارية ',
      Partners_Joint_Agreement_Link_After: 'السكنية والتجارية أونلاين بكل سهولة.',
      Partners_Agreement_Result: `وبهذا الصدد، قال الدكتور زكريا أحمد هجرس رئيس مجلس إدارة منصة إيجلوز "تأتي هذه الإتفاقية نتاج لجهود مثمرة بين منصة إيجلوز وشركة سداد بهدف دعم الإبتكار والتحول الرقمي في القطاع العقاري، حيث ما ستزيح هذه الخدمة من عبأ عن العقاريين وتساهم في تقليل المصروفات والجهد والوقت مع ضمان تقديم  خدمات ذات جودة  وفعالية للعملاء."  وأضاف الدكتور زكريا "سعداء بتوقيع هذه الاتفاقية انسجاماً مع توجهنا الاستراتيجي بالعمل مع الجهات المختلفة والتركيز على المبادرات الوطنية التي تلبي الرؤية الاقتصادية للمملكة، وتدعم الخطة الوطنية للقطاع العقاري".`,
      Partners_Board_Directors: 'ومن جانبه، قال الدكتور محمد رفعت الكاشف عضو مجلس إدارة سداد البحرين " إن هذه الإتفاقية تهدف إلى توثيق أطر التعاون بين سداد وإيجلوز في المجال التقني وفي مجالات تطوير خدمات المدفوعات الإلكترونية، حيث تم تطوير آلية خدمة الدفع الإلكتروني التي يحتاجها القطاع العقاري، فضلاً عن دعم عمليات التحويل المالي والآلية المتبعة لاستيفاء مستحقات الفواتير بصورة إلكترونية"',
      Partners_CEO: 'وعلق السيد عصام القراضي المؤسس والرئيس التنفيذي لمنصة إيجلوز " شراكتنا مع سداد تأتي ضمن أهدافنا لتوظيف التقنيات المالية والعقارية وإبتكار حلول رقمية تخدم المتعاملين في القطاع العقاري وتوفر لهم تجربة عملاء أفضل وأيسر، فهذه الإتفاقية ستسهم في تحفيز التحول في دفع الإيجارات العقارية الى الدفع الإلكتروني بصورة كبيرة".',
      Partners_Eglooz_Partners: 'الجدير بالذكر إن منصة Eglooz تقدم مفهوماً مبتكرا في التكنولوجيا العقارية، إذ توفر منصة آمنة وسهلة لإدارة العقارات وتحصيل الإيجارات عبر لوحة التحكم الذكية ودفتر الإيجار الرقمي، ومن خدماتها إصدار الفواتير الالكترونية، الدفع المالي المباشر الكترونيا، الأرصدة الالكترونية، العرض والتسويق الرقمي للعقارات، المشاركة في وسائل التواصل الاجتماعي، التواصل الفوري بين مدير العقار والمستأجر، إدارة الصيانة ومتابعتها، إرسال الإشعارات، توقيع عقود الإيجار الكترونيا، تجديد عقود الإيجار آليا، تسجيل التذكيرات، وإستخراج التقارير والإحصاءات.',
      Partners_Our_Payment_Partner: 'شركائنا',
      User_Language: 'لغة المستخدم',
      View_Group_Invoice: 'إظهار فاتورة المجموعة',
      Home_ViewGroupDetails: 'إظهار تفاصيل المجموعة',
      Are_You_Sure_Invoice: "هل أنت متأكد من رغبتك بحذف الفاتورة؟",
      Select_Bank_Account: 'إختار البنك',
      Linked_Bank_Account: 'ربط البنك',
      building: 'المبنى',
      unit: 'الوحدة',
      tenant: 'المستأجر',
      Paid: 'مدفوعة',
      Download_Group_Invoice: 'تحميل فاتورة المجموعة',
      Add_Past_Invoice: 'إظافة فاتورة قديمة',
      Soft_Delete: 'محذوف',
      recievePaymentConfirmation: 'إستلام تأكيد دفع',
      delete: 'حذف',
      Accounts: 'الحسابات',
      Accounts_Of_Tenants: 'حسابات المستأجرين',
      Contracts: 'العقود',
      List_Contracts: 'قائمة العقود',
      contracts: 'العقود',
      Contract_Start_Date: 'تاريخ توقيع العقد',
      Invoice_Day: 'تاريخ الفاتورة',
      Transaction_Date: 'تاريخ العملية',
      Commision: 'العمولة',
      Reference: 'المرجع',
      Mode_Of_Payment: 'طريقة الدفع',
      viewList: 'إظهار القائمة',
      viewAccounts: 'إظهار الحسابات',
      add_rent_invoice: 'إضافة فاتورة إيجار',
      Reciepts: 'الأرصدة',
      receipts: 'الأرصدة',
      Payment_RecieptNo: 'رقم الرصيد',
      Payment_RecieptId: 'رقم الرصيد',
      Payment_Receipt_Details: 'تفاصيل الرصيد',
      Payment_Option_ViewReceipt: 'مشاهدة الرصيد',
      Payment_Option_DownloadReceipt: 'تحميل الرصيد',
      Payment_Date: 'تاريخ الدفع',
      Outstanding_Payments: 'المبالغ المتأخرة',
      Advance_Amounts: 'المبلغ المقدم',
      Date_Of_Last_Payments: 'تاريخ آخر دفع',
      Equipments: 'Equipments',
      Cost_Of_Equipment: 'إجمالي عدد العناصر',
      Records: 'السجلات',
      Previous: 'السابق',
      Invoice_Generate_Now: 'هل ترغب في إصدار الفاتورة الأن؟',
      Are_You_Sure_Cancel_Invoice: 'هل أنت متأكد من رغبتك في إلغاء الفاتورة؟',
      Add_New: 'إضافة جديد',
      Download_List: 'تحميل القائمة',
      Add_New_Equipment: 'إضافة عنصر جديد',
      inventoryId: 'رقم الجرد',
      category: 'التصنيف الرئيسي',
      subCategory: 'التصنيف الفرعي',
      make: 'الصنع',
      modelNumber: 'رقم الموديل',
      serialNumber: 'الرقم التسلسلي',
      height: 'الطول',
      widht: 'العرض',
      depth: 'العمق',
      color: 'اللون',
      condition: 'الحالة',
      price: 'السعر',
      deprecation: 'الوصف',
      dateOfPurchase: 'تاريخ الشراء',
      warrantyExpireDate: 'تاريخ إنتهاء الضمان',
      purchasedFrom: 'الشراء من',
      vendorContact: 'رقم تواصل المورد',
      isLiveTimeWarranty: 'ضمان مدى الحياة',
      details: 'التفاصيل',
      add_Pic_Pdf_Warrenty: 'إضافة صورة/ملف للعنصر أو الضمان',
      equipmentId: 'رقم العنصر',
      warranty: 'الضمان',
      repeat: 'تكرار',
      available: 'متوفر',
      addCategory: 'إضافة تصنيف رئيسي',
      addSubCategory: 'إضافة تصنيف فرعي',
      addCondition: 'إضافة حالة',
      occupancyRate: 'معدل التأجير',
      nameEn: 'الإسم بالإنجليزي',
      nameAr: 'الإسم بالعربي',
      depreciation: 'التهالك',
      listOfEquipments: 'قائمة العناصر',
      equipmentList: 'قائمة العناصر',
      Added_Date: 'تاريخ الإضافة',
      Deleted_Images: 'صور الحذف',
      basicInformation: 'معلومات أساسية',
      purchaseInformation: 'معلومات الشراء',
      equipmentDetails: 'تفاصيل العنصر',
      editEquipment: 'تعديل العنصر',
      back: 'الخلف',
      Pic_Pdf_Warrenty: 'صور /ملفات العنصر/ التأمين',
      equipment: 'العناصر',
      numberOfMonths: 'عدد الشهور',
      Tenant_Outstanding_Other_Payment: 'متأخرات الفواتير الاخرى',
      Payments_Due_Payments: 'متأخرات فواتير الإيجار',
      Payments_Other_Payments: 'المدفوعات الآخرى',
      Outstanding_Invoice_Payments: 'Outstanding Invoiced Payments',
      Direct_Vacate: 'إخلاء مباشر',
      identity: 'الهوية',
      Select_Start_Date: 'إختر تاريخ البدء',
      Enter_Number_Of_Months: 'إدخل عدد الأشهر',
      Adjust_Amount: 'يمكنك تعديل هذا المبلغ يدوياً',
      Outstanding_Rent: 'متأخرات الإيجار',
      Contract_End_Date: 'تاريخ إنتهاء العقد',
      legal_Case_Start_Date: 'تاريخ بدء القضية',
      Lawyer_Office: 'مكتب المحامي',
      Mobile_Contact: 'رقم الهاتف',
      Email_Contact: 'الإيميل',
      Legal_Heading: 'تحويل المستأجر للشئون القانونية',
      Legal_Info: 'Moving Tenant to Legal Case means all Invoices of this Tenant will be moved from the Rent Book and it will be available in the Legal Page.',
      Send_To_Legal: 'تحويل للشئون القانونية',
      Legal_Cases_List: 'قائمة القضايا',
      Legal_Invoices: 'فواتير القضايا',
      Date_Of_Moving: 'تاريخ التحويل',
      Total_Outstanding: 'مجموع المتأخرات',
      Lawyer_Mobile: 'رقم المحامي',
      Update_Lawyer: 'تحديث معلومات المحامي',
      Move_Tenant_Back: 'إعادة المستاجر',
      Move: 'إعادة',
      Legal: 'الشئون القانونية',
      Lawyer: 'المحامي',
      AreYouSureYouWantToMoveCase: "Are you sure you want to move case?",
      tenantName: 'إسم المستأجر كما هو في الهوية',
      Tenant_Outstanding_Other_Invoice: 'متأخرات الفواتير الآخرى',
      Contract_Ends_In: 'ينتهى العقد في',
      Update_Tenant_Information: 'حدث بيانات المستأجر',
      Update_Tenant_Info: 'التعديل على البيانات ستتحدث في جميع الاقسام.',
      Update_Info: 'يمكنك تعديل هذا المبلغ من خلال خاصية تعديل الفاتورة',
      Expected_Life_Time: 'متوقع العمر الإفتراضي',
      Expected_Life_Time_Placeholder: 'الرجاء إدخال العمر الافتراضي بالسنوات',
      Purchase_Date: 'تاريخ الشراء',
      Life_Time_In_Years: 'العمر الافتراضي بالسنوات',
      Item_In_Life: 'فترة الاستخدام',
      Charges: 'Charges',
      Update_Legal: 'تحديث البيانات القانونية',
      Occupancy_rate: 'معدل التأجير',
      propertyOverview: 'العقارات',
      Dashboard_Onboard: 'مضافة',
      Occupied_Units: 'الوحدات المؤجرة',
      Vacant_Units: 'الوحدات الفارغة',
      Legal_Overview: 'الشوؤن القانونية',
      Legal_Cases: 'عدد القضايا',
      Invoices_In_Legal: 'عدد الفواتير',
      Legal_Number: 'عدد القضايا',
      Block_Number: 'رقم الوحدة',
      Search_Option: 'خيارات البحث',
      Enter_Unit_No: 'إدخال رقم الوحدة',
      Current_Phone_Number: 'رقم الهاتف الحالي',
      New_Phone_Number: 'رقم الهاتف الجديدr',
    },
  },
};
