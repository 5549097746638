import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminService} from 'src/app/services/admin.service';
import {apiUrl} from 'src/app/global/global';
import {TranslateService} from "../../shared/lang/translate.service";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    userForm: FormGroup;
    showError = false;

    constructor(
        public admin: AdminService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
    ) {
        this.userForm = this.fb.group({
            'oldPassword': ['', Validators.required],
            'newPassword': ['', Validators.required],
            'confirmPassword': ['', Validators.required]
        }, {validator: this.validateIsSpouse});
    }

    validateIsSpouse(group: FormGroup) {
        let to = group.get('newPassword').value;
        let from = group.get('confirmPassword').value;
        if (to != from) {
            return group.controls['confirmPassword'].setErrors({min: true});
        }
        return null;
    }

    ngOnInit() {
    }

    changePassword(form) {
        if (form.valid) {
            this.userForm.get('confirmPassword').disable();
            this.admin.putData(apiUrl._changePassword, form.value).subscribe(success => {
                this.userForm.reset();
                this.userForm.get('confirmPassword').enable();
                this.admin.showAlert('Password changed successfully', '');
            });
        } else {
            this.userForm.get('confirmPassword').enable();
            this.showError = true;
            setTimeout(() => {
                this.showError = false;
            }, 5000);
            return;
        }
    }

}
