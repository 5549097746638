import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from '../services/admin.service';
import { apiUrl } from '../global/global';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(public api: AdminService,) { }

  registerData:any = {};
  userType:string='';
  userDocument:boolean = true;
  otpId:string;
  fullNumber:string;
  redirectStatus:string;

  getList(data,url) {
    return this.api.getData(url,data);
  }

  getLanguage() {
    return this.api.getData(apiUrl._getLanguage,'');
  }

  uploadImage(file,type) {
    const fd = new FormData();
    if(file.length)
    {
      for(let x of file)
      {
        fd.append('image', x );
      }
    }
    else
      fd.append('image', file );
      fd.append('type', type );
    return this.api.postData(apiUrl._uploadImage, fd);
  }

  submitForm(data,url) {
    return this.api.postData(url, data);
  }

  putData(data,url) {
    return this.api.putData(url, data);
  }

  delete(id)
  {
    let fd = new FormData();
    fd.append('questionId',id)
    return this.api.postData(apiUrl._deleteServiceProvider,fd);
  }

  deleteData(id,url)
  {
    return this.api.postData(url,id);
  }
  updateStatus(url,data)
  {
    return this.api.postData(url,data);
  }


}


