import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appNoSpecialCharacters]'
})
export class NoSpecialCharacters implements AfterViewInit {
    @Input() decimalPlace = 6;

    private regex: RegExp;
    // private regex: any;
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'Delete', 'ArrowLeft', 'ArrowRight'];


    ngAfterViewInit(): void {
        let reg: string;
        reg = '^[_A-z0-9,.]*((\\s)*[_A-z0-9,.])*$';

        // '^-?[0-9]{0,15}(\\.[0-9]{0,' + this.decimalPlace + '})?$'
        this.regex = new RegExp(reg);
        // this.regex = reg;
        console.log(this.regex)
    }

    // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    // Allow decimal numbers and negative values

    constructor(private el: ElementRef) {
        // alert(this.decimalPlace);
    }

    @HostListener('keydown', ['$event'])

    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        console.log(String(next).match(this.regex))
        if (next && next.match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('mousewheel', ['$event'])
    onMouseWheel(event: KeyboardEvent) {
        event.preventDefault();
    }
}
