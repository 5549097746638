import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { Subscriber } from 'rxjs';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-not-paid',
  templateUrl: './not-paid.component.html',
  styleUrls: ['./not-paid.component.css']
})
export class NotPaidComponent implements OnInit {
  totalRent: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter: any;
  date: Date = new Date();
  @Input() pagination: any;
  subscription = new Subscriber();

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public admin: AdminService) {
  }

  ngOnInit() { }

  checkOverDue(date) {

    let status = this.translate.lang.Payment_Status_NotPaid;
    let currentDate = + new Date();
    if (date < currentDate) {
      status = this.translate.lang.Dashboard_Chips_Overdue;
    }

    return status;
  }


  // setTotal(model) {
  //   let rent = 0;
  //   if (Object.keys(model).length) {
  //     if (model.listing.length > 0) {
  //       model.listing.forEach(element => {
  //         console.log(element);
  //         rent = rent + element.rentAmount;
  //       });
  //     }
  //   }
  //   return rent;
  // }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
