import {Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {AdminService} from 'src/app/services/admin.service';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {apiUrl} from 'src/app/global/global';
import {InternalServiceService} from 'src/app/internal/internal-service.service';
import {TranslateService} from "../../shared/lang/translate.service";

@Component({
    selector: 'app-vacate-requests',
    templateUrl: './vacate-request.component.html',
    styleUrls: ['./vacate-request.component.css']
})
export class VacateRequestComponent implements OnInit {

    vacateForm: FormGroup;
    vacateError: boolean = false;
    vacateReason: any = [];
    planData: any[] = [];
    data: any = {};
    radio = {packageId: '', property: '', staticId: ''};
    @ViewChild('vdfClose') vdfClose: ElementRef;
    @ViewChild('vdfShow') vdfShow: ElementRef;
    @Output() messageEvent = new EventEmitter<string>();
    file: any;
    minDate1=new Date();

    constructor(private api: InternalServiceService, public admin: AdminService, private fb: FormBuilder, public translate: TranslateService) {
        this.vacateForm = fb.group({
            propertyId: ['', Validators.required],
            description: ['', Validators.required],
            vacateDate: ['',Validators.required],
            name: [''],
            image: this.fb.array([])
            // other:['']
        });
    }

    ngOnInit() {
        this.api.vacateState.subscribe(data => {
            this.data = JSON.parse(data);

            if (this.data['vacateData'] && this.data['vacateData'].length) {

            } else {
                this.vacateForm.patchValue({
                    propertyId: this.data.property,
                    description: '',
                    vacateDate: '',
                    image: [],
                    // other: '',
                    name: ''
                });
            }
            this.vdfShow.nativeElement.click();
        });
        this.getVacateReason();
    }

    getVacateReason() {
        this.admin.showLoader = false;
        const userType = localStorage.getItem('userType')

        this.admin.getData(apiUrl._getPropertyType, {type: (userType === 'LANDLORD' || userType === 'MANAGER' || userType === 'TEAM_MEMBER') ? 'Landlord_Reason' : 'Tenant_Reason'}).subscribe(success => {
            this.vacateReason = success.data.propertyType[0].propertyData;
        });
    }

    enterId(property) {
        this.vacateForm.patchValue({
            propertyId: property,
            description: '',
            vacateDate: '',
            image: [],
            other: ''
        });
    }

    createArray(org, thu, name, type) {
        return this.fb.group({
            original: [org, Validators.required],
            thumbnail: [thu, Validators.required],
            name: [name],
            type: [type, Validators.required]
        })
    }

    vacateSubmit() {
        this.vacateForm.get('name').clearValidators();
        this.vacateForm.get('name').updateValueAndValidity();
        if (this.vacateForm.valid && this.vacateForm.value.image.length) {
            let data = {
                propertyId: this.vacateForm.value.propertyId,
                image: this.vacateForm.value.image,
                vacateDate: +new Date(this.vacateForm.value.vacateDate),
            }
            if (this.vacateForm.value.description == 'other') {
                data['description'] = this.vacateForm.value.description;
            } else {
                data['description'] = this.vacateForm.value.description;
            }
            this.admin.postData(apiUrl._makeVacateRequest, data).subscribe(success => {
                this.vdfClose.nativeElement.click();
                this.messageEvent.emit('true');
                this.admin.toast('Vacant Request Send', '', 'success');
            });
        } else {
            this.vacateError = true;
        }
    }

    deleteImage(i) {
        const serviceDta = <FormArray>this.vacateForm.controls['image'];
        serviceDta.removeAt(i);
    }

    upload() {
        if (this.file && this.vacateForm.value.name != '') {
            this.api.uploadImage(this.file, this.file.name.split('.').pop() == 'pdf' ? 'pdf' : 'img').subscribe(success => {
                const serviceDta = <FormArray>this.vacateForm.controls['image'];
                serviceDta.push(this.createArray(success.data.original, success.data.thumbnail, this.vacateForm.value.name, success.data.type));
                this.vacateForm.patchValue({
                    name: ''
                })
            });
        } else {
            this.vacateError = true;
        }

    }

    uploadImage(event) {
        if (event.target.files && event.target.files.length) {
            const temp = event.target.files[0].type;
            if (temp == 'image/jpeg' || temp == 'image/png' || temp == 'image/jpg' || temp == 'image/pdf') {
                this.file = event.target.files[0];
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }

    getPlans() {
        this.admin.showLoader = false;
        this.admin.getData(apiUrl._getPackage, {}).subscribe(success => {
            this.planData = success.data;
            for (let x of this.planData) {
                if (x.isMostPopular) {
                    this.radio.packageId = x._id;
                    this.radio.staticId = x._id;
                }
            }
        });
    }

}
