import { Component, OnInit, ViewChild } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { Router, ActivatedRoute } from "@angular/router";
import { ExternalService } from '../external.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { AdminService } from 'src/app/services/admin.service';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.css']
})
export class NeedHelpComponent implements OnInit {
  submit: boolean = false;
  callingCode = '+973'
  number: any = ''
  inItOpt: any = { initialCountry: 'bh' };
  @ViewChild('tel') tel: any;
  subscribe = new Subscriber();
  constructor(
    public admin: AdminService,
    public translate: TranslateService,
    private api: ExternalService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.subscribe.add(this.activatedRoute.params.subscribe(params => {
      console.log(params);

      if(params.language){
        localStorage.setItem('language', params.language);
        this.translate.setLanguage({code: params.language, name: 'عربى', lang: params.language})
      }
    }))
  }

  _keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    var key = event.keyCode || event.charCode;
    if (key != 8 || key != 46) {
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
        return false;
      }
    }
  }


  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      this.number = '';
      return false;
    }
  }

  onFocus() {
    this.submit = false;
  }


  submitPhoneNumber() {
    this.submit = true;
    let obj = {}
    if (this.number && this.number.length >= 5) {

      obj = {
        phoneNumber: this.callingCode + this.number
      }
      this.admin.postData(apiUrl.__help, obj).subscribe(res => {
        this.submit = false;
        this.admin.showAlert('Your request sent successfully', 'We will contact you shortly');
        this.number = '';
        this.callingCode = '+973'
      })
    }
  }



  hasError() {
  }

  getNumber(event) {

  }

  onCountryChange(event) {
    this.callingCode = '+' + event.dialCode;
  }

}
