import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class SharedService {

    userDataSource = new BehaviorSubject<any>({});
    userData = this.userDataSource.asObservable();

    filter = new BehaviorSubject<any>({});
    filter$ = this.filter.asObservable();

    constructor(private http: HttpClient, private router: Router) {
        const user = localStorage.getItem('');
    }


    changeUserData(val) {
        localStorage.setItem('loginData', JSON.stringify(val));
        this.userDataSource.next(val);
    }


    getUtcDateOrTime(date, type: DATE_CONVERTER_TYPE) {
        let finalTime;
        if (type === DATE_CONVERTER_TYPE.startDate) {
            const newDate: Date = new Date(date);
            newDate.setHours(0, 0, 0);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }

        if (type === DATE_CONVERTER_TYPE.endDate) {
            const newDate: Date = new Date(date);
            newDate.setHours(23, 59, 59);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }
        if (type === DATE_CONVERTER_TYPE.dateTime) {
            const newDate: Date = new Date(date);
            newDate.setHours(0, 0, 0);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }
        return new Date(finalTime).getTime();
    }

    getLocalDateFromUTC(UTC_Date) {
        const dd: Date = new Date(UTC_Date);
        return new Date(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds());
    }

}

export enum DATE_CONVERTER_TYPE {
    startDate = 'START_DATE',
    endDate = 'END_DATE',
    dateTime = 'DATE_TIME',
}
