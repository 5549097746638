import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExternalService } from '../external.service';
import { apiUrl } from '../../global/global';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import * as CONSTANT from '../../services/constants';
import { TranslateService } from "../../shared/lang/translate.service";

@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {

    showError = false;
    userForm: FormGroup;
    extension: any[] = [];
    minDate = new Date();
    typeData: any[] = [];
    name: string = '';
    @Output() messageEvent = new EventEmitter<string>();
    @ViewChild('file') file: ElementRef
    documentError = false;

    constructor(private api: ExternalService,
        private fb: FormBuilder,
        private router: Router,
        public admin: AdminService,
        public translate: TranslateService
    ) {
        this.userForm = this.fb.group({
            userType: [this.api.userType],
            userDocument: this.fb.array([]),
            name: ['', Validators.compose([Validators.required])],
            arName: [''],
            type: [''],
            number: ['', Validators.compose([Validators.required])],
            date: ['', Validators.compose([Validators.required])],
            identity: [''],
            img: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.getProperty();
    }

    getProperty() {
        this.admin.showLoader = false;
        this.api.getList({ type: 'Identity' }, apiUrl._getPropertyType).subscribe(success => {
            if (success.data.propertyType[0])
                this.typeData = success.data.propertyType[0].propertyData;
            this.userForm.patchValue({ type: this.typeData[0]._id });
        });
    }

    sendMessage(status) {
        this.messageEvent.emit(status);
    }

    keyPress2(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.fullName = '';
            return false;
        }
    }


    keyPress(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.name = '';
            return false;
        }
    }

    keyPress3(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (e.keyCode != 0 && e.keyCode != 32) {
            if (!isArabic.test(e.key)) {
                this.userForm.value.arName = '';
                return false;
            }
        }
    }

    onSubmit(form) {
        this.userForm.patchValue({ name: this.userForm.value.name.trim() })
        console.log(form.value);


        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;

        if (isArabic.test(this.userForm.value.number.trim())) {
            this.admin.toast('', 'Arabic ID number is not allowed', 'warning')
            return false;
        }

        if (this.userForm.valid) {
            const serviceDta = <FormArray>this.userForm.controls['userDocument'];

            let temp = {
                name: form.value.name,
                original: form.value.img.original,
                thumbnail: form.value.img.thumbnail,
                documentSize: '0',
            }


            if (form.value.number !== '') {
                temp['cprNumber'] = form.value.number;
            }

            if (form.value.date !== '') {
                temp['documentExpiryDate'] = +new Date(form.value.date);
            }

            if (form.value.arName !== '') {
                temp['arName'] = form.value.arName;
            }

            temp['identity'] = form.value.identity;
            temp['type'] = form.value.type;



            serviceDta.push(this.fb.group(temp));
            this.showError = false;
            this.userForm.patchValue({
                name: '',
                number: '',
                img: '',
                arName: '',
                date: ''
            });
            this.name = '';
            this.file.nativeElement.value = '';
        } else {
            this.showError = true;
            setTimeout(() => {
                this.showError = false;
            }, 5000);
        }
    }

    uploadImage(event) {
        if (event.target.files && event.target.files.length && this.userForm.value.userDocument.length < 10) {
            const temp = event.target.files[0].type;
            if (temp == 'image/jpeg' || temp == 'application/pdf' || temp == 'image/png' || temp == 'image/jpg' || temp == 'image/pdf') {
                const file = event.target.files[0];
                this.api.uploadImage(file, file.name.split('.').pop() == 'pdf' ? 'pdf' : 'img').subscribe(success => {

                    console.log(temp);

                    this.userForm.patchValue({ type: file.name.split('.').pop() })
                    console.log(success);

                    this.userForm.patchValue({ img: success.data });
                    this.extension.push(success.data.type);
                    if (event.target.files[0].name.length > 12) {
                        let temp = (event.target.files[0].name).substr(0, 5);
                        let temp1 = (event.target.files[0].name).substr(event.target.files[0].name.length - 6);
                        this.name = temp + '.....' + temp1;
                    } else {
                        this.name = event.target.files[0].name;
                    }
                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }

    deleteFile(i) {
        const serviceDta = <FormArray>this.userForm.controls['userDocument'];
        serviceDta.removeAt(i);
        this.extension.splice(i, 1);
    }

    // clickContinue() {
    //     if (this.userForm.value.userDocument.length) {
    //         this.userForm.controls['name'].disable();
    //         this.userForm.controls['number'].disable();
    //         this.userForm.controls['img'].disable();
    //         this.userForm.controls['date'].disable();
    //         this.userForm.controls['arName'].disable();

    //         const data = {
    //             userType: this.userForm.value.userType,
    //             userDocument: this.userForm.value.userDocument
    //         };

    //         console.log(data);

    //         this.api.submitForm(data, apiUrl._uploadDocument).subscribe(
    //             success => {

    //                 localStorage.setItem('adminToken', success.data.accessToken);
    //                 let type = localStorage.getItem('userType');

    //                 if(type != 'MANAGER'){
    //                     localStorage.setItem('userDocument', 'true');
    //                 }
    //                 console.log(this.admin.path)
    //                 if (this.admin.path.flag) {
    //                     if (this.admin.path.type == type) {
    //                         const loginData = JSON.parse(localStorage.getItem('loginData'));
    //                         const str = loginData.fullNumber;
    //                         const res = str.replace('+', ' ');
    //                         if (this.admin.path.status.phoneNumber && this.admin.path.status.phoneNumber == res) {
    //                             const contractPeriod = this.admin.path.status.contractPeriod
    //                             const inviteId = this.admin.path.status.inviteId
    //                             const path = this.admin.path.value;
    //                             this.admin.path.status = {};
    //                             this.admin.path.value = '';
    //                             this.admin.path.flag = false;
    //                             this.admin.path.type = '';

    //                             this.router.navigate([path], {
    //                                 queryParams: {
    //                                     contractPeriod: contractPeriod,
    //                                     inviteId: inviteId
    //                                 }
    //                             });
    //                         } else {
    //                             const path = this.admin.path.value;
    //                             this.admin.path.status = {};
    //                             this.admin.path.value = '';
    //                             this.admin.path.flag = false;
    //                             this.admin.path.type = '';
    //                             this.router.navigate([path]);
    //                         }
    //                     } else {
    //                         console.log(type);
    //                         this.router.navigate([type === 'LANDLORD' || type === 'MANAGER' ? '/landlord' : '/tenants']);
    //                     }
    //                 } else {
    //                     console.log(type);
    //                     if (type === 'MANAGER') {
    //                         this.router.navigate(['signUp/upload-company-documents']);
    //                     } else {
    //                         this.router.navigate([type === 'LANDLORD' ? '/landlord' : '/tenants']);
    //                     }
    //                 }
    //                 // this.messageEvent.emit('closeUpload');
    //                 this.admin.toast('Uploaded Successfully', '', 'success');
    //             }
    //         );
    //     } else {
    //         this.documentError = true;
    //         //   setTimeout(() => {
    //         //     this.documentError = false;
    //         // }, 5000);
    //     }
    // }


    clickContinue() {
        if (this.userForm.value.userDocument.length) {
            this.userForm.controls['name'].disable();
            this.userForm.controls['number'].disable();
            this.userForm.controls['img'].disable();
            this.userForm.controls['date'].disable();
            this.userForm.controls['arName'].disable();

            const data = {
                userType: this.userForm.value.userType,
                userDocument: this.userForm.value.userDocument
            };
            this.api.submitForm(data, apiUrl._uploadDocument).subscribe(
                success => {

                    localStorage.setItem('adminToken', success.data.accessToken);
                    let type = localStorage.getItem('userType');
                    localStorage.setItem('userDocument', 'true');
                    localStorage.setItem('loginData', JSON.stringify(success.data));
                    if (this.admin.path.flag) {
                        if (this.admin.path.type == type) {
                            const loginData = JSON.parse(localStorage.getItem('loginData'));
                            const str = loginData.fullNumber;
                            const res = str.replace('+', ' ');
                            if (this.admin.path.status.phoneNumber && this.admin.path.status.phoneNumber == res) {
                                const contractPeriod = this.admin.path.status.contractPeriod
                                const inviteId = this.admin.path.status.inviteId
                                const path = this.admin.path.value;
                                this.admin.path.status = {};
                                this.admin.path.value = '';
                                this.admin.path.flag = false;
                                this.admin.path.type = '';

                                this.router.navigate([path], {
                                    queryParams: {
                                        contractPeriod: contractPeriod,
                                        inviteId: inviteId
                                    }
                                });
                            } else {
                                const path = this.admin.path.value;
                                this.admin.path.status = {};
                                this.admin.path.value = '';
                                this.admin.path.flag = false;
                                this.admin.path.type = '';
                                this.router.navigate([path]);
                            }
                        } else {
                            this.router.navigate([type === 'LANDLORD' || type === 'MANAGER' ? '/landlord' : '/tenants']);
                        }
                    } else {
                        console.log(type);
                        // if (type === 'MANAGER') {
                        //     this.router.navigate(['signUp/upload-company-documents']);
                        // } else {
                        this.router.navigate([type === 'LANDLORD' || type === 'MANAGER' ? '/landlord' : '/tenants']);
                        // }
                    }
                    // this.messageEvent.emit('closeUpload');
                    this.admin.toast('Uploaded Successfully', '', 'success');
                }
            );
        } else {
            this.documentError = true;
            //   setTimeout(() => {
            //     this.documentError = false;
            // }, 5000);
        }
    }


    checkData(status) {
        return status.name;
    }

    check() {
        return localStorage.getItem('userType') != 'LANDLORD'
    }
}
