import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../shared/lang/translate.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.css']
})
export class TenantComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
