export const apiUrl = {
  _getLanguage: "/users/getLanguages",
  _uploadImage: "/common/uploadFile",
  _getServiceProvider: "/admin/serviceListing",
  _addServiceProvider: "/admin/addServices",
  _updateServiceProvider: "/admin/UpdateServices",
  _deleteServiceProvider: "/admin/deleteService",
  _getServiceBranch: "/admin/serviceBranchListing",
  _addServiceBranch: "/admin/addServiceBranches",
  _updateServiceBranch: "/admin/updateServicesBranch",
  _deleteServiceBranch: "/admin/deleteServicesBranch",
  _getQuestion: "/admin/getQuestionsInSubcategory",
  _addQuestion: "/admin/addQuestion",
  _updateQuestion: "/admin/updateQuestions",
  _deleteQuestion: "/admin/deleteQuestions",
  _addLanguage: "/admin/addLanguage",
  _addOption: "/admin/addOptionInQuestion",
  _addVendor: "/admin/addVendor",
  logout: "/user/logout",
  _editVendor: "/admin/vendorEditProfile",
  _getVendor: "/admin/getVendor",
  _getBankAccount: "/user/getBankAccount",
  _addBankAccount: "/user/addBankAccount",
  _editBankAccount: "/user/editBankAccount",
  _addBusinessDetails: "/admin/addBusinessDetails",
  _getVendorDetails: "/admin/getVendorDetails",
  _addBusinessdetail: "/admin/addBusinessDocuments",
  _addWorkDetail: "/admin/addWorkDetails",
  _approveVendor: "/admin/approveVendor",
  _getSpeciality: "/admin/specialityListing",
  _addNote: "/admin/addDescription",
  _orderListing: "/admin/orderListing",
  _getDeclineQuestion: "/admin/getDeclineQuestion",
  _getDeclineQuestionAndOption: "/admin/getDeclineOption",
  _getDeclineOptionById: "/admin/getOptionById",
  _addDeclineOption: "/admin/addDeclineOptions",
  _editDeclineOption: "/admin/editDeclineOption",
  _editDeclineQuestion: "/admin/editDeclineQuestion",
  _getProviderJobs: "/admin/getVendorJobDescription",
  _vendorJobsListing: "/admin/vendorSideOrderListing",
  _getServiceWithBranch: "/admin/getServiceWithBranches",
  _getCustomer: "/admin/customerListing",
  _getCustomerById: "/admin/getUserById",
  _addCustomer: "/admin/addCustomer",
  _editCustomer: "/admin/editCustomer",
  _deleteCustomer: "/admin/deleteCustomer",
  _approveCustomer: "/admin/changeActiveStatus",
  _getCoupon: "/admin/couponListing",
  _addCoupon: "/admin/addCoupon",
  _editCoupon: "/admin/updateCoupons",
  _assignJob: "/admin/assignJob",
  _changeDate: "/admin/changeDateOfOrder",
  _getDashboard: "/admin/getGraphAndCounts",
  _addPermission: "/admin/addPermissions",
  _getPermissionType: "/admin/getPermissionTypes",
  _getPermissionById: "/admin/getPermissionById",
  _adminListing: "/admin/adminListing",
  _adminById: "/admin/adminById",
  _addAdmin: "/admin/addAdmins",
  _editAdmin: "/admin/editAdmins",
  _addLink: "/admin/addLinks",
  _updateLink: "/admin/updateLinks",
  _getSpecificContent: "/admin/getSpecificContent",
  _editReview: "/admin/editReview",
  _userOrderListing: "/admin/userSideOrderListing",
  _sendPush: "/admin/sendPush",
  _getSpecificContentById: "/admin/getLinkById",
  _getSpecificContentActive: "/admin/getSpecificContentActive",
  _getBillingInfo: "/admin/listPayment",
  _addBillingInfo: "/admin/addPayment",
  _deleteBillingInfo: "/admin/deletePayment",
  _placeOrder: "/users/placeOrder",
  _copyCategory: "/admin/copySubBranches",
  _getServiceAllLanguage: "/",
  _jobPhotos: "/users/getOrderById",
  _changeOrderStatus: "/admin/changeOrderStatus",
  _automaticPush: "/admin/notificationType",
  _getNotificationType: "/admin/getNotificationType",
  _getLanguageLabel: "/admin/displayMessageType",
  _editLanguageLabel: "/admin/editDisplayMessageType",
  _addLanguageLabel: "/admin/createMessageType",
  _getSoundNotification: "/",
  _getSeoList: "/admin/displaySeo",
  _addSeoList: "/admin/saveSeo",
  _addSocialMedia: "/admin/addSocialMediaUrl",
  _getSocialMedia: "/admin/getSocialMediaUrl",
  _addGeneralSetting: "/admin/generalSetting",
  _getGeneralSetting: "/admin/generalSettingListing",
  _addAnalyticSetting: "/admin/analyticSetting",
  _getAnalyticSetting: "/admin/analyticSettingListing",
  _getEmailTemplate: "/",
  _addEmailTemplate: "/",
  _getOrderListingTorefund: "/admin/orderListingToRefund",
  _refundAmount: "/admin/refundAmount",
  _registerUser: "/user/register",
  _userLogin: "/user/login",
  _contactForm: "/user/contactusrequest",
  __help: "/user/contactusrequestUsingPhone",
  _sendOtp: "/user/sendOtp",
  _verifyOtp: "/user/verifyOtp",
  _editPhoneVerify: "/user/editPhoneVerify",
  _forgotPassword: "/user/forgotPassword",
  _setPassword: "/user/setPassword",
  _uploadDocument: "/user/uploadDocument",
  _getPropertyType: "/user/getCommonType",
  _addProperty: "/property/addProperty",
  _addPropertyDocument: "/property/updatePropertyDocument",
  _contractPeriodAgreement: "/user/contractPeriodAgreement",
  _getPropertyDetail: "/property/getPropertyDetails",
  _reportForRentalCard: "/user/reportForRentalCard",
  _reportForPropertyStatement: "/user/propertyStatement",
  _toBeRenewedReports: "/user/tobeRenewedReport",
  _reportsForWithdrawal: "/user/getWithdrawalReport",
  _notPaidCard: "/user/notPaidCardReport",
  _vacantPropertyCard: "/user/getVacantPropertyCard",
  _reportForExpanseCard: "/user/reportForExpanseCard",
  _getAllProperty: "/property/getAllProperty",
  _viewProperty: "/user/viewMore",
  _getAllPropertyWeb: "/user/getAllPropertyToWebUser",
  _propertyStatus: "/user/getTenantProperty",
  _getDashboardData: "/user/getDashboardData",
  _getTenantsProperty: "/user/getTenantProperty",
  _getRequestedProperty: "/user/getRequestedProperty",
  _loginGuestUser: "/user/loginGuestUser",
  _getRentalRequest: "/user/getRentalRequest",
  _cancelRequest: "/user/cancellRequest/",
  _acceptRequest: "/user/acceptRequest",
  _placeRequest: "/user/makeRentalRequest",
  _getRequests: "/user/getMiantainenceQueryList",
  _rejectAggrement: "/user/rejectAggrement",
  _acceptAggrement: "/user/signAggrement",
  _makePayment: "/user/makePayment",
  _getRatingPoints: "/user/ratingPointsAverageRating",
  _makeMaintainenceQuery: "/user/makeMaintainenceQuery",
  _getMaintainenceQueryById: "/user/getMaintainceQueryById",
  // _makeMaintainenceQuery: '/user/makeMaintainenceQuery'
  _resendRequestToUser: "/user/resendRequestToUser",
  _replyOnRequest: "/user/replyOnRentalRequest",
  _propertyListingToTenant: "/user/propertyListingToTenant",
  _resolved: "/user/markResolved/",
  _getCurrentTenants: "/user/getTenantList/",
  _getPastTenants: "/user/getPastTenants/",
  _getUserDetail: "/user/getUserDetail/",
  _getPaymentList: "",
  _deleteBank: "/admin/deleteBank/",
  _getTeamMember: "/user/getTeamMember/",
  _tenantInviteLink: "/property/tenantInviteLink",
  _previewInvoices: "/user/previewInvoices",

  _dryRun: "/user/dryRun",
  _dryRunGetInvoices: "/user/getDateWiseInvoices",
  _getPackage: "/user/getSubscribePackage",
  _buyPackage: "/user/buySubscribePackage",
  _chatHistory: "/user/chatHistory",
  _chatSummery: "/user/chatSummery",
  _chatTenantsSummery: "/user/getAllTenantlistChat",
  _addTeam: "/user/editTeam",
  _editTeam: "/user/addTeam",
  _deleteTeam: "/user/removeByParent/",
  _changeStatusTeam: "/user/blockByParent/",
  _roleListing: "/user/accessRoleListing",
  _getTeamMemberDetail: "/user/getTeamMemberDetail",
  _addRole: "/user/accessRoleToTeamMember",
  _editRole: "/user/editAccessRoleToTeamMember",
  _assignUnassignProperty: "/user/assignedUnassignedProperty",
  _getNotification: "/user/getUserNotification",
  _dontShow: "/user/donotShowAgain",
  _downloadCsv: "/user/downloadSampleCsv.csv",
  _uploadCSVProperty: "/user/uploadCsvProperty",
  _getAllInvoice: "/invoice/getAllInvoice",
  _getAllInvoiceById: "/invoice/getInvoiceById",
  _generateInvoice: "/invoice/generateInvoice",
  _getAllWithdrawal: "/invoice/getAllWithdrawal",
  _getVacateRequest: "/user/getVacantRequest",
  _getHomePage: "/property/getTenantsExplore",
  _makeVacateRequest: "/user/vacantRequest",
  _acceptVacantRequest: "/user/acceptVacateRequest",
  _rejectVacantRequest: "/user/rejectVacateRequest",
  _paidVacantRequest: "/user/makePaymentForVacate",
  _editProfile: "/user/editProfile",
  _paidInvoice: "/invoice/paidInvoice",
  _sendReminder: "/user/sendRemainder",
  _changePassword: "/user/changePassword",
  _getWebViewList: "/user/getTermsAndCondition",
  _getPropertyDocument: "/user/getPropertyDocument",
  _getTeamProperty: "/user/getPropertyListForTeam",
  _getPropertyDetailById: "/property/getPropertyDetails",
  _acceptInvite: "/property/acceptTenantRequest",
  _getAgreement: "/property/agreementForProperty",
  _getPdfAgreement: "/admin/agreementView",
  _rateTenant: "/user/rateYourTenant",
  _rateLandlord: "/user/rateYourLandlord",
  _reviewListing: "/user/getUserReviewListing",
  _reviewListingProperty: "/user/getReviewListingForProperty",
  _checkInviteOtp: "/user/checkInviteOtp",
  _getInvites: "/user/getUserInvite",
  _reopenReq: "/user/reopenMaintenanceQuery",
  _getCurrentTenantPropertyList: "/user/getCurrentTenantPropertyList",

  _getAllProperties: "/property/getAllProperty",
  _activeInactiveProperty: "/user/innactiveProperty/",
  _getPropertyStatusDetails: "/user/getPropertyStatusDetails",
  _markFavUnFavProperty: "/user/markFavouriteUnfavouriteProperty",

  _getCompanyProfile: "/user/getCompanyProfile",
  _editCompanyProfile: "/user/editCompanyProfile",

  _getManagerList: "/user/getAllManagerList",
  _getFavouriteProperty: "/user/getFavouritePropertyListing",

  _getFaq: "/admin/getFaq",
  _shortLink: "/user/generateShortLinkForWeb",
  getPropertyList: "/property/getPropertyList",
  _propertyBankLinkVerify: "/property/propertyBankLinkVerify",
  _addTenant: "/user/addTenant",
  _deleteTenant: "/user/removeOwnerTenentFromProperty",
  _editInvoice: "/user/editInvoice",
  _addFee: "/user/addInvoicePrice",
  _getCounts: "/user/aboutUsCounts",
  _editPayment: "/property/editOccupiedRent",
  _invoice: "/user/webInvoice",
  _payNow: "/user/redirectToPay",
  _dashboardProperties: "/property/dashboard",
  _getPropertyGroupWise: "/property/getAllPropertyGroupWise",
  _changePhoneNumber: "/user/changeTenantPhoneNumber",
  _getPrefilledDeatils: "/user/advanceInvoicePrefilledInfo",
  __getPreviewAdvanceInvoice: "/user/previewAdvanceInvoice",
  _calculateduration: "/user/calculateduration",
  _generateAdvanceInvoice: "/user/generateAdvanceInvoice",
  _cancelInvoice: "/user/cancellAdvanceInvoice",
  _addNotes: "/user/addNotesOnMaintainance",
  _assignTeamOnMaintenance: "/user/assignTeamMemberOnMaintainance",
  _getTenantsForMaintenance: '/user/getTenantListForMaintainanceFilter',
  _downloadMaintenance: "/user/downloadMaintainance",
  _rejectMaintenance: '/user/cancelMaintenanceQuery',
  _addRemainingToResolvingMaintainance: '/user/addRemainingToResolvingMaintainance',
  _getAllOtherInvoice: '/user/getAllOtherInvoice',
  _getPrefilledInfo: '/user/advanceInvoicePrefilledInfo',
  _generateOtherInvoice: '/user/generateOtherFeeInvoice',
  _previewOtherInvoice: '/user/previewOtherFeeInvoice',
  _downloadOtherInvoice: '/user/downloadOtherFeeInvoice',
  _payOtherInvoice: '/user/Paidotherinvoice',
  _cancelOtherInvoice: '/user/cancellOtherInvoice',
  _addAmountOnOtherInvoice: '/user/addAmountOnOtherInvoice',
  _addDiscountOnOtherInvoice: '/user/discountOnOtherInvoice',
  _sendReminderOnOtherInvoice: '/user/sendOtherInvoiceReminder',
  _brokerRequests: '/user/landloardPlusRequests',
  _tenantRequests: '/user/tenantPlusRequests',
  _tenantPaymentListRequest: '/user/listTenantPaymentRequest',
  _tenantPaymentRequest: '/user/tenantPaymentRequest',
  _rejectOfflinePayment: '/user/acceptRejectPaymentRequest',
  _readUpdateOnMaintainance: '/user/readUpdateOnMaintainance',
  _deleteOfflinePayment: '/user/deletePaymentRequestByTenant',
  _blockUnblockOwner: '/user/activeInnactiveLandlord/',
  _deleteProperty: '/user/removeUserProperty',
  _editMaintenance_cost: '/user/editMaintainanceCost',
  _deleteRequest: '/user/removeUserMaintainanceReminderQuery',
  _addOwner: '/user/addLandlord',
  _editOwner: '/user/editLandlord',
  _deleteOwner: '/user/deleteLandlord',
  _listOwner: '/user/listLandlord',
  _deleteInvoice: '/user/deleteInvoice',
  _list_reciepts: '/invoice/listReciept',
  _reciept_details: '/invoice/recieptById',
  _invoicedProperty: '/invoice/getAllGroupedPropertyForInvoice',
  _listInvoice: '/invoice/getAllInvoicePropertyWise',
  _listPaymentCounts: '/invoice/paymentCounts',

  //Business
  _business_login: '/business/login',
  _business_list_offers: '/business/listMyOffers',
  _business_add_offer: '/business/addOffer',
  _business_list_category: '/business/listCategory',
  _business_change_password: '/business/changePassword',
  _business_block_unblock_offer: '/business/activeInnactiveOffer',
  _business_delete_offer: '/business/deleteOffer',
  _business_add_business: '/business/addBusiness',
  _business_edit_business: '/business/editBusiness',
  _business_getOfferById: '/business/getOfferById',
  _business_editOffer: '/business/editOffer',
  _tenant_addGroup: '/user/addGroup',
  _tenant_listGroup: '/user/listGroup',
  _delete_group: '/user/deleteGroup',
  _group_details: '/user/groupById',
  _gorup_edit: '/user/editGroup',
  _update_Image: '/property/updatePropertyAllUnitImage',
  _group_invoice: '/invoice/getCompanyTenantInvoiceById',
  _tenant_Group_Details: '/user/groupDetail',
  _update_property_image: '/property/updatePropertyAllUnitImage',
  _group_paidInvoice: '/invoice/paidGroupInvoice',
  _group_download_invoice: '/invoice/downloadCompanyTenantInvoice',
  _current_tenant_details: '/user/getCurrentTenantDetailOnProperty',
  _add_previous_invoice: '/invoice/addInvoices',
  _getTenantAccounts: '/user/listAccounts',
  _getTenantContracts: '/user/listContracts',
  _updateMoveDate: '/user/updateMoveDate',
  _listTransactions: '/user/transactionList',
  _shareInvoice: "/property/showAndHideInvoiceToTenant/",
  _hideProperty: "/property/showAndHideToExplorePage/",
  _calcDuration: '/user/calculateDuration',
  _listEquipments: '/equipment/listEquipment',
  _addEquipment: '/equipment/addEquipment',
  _listEquipmentType: '/equipment/getEquipmentCommonType',
  _editEquipmentType: '/equipment/editEquipmentCommonType',
  _addEquipmentType: '/equipment/addEquipmentCommonType',
  _deleteEquipment: '/equipment/deleteEquipment',
  _editEquipment: '/equipment/editEquipment',
  _updateTenantInfo: "/user/updateTenantInfoByOwner",
  _addLegal: '/cases/addCases',
  _listCases: '/cases/listCases',
  _legalGroupedInvoices: '/cases/getAllGroupedTenantForLegalInvoice',
  _legalInvoices: '/cases/getAllLegalInvoiceTenantWise',
  _updateLegalCase:'/cases/editCases',
  _resolveCase: '/cases/resolveCases/'
};
