import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from 'src/app/services/admin.service';
import {apiUrl} from 'src/app/global/global';
import {TranslateService} from "../../shared/lang/translate.service";
import {Subscriber} from "rxjs";


@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

    value: String;
    allData: any = {};
    flag: Boolean = true;
    text: String;
    temp2: Boolean = true;
    private subs: Subscriber<any> = new Subscriber();

    constructor(private activeRoute: ActivatedRoute, public admin: AdminService, private router: Router, public translate: TranslateService) {
        this.text = activeRoute.snapshot.data.text;
        this.value = activeRoute.snapshot.data.value;
        this.getList();

        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp2 = false;
        }
    }

    ngOnInit() {
        this.admin.loginState.subscribe(data => {
            this.temp2 = data;
        });
    }

    getList() {
        let data = {
            type: this.value
        }
        this.admin.getData(apiUrl._getWebViewList, data)
            .subscribe(res => {
                    let temp = res.data.template;
                    if (temp.length) {
                        this.flag = true;
                        this.allData = this.admin.lang == 'en' ? temp[0] : temp[1];
                    } else {
                        this.flag = false;
                    }
                }
            );
    }

}
