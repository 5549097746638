import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { AdminService } from 'src/app/services/admin.service';
import { Subscriber } from 'rxjs';
import { apiUrl } from 'src/app/global/global';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-rent-invoice-link',
  templateUrl: './rent-invoice-link.component.html',
  styleUrls: ['./rent-invoice-link.component.css']
})

export class RentInvoiceLinkComponent implements OnInit, OnDestroy {
 
  constructor(
    public translate: TranslateService,
    public admin: AdminService,
    private activatedRoute: ActivatedRoute,

  ) { }

  invoiceId:any;
  subscription = new Subscriber();
  model: any = [];
  ngOnInit() {
    this.subscription.add(this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.invoiceId = params.id;
        this.getInvoice(params.id);
      }
    }))
  }

  getInvoice(id) {
    this.subscription.add(this.admin.getData(apiUrl._invoice + '/' + id, '').subscribe(res => {
      this.model = res.data;
    }))
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  //   addFeeAt: ""
  // additionalAmount: ""
  // additionalKey: ""
  // additionalNoteKey: ""
  // additionalNotes: ""
  // adminAmount: 0
  // color: "#FF011E"
  // createdAt: "20-05-2020"
  // dateStatus: "Due date"
  // dateVal: "20-05-2020"
  // discountAmount: ""
  // discountKey: ""
  // discountNotes: ""
  // discountedAt: ""
  // houseKeeping: 0
  // invoiceDate: "Invoice from 25-04-2020 to 24-08-2020"
  // invoiceInterval: 4
  // invoiceNo: "205201239"
  // link: "https://tstnd.eglooz.com/user/redirectToPay/5ec5abe2a6e2ee0a1ca4d6f5"
  // logo: "https://tstnd.eglooz.com/common/getImage/thumbnail/1573128341560-thumbnail-file.png"
  // maintainanceAmount: 0
  // moveDate: "25-12-2019"
  // noteKey: ""
  // otherPayments: 0
  // ownerCompany: ""
  // ownerEmail: ""
  // ownerfullName: "test"
  // ownerfullNumber: "+9738727832033"
  // parkingAmount: 0
  // propertyAddress: "U:223232,↵                                B:2323,↵                                R:232↵                                Blk:232"
  // propertyName: "testing empty"
  // rentAmount: 222
  // status: "Overdue"
  // statusAction: "Pay Now"
  // teanantEmail: ""
  // tenantfullName: "fdsfdsf"
  // tenantfullNumber: "+9738727832033"
  // totalAmount: 888
  // msg: "بنجاح"

  // getPaymentGatewayLink = () => {
  //   try {
  //     $.get('<%- link %>', function (response, data) {
  //       window.location.href = response.data.link;
  //     });
  //   }
  // };


  getPaymentGatewayLink() {
    this.subscription.add(this.admin.getData(apiUrl._payNow + '/' + this.invoiceId, '').subscribe(res => {
      window.open(res.data.link,"_blank");
    }))
  }

  // getLink(){

  // }  

  // $(function (e) {
  //   $('#btnVerify').click(function (event) {
  //     getPaymentGatewayLink();
  //   });
  // });

}
