import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { AdminService } from '../services/admin.service';

@Component({
    selector: 'mapArea',
    template: ``
})
export class PropertyViewComponent implements OnInit {

    sub;
    constructor(private route: ActivatedRoute, private router: Router, public admin:AdminService) {
        this.sub = route.queryParams.subscribe( params => {


            if (params['id'] && params['phoneNumber']) {
                if (localStorage.getItem('adminToken')) {
                    const loginData = JSON.parse(localStorage.getItem('loginData'));
                    const str = loginData.fullNumber;
                    const res = str.replace("+", " ");
                    if (localStorage.getItem('userType') === 'TENANT') {
                        if (res === params.phoneNumber) {
                            this.router.navigate(['/tenants/propertyDetail/' + params.id], { queryParams: { contractPeriod: params.contractPeriod, inviteId: params.inviteId, phoneNumber: params.phoneNumber }});
                        } else {
                            this.router.navigate(['/']);
                        }
                    } else {
                        this.router.navigate(['/']);
                    }
                } else {
                    admin.path.type = 'TENANT';
                    admin.path.value = 'tenants/propertyDetail/'+params['id'];
                    admin.path.status = {contractPeriod:params['contractPeriod'],phoneNumber: params['phoneNumber'],inviteId:params['inviteId']};
                    admin.path.flag = true;
                    this.router.navigate(['/'], { queryParams: { status: 'login' }});
                }
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    ngOnInit() {}

}
