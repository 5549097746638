import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrl } from 'src/app/global/global';

@Component({
  selector: 'app-sign-sgreement',
  templateUrl: './sign-sgreement.component.html',
  styleUrls: ['./sign-sgreement.component.css']
})
export class SignAgreementComponent implements OnInit {
  file = {};
  signatureImage = {};
  signError = 'false';
  id: string;
  canvas;
  routeValue: string;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('parentBlock') parentBlock: ElementRef;
  // signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  //   'minWidth': 1,
  //   'canvasWidth': 500,
  //   'canvasHeight': 300
  // };
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 1500,
    'canvasHeight': 1000
  };

  // 'canvasWidth': 975,
  // 'canvasHeight': 520
  constructor(private api: InternalServiceService, private route: ActivatedRoute, private router: Router) {
    this.id = route.snapshot.paramMap.get('id');
    this.routeValue = route.snapshot.data.link;
  }

  ngOnInit() {
  }
  uploadImage() {
    if (this.file['size']) {
      this.api.uploadImage(this.file, 'img').subscribe(success => {
        this.signatureImage = success.data;
        console.log(this.signatureImage);
        this.finalSubmit();
      });
    } else {
      this.signError = 'true';
    }
  }


  finalSubmit() {
    const sign = {
      'original': this.signatureImage['original'],
      'thumbnail': this.signatureImage['thumbnail'],
    };
    let data = {
      requestId: this.id,
      signature: sign
    };
    // return false;
    this.api.submitForm(data, apiUrl._acceptAggrement).subscribe(success => {
      this.router.navigate(['/' + this.routeValue + '/booking/' + this.id]);
    });
  }

  clear() {
    this.signaturePad.clear();
    this.signError = 'true';
    this.file = {};
  }
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    const ImageURL = this.signaturePad.toDataURL();
    console.log(ImageURL);

    // Split the base64 string in data and contentType
    const block = ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    const blob = this.b64toBlob(realData, contentType, 512);
    // Create a FormData and append the file with "image" as parameter name
    this.signError = 'false';
    this.file = blob;
  }

  drawStart() {
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const imageFile = new File([blob], 'signature.png', { type: 'image/png' });
    return imageFile;
  }


}
