import { Component, OnInit } from '@angular/core';
import { apiUrl } from '../../global/global';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { InternalServiceService } from 'src/app/internal/internal-service.service';

@Component({
    selector: 'app-maintenance-reminder',
    templateUrl: './maintenance-reminder.component.html',
    styleUrls: ['./maintenance-reminder.component.css']
})
export class MaintenanceReminderComponent implements OnInit {

    constructor(
        public admin: AdminService,
        private api: InternalServiceService,
        private route: Router,
        private activeRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        public translate: TranslateService
    ) {
        this.userType = localStorage.getItem('userType');

    }

    formGroup: FormGroup;

    id: string;
    userType: any;
    allData: any = {};
    togl = true;

    ngOnInit() {
        this.activeRoute.params.subscribe(params => {
            this.id = this.activeRoute.snapshot.paramMap.get('id');
            this.getList();
        });

        window.scrollTo(0, 0);
        this.initForm();
    }

    initForm() {

        this.formGroup = this.formBuilder.group({
            amount: new FormControl('', [Validators.compose([Validators.required])]),
            comment: new FormControl('', [Validators.compose([Validators.required])])
        });

        if (this.userType != 'TENANT') {
            this.formGroup.setControl('resolvedImage', new FormControl([]));
        }

    }


    getList() {
        const data: any = {
            id: this.id
        };
        this.admin.getData(apiUrl._getMaintainenceQueryById, data).subscribe(success => {
            this.allData = success.data;
            if (this.userType != 'TENANT' && this.allData.requestForward) {
                this.formGroup.setControl('requestForwardTenant', new FormControl('false'));
            }
        });
    }

    upload(event) {
        if (event.target.files && event.target.files.length) {
            const temp = event.target.files[0].type;
            if (temp === 'image/jpeg' || temp === 'image/png' || temp === 'image/jpg') {
                const file = event.target.files[0];
                this.api.uploadImage(file, 'img').subscribe(success => {
                    const temp = this.formGroup.value.resolvedImage ? this.formGroup.value.resolvedImage : [];
                    temp.push({ original: success.data.original, thumbnail: success.data.thumbnail });
                    this.formGroup.patchValue({ resolvedImage: temp });
                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }

    openModal() {
        this.formGroup.reset();
        document.getElementById('openModal').click();

    }


    // keyPress(event) {
    //     console.log(event.keyCode);
    //     if (event.key != "." && event.key != "Delete") {
    //         if ((event.keyCode >= 48) && (event.keyCode <= 57)) {
    //             return
    //         } else {
    //             return false;
    //         }
    //     }
    // }


    removeImage(i){
        this.formGroup.value.resolvedImage.splice(i,1)
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const request: any = this.formGroup.value;
            request.id = this.allData._id;

            if (!request.resolvedImage) {
                delete request.resolvedImage;
            }

            this.admin.putData(apiUrl._resolved, request).subscribe(success => {
                this.admin.showAlert('Reminder Completed', '');
                this.admin.showLoader = false;
                this.getList();
                this.closeModal();
            });
        } else {
            Object.keys(this.formGroup.controls).forEach(key => {
                this.formGroup.get(key).markAsTouched({ onlySelf: true });
            });
        }
    }
    closeModal() {
        this.formGroup.get('amount').reset();
        this.formGroup.get('comment').reset();
        document.getElementById('close').click();
    }


}
