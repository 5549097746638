import { Component, OnInit, Input } from '@angular/core';
import { property } from '../shared/property-model';
import { AdminService } from '../services/admin.service';
import { TranslateService } from "../shared/lang/translate.service";
import { HttpParams } from "@angular/common/http";
import { apiUrl } from "../global/global";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-property-tab-details',
    templateUrl: './property-details.component.html',
    styleUrls: ['./property-details.component.css']
})
export class PropertyDetailsComponent implements OnInit {
    @Input() allData: any;
    @Input() link: string;

    ratingData: any[] = [];
    limit = 150;
    language = 'en'

    constructor(
        public admin: AdminService,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.language = localStorage.getItem('language')
    }

    pagination: any = { perPage: 20, page: 1, count: 0 };

    ngOnInit() {

    }


    getList() {
        let params = new HttpParams();

        params = params.append('propertyId', this.route.snapshot.paramMap.get('id'));
        params = params.append('page', this.pagination.page);
        params = params.append('perPage', this.pagination.perPage);

        this.admin.getData(apiUrl._reviewListing, params).subscribe(res => {
            this.ratingData = res.data.listing;
            console.log(res);
        });
    }

}
