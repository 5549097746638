import {Component, OnInit} from '@angular/core';
import {Subscriber} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../../services/admin.service";
import {TranslateService} from "../../shared/lang/translate.service";
import {apiUrl} from "../../global/global";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    constructor(
        private activeRoute: ActivatedRoute,
        public admin: AdminService,
        private router: Router,
        public translate: TranslateService
    ) {
    }

    allData: any[] = [];
    private subs: Subscriber<any> = new Subscriber();

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.admin.getData(apiUrl._getFaq, {}).subscribe(res => {
            this.allData = res.data;
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        });
    }

}
