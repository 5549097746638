import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ExternalService } from 'src/app/external/external.service';
import { apiUrl } from 'src/app/global/global';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.css']
})
export class ChangePhoneNumberComponent implements OnInit {
  showError = false;
  userForm: FormGroup;
  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    public admin: AdminService,
    private api: ExternalService,
  ) {
    this.userForm = this.fb.group({
      phoneNumber: ['', Validators.required],
      callingCode: ['+973', Validators.required],
    });
  }

  ngOnInit() {
  }

  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      this.userForm.value.phoneNumber = '';
      return false;
    }
  }

  getNumber(event) { }

  hasError(event) {
  }

  onCountryChange(event) {
    this.userForm.patchValue({
      callingCode: '+' + event.dialCode,
      phoneNumber: ''
    });
  }

  telInputObject(event) {
  }

  onSubmit(fd) {
    this.showError = true;
    if (this.userForm.valid) {
      this.admin.postData(apiUrl._editProfile, fd).subscribe(res => {
        // this.api.showSuccess('Phone number changed successfully')
        this.setOtpDataToLocal(res, localStorage.getItem('userType'));
      })
    }
  }


  setOtpDataToLocal(res, userType?) {
    this.api.otpId = res.data._id;
    this.api.fullNumber = res.data.fullNumber;
    const otpData: any = {};
    otpData.id = res.data._id;

    if (userType) {
      otpData['userType'] = userType;
    }

    otpData.phoneNumber = res.data.fullNumber;
    localStorage.setItem('OTPData', JSON.stringify(otpData));
    localStorage.setItem('OTPData', JSON.stringify(otpData));
    localStorage.setItem('adminToken', res.data.accessToken);
    this.router.navigate(['/forgotPassword/verified'], { queryParams: { 'changeNumber': true } });
  }
}
