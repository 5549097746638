import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdminService } from './admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from "../shared/lang/translate.service";

@Injectable({
    providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {

    constructor(public admin: AdminService, private activatedRoute: ActivatedRoute, private router: Router, public translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        !localStorage.getItem('adminToken') ? (localStorage.getItem('userType')) ? (localStorage.removeItem('userType')) : '' : ''
        const token = (localStorage.getItem('userType')) ? localStorage.getItem('adminToken') : localStorage.getItem('guestToken');
        const businessToken = (localStorage.getItem('businessData') ? JSON.parse(localStorage.getItem('businessData'))['accessToken'] : '')
        const panel = this.router.url.startsWith('/business') ? 'business' : 'eglooz'
        if (token && panel === 'eglooz') {
            req = req.clone({ headers: req.headers.set('authorization', 'bearer ' + token) });
        }
        if (panel === 'business' && businessToken) {
            req = req.clone({ headers: req.headers.set('authorization', 'bearer ' + businessToken) });
        }
        // if (token) {
        //     req = req.clone({ headers: req.headers.set('authorization', 'bearer ' + token) });
        // }
        req = req.clone({ headers: req.headers.set('role', 'user') });
        req = req.clone({ headers: req.headers.set('language', this.translate.lang_code) });
        req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
        // req = req.clone({ headers: req.headers.set('X-PINGOTHER', 'pingpong') });

        if (this.admin.showLoader) {
            this.admin.loader(true);
        } else {
            this.admin.showLoader = true;
        }

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.admin.loader(false);
            }
        },
            (err: any) => {
                this.admin.loader(false);
                if (err instanceof HttpErrorResponse) {
                    if (err.error.status == 401 || err.status === 401) {
                        const abc = this.router.url.split('/', 2);
                        this.admin.path.flag = true;
                        this.admin.path.type = localStorage.getItem('uerType');
                        this.admin.path.value = this.router.url;
                        localStorage.removeItem('adminToken');
                        localStorage.clear();
                        this.admin.errorAlert(err.error.msg, true);
                    } else if (err.error.status == 402 || err.error.status == 403) {
                        this.admin.errorAlert(err.error.msg, true);
                    } else {
                        this.admin.errorAlert(err.error.msg, false);
                    }
                }
            }));
    }
}
