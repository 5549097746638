import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { TranslateService } from "../../shared/lang/translate.service";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

    tenantList: any[] = [];
    senderId: string;
    tenantChat: any[] = [];
    profileData: any = {};
    message: string;
    typingFlag: Boolean = false;
    typeSenderId: string;
    index: number;
    flag: Boolean = true;
    @ViewChild('panel') panelScroll: ElementRef;
    userDetail: any
    constructor(public admin: AdminService, public translate: TranslateService) {
        // const userType = localStorage.getItem('landLordId');
        this.senderId = localStorage.getItem('userId');
    }

    getDetail() {
        const data = {
            'userId': this.senderId
        };
        this.admin.getData(apiUrl._getUserDetail, data).subscribe(success => {
            console.log(success.data);
            this.userDetail = success.data;
        });
    }

    ngOnInit() {
        this.admin.subjectState.subscribe(data => {
            console.log(data);

            this.tenantChat.push(data[0]);
            setTimeout(() => {
                this.scrollToBottom();
            }, 100);
        });

        this.admin.subjectBroadcast.subscribe(data => {
            for (const y of this.tenantList) {
                if (y['senderId']['_id'] == data['userId']) {
                    y['senderId']['isOnline'] = data['isOnline'];
                    if (this.profileData['senderId']['_id'] == data['userId']) {
                        this.profileData['senderId']['isOnline'] = data['isOnline'];
                    }
                    break;
                }
            }
        });

        this.admin.subjectTyping.subscribe(data => {
            this.typingFlag = data['isTyping'];
            this.typeSenderId = data['senderId'];
        });
        this.getList();
        window.scrollTo(0, 0);
    }

    scrollToBottom() {
        this.panelScroll.nativeElement.scrollTop = this.panelScroll.nativeElement.scrollHeight;
    }

    getList() {
        const data = {};
        this.admin.getData(apiUrl._chatTenantsSummery, data).subscribe(success => {
            this.tenantList = success.data.listing;
            console.log(this.tenantList);
            for (const tenant of this.tenantList) {
                const chatData: any = {};
                const userId = localStorage.getItem('userId');
                if (userId) {
                    chatData.senderId = userId;
                    chatData.receiverId = tenant._id;
                }
                this.admin.socket.emit('initiateChat', chatData);
            }
            this.getListOfTenants();
        });
    }


    getListOfTenants() {
        const data = {
            'page': '1',
            'perPage': '10'
        };
        this.admin.getData(apiUrl._chatSummery, data).subscribe(success => {
            this.tenantList = success.data.listing;
            if (this.tenantList.length) {
                console.log('hello', this.admin.chat);

                if (this.admin.chat) {

                    for (let x = 0; x < this.tenantList.length; x++) {
                        if (this.admin.chat == this.tenantList[x].senderId._id) {
                            this.getChatSummary(this.tenantList[x], x, null);
                            break;
                        }
                    }
                } else {

                    this.getChatSummary(this.tenantList[0], 0, null);
                }
            }

        });
    }

    getListOfTenants1(event) {
        const data = {
            'page': '1',
            'perPage': '10',
            fullName: event.target.value
        };
        this.admin.showLoader = false;
        this.admin.getDataObject(apiUrl._chatSummery, data).subscribe(success => {
            this.tenantList = success.data.listing;
        });
    }

    getChatSummary(data, i, chatId) {
        console.log(i);

        if (this.index != i) {
            this.index = i;
            this.profileData = data;
            this.tenantChat = [];
            const data1 = {
                'perPage': '1000',
                'userId': this.profileData['senderId']._id
            };
            if (chatId) {
                data['chatId'] = chatId;
            }
            this.admin.showLoader = false;
            this.admin.getDataObject(apiUrl._chatHistory, data1).subscribe(success => {
                this.tenantChat = success.data.listing;
                this.tenantChat = this.tenantChat.reverse();
                setTimeout(() => {
                    this.scrollToBottom();
                }, 100);
            });
        }
    }

    sendMessage() {
        if (this.message) {
            const data = {
                'message': this.message,
                'messageType': 'TEXT',
                'senderId': this.senderId,
                'receiverId': this.profileData['senderId']._id,
                'sentAt': +new Date()
            };
            this.admin.socket.emit('sendMessage', data);
            this.tenantChat.push(data);
            this.message = '';
            setTimeout(() => {
                this.scrollToBottom();
            }, 100);
        }
    }

    typing1(status) {
        this.typing(status);
        setTimeout(() => {
            this.typing(!status);
        }, 1000);
    }

    typing(status) {
        const data = {
            'isTyping': status,
            'senderId': this.senderId,
            'receiverId': this.profileData['senderId']._id,
        };
        this.admin.socket.emit('typing', data);
        this.typeSenderId = null;
    }


    uploadFile(event) {
        if (event.target.files && event.target.files.length) {
            const temp = event.target.files[0].type;
            if (temp == 'image/jpeg' || temp == 'image/png' || temp == 'image/jpg') {
                const file = event.target.files[0];
                this.admin.uploadImage(file, file.name.split('.').pop() == 'pdf' ? 'pdf' : 'img').subscribe(success => {
                    const data = {
                        'imageUrl': { original: success.data.original, thumbnail: success.data.thumbnail },
                        'senderId': this.senderId,
                        'messageType': 'IMAGE',
                        'receiverId': this.profileData['senderId']._id,
                        'sentAt': +new Date()
                    };
                    this.admin.socket.emit('sendMessage', data);
                    this.tenantChat.push(data);
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 100);
                });
            } else {
                this.admin.errorAlert('Invalid format!', '');
            }
        }
    }
}
