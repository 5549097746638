import { Injectable } from '@angular/core';
import { apiUrl } from '../global/global';
import { HttpParams } from '@angular/common/http';
import { generalModel } from '../shared/models/general.model';
import { AdminService } from '../services/admin.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TenantsService {

    generalModel = new generalModel();
    index: number = 0;
    // propertyTab:number;
    maintanceQuery:number;
    // vacateRequest:number;
    chat: String;
    payment: number;

    constructor(private api:AdminService, private router:Router) { }

    getList(data,url)
    {
      if(localStorage.getItem('adminToken')) {
        let  params = new HttpParams();
        for(let x of Object.keys(data))
        {
            params = params.append(x,data[x]);
        }
        return this.api.getData(url,params);
      } else {
        this.router.navigate(['home']);
      }
    }

    getLanguage()
    {
      if(localStorage.getItem('adminToken')) {
        return this.api.getData(apiUrl._getLanguage,'');
      } else {
        this.router.navigate(['home']);
      }
    }

    uploadImage(file,type)
    {
      if(localStorage.getItem('adminToken')) {
        const fd = new FormData();
        fd.append('image', file );
        fd.append('type', type );
        return this.api.postData(apiUrl._uploadImage,fd);
      } else {
        this.router.navigate(['home']);
      }
    }

    submitForm(data,url)
    {
      if(localStorage.getItem('adminToken')) {
        return this.api.postData(url,data);
      } else {
        this.router.navigate(['home']);
      }
    }

    submitPutForm(data,url)
    {
      if(localStorage.getItem('adminToken')) {
        return this.api.putData(url,data);
      } else {
        this.router.navigate(['home']);
      }
    }
    
    delete(id) {
      if(localStorage.getItem('adminToken')) {
        return this.api.postData(apiUrl._deleteServiceProvider,{serviceId:id});
      } else {
        this.router.navigate(['home']);
      }
    }

    uploadDocument(data)
    {
      if(localStorage.getItem('adminToken')) {
        return this.api.putData(apiUrl._addPropertyDocument,data);
      } else {
        this.router.navigate(['home']);
      }
    }
    putData(data,url)
    {
      if(localStorage.getItem('adminToken')) {
        return this.api.putData(url,data);
      } else {
        this.router.navigate(['home']);
      }
    }

      // Show and hide Loader
  private stepSubject = new Subject<number>();
  stepState = this.stepSubject.asObservable();

  step(value){this.stepSubject.next( value ); }

  min(count,page) {
    if(count != 0) {
      let temp = ((page*10)-10)+1;
      return temp;
    } else {
      return '0';
    }
  }

  max(count,page) {
    if(count != 0) {
      let temp = ((page*10)-10);
      if(temp+10 <= count) {
        return temp+10;
      } else {
        return '-'+(temp+(count-temp));
      }
    }
  }

    // call getApi with pageChanged
    private callFunction = new Subject<number>();
    functionState = this.callFunction.asObservable();
    getData(value){this.callFunction.next( value ); }
  
    pageChange (status,page,count) {
        if ( status === 'next' && (page * 10 ) < count ) {
          page++;
          this.getData(page);
        } else if ( status === 'pre' && page > 1 ) {
          page--;
          this.getData(page);
        }
      }


}

