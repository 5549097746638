import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { API_URL, API_END_POINT, WEB_URL } from './global';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import {
    generalModel,
    icons,
    menu,
    BtnText,
    permission,
    tabArray,
    optionArray,
    specificCountry,
    NumberCode,
    accessControle,
    country
} from '../shared/models/general.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { language } from './constants';
import * as io from 'socket.io-client';
import { apiUrl } from '../global/global';
import { TranslateService } from '../shared/lang/translate.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { element } from '@angular/core/src/render3/instructions';
declare var VRView: any
declare var $: any

@Injectable({
    providedIn: 'root'
})

export class AdminService {
    public apiEndpoint: string = API_END_POINT;
    public webUrl: string = WEB_URL;
    public apiUrl: string = API_URL;
    public showLoader: boolean = true;
    public countries = country;
    public number: string = '1.0-3';
    public path: any = { value: '', type: '', status: {}, flag: false };
    public icon = icons;
    public chat: String;
    public control = accessControle;
    public menu = menu;
    public btnText = BtnText;
    public generalModel = new generalModel();
    public Permission = permission;
    public lang = 'en';
    public language = language;
    public userType: string;
    public socket;
    public socket_id: any;
    public connected: any;
    public country = specificCountry;
    public numberCode = NumberCode
    public decimalValue: string = '1.0-3';
    public oneDecimalValue: string = '1.0-1';
    public _albums = [];
    // language: String = 'en';
    // multi = language;
    public phoneLength: number = 15;
    modalRef: BsModalRef = new BsModalRef()

    constructor(private http: HttpClient, private router: Router, private _lightbox: Lightbox, public translate: TranslateService, public modalService: BsModalService) {
        this.userType = localStorage.getItem('userType') ? localStorage.getItem('userType') : 'LANDLORD';
        this.initSocket();
        if (localStorage.getItem('language')) {
            this.lang = localStorage.getItem('language');
        }
        //
    }

    // ngAfterViewInit() {
    //
    //   this.getCountry();
    // }
    checkValue(status) {
        return this.language[status][this.lang];
    }

    ngOnInit() {
    }

    private logoutFromSystem = new Subject<boolean>();
    logout$ = this.logoutFromSystem.asObservable();

    private socketSubject = new Subject<string>();
    subjectState = this.socketSubject.asObservable();

    subject(value) {
        this.socketSubject.next(value);
    }

    private typingSubject = new Subject<string>();
    subjectTyping = this.typingSubject.asObservable();

    typing(value) {
        this.typingSubject.next(value);
    }

    private broadcastSubject = new Subject<string>();
    subjectBroadcast = this.broadcastSubject.asObservable();

    broadcastFun(value) {
        this.broadcastSubject.next(value);
    }


    private noficationSubject = new Subject<Number>();
    subjectNotification = this.noficationSubject.asObservable();

    notification(value: Number) {
        this.noficationSubject.next(value);
    }


    uploadVideo(url, file) {
        console.log('filer', file);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('type', 'video');
        return this.http.post<any>(this.apiUrl + url, formData);
    }

    checkVideo(file) {
        console.log(file);
        if (file) {
            if (file.size < 20000000) {
                if (file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/avi') {
                    return true;
                } else {
                    this.toast('', 'Please add video in proper format', 'warning');
                }
            } else {
                this.toast('', 'Please add video less than 200 MB', 'warning');
            }
        }
        return false;
    }


    public initSocket(): void {
        this.socket = io.connect(this.apiUrl, {
            query: {
                accessToken: 'bearer ' + localStorage.getItem('adminToken')
            }
        });
        try {
            this.socket.on('socketConnected', fun => {
                this.connected = this.socket.connected;
                if (this.connected) {


                    this.socket.on('messageFromServer', (res: any) => {
                        if (res) {
                            this.subject(res);
                        }
                    });
                    this.socket.on('typing', (res: any) => {
                        if (res) {
                            this.typing(res);
                        }
                    });
                    this.socket.on('chat', (res: any) => {
                        if (res) {

                        }
                    });
                    this.socket.on('broadcast', (res: any) => {
                        if (res) {
                            this.broadcastFun(res);
                        }
                    });

                    this.socket.on('REQUEST', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCEPT_REQUEST', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('REJECT_REQUEST', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('SIGN_AGREEMENT_LANDLORD', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('SIGN_AGREEMENT_TENANT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('REPLIED_COMMENT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('RESEND_AGREEMENT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('PAYMENT_DONE', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('INVITE_USER', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCEPT_INVITATION', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('QUERIES', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('MAINTENANCE', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('INVOICE', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('PROPERTY_APPROVED', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ADMIN', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCOUNT_DELETED', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCOUNT_BLOCK', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('CHAT', (res: any) => {
                        if (res) {

                            // this.toast(res.message,'', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('PAYMENT_DONE_VACATE', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_ACCEPT_TENANT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_ACCEPT_LANDLORD', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_REJECT_TENANT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_REJECT_LANDLORD', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_TENANT', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('VACATE_REQUEST_LANDLORD', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCOUNT_UNAPPROVED', (res: any) => {
                        if (res) {
                            const UserDate: any = JSON.parse(localStorage.getItem('loginData'));
                            UserDate.isApproved = false;
                            localStorage.setItem('loginData', JSON.stringify(UserDate));
                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('ACCOUNT_APPROVED', (res: any) => {
                        if (res) {
                            const UserDate: any = JSON.parse(localStorage.getItem('loginData'));
                            UserDate.isApproved = true;
                            localStorage.setItem('loginData', JSON.stringify(UserDate));
                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('PROPERTY_UNAPPROVED', (res: any) => {
                        if (res) {

                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                        }
                    });
                    this.socket.on('REJECT_AGREEMENT', (res: any) => {
                        if (res) {
                            this.toast(res.message, '', 'info');
                            this.notification(res.badeCount);
                            this.router.navigate(['/tenants/property'], {
                                queryParams: { status: 'inAgreement' }
                            })
                        }
                    });


                }
            });
        }
        catch (e) {
            console.log(e);
        }

        try {

            this.socket.on('disconnect', fun => {

            });
        }
        catch (e) {
            console.log(e);
        }
    }

    getDataObject(url, data) {
        if (localStorage.getItem('adminToken')) {
            let params = new HttpParams();
            for (let x of Object.keys(data)) {
                params = params.append(x, data[x]);
            }
            return this.getData(url, params);
        } else {
            this.router.navigate(['home']);
        }
    }

    uploadImage(file, type) {
        if (localStorage.getItem('adminToken') || (localStorage.getItem('businessData') && JSON.parse(localStorage.getItem('businessData'))['accessToken'])) {
            const fd = new FormData();
            fd.append('image', file);
            fd.append('type', type);
            return this.postData(apiUrl._uploadImage, fd);
        } else {
            this.router.navigate(['home']);
        }
    }

    getData(url, param) {
        return this.http.get<any>(this.apiUrl + url, { params: param });
    }


    deleteTenant(url, id) {
        if (typeof id === 'string') {
            return this.http.delete<any>(this.apiUrl + url + '/' + id, {});
        }
        else {
            for (const obj in id) {
                url = url + '/' + id[obj]
            }
            return this.http.delete<any>(this.apiUrl + url, {});
        }
    }

    deleteData(url, data) {
        console.log(data);

        return this.http.delete<any>(this.apiUrl + url, data);
    }

    deleteFormData(url, data) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                tenantId: data.tenantId,
                groupId: data.groupId,
                propertyId: data.propertyId
            },
        };

        return this.http.delete<any>(this.apiUrl + url, options);
    }

    postData(url, value) {
        return this.http.post<any>(this.apiUrl + url, value);
    }

    putData(url, data) {
        return this.http.put<any>(this.apiUrl + url, data);
    }

    putData1(url) {
        return this.http.put<any>(this.apiUrl + url, {});
    }

    fireBasePost(data) {
        const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAjt-OwP2XYCRc1vylEyVrNhgm59MjXVmA';
        return this.http.post(url, data);
    }

    putDataParams(url, id) {
        return this.http.put<any>(this.apiUrl + url + id, {});
    }


    // putCancelData(url, data) {

    //     console.log(data);

    //         return this.http.put<any>(this.apiUrl + url + data.propertyId + '/' + data.requestId, {});

    // }


    showAlert(title: string, text: string) {
        swal({
            title: title,
            text: text,
            type: 'success',
            showConfirmButton: false,
            timer: 1500
        })
    }

    successAlert(title: string, text: string) {
        swal({
            title: title,
            text: text,
            type: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
        })
    }

    errorAlert(text, status) {

        swal({
            type: 'error',
            title: 'Oops...',
            text: text,
        }).then((result) => {
            if (status) {
                this.logout();
            }
        });
    }

    toast(title, text, type) {
        const toast = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000,

            background: (type != 'error') ? '#096A74' : (type == 'warning') ? '#f89406' : '#bd362f',
            // customClass: 'myCustomClass',
        });
        toast({
            type: type,
            title: '<span style="color:#ffffff!important">' + title + '</span>',
            text: text,
        })
    }

    open(image): void {
        if (image !== null && image !== undefined && image !== '') {
            this._albums = [];
            const album: any = {};
            if (image.endsWith('blob')) {
                album.src = image + '.png';
            } else if (image.type !== 'p_img') {
                album.src = image;
            }
            if (Object.keys(album).length) {
                this._albums.push(album)
            }
            // open lightbox
            this._lightbox.open(this._albums, 0, { disableScrolling: true, centerVertically: true });
        }
    }



    openMulty(imageArray): void {
        if (imageArray !== null && imageArray !== undefined && imageArray.length > 0) {
            this._albums = [];

            let album: any = {};
            imageArray.forEach(image => {
                if(image.type!='pdf') {
                    album = {}
                    if (image.original.endsWith('blob')) {
                        album.src = this.apiEndpoint + image.original + '.png';
                    } else if (image.type !== 'p_img') {
                        album.src = this.apiEndpoint + image.original;
                    }
                    if (Object.keys(album).length) {
                        this._albums.push(album)
                    }
                }
            })
            if(this._albums.length) {
            this._lightbox.open(this._albums, 0, { disableScrolling: true, centerVertically: true });
            }

            imageArray.forEach(image => {
                if(image.type=='pdf') {
                    window.open(this.apiEndpoint + image.original, '_blank')
                }
            })

        }
    }

    openPdf(image) {

    }

    openMultyWithIndex(imageArray, index): void {
        if (imageArray !== null && imageArray !== undefined && imageArray.length > 0) {
            this._albums = [];
            let album: any = {};
            if (imageArray[index].original.endsWith('blob')) {
                album.src = this.apiEndpoint + imageArray[index].original + '.png';
            } else if (imageArray[index].type !== 'p_img') {
                album.src = this.apiEndpoint + imageArray[index].original;
            }
            if (Object.keys(album).length) {
                this._albums.push(album)
            }
            imageArray.forEach((image, ind) => {
                if (ind !== index) {
                    album = {};
                    if (image.original.endsWith('blob')) {
                        album.src = this.apiEndpoint + image.original + '.png';
                    } else if (image.type !== 'p_img') {
                        album.src = this.apiEndpoint + image.original;
                    }
                    if (Object.keys(album).length) {
                        this._albums.push(album)
                    }
                }
            });
            if(this._albums.length) {
            this._lightbox.open(this._albums, 0, { disableScrolling: true, centerVertically: true });
            }

            imageArray.forEach(image => {
                if(image.type=='pdf') {
                    window.open(this.apiEndpoint + image.original, '_blank')
                }
            })
        }
    }

    openModal(templateRef, y) {
        this.modalRef = this.modalService.show(templateRef, { backdrop: "static" })
        // window.addEventListener('load', this.onVrViewLoad)
        this.onVrViewLoad(y)
        if (localStorage.getItem("language") == "ar") {
            $("body").attr("class", "arabic-class body-small modal-open");
        }
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    onVrViewLoad(y) {
        // Selector '#vrview' finds element with id 'vrview'.
        var vrView = new VRView.Player('#myVrView', {
            image: (`${y ? this.apiEndpoint + y : 'https://dummyimage.com/400x240/ccc/fff}'}`),
            preview: (`${y ? this.apiEndpoint + y : 'https://dummyimage.com/400x240/ccc/fff}'}`),
            width: '100%'
        })
    }


    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }


    // Show and hide Loader
    private loaderSubject = new Subject<Boolean>();
    loaderState = this.loaderSubject.asObservable();

    loader(value) {
        this.loaderSubject.next(value);
    }

    setdata(data) {
        for (let x of tabArray) {
            let array1 = {}
            for (let y of x.value) {
                array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
            }
            permission[x.name] = array1;
        }
        permission['status'] = "updated";
        return true;
    }

    checkObject(x) {
        for (let key in x) {
            if (x.hasOwnProperty(key)) {
                return true;
            }
        }
        return false;
    }

    private loginCheck = new Subject<Boolean>();
    loginState = this.loginCheck.asObservable();


    signout() {
        swal({
            title: this.translate.lang.LogOutMsg,
            type: 'warning',
            customClass: 'swal-wide',
            showCancelButton: true,
            confirmButtonText: this.translate.lang.SideNav_Logout,
            cancelButtonText: this.translate.lang.Cancel,
            confirmButtonColor: '#FBA31C',
            cancelButtonColor: '#ed5565',
        }).then((result) => {
            if (result.value) {
                this.logout();
            }
        });
    }


    cancelRequest() {
        swal({
            title: this.translate.lang.LogOutMsg,
            type: 'warning',
            customClass: 'swal-wide',
            showCancelButton: true,
            confirmButtonText: this.translate.lang.SideNav_Logout,
            cancelButtonText: this.translate.lang.Cancel,
            confirmButtonColor: '#FBA31C',
            cancelButtonColor: '#ed5565',
        }).then((result) => {
            if (result.value) {

            }
        });
    }

    DeleteTenant(title, confirmBtn, cancelBtn) {
        return new Promise(function (resolve) {
            swal({
                title: title,
                type: 'warning',
                customClass: 'swal-wide',
                showCancelButton: true,
                confirmButtonText: confirmBtn,
                cancelButtonText: cancelBtn,
                confirmButtonColor: '#FBA31C',
                cancelButtonColor: '#ed5565',
            }).then((result) => {
                if (result.value) {
                    resolve('success');
                }
            });
        })
    }

    DeleteGroup(title, confirmBtn) {
        return new Promise(function (resolve) {
            swal({
                title: title,
                type: 'warning',
                customClass: 'swal-wide',
                showCancelButton: false,
                confirmButtonText: confirmBtn,
                confirmButtonColor: '#FBA31C',
            }).then((result) => {
                if (result.value) {
                    resolve('success');
                }
            });
        })
    }


    logout() {
        if (localStorage.getItem('adminToken')) {
            this.putData(apiUrl.logout, localStorage.getItem('adminToken')).subscribe(res => {
                localStorage.removeItem('adminToken');
                localStorage.removeItem('loginData');
                localStorage.removeItem('userType');
                localStorage.removeItem('userDocument');
                localStorage.removeItem('permissionData');
                localStorage.removeItem('landLordId');
                localStorage.removeItem('profileImage');
                localStorage.removeItem('propertyId');
                this.socket.disconnect();
                this.loginCheck.next(true);
                this.router.navigate(['/']);
            })
        } else {
            localStorage.removeItem('adminToken');
            localStorage.removeItem('loginData');
            localStorage.removeItem('userType');
            localStorage.removeItem('userDocument');
            localStorage.removeItem('permissionData');
            localStorage.removeItem('landLordId');
            localStorage.removeItem('profileImage');
            localStorage.removeItem('propertyId');
            this.socket.disconnect();
            this.loginCheck.next(true);
            this.router.navigate(['/']);
            this.logoutFromSystem.next(true);

        }

    }

    paginationMax(max) {
        if (Math.floor(max) != max) {
            if (Math.floor(max) < max) {
                max = Math.floor(max) + 1;
            } else if (Math.floor(max) > max) {
                max = Math.floor(max);
            }
        }
        return max;
    }

    paginationMaxValue(max, value) {
        if (max > value) {
            max = max / value;
            if (Math.floor(max) != max) {
                if (Math.floor(max) < max) {
                    max = Math.floor(max) + 1;
                } else if (Math.floor(max) > max) {
                    max = Math.floor(max);
                }
            }
            return max;
        } else {
            return 1;
        }
    }

    getDirection(data) {
        navigator.geolocation.getCurrentPosition((position) => {
            window.open('http://maps.google.com/?saddr=' + position.coords.latitude + ',' + position.coords.longitude + '&daddr=' + data['address']['lat'] + ',' + data['address']['lng'], '_blank');
        });
    }

    onlyNumber(event) {
        return ((event.which >= 48) && (event.which <= 57));
    }

    checkMinMax(y) {
        if (y < 12) {
            return (y + ' ' + this.translate.lang.Month);
        } else {
            if (y === 12) {
                return this.translate.lang.AddProperty_PaymentDetails_oneYear;
            } else if (y > 12 && y < 120) {
                const data = (y / 12).toString();
                let bcd = (y - (12 * parseInt(data[0]))).toString();
                bcd = bcd !== '0' ? data[0] + '.' + bcd : data[0];
                return (bcd + ' ' + this.translate.lang.Year);
            } else if (y === 120) {
                return ('10 ' + ' ' + this.translate.lang.Year);
            }
        }
    }

    // call getApi with pageChanged
    private callFunction = new Subject<number>();
    functionState = this.callFunction.asObservable();

    paginationData(value) {
        console.log(value);

        this.callFunction.next(value);
    }

    // call getApi with pageChanged
    private renatlInvoiceFunction = new Subject<boolean>();
    rentalState = this.renatlInvoiceFunction.asObservable();


    public rentalSuccess = new BehaviorSubject<any>({});
    rentalSuccess$ = this.rentalSuccess.asObservable();

    rentalStatus(value) {
        this.renatlInvoiceFunction.next(value);
    }

    // call getApi with pageChanged
    private paymentFilterFunction = new Subject<boolean>();
    paymentFilterState = this.paymentFilterFunction.asObservable();

    paymentFilterStatus(value) {
        this.paymentFilterFunction.next(value);
    }


}
