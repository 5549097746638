import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { AdminService } from 'src/app/services/admin.service';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalService } from '../external.service';
import { SharedService } from 'src/app/shared/shared.service';
import { AddBrokerRequestComponent } from './add-broker-request/add-broker-request.component';
import { AddTenantRequestComponent } from './add-tenant-request/add-tenant-request.component';
declare const $: any
@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenSize = window.innerWidth
  }

  screenSize = 0
  componentRef;
  activeFirstButton: boolean = true
  whyJoinArray = [
    { description: '', img: 'assets/img/illustraion-2.png', img1: 'assets/img/illustraion-2@2x.png', img2: 'assets/img/illustraion-2@3x.png', active: true },
    { description: 'Increase your reach and client base', img: 'assets/img/illustraion-3.png', img1: 'assets/img/illustraion-3@2x.png', img2: 'assets/img/illustraion-3@3x.png', active: false },
    { description: 'Get direct genuine leads', img: 'assets/img/illustraion-4.png', img1: 'assets/img/illustraion-4@2x.png', img2: 'assets/img/illustraion-4@3x.png', active: false },
    { description: 'Save time', img: 'assets/img/illustraion-5.png', img1: 'assets/img/illustraion-5@2x.png', img2: 'assets/img/illustraion-5@3x.png', active: false },
    { description: 'Pay only per successful closed deals', img: 'assets/img/illustraion-6.png', img1: 'assets/img/illustraion-6@2x.png', img2: 'assets/img/illustraion-6@3x.png', active: false },
  ]

  whyShareContactArray = [
    { description: '', img: 'assets/img/illustraion-7.png', img1: 'assets/img/illustraion-7@2x.png', img2: 'assets/img/illustraion-7@3x.png', active: true },
    { description: 'Easily find your next home', img: 'assets/img/illustraion-8.png', img1: 'assets/img/illustraion-8@2x.png', img2: 'assets/img/illustraion-8@3x.png', active: false },
    { description: 'Keep away from unwanted calls', img: 'assets/img/illustraion-9.png', img1: 'assets/img/illustraion-9@2x.png', img2: 'assets/img/illustraion-9@3x.png', active: false },
    { description: 'Save time', img: 'assets/img/illustraion-5.png', img1: 'assets/img/illustraion-5@2x.png', img2: 'assets/img/illustraion-5@3x.png', active: false },
    { description: 'Get what you want', img: 'assets/img/illustraion-10.png', img1: 'assets/img/illustraion-10@2x.png', img2: 'assets/img/illustraion-10@3x.png', active: false },
    { description: 'Your contact and request is safe with us', img: 'assets/img/illustraion-11.png', img1: 'assets/img/illustraion-11@2x.png', img2: 'assets/img/illustraion-11@3x.png', active: false },
    { description: "it's free for you Get Rewards ", img: 'assets/img/illustraion-12.png', img1: 'assets/img/illustraion-12@2x.png', img2: 'assets/img/illustraion-12@3x.png', active: false },
  ]

  lang: string = 'en'

  constructor(
    public translate: TranslateService,
    public admin: AdminService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private externalService: ExternalService,
    private sharedService: SharedService,
    private componentModal: NgbModal
  ) {
    this.screenSize = this.screenSize
  }

  ngOnInit() {
    this.lang = localStorage.getItem('language')
  }

  public activeImage(ind): void {
    if (this.activeFirstButton) {
      this.whyJoinArray.map(x => x.active = false)
      this.whyJoinArray[ind].active = true
    } else {
      this.whyShareContactArray.map(x => x.active = false)
      this.whyShareContactArray[ind].active = true
    }
  }

  public openModal(type: string): void {
    switch (type) {
      case 'Broker':
        this.openComponent(AddBrokerRequestComponent)
        break;
      case 'Tenant':
        this.openComponent(AddTenantRequestComponent)
        break;
    }
  }

  public getActiveImage() {
    if (this.activeFirstButton) {
      if (this.screenSize < 1900) {
        return this.whyJoinArray.find(x => x.active).img
      }
      if (this.screenSize < 3000 && this.screenSize > 1900) {
        return this.whyJoinArray.find(x => x.active).img1
      }
      if (this.screenSize > 3000) {
        return this.whyJoinArray.find(x => x.active).img2
      }
    } else {
      if (this.screenSize < 1900) {
        return this.whyShareContactArray.find(x => x.active).img
      }
      if (this.screenSize < 3000 && this.screenSize > 1900) {
        return this.whyShareContactArray.find(x => x.active).img1
      }
      if (this.screenSize > 3000) {
        return this.whyShareContactArray.find(x => x.active).img2
      }
    }
  }

  public openComponent(component): void {
    this.componentRef = this.componentModal.open(component, { backdrop: 'static', keyboard: false, windowClass: 'md' });
    if (localStorage.getItem('language') == 'ar') {
      $("body").attr("class", "arabic-class body-small modal-open");
    }
    this.componentRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log(receivedEntry);
    })

    this.componentRef.result.then((result) => { }).catch((result) => { });
  }

  resetImage() {
    if (this.activeFirstButton) {
      this.whyJoinArray.map(x => x.active = false)
      this.whyJoinArray[0].active = true
    } else {
      this.whyShareContactArray.map(x => x.active = false)
      this.whyShareContactArray[0].active = true
    }
  }

}
