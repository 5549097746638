import { Component, OnInit, Input } from '@angular/core';
import { Subscriber } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-vacant-card',
  templateUrl: './vacant-card.component.html',
  styleUrls: ['./vacant-card.component.css']
})
export class VacantCardComponent implements OnInit {

 
  totalRent: number = 0;
  totalOccupiedRent: number = 0;
  totalCollectedRent: number = 0;
  totalExpenses: number = 0;
  @Input() model: any;
  @Input() type: any;
  @Input() filter:any;
  @Input() pagination: any;
  date: Date = new Date();

  subscription = new Subscriber();

  constructor(private router: Router,
    public translate:TranslateService,
    private activatedRoute: ActivatedRoute, public admin: AdminService) { }

  ngOnInit() {
    if (this.model.length) {
      if (this.model.listing.length) {
        this.model.listing.forEach(element => {
          if (element.invoiceData) {
            this.totalRent = this.totalRent + element.invoiceData.totalAmount;
            if (element.invoiceData.flag == 2) {
              this.totalCollectedRent = this.totalCollectedRent + element.totalAmount
            }
          }
          if (element.status == 2) {
            this.totalOccupiedRent = this.totalOccupiedRent + element.rentAmount;
          }
        });
      }
      if (this.model.aggregateProp.length) {
        this.totalExpenses = this.totalExpenses + this.model.aggregateProp[0].expenseData.amount;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}