import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from "../../shared/lang/translate.service";
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
declare var VRView: any
declare var $: any
@Component({
    selector: 'app-properties-view',
    templateUrl: './properties-view.component.html',
    styleUrls: ['./properties-view.component.css']
})
export class PropertiesViewComponent implements OnInit, OnDestroy {
    @Input() y: any;
    @Input() singleImag: boolean;
    temp1: boolean = false
    modalRef: BsModalRef
    constructor(
        public admin: AdminService,
        public translate: TranslateService,
        public _sanitizer: DomSanitizer,
        private modalService: BsModalService,
    ) {
    }

    ngOnInit() {
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument')) {
            this.temp1 = true;
        }
    }

    openModal(templateRef, y) {
        this.modalRef = this.modalService.show(templateRef, { backdrop: "static" })
        // window.addEventListener('load', this.onVrViewLoad)
        this.onVrViewLoad(y)
        if (localStorage.getItem("language") == "ar") {
            $("body").attr("class", "arabic-class body-small modal-open");
        }
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    onVrViewLoad(y) {
        // Selector '#vrview' finds element with id 'vrview'.
        var vrView = new VRView.Player('#vrview', {
            image: (`${y ? this.admin.apiEndpoint + y : 'https://dummyimage.com/400x240/ccc/fff}'}`),
            preview: (`${y ? this.admin.apiEndpoint + y : 'https://dummyimage.com/400x240/ccc/fff}'}`),
            width: '100%'
        })
    }

    ngOnDestroy() {
        this.closeModal()
    }

}
