import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InternalServiceService } from '../../internal/internal-service.service';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../shared/lang/translate.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-company-documents',
  templateUrl: './upload-company-documents.component.html',
  styleUrls: ['./upload-company-documents.component.css']
})
export class UploadCompanyDocumentsComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: InternalServiceService,
    public admin: AdminService,
    public translate: TranslateService,
    public formBuilder: FormBuilder
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    name: new FormControl('', [Validators.compose([Validators.required])]),
    commercialRegistration: new FormControl('', [Validators.compose([Validators.required])]),
    address: new FormControl('', [Validators.compose([Validators.required])]),
    isBroker: new FormControl(false, [Validators.compose([Validators.required])]),
    document: this.formBuilder.array([]),
    reraDocument: this.formBuilder.array([]),
  });


  isBroker = true;

  get document(): FormArray {
    return this.formGroup.get('document') as FormArray;
  }

  get reraDocument(): FormArray {
    return this.formGroup.get('reraDocument') as FormArray;
  }

  userData: any = [];
  event;
  userId;
  show: boolean = false;

  public userSettings4: any = {
    showSearchButton: false,
    showCurrentLocation: false,
    geoCountryRestriction: ['bh'],
    inputPlaceholderText: this.translate.lang.Home_Place_EnterAddress,
    locationIconUrl: 'http://www.myiconfinder.com/uploads/iconsets/369f997cef4f440c5394ed2ae6f8eecd.png',
    noOfRecentSearchSave: 8
  };


  ngOnInit() {

  }



  onChange(e) {
    console.log(e.target.value);
    console.log(this.formGroup.get('isBroker').value);
    this.isBroker = !this.isBroker;
  }

  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      this.formGroup.value.authorizedPersonContact = '';
      return false;
    }
  }



  removeDocument(ind: number) {
    console.log(ind);
    if (this.formGroup.get('document').value.length) {
      this.document.removeAt(ind);
    }
  }

  removeReraDocument(ind: number) {
    console.log(ind);
    if (this.formGroup.get('reraDocument').value.length) {
      this.document.removeAt(ind);
    }
  }

  creattDocumentForm(data) {
    const docFormGroup: FormGroup = this.formBuilder.group({
      thumbnail: new FormControl(data.thumbnail, []),
      original: new FormControl(data.original, []),
      type: new FormControl(data.type, []),
      name: new FormControl('', [])
    });
    this.document.push(docFormGroup);
    console.log(this.document);
  }


  createReraDocumentForm(data) {
    const docFormGroup: FormGroup = this.formBuilder.group({
      thumbnail: new FormControl(data.thumbnail, []),
      original: new FormControl(data.original, []),
      type: new FormControl(data.type, []),
      name: new FormControl('', [])
    });
    this.reraDocument.push(docFormGroup);
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const data: any = this.formGroup.value;
      const request: any = {};

      request.name = data.name;
      request.commercialRegistration = data.commercialRegistration;
      request.companyNumber = data.companyNumber;
      request.address = data.address;
      if (data.isBroker) {
        request.isBroker = data.isBroker;
        if (this.reraDocument.length) {
          request.reraDocument = this.reraDocument.value;
        } else {
          this.admin.toast('Rera Documents are required', '', 'error');
          return false;
        }
      }

      if (this.document.length) {
        request.document = this.document.value;
      } else {
        request.document = [];
      }

      this.api.submitPutForm(request, apiUrl._editCompanyProfile).subscribe(response => {

        let data = JSON.parse(localStorage.getItem('loginData'));
        if (request.reraDocument.length) {
          data.companyProfile['reraDocument'] = request.reraDocument;
        }

        if (request.document.length) {
          data.companyProfile['document'] = request.document;
        }

        localStorage.setItem('userDocument', 'true');
        localStorage.setItem('loginData', JSON.stringify(data));


        this.router.navigate(['/landlord']);
      });

      console.log(request);


    } else {
      Object.keys(this.formGroup.controls).forEach(formKey => {
        this.formGroup.get(formKey).markAsTouched({ onlySelf: true });
      });

    }
  }

  upload(event, type) {
    if (event.target.files && event.target.files.length) {
      const fileType = event.target.files[0].type;
      if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'application/pdf') {
        const file = event.target.files[0];
        let docType = "img";
        if (fileType == 'application/pdf') {
          docType = 'pdf';
        }

        this.api.uploadImage(file, docType).subscribe(success => {
          console.log(type);

          if (type === 'document') {
            this.creattDocumentForm(success.data);
          }

          if (type === 'reraDocument') {
            this.createReraDocumentForm(success.data);
          }
        });
      } else {
        this.admin.errorAlert('Invalid format!', '');
      }
    }
  }

  autoCompleteCallback1(selectedData: any) {
    if (selectedData.response) {
      this.formGroup.get('address').patchValue(selectedData.response ? selectedData.data.formatted_address : '');
    }
  }
}
