import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { Observable } from "rxjs/Observable";
import { TranslateService } from "./shared/lang/translate.service";
import { Subscriber } from "rxjs";
import { AdminService } from "./services/admin.service";
declare var $: any;
// declare var jQuery: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    sub;
    loginData;
    show: boolean = true;


    isConnected: Observable<boolean>;
    @Output() messageEvent = new EventEmitter<string>();
    private subs: Subscriber<any> = new Subscriber();

    constructor(public router: Router, private swUpdate: SwUpdate, private route: ActivatedRoute, public translate: TranslateService, private activatedRoute: ActivatedRoute) {

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.isConnected = Observable.merge(
            Observable.of(navigator.onLine),
            Observable.fromEvent(window, 'online').map(() => true),
            Observable.fromEvent(window, 'offline').map(() => false));

        this.activatedRoute.params.subscribe(params => {
            console.log(params);

            if (params.language) {
                localStorage.setItem('language', params.language);
            }
        })

        if (localStorage.getItem('language')) {

        } else {
            localStorage.setItem('language', 'en');
        }
        // this.onActivate(event,'scrollContainer');
    }

    onActivate(e,scrollContainer) {
        // const scrollToTop = window.setInterval(() => {
        //     const pos = window.pageYOffset;
        //     if (pos > 0) {
        //         window.scrollTo(0, pos - 20); // how far to scroll on each step
        //     } else {
        //         window.clearInterval(scrollToTop);
        //     }
        // }, 16);

        scrollContainer.scrollTop = 0;
    }

    ngOnInit() {

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }


        if (localStorage.getItem('cookies')) {
            this.show = false;
        }

        $(document).ready(function () {
            setTimeout(function () {
                $("#cookieConsent").fadeIn(200);
            }, 2000);
            $("#closeCookieConsent, .cookieConsentOK").click(function () {
                $("#cookieConsent").fadeOut(200);
            });
        });

        $("#cookieConsentOK, .cookieConsentOK").click(function () {
            $("#cookieConsent").fadeOut(200);
        });

        // this.sub = this.route.queryParams.subscribe( params => {
        //     if(localStorage.getItem('adminToken')) {
        //         if (localStorage.getItem('userType') === 'TENANT') {
        //             if(params.id && params.phoneNumber) {
        //                 this.loginData = JSON.parse(localStorage.getItem('loginData'));
        //                 const str = this.loginData.fullNumber;
        //                 const res = str.replace("+", " ");
        //                 if (res === params.phoneNumber) {
        //                     this.router.navigate(['/tenants/propertyView/'+params.id]);
        //                 } else {
        //                     this.router.navigate(['/home']);
        //                 }
        //             }
        //         } else {
        //             this.router.navigate(['/home']);
        //         }
        //     } else {       //         if(params.id && params.phoneNumber) {
        //             this.router.navigate(['/home'],{queryParams: {status:'login'}});
        //         } else {
        //             this.router.navigate(['/home']);
        //         }
        //     }
        // //         if (localStorage.getItem('userType') === 'TENANT') {
        // //             if(params.id && params.phoneNumber) {
        // //             this.loginData = JSON.parse(localStorage.getItem('loginData'));
        // //             const str = this.loginData.fullNumber;
        // //             const res = str.replace("+", " ");
        // //             if (res === params.phoneNumber) {
        // //                 this.router.navigate(['/tenants/propertyView/'+params.id]);
        // //             }
        // //         } else if(localStorage.getItem('adminToken')) {
        // //             this.router.navigate(['/home']);
        // //         } else {
        // //             this.router.navigate(['/home'],{ queryParams: {status:'login'}});
        // //         }
        // //     } else {
        // //         // this.router.navigate(['/home']);
        // //     }
        // });
    }

    allowCookies() {
        localStorage.setItem('cookies', JSON.stringify(true));
    }

}
