import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buisness-plan',
  templateUrl: './buisness-plan.component.html',
  styleUrls: ['./buisness-plan.component.css']
})
export class BuisnessPlanComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public router:Router,
  ) { }

  ngOnInit() {
  }

}
