import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { ExternalService } from '../external.service';
import * as CONSTANT from '../../services/constants';
import { Router } from '@angular/router';
import { TranslateService } from "../../shared/lang/translate.service";

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

    @Output() messageEvent = new EventEmitter<string>();

    buttonStatus: boolean = false;
    userForm: FormGroup;
    showError = false;
    status = 'type';
    password = ['password', 'password'];
    arabicError=false;

    isArabic: RegExp = /^([\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\ufdf0-\ufdfd])*$/g;


    constructor(
        private fb: FormBuilder,
        private api: ExternalService,
        private router: Router,
        public admin: AdminService,
        public translate: TranslateService
    ) {
        this.userForm = this.fb.group({
            userType: ['LANDLORD', Validators.required],
            fullName: ['', Validators.compose([Validators.required])],
            phoneNumber: ['', Validators.required],
            callingCode: ['+973', Validators.required],
            languageType: ['EN'],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            deviceType: ['WEB', Validators.required],
            deviceToken: ['fkdkfjdfk', Validators.required]
        }, { validator: this.validateIsSpouse });
    }

    validateIsSpouse(group: FormGroup) {
        let to = group.get('password').value;
        let from = group.get('confirmPassword').value;
        if (to != from) {
            return group.controls['confirmPassword'].setErrors({ min: true });
        }
        return null;
    }

    ngOnInit() {
    }

    sendMessage(status) {
        this.messageEvent.emit(status);
    }

    onSubmit(form) {


        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;

        if (isArabic.test(this.userForm.value.fullName.trim())) {
            this.admin.toast('','Arabic Letter on name field is not allowed','warning')
            return false;
        }


        if (isArabic.test(this.userForm.value.phoneNumber.trim())) {
            this.admin.toast('','Arabic Letter on phone number field is not allowed','warning')
            return false;
        }


        this.userForm.patchValue({ fullName: this.userForm.value.fullName.trim() })
        if (form.valid) {
            const data = {
                'fullNumber': this.userForm.value.callingCode + this.userForm.value.phoneNumber,
                'userType': this.userForm.value.userType
            };
            let temp = this.userForm.value.callingCode.slice(1);
            this.api.fullNumber = '+ (' + temp + ') ' + this.userForm.value.phoneNumber;
            this.userForm.get('confirmPassword').disable();
            for (let x in this.userForm.controls) {
                this.api.registerData[x] = form.value[x];
            }
            this.api.submitForm(data, apiUrl._sendOtp)
                .subscribe(res => {
                    console.log(res.data);
                    this.api.otpId = res.data._id;
                    const data1: any = { id: res.data._id, phoneNumber: res.data.fullNumber, userType: this.userForm.value.userType };
                    localStorage.setItem('OTPData', JSON.stringify(data1));
                    // this.api.redirectStatus = 'signup';
                    // this.admin.showAlert('Sign up successfull', '');
                    // this.messageEvent.emit('otp');
                    this.router.navigate(['/signUp/verified']);
                });
        } else {
            this.showError = true;
            setTimeout(() => {
                this.showError = false;
            }, 5000);
            return;
        }
    }

    getNumber(event) {
        const numArray = event.split(this.userForm.value.callingCode);
        this.userForm.patchValue({
            phoneNumber: numArray[1]
        });
    }

    hasError(event) {
    }

    keyPress(e) {
     var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.fullName='';
            return false;
        }
    }

    keyPress2(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.phoneNumber='';
            return false;
        }
    }

    onCountryChange(event) {
        this.userForm.patchValue({
            callingCode: '+' + event.dialCode,
            // phoneNumber: ''
        });
    }

    telInputObject(event) {
    }

}
