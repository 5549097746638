import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { InternalServiceService } from 'src/app/internal/internal-service.service';

@Component({
  selector: 'app-add-tenant-request',
  templateUrl: './add-tenant-request.component.html',
  styleUrls: ['./add-tenant-request.component.css']
})
export class AddTenantRequestComponent implements OnInit {
  @Input() public propData: any = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  inItOpt: any = { initialCountry: 'bh' };
  temp = [0, 1000];
  temp2 = [0, 1000];
  filteredBrands: any[] = [];
  cityData: any[] = [];
  propertyType: any[] = [];

  typeArray = [
    { active: true, name: 'Residential' },
    { active: false, name: 'Commercial' }
  ]

  lookingFor = [
    { active: true, name: 'Sale' },
    { active: false, name: 'Rent' }
  ]

  roomsArray = [
    { active: false, name: 'Studio' },
    { active: false, name: '1 BHK' },
    { active: false, name: '2 BHK' },
    { active: false, name: '3 BHK' },
    { active: false, name: '4 BHK' },
    { active: false, name: '5 BHK' },
    { active: false, name: '6 BHK' },
    { active: false, name: '7 BHK' },
    { active: false, name: 'All' }
  ]

  furnishingArray = [
    { active: false, name: 'Unfurnished' },
    { active: false, name: 'Semi Furnished' },
    { active: false, name: 'Fully Furnished' }
  ]

  ewaArray = [
    { active: false, name: 'Including' },
    { active: false, name: 'Exclusive' },
    { active: false, name: 'Partial' }
  ]
  constructor(public translate: TranslateService, private fb: FormBuilder, public activeModal: NgbActiveModal, public admin: AdminService,
    private api: InternalServiceService
  ) { }

  ngOnInit() {
    this.initForm()
    this.getCity()
    this.getPropertyType()
    this.form.get('amountRange').valueChanges.subscribe(data => {
      if (data.length) {
        this.temp = data;
      }
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: [''],
      phoneNumber: ['', [Validators.required]],
      callingCode: ['+973'],
      lookingFor: ['Sale'],
      type: ['Residential'],
      city: ['', [Validators.required]],
      propertyType: ['', [Validators.required]],
      amountRange: [[0, 1000]],
      rooms: [[], [Validators.required]],
      furnishingType: ['', [Validators.required]],
      ewa: ['', [Validators.required]],
      areaSize: [[0, 1000]],
      landmark: [''],
    })
  }

  getCity() {
    this.admin.showLoader = false;
    this.admin.getData(apiUrl._getPropertyType, { type: 'City' }).subscribe(success => {
      this.cityData = success.data.propertyType[0].propertyData;
    });
  }

  getPropertyType() {
    this.admin.showLoader = false;
    this.admin.getData(apiUrl._getPropertyType, { type: this.form.get('type').value }).subscribe(success => {
      this.propertyType = success.data.propertyType[0].propertyData;
    });
  }

  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      return false;
    }
  }

  hasError(event) {
  }

  onCountryChange(event) {
    this.form.patchValue({
      callingCode: '+' + event.dialCode,
    });
  }

  getNumber(event) {
    const numArray = event.split(this.form.value.callingCode);
    this.form.patchValue({
      phoneNumber: numArray[1]
    });
  }

  telInputObject(event) {
    console.log(event);
  }

  changeStatus(data, index, type: string) {
    switch (type) {
      case 'lookingFor':
        this.lookingFor.map(x => x.active = false)
        this.lookingFor[index].active = true
        this.form.get('lookingFor').patchValue(this.lookingFor[index].name)
        break;
      case 'Type':
        this.typeArray.map(x => x.active = false)
        this.typeArray[index].active = true
        this.form.get('type').patchValue(this.typeArray[index].name)
        this.getPropertyType()
        break;
      case 'Rooms':
        this.roomsArray[index].active = !this.roomsArray[index].active
        const ar = this.roomsArray.filter(x => x.active)
        this.form.get('rooms').patchValue(ar.map(({ name }) => name))
        break;
      case 'furnishingType':
        this.furnishingArray.map(x => x.active = false)
        this.furnishingArray[index].active = true
        this.form.get('furnishingType').patchValue(this.furnishingArray[index].name)
        break;
      case 'EWA':
        this.ewaArray.map(x => x.active = false)
        this.ewaArray[index].active = true
        this.form.get('ewa').patchValue(this.ewaArray[index].name)
        break;
    }
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.cityData.length; i++) {
      let brand = this.cityData[i].name;
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }


  onSubmit(): void {
    console.log(this.form)
    if (this.form.valid && this.form.value.rooms.length && this.form.value.furnishingType.length && this.form.value.ewa.length) {
      const request = { ... this.form.value }
      this.admin.postData(apiUrl._tenantRequests, request).subscribe(res => {
        this.admin.showAlert('Success', 'Request sent successfully')
        this.activeModal.close('cross click')
      })
    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched({ onlySelf: true })
      });
    }
  }
}
