import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { ExternalService } from '../external.service';
import { apiUrl } from '../../global/global';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { TenantsService } from 'src/app/tenants/tenants.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

    showError = false;
    userForm: FormGroup;
    @Output() messageEvent = new EventEmitter<string>();
    @ViewChild('openType') openType: ElementRef;
    @ViewChild('closeType') closeType: ElementRef;
    type = { landlord: false, team: false, tenants: false, manager: false }
    otpForm: FormGroup;
    constructor(private fb: FormBuilder,
        private router: Router,
        public admin: AdminService,
        private api: ExternalService,
        public translate: TranslateService,
        private service: TenantsService
    ) {
        this.userForm = this.fb.group({
            fullNumber: ['', Validators.required],
            callingCode: ['+973', Validators.required],
        });
    }

    ngOnInit() {
        this.initiateOtpForm();
    }

    initiateOtpForm() {
        this.otpForm = this.fb.group({
            otp1: ['', Validators.required],
            otp2: ['', Validators.required],
            otp3: ['', Validators.required],
            otp4: ['', Validators.required],
            otp5: ['', Validators.required],
            otp6: ['', Validators.required],
            otp: [''],
        });
        ;
    }

    keyPress2(e) {
        var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
        if (isArabic.test(e.key)) {
            this.userForm.value.fullNumber = '';
            return false;
        }
    }


    sendMessage(status) {
        this.messageEvent.emit(status);
    }

    setOtpDataToLocal(res, userType?) {
        this.api.otpId = res.data._id;
        this.api.fullNumber = res.data.fullNumber;
        const otpData: any = {};
        otpData.id = res.data._id;
       
        if (userType) {
            otpData['userType'] = userType;
        }

        otpData.phoneNumber = res.data.fullNumber;
        localStorage.setItem('OTPData', JSON.stringify(otpData));
        this.router.navigate(['/forgotPassword/verified']);
    }


    onSubmit() {
        if (this.userForm.valid) {
            let data = {
                fullNumber: this.userForm.value.callingCode + this.userForm.value.fullNumber
            }

            this.api.submitForm(data, apiUrl._forgotPassword)
                .subscribe(res => {
                    if (res.data.roleAccounts) {
                        this.openType.nativeElement.click();
                        for (let x of res.data.roleAccounts) {
                            if (x.userType == 'TENANT') {
                                this.type.tenants = true;
                            } else if (x.userType == 'LANDLORD') {
                                this.type.landlord = true;
                            } else if (x.userType == 'MANAGER') {
                                this.type.manager = true;
                            } else if (x.userType == 'TEAM_MEMBER') {
                                this.type.team = true;
                            }
                        }
                    } else {
                        this.setOtpDataToLocal(res);
                    }
                });

        }
    }

    keytab(event) {
        let element = event.srcElement.nextElementSibling; // get the sibling element
        if (element == null) {
            return;
        } else {
            element.focus();   // focus if not null
        }
    }


    openModal(status, event) {
        if (this.userForm.valid) {
            let data: any = {};

            data = {
                fullNumber: this.userForm.value.callingCode + this.userForm.value.fullNumber
            }

            if (status) {
                this.closeType.nativeElement.click();
                data['userType'] = event.target.value;
            }

            this.api.submitForm(data, apiUrl._forgotPassword)
                .subscribe(res => {
                    this.setOtpDataToLocal(res, event.target.value)
                });
        } else {
            this.showError = true;
            setTimeout(() => {
                this.showError = false;
            }, 5000);
            return;
        }
    }

    getNumber(event) {
        // this.userForm.patchValue({
        //     fullNumber: event
        // });
    }

    hasError(event) {
    }

    onCountryChange(event) {
        this.userForm.patchValue({
            callingCode: '+' + event.dialCode,
            phoneNumber: ''
        });
    }

    telInputObject(event) {
    }

}
