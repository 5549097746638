import {Component, OnInit} from '@angular/core';
import {AdminService} from "../../services/admin.service";
import {Router} from "@angular/router";
import {TranslateService} from "../../shared/lang/translate.service";

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {

    temp2: boolean = true;
    image: any;

    constructor(
        public admin: AdminService,
        private router: Router,
        public translate: TranslateService
    ) {
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp2 = false;
        }
        if (localStorage.getItem('profileImage')) {
            this.image = JSON.parse(localStorage.getItem('profileImage'));
        }

    }

    ngOnInit() {
    }

    check() {
        const type = localStorage.getItem('userType');
        this.router.navigate([type === 'LANDLORD' || type === 'MANAGER' || type === 'TEAM_MEMBER' ? '/landlord' : '/tenants']);
    }

    changeLanguage(lang) {
        if (this.translate.lang_code !== lang) {
            this.admin.lang = lang;
            localStorage.setItem('language', lang);
            window.location.reload();
        }
    }
}
