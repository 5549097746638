import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {apiUrl} from '../../global/global';
import {AdminService} from '../../services/admin.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExternalService} from '../external.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.css']
})

export class PaymentStatusComponent implements OnInit {

    constructor(
        public admin: AdminService,
        private api: ExternalService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder
    ) {

        const data = this.activatedRoute.snapshot.queryParams;
        console.log(data);

        this.paymentData.transaction = data.tranid;
        this.paymentData.paymentid = data.paymentid;
        this.paymentData.responsecode = data.responsecode ? data.responsecode.toString() : '';
        // this.paymentData.responsecode = '05';
        this.paymentData.result = data.result;
        this.paymentData.version = data.version;
        this.paymentData.error =  data.error ? data.error : '';
        this.paymentData.errortext =  data.errortext ? data.errortext : '';
        this.userType = localStorage.getItem('userType');

    }

    paymentData: any = {};
    userType: any = '';

    ngOnInit() {
    }
}
