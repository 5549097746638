import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './../external/login/login.component';
import { SignupComponent } from './../external/signup/signup.component';
import { ForgotPasswordComponent } from './../external/forgot-password/forgot-password.component';
import { OtpComponent } from './../external/otp/otp.component';
import { UploadDocumentComponent } from './../external/upload-document/upload-document.component';
import { SetPasswordComponent } from './../external/set-password/set-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { CalendarModule } from 'primeng/calendar';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { SliderModule } from 'primeng/slider';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
// import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CustPipe } from '../pipe/cust.pipe';
import { Cust1Pipe } from '../pipe/cust1.pipe';
import { LimitPipe } from '../pipe/limit.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { PropertyDetailsComponent } from '../property-details/property-details.component';
import * as CONSTANTS from './../services/constants';
import { AgmCoreModule } from '@agm/core';
import { PaginationComponent } from '../common/pagination/pagination.component';
import { TableProfileDataComponent } from '../common/table-profile-data/table-profile-data.component';
import { TablePropertyDataComponent } from '../common/table-property-data/table-property-data.component';
import { ChangePasswordComponent } from '../common/change-password/change-password.component';
import { ChatComponent } from '../common/chat/chat.component';
import { RequestViewComponent } from '../common/request-view/request-view.component';
import { ReviewComponent } from '../common/review/review.component';
import { ReportsComponent } from '../common/reports/reports.component';
import { NotificationComponent } from '../common/notification/notification.component';
import { VacateRequestComponent } from '../common/vacate-request/vacate-request.component';
import { AddressComponent } from '../common/address/address.component';
import { VacateViewComponent } from '../common/vacate-view/vacate-view.component';
import { ProfileComponent } from '../common/profile/profile.component';
import { EditProfileComponent } from '../common/profile/edit-profile/edit-profile.component';
import { SignAgreementComponent } from '../common/sign-sgreement/sign-sgreement.component';
import { RentalInvoiceComponent } from '../common/rental-invoice/rental-invoice.component';
import { MarkFavComponent } from '../common/mark-fav/mark-fav.component';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { BookingPropertyComponent } from '../common/booking-property/booking-property.component';
import { PaymentFilterComponent } from '../common/payment-filter/payment-filter.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TruncatePipe } from '../pipe/truncate';
import { MaintenanceReminderComponent } from '../common/maintenance reminder/maintenance-reminder.component';
import { NumberOnlyDirective } from '../shared/numberonly';
import { PaymentStatusComponent } from '../external/payment-status/payment-status.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FooterComponent } from '../external/footer/footer.component';
import { NoSpecialCharacters } from '../shared/no-special-characters.directive';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { UtcDatePipe } from "../pipe/utc-date.pipe";
import { UtcTimePipe } from "../pipe/utc-time.pipe";
import { TimeagoModule } from "ngx-timeago";
import { RentCardComponent } from '../common/reports/rent-card/rent-card.component';
import { ExpenseCardComponent } from '../common/reports/expense-card/expense-card.component';
import { VacantCardComponent } from '../common/reports/vacant-card/vacant-card.component';
import { NotPaidComponent } from '../common/reports/not-paid/not-paid.component';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { WithrawalReportComponent } from '../common/reports/withrawal-report/withrawal-report.component';
import { ToBeRenewedComponent } from '../common/reports/to-be-renewed/to-be-renewed.component';
import { PropertyStatementComponent } from '../common/reports/property-statement/property-statement.component';

import {
    AccordionModule,
    TabViewModule,
    SpinnerModule,
    AutoCompleteModule
} from 'primeng/primeng';
import { NeedHelpComponent } from '../external/need-help/need-help.component';
import { SupportComponent } from '../external/support/support.component';
import { ChangePhoneNumberComponent } from '../common/change-phone-number/change-phone-number.component';
import { CompanyAddEditComponent } from '../internal/company-add-edit/company-add-edit.component';
import { AddBrokerRequestComponent } from '../external/link/add-broker-request/add-broker-request.component';
import { AddTenantRequestComponent } from '../external/link/add-tenant-request/add-tenant-request.component';
import { AddPaymentReceiptComponent } from '../tenants/payment/add-payment-receipt/add-payment-receipt.component';

@NgModule({
    imports: [
        NgSelectModule,
        CommonModule,
        ProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule,
        TabViewModule,
        SpinnerModule,
        AutoCompleteModule,
        CarouselModule,
        Ng2TelInputModule,
        CalendarModule,
        NgbModule,
        SignaturePadModule,
        NgxPaginationModule,
        RouterModule,
        NgbRatingModule,
        // InternationalPhoneNumberModule,
        // NgxIntlTelInputModule,
        Ng4GeoautocompleteModule,
        SliderModule,
        LazyLoadImageModule,
        UiSwitchModule,
        AgmCoreModule.forRoot({
            apiKey: CONSTANTS.apiKey,
        }),
        ChartModule,
        BsDatepickerModule.forRoot(),
        ImageCropperModule,
        NgxCaptchaModule,
        ShareButtonsModule.withConfig({
            debug: true,
            include: ['facebook', 'whatsapp', 'copy'],
            // 'twitter', 'linkedin', 'messenger',
        }),
        ModalModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        TimeagoModule.forRoot(),
    ],
    entryComponents: [AddBrokerRequestComponent, AddTenantRequestComponent, AddPaymentReceiptComponent],
    declarations: [
        ToBeRenewedComponent,
        AddBrokerRequestComponent,
        AddTenantRequestComponent,
        AddPaymentReceiptComponent,
        PropertyStatementComponent,
        NotPaidComponent,
        VacantCardComponent,
        WithrawalReportComponent,
        LoginComponent,
        SupportComponent,
        ExpenseCardComponent,
        NeedHelpComponent,
        CompanyAddEditComponent,
        SignupComponent,
        ForgotPasswordComponent,
        OtpComponent,
        UploadDocumentComponent,
        SetPasswordComponent,
        RentCardComponent,
        CustPipe,
        Cust1Pipe,
        LimitPipe,
        UtcDatePipe,
        UtcTimePipe,
        TruncatePipe,
        PropertyDetailsComponent,
        PaginationComponent,
        TableProfileDataComponent,
        TablePropertyDataComponent,
        ChangePasswordComponent,
        ChatComponent,
        RequestViewComponent,
        ReviewComponent,
        ReportsComponent,
        NotificationComponent,
        VacateRequestComponent,
        AddressComponent,
        VacateViewComponent,
        ProfileComponent,
        EditProfileComponent,
        SignAgreementComponent,
        RentalInvoiceComponent,
        MarkFavComponent,
        BookingPropertyComponent,
        PaymentFilterComponent,
        MaintenanceReminderComponent,
        NumberOnlyDirective,
        PaymentStatusComponent,
        FooterComponent,
        NoSpecialCharacters,
        ChangePhoneNumberComponent,

    ],
    exports: [
        MarkFavComponent,
        ChangePhoneNumberComponent,
        PaymentFilterComponent,
        LoginComponent,
        SignupComponent,
        SupportComponent,
        NeedHelpComponent,
        ForgotPasswordComponent,
        OtpComponent,
        ProgressBarModule,
        CompanyAddEditComponent,
        UploadDocumentComponent,
        CustPipe,
        Cust1Pipe,
        LimitPipe,
        TruncatePipe,
        NgxPaginationModule,
        SetPasswordComponent,
        FormsModule,
        CarouselModule,
        Ng4GeoautocompleteModule,
        CalendarModule,
        SliderModule,
        UiSwitchModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        SignaturePadModule,
        PropertyDetailsComponent,
        AgmCoreModule,
        PaginationComponent,
        TableProfileDataComponent,
        TablePropertyDataComponent,
        ChangePasswordComponent,
        ChatComponent,
        RequestViewComponent,
        ReviewComponent,
        NotificationComponent,
        VacateRequestComponent,
        AddressComponent,
        VacateViewComponent,
        RentalInvoiceComponent,
        ChartModule,
        BookingPropertyComponent,
        BsDatepickerModule,
        ImageCropperModule,
        MaintenanceReminderComponent,
        NumberOnlyDirective,
        PaymentStatusComponent,
        NgxCaptchaModule,
        FooterComponent,
        NoSpecialCharacters,
        ShareButtonsModule,
        ModalModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        UtcDatePipe,
        UtcTimePipe,
        TimeagoModule
    ]
})
export class SharedModuleModule {
}
