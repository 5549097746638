import {Injectable} from '@angular/core';
import {apiUrl} from '../global/global';
import {HttpParams} from '@angular/common/http';
import {generalModel} from '../shared/models/general.model';
import {AdminService} from '../services/admin.service';
import {Subject} from 'rxjs';
import {FormArray} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from "../shared/lang/translate.service";

@Injectable({
    providedIn: 'root'
})
export class InternalServiceService {
    generalModel = new generalModel();
    index: number = 0;
    propertyTab: number;
    requestTab: number;
    paymentStatus: number;
    loginData = {};
    bankData = {};
    team: Boolean = false;
    bank: Boolean = false;
    vacateRequest: number;
    chat: String;

    constructor(private api: AdminService, private router: Router, public translate: TranslateService) {
    }

    // Show and hide team
    private teamSubject = new Subject<Boolean>();
    teamState = this.teamSubject.asObservable();
    // Show and hide bank
    private bankSubject = new Subject<Boolean>();
    bankState = this.bankSubject.asObservable();

    teamFunction(value: Boolean) {
        this.teamSubject.next(value);
    }



    bankFunction(value: Boolean) {
        this.bankSubject.next(value);
    }

    getList(data, url) {
        if (localStorage.getItem('adminToken')) {
            let params = new HttpParams();
            for (let x of Object.keys(data)) {
                params =  params.append(x, data[x]);
            }
            return this.api.getData(url, params);
        } else {
            this.router.navigate(['home']);
        }
    }

    getLanguage() {
        if (localStorage.getItem('adminToken')) {
            return this.api.getData(apiUrl._getLanguage, '');
        } else {
            this.router.navigate(['home']);
        }
    }

    uploadImage(file, type) {
        if (localStorage.getItem('adminToken')) {
            const fd = new FormData();
            fd.append('image', file);
            fd.append('type', type);
            return this.api.postData(apiUrl._uploadImage, fd);
        } else {
            this.router.navigate(['home']);
        }
    }

    uploadCSV(file, url) {
        if (localStorage.getItem('adminToken')) {
            return this.api.postData(url, file);
        } else {
            this.router.navigate(['home']);
        }
    }

    submitForm(data, url) {
        if (localStorage.getItem('adminToken')) {
            return this.api.postData(url, data);
        } else {
            this.router.navigate(['home']);
        }
    }

    submitPutForm(data, url) {
        if (localStorage.getItem('adminToken')) {
            return this.api.putData(url, data);
        } else {
            this.router.navigate(['home']);
        }
    }

    submitPutFormParams(id, url) {
        if (localStorage.getItem('adminToken')) {
            return this.api.putDataParams(url, id);
        } else {
            this.router.navigate(['home']);
        }
    }

    delete(id) {
        if (localStorage.getItem('adminToken')) {
            return this.api.postData(apiUrl._deleteServiceProvider, {serviceId: id});
        } else {
            this.router.navigate(['home']);
        }
    }

    uploadDocument(data) {
        if (localStorage.getItem('adminToken')) {
            return this.api.putData(apiUrl._addPropertyDocument, data);
        } else {
            this.router.navigate(['home']);
        }
    }

    // Show and hide Loader
    private stepSubject = new Subject<number>();
    stepState = this.stepSubject.asObservable();

    step(value) {
        this.stepSubject.next(value);
    }


    min(count, page) {
        if (count != 0) {
            let temp = ((page * 10) - 10) + 1;
            return temp;
        } else {
            return '0';
        }
    }

    max(count, page) {
        if (count != 0) {
            let temp = ((page * 10) - 10);
            if (temp + 10 <= count) {
                return '- ' + (temp + 10);
            } else {
                return '- ' + (temp + (count - temp));
            }
        }
    }

    // call getApi with pageChanged
    private callFunction = new Subject<number>();
    functionState = this.callFunction.asObservable();

    getData(value) {
        this.callFunction.next(value);
    }

    // call vacate request
    private vacateFunction = new Subject<string>();
    vacateState = this.vacateFunction.asObservable();

    vacateData(value) {
        this.vacateFunction.next(value);
    }

    pageChange(status, page, count) {
        if (status === 'next' && (page * 10) < count) {
            page++;
            this.getData(page);
        } else if (status === 'prev' && page > 1) {
            page--;
            this.getData(page);
        }
    }

    checkMinMax(y) {
        if (y < 12) {
            return (y + this.translate.lang.Month);
        } else {
            if (y === 12) {
                return '1 ' + this.translate.lang.Year;
            } else if (y > 12 && y < 120) {
                const data = (y / 12).toString();
                let bcd = (y - (12 * parseInt(data[0]))).toString();
                bcd = bcd !== '0' ? data[0] + '.' + bcd : data[0];
                return (bcd + ' ' + this.translate.lang.Year);
            } else if (y === 120) {
                return ('10 ' + this.translate.lang.Year);
            }
        }
    }
}

