import { Component, OnInit, Input } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';

@Component({
  selector: 'app-table-profile-data',
  templateUrl: './table-profile-data.component.html',
  styleUrls: ['./table-profile-data.component.css']
})
export class TableProfileDataComponent implements OnInit {
  @Input() allData: any;
  constructor(
    public admin: AdminService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
  }

}
