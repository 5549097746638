import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.css']
})
export class ManagerListComponent implements OnInit {

  pagination = { perPage: 20, page: 1, count: 0, max: 0, init: 1 };
  allData: any[] = [];

  subscription: Subscription;
  constructor(public admin: AdminService) {

    this.subscription = this.admin.functionState.subscribe(data => {
      this.pagination.page = data;
      this.getManagerList();
    });
  }

  ngOnInit() {
    this.getManagerList();
  }

  getManagerList() {
    // user/getAllManagerList
    let data = {
      page: this.pagination.page,
      perPage: this.pagination.perPage,
    }
    this.admin.getData(apiUrl._getManagerList, data).subscribe(success => {
      this.pagination.count = success.data.count;
      // this.pagination.max = this.admin.paginationMaxValue(this.pagination.count,this.pagination.perPage);
      this.allData = success.data.listing;
    });
  }

}
