import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class Auth1Guard implements CanActivate {
  constructor(private router: Router, public admin: AdminService) { }
  canActivate() {
    let token = localStorage.getItem("adminToken");
    let document1 = localStorage.getItem("userDocument");

    let loginData = JSON.parse(localStorage.getItem("loginData"));
    let userType = localStorage.getItem("userType");

    console.log(loginData);
    console.log(this.router.url);

    // if (token && document1 == 'true' && (userType == 'LANDLORD' || userType == 'MANAGER' || userType == 'TEAM_MEMBER') && !loginData.isApproved && (this.router.url!='/login' && this.router.url!='/dashboard')) {
    //   this.admin.toast('Please Wait For Admin Approval', '', 'warning');
    //   return false;
    // }

    if (token && document1 == 'true' && (userType == 'LANDLORD' || userType == 'MANAGER' || userType == 'TEAM_MEMBER')) {
      return true;
    } else if (userType == 'TENANT') {
      this.router.navigate(['/']);
      return false;
    } else {
      this.admin.logout();
      return false;
    }
  }
}
