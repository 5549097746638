import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { apiUrl } from 'src/app/global/global';
import { TranslateService } from "../../shared/lang/translate.service";
import { API_FILE_END_POINT } from "../../services/global";
import { InternalServiceService } from 'src/app/internal/internal-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { SharedService, DATE_CONVERTER_TYPE } from 'src/app/shared/shared.service';
import { property } from 'src/app/shared/property-model';
// import { element } from 'protractor';
import 'rxjs/add/operator/debounceTime';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    model: any = [];
    allData = [];
    paymentData = {};
    link: any;
    cityData: any = [];
    sortFilter: string = '';
    filterValue = {
        collected: 2,
        late: 3,
        expense: 4,
        rent: 5,
        vacant: 6,
        notPaid: 7,
        statement: 8,
        renewed: 9,
        withdrawal: 10,
    }
    index: any[] = [];
    date = new Date();
    startDate: Date;
    // startDate: Date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    endDate: Date;
    // endDate: Date = new Date();
    // maxDate: Date = new Date();
    pagination = { perPage: 10, page: 1, count: 0, max: 0, init: 1 };
    subscription: Subscription;
    minDate: Date;
    minDate1: Date = new Date();
    maxDate: Date = new Date();
    maxDate1: Date = new Date();
    typeFilter: number;
    sub;
    noData = false
    tabValue: string;
    routeValue: string;
    tenantsPropertyAddress: any[] = [];
    propData: any[] = [];
    bsRef: BsModalRef;
    userType: any = localStorage.getItem('userType')
    filter = {
        propertyName: '',
        tenantName: '',
        ownerName: '',
        property: '',
        city: '',
        unitNo: '',
        searchBy: 'ADDRESS',
        year: new Date().getFullYear(),
        date: { startDate: undefined, endDate: undefined }
    };
    yearArray: any = []
    months = [];
    // { id: 0, name: 'January', disabled: false },
    // { id: 1, name: 'Feburary', disabled: false },
    // { id: 2, name: 'March', disabled: false },
    // { id: 3, name: 'April', disabled: false },
    // { id: 4, name: 'May', disabled: false },
    // { id: 5, name: 'June', disabled: false },
    // { id: 6, name: 'July', disabled: false },
    // { id: 7, name: 'August', disabled: false },
    // { id: 8, name: 'September', disabled: false },
    // { id: 9, name: 'October', disabled: false },
    // { id: 10, name: 'November', disabled: false },
    // { id: 11, name: 'December', disabled: false },

    monthsArray = [
        'January', 'Feburary', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October',
        'November', 'December',
    ]
    selectedMonths: string[];

    @ViewChild('filters') filters: ElementRef;

    constructor(public admin: AdminService, private router: Router, private route: ActivatedRoute, public translate: TranslateService, private modalService: BsModalService,
        private sharedService: SharedService,
        private api: InternalServiceService,
    ) {
        this.getCities();
        this.initMonths();
        this.tabValue = route.snapshot.data.title;
        this.routeValue = route.snapshot.data.link;

        const year = new Date().getFullYear()
        for (let i = year; i > (year - 10); i--) {
            this.yearArray.push(i)
        }

        if (this.userType != 'TENANT' &&
            !admin.control.reports.expense_card &&
            !admin.control.reports.expanse_card &&
            !admin.control.reports.rent_card &&
            !admin.control.reports.vacant_card &&
            !admin.control.reports.not_paid_card &&
            !admin.control.reports.withdrawal &&
            !admin.control.reports.property_statement &&
            !admin.control.reports.to_be_renewed) {
            this.noData = true
        }

    }

    ngOnInit() {
        this.subscription = this.admin.functionState.subscribe(data => {
            this.pagination.page = data;
            if (this.userType == 'TENANT') {
                this.getList(false);
            } else {
                this.getProperties();
            }
        });

        this.sub = this.route.queryParams.subscribe(params => {
            this.model = [];
            window.scrollTo(0, 0);
            this.pagination = { perPage: 10, page: 1, count: 0, max: 0, init: 1 };
            this.selectedMonths = [];
            this.filter.date.startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
            this.filter.date.endDate = new Date();
            if (this.userType == 'TENANT') {
                if (params['status']) {
                    this.typeFilter = this.filterValue[params['status']];
                    this.pagination.page = 1;
                } else {
                    this.typeFilter = this.filterValue['collected'];
                }
            } else {
                if (params['status']) {
                    this.typeFilter = this.filterValue[params['status']];
                    this.pagination.page = 1;
                    this.clearAll();
                } else {
                    this.typeFilter = this.filterValue['expense'];
                    this.clearAll();
                }
            }

            if (this.userType == 'TENANT') {
                this.getList(false);
            }
            else {
                this.getProperties();
                if (this.typeFilter == 5 || this.typeFilter == 6) {
                    this.initMonths();
                    this.filter.date = { startDate: undefined, endDate: undefined }
                }
            }
        })
    }


    getCities() {
        let request = {
            type: 'City'
        }
        this.api.getList(request, apiUrl._getPropertyType).subscribe(success => {
            this.cityData = success.data.propertyType[0].propertyData;
        });
    }

    checkValue() {
        console.log(this.selectedMonths);
        if (this.selectedMonths.length >= 3) {
            this.months = [
                { id: 0, name: 'January', disabled: true },
                { id: 1, name: 'Feburary', disabled: true },
                { id: 2, name: 'March', disabled: true },
                { id: 3, name: 'April', disabled: true },
                { id: 4, name: 'May', disabled: true },
                { id: 5, name: 'June', disabled: true },
                { id: 6, name: 'July', disabled: true },
                { id: 7, name: 'August', disabled: true },
                { id: 8, name: 'September', disabled: true },
                { id: 9, name: 'October', disabled: true },
                { id: 10, name: 'November', disabled: true },
                { id: 11, name: 'December', disabled: true },
            ];
            this.months.map(x => { x.disabled = true });
            this.months.forEach(element => {
                this.selectedMonths.forEach(month => {
                    if (month == JSON.stringify(element.id)) {
                        element.disabled = false;
                    }
                });
            });
        } else {
            if (Number(this.filter.year) < Number(new Date().getFullYear())) {
                this.initYears();
            } else {
                this.initMonths();
            }
        }
    }

    initMonths() {
        this.months = [];
        for (let i = 0; i <= 11; i++) {
            if (i <= new Date().getMonth()) {
                this.months.push({ id: i, name: this.monthsArray[i], disabled: false })
            } else {
                this.months.push({ id: i, name: this.monthsArray[i], disabled: true })
            }
        }
    }

    initYears() {
        this.months = []
        for (let i = 0; i <= 11; i++) {
            // if (i >= 9) {
            this.months.push({ id: i, name: this.monthsArray[i], disabled: false })
            // } else {
            //     this.months.push({ id: i, name: this.monthsArray[i], disabled: true })
            // }
        }
    }

    getProperties() {
        let request = {
            forReminderList: true
        }
        this.api.getList(request, apiUrl._getCurrentTenantPropertyList).subscribe(success => {

            const resData = success.data.property;

            for (const item of resData) {
                const index = this.propData.findIndex(property => property.propName.toLowerCase() == item.propertyName.toLowerCase());
                if (index != -1) {
                    const address = { _id: item._id, userId: item.userId, ownerId: item.ownerId, address: item.address, occupied: item.occupied };
                    this.propData[index].propertyData.push(address);
                } else {
                    const data = {
                        propName: item.propertyName,
                        propertyData: [{ _id: item._id, userId: item.userId, ownerId: item.ownerId, address: item.address, occupied: item.occupied }]
                    };
                    this.propData.push(data);
                }
            }
            if (this.propData.length > 0) {
                if (!!this.filter.property || !!this.filter.propertyName || !!this.filter.city || !this.filter.date.startDate) {
                    this.getReports(this.typeFilter, false);
                } else {
                    this.clearAll();
                }
            }
        });
    }

    changFilter(status) {
        if (this.typeFilter != this.filterValue[status]) {
            this.router.navigate(['/' + this.routeValue + '/reports'], { queryParams: { status: status } });
        }
    }

    getList(isDownlable) {

        const data = {
            'status': this.typeFilter,
            page: (this.pagination.page),
            perPage: this.pagination.perPage,
            'isCSV': isDownlable
        };

        if (this.filter.propertyName != '') {
            data['propertyName'] = this.filter.propertyName['_id'];
        }

        if (this.filter.property != '') {
            data['property'] = this.filter.property['_id'];
        }

        if (this.filter.city != '') {
            data['City'] = this.filter.city['_id'];
        }

        if (this.filter.tenantName != '') {
            data['tenantName'] = this.filter.tenantName;
        }

        if (this.filter.date.startDate != null && this.filter.date.endDate != null) {
            const startDate = +new Date(this.filter.date.startDate);
            const endDate = +new Date(this.filter.date.endDate);
            const date = { startDate: startDate, endDate: endDate };
            data['date'] = JSON.stringify(date);
        }
        this.admin.getData(apiUrl._getAllInvoice, data).subscribe(success => {
            if (isDownlable) {
                this.link = success.data.link;
                window.open(this.link, '_blank');
            } else {
                this.allData = success.data.invoice;
                this.paymentData = success.data.payments;
                this.pagination.count = success.data.count;
                if (this.pagination.count > 10) {
                    this.pagination.max = this.admin.paginationMax(this.pagination.count / 10);
                } else {
                    this.pagination.max = 1;
                }
                this.index = [];
                for (let x of this.allData) {
                    this.index.push('');
                }
            }
        });
    }

    public captureScreen() {

        let id = 'rentCard';
        if (this.typeFilter == 4) {
            id = 'expenseCard'
        }

        if (this.typeFilter == 5) {
            id = 'rentCard'
        }

        if (this.typeFilter == 6) {
            id = 'vacantCard'
        }

        if (this.typeFilter == 7) {
            id = 'notPaidCard'
        }

        if (this.typeFilter == 5) {
            id = 'withdrawal'
        }


        var data = document.getElementById(id);
        html2canvas(data).then(canvas => {
            // Few necessary setting options
            var imgWidth = 11.11;
            var pageHeight = 10;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jspdf('p', 'in', [820, 800]); // A4 size page of PDF
            var position = 0;
            let date = new Date()
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            pdf.save(`rental-card-${date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()}.pdf`); // Generated PDF
        });
    }

    downloadFile(fileType) {
        if (fileType) {
            if (this.userType == 'TENANT') {
                this.getList(true);
            }
            else {
                this.getReports(this.typeFilter, fileType)
            }
        }
    }


    filterDate() {
        if (+new Date(this.startDate) < +new Date(this.endDate)) {
            if (this.userType == 'TENANT') {
                this.getList(false);
            }
            else {
                this.getReports(this.typeFilter, false)
            }
        } else {
        }
    }

    clearDate() {
        this.startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        this.endDate = new Date();
        if (+new Date(this.startDate) < +new Date(this.endDate)) {
            if (this.userType == 'TENANT') {
                this.getList(false);
            }
            else {
                this.getReports(this.typeFilter, false)
            }
        } else {
        }
    }

    openModal(template) {
        this.bsRef = this.modalService.show(template, { backdrop: 'static' });
    }


    clickContinue() {
        if (this.filter.date.startDate != null || this.filter.date.endDate != null) {
            if (this.filter.date.startDate == null || this.filter.date.endDate == null) {
                return false;
            }
        }
        if (this.userType == 'TENANT') {
            this.getList(false);
        }
        else {
            this.getReports(this.typeFilter, false)
        }
        this.bsRef.hide();
    }

    onSearch(e) {

    }

    getReports(type, downloadType) {
        const data = {
            page: (this.pagination.page),
            perPage: this.pagination.perPage,
        };

        if (downloadType) {
            data['fileFormat'] = downloadType
        }

        if (!!this.filter.propertyName) {
            data['propertyName'] = this.filter.propertyName['_id'];
        }

        if (this.typeFilter == 8 && !this.filter.property) {
            this.filter.property = this.propData[0];
        }

        if (!!this.filter.property) {
            // return false
            data['propertyId'] = this.filter.property['propertyData'][0]['_id'];
        }

        if (this.filter.city != '') {
            data['cityName'] = this.filter.city['_id'];
        }

        if (this.filter.tenantName != '') {
            data['tenantName'] = this.filter.tenantName;
        }

        if (this.filter.unitNo) {
            data['unitNo'] = this.filter.unitNo;
        }


        if (this.selectedMonths && this.selectedMonths.length > 0 && this.typeFilter == 5) {
            data['months'] = [];
            this.selectedMonths.forEach(element => {
                data['months'].push(element);
            });
            data['year'] = this.filter.year
        }

        if (this.filter.date.startDate != null && this.filter.date.endDate != null) {
            const dateObj = this.convertDate();
            data['date'] = JSON.stringify(dateObj);
        }

        let url = '';

        if (this.typeFilter == 4) {
            url = apiUrl._reportForExpanseCard;
        }

        if (this.typeFilter == 5) {
            url = apiUrl._reportForRentalCard;
        }

        if (this.typeFilter == 6) {
            url = apiUrl._vacantPropertyCard;
        }


        if (this.typeFilter == 7) {
            url = apiUrl._notPaidCard;
        }

        if (this.typeFilter == 8) {

            url = apiUrl._reportForPropertyStatement;
        }

        if (this.typeFilter == 9) {
            url = apiUrl._toBeRenewedReports;
        }

        if (this.typeFilter == 10) {
            url = apiUrl._reportsForWithdrawal;
        }

        if (this.typeFilter == 8 && !this.filter.property) {
            return
        }

        if (this.typeFilter != 5) {
            this.subscription.add(this.admin.getData(url, data).subscribe(success => {
                if (success.data) {
                    if (downloadType) {
                        this.link = success.data.link;
                        window.open(this.link, '_blank');
                    } else {
                        this.model = success.data;
                        this.pagination.count = success.data.count;
                        if (this.pagination.count > 10) {
                            this.pagination.max = this.admin.paginationMax(this.pagination.count / 10);
                        } else {
                            this.pagination.max = 1;
                        }
                    }
                }
            }))
        } else {
            this.subscription.add(this.admin.postData(url, data).subscribe(success => {
                if (success.data) {
                    if (downloadType) {
                        this.link = success.data.link;
                        window.open(this.link, '_blank');
                    } else {
                        this.model = success.data;
                        this.pagination.count = success.data.count;
                        if (this.pagination.count > 10) {
                            this.pagination.max = this.admin.paginationMax(this.pagination.count / 10);
                        } else {
                            this.pagination.max = 1;
                        }
                    }
                }
            }))
        }
    }

    clearAll(type?) {
        this.tenantsPropertyAddress = [];
        this.selectedMonths = [];
        let month = new Date().getMonth();
        this.selectedMonths.push(this.months[month].id);
        this.checkValue();
        this.initMonths();
        this.filter = {
            propertyName: '',
            tenantName: '',
            ownerName: '',
            unitNo: '',
            searchBy: 'ADDRESS',
            property: '',
            city: '',
            year: new Date().getFullYear(),
            // date: { startDate: new Date(this.date.getFullYear(), this.date.getMonth(), 1), endDate: new Date() }
            date: !!type ? { startDate: undefined, endDate: undefined } : { startDate: new Date(this.date.getFullYear(), this.date.getMonth(), 1), endDate: new Date() }
        };

        if (this.userType == 'TENANT') {
            this.getList(false);
        }
        else {
            this.getReports(this.typeFilter, false);
        }
    }


    convertDate() {
        const dd: Date = new Date(this.filter.date.startDate);
        dd.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
        var startDate = this.sharedService.getUtcDateOrTime(dd, DATE_CONVERTER_TYPE.dateTime);

        const dd2: Date = new Date(this.filter.date.endDate);
        dd2.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
        var endDate = this.sharedService.getUtcDateOrTime(dd2, DATE_CONVERTER_TYPE.dateTime);

        var date = {
            startDate: startDate,
            endDate: endDate
        }

        return date;

    }

    selectProperty(event) {
        const index = this.propData.findIndex(x => x.propName === this.filter.property['propName']);
        this.tenantsPropertyAddress = [];
        let ar = []
        ar = this.propData[index]['propertyData'];
        for (let i = 0; i < ar.length; i++) {
            let found = false;
            for (let j = i + 1; j < ar.length; j++) {
                if (ar[i].address.unitNo == ar[j].address.unitNo) {
                    found = true;
                }
            }
            if (!found) {
                this.tenantsPropertyAddress.push(ar[i]);
            }
        }
    }

    selectAddress(event) {
        if (event.target.value != 'true') {
            const index = this.tenantsPropertyAddress.findIndex(x => x._id === this.filter.propertyName['_id'])
            const data = this.tenantsPropertyAddress[index];
        }
    }

    selectCity(event) { }

}
