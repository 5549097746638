import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { TranslateService } from 'src/app/shared/lang/translate.service';
import { ExternalService } from '../external.service';
import { Router, ActivatedRoute } from "@angular/router";
import { apiUrl } from 'src/app/global/global';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  public form: FormGroup;
  showError: boolean = false;
  isReloaded = true;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  typeList: any[] = [{type:'RESIDENTIAL',name:'Residential', nameAr : 'سكني' , route:'explore/property?typeValue=1'},{type:'COMMERCIAL', nameAr : 'تجاري' , name:'Commercial',route:'explore/property?typeValue=2'}];
  subscribe = new Subscriber();
  language='en';
  constructor(
    private formBuilder: FormBuilder,
    public admin: AdminService,
    public translate: TranslateService,
    private api: ExternalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.language=localStorage.getItem('lang');
    this.initForm();
    if (localStorage.getItem('guestToken') == null) {
      this.getToken();
    } else {
      // this.getList();
    }

    this.subscribe.add(this.activatedRoute.params.subscribe(params => {
      console.log(params);
      if (params.language) {
        localStorage.setItem('language', params.language);
        this.translate.setLanguage({code: params.language, name: 'عربى', lang: params.language})
      }
    }))
  }


  navigate(route){
    this.router.navigateByUrl(route);
  }



  keyPress2(e) {
    var isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
    if (isArabic.test(e.key)) {
      this.form.value.phoneNumber = '';
      return false;
    }
  }

  getToken() {
    const data = {};
    this.api.getList(data, apiUrl._loginGuestUser).subscribe(success => {
      //
      localStorage.setItem('guestToken', success.data.accessToken);
      if (localStorage.getItem('guestToken')) {
        // this.getList();
      }
    });
  }

  // getList() {
  //   this.api.getList({}, apiUrl._getHomePage).subscribe(success => {
  //     this.typeList = success.data.collectionCategory;
  //     // this.allData = success.data;
  //   });
  // }

  initForm() {
    this.form = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  hasError() {
  }

  getNumber(event) {

  }

  //    ==============================
  handleReset() {
    console.log('handleReset');
    this.form.get('recaptcha').reset();
  }

  handleExpire() {
    console.log('handleExpire');
    this.form.get('recaptcha').reset();
  }

  handleLoad() {
    console.log('handleLoad');
    this.form.get('recaptcha').reset();
  }

  handleSuccess(event) {
    console.log(event);
    console.log('handleSuccess');
    this.form.get('recaptcha').patchValue(event);
  }

  resetReCaptcha() {
    this.captchaElem.resetCaptcha();
  }

  telInputObject() {

  }

  onCountryChange(event) {
    // this.callingCode = '+' + event.dialCode;
  }

  contactFun() {
    if (this.form.valid) {
      const data = this.form.value;
      delete data.recaptcha;
      this.admin.postData(apiUrl._contactForm, data)
        .subscribe(res => {
          this.showError = false;
          this.initForm();
          this.admin.toast(res.msg, '', 'success');
          this.form.reset();
          this.isReloaded = true;
          this.resetReCaptcha();
        });
    } else {
      this.showError = true;
    }
  }
}
