import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  @Input() address: any;
  @Input() showAddressPart = true;
  @Input() showLocation = true;
  @Input() showAddress = true;
  @Input() type :any;



  constructor() { }

  ngOnInit() {
  }

}
