export const apiKey = 'AIzaSyAjt-OwP2XYCRc1vylEyVrNhgm59MjXVmA';
// AIzaSyB7yjqvGC1AKJpRn286BLSLC7z6aIkvjuk

export var DATE_REGEX = 'dd/MM/yyyy, hh:mm a';

export var MOBILE_REGEX = '^[0-9]*$';

// export var NO_SPACE_REGEX = '[0-9a-zA-Z][0-9a-zA-Z ]+';

export var EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// export var SPACE_REGEX =  /^[a-zA-Z][a-zA-Z0-9. ]+$/;
export var SPACE_REGEX =  /^[a-zA-Z0-9]{1}/;
export var Percentage_REGEX =  /^([1-9]|[1-9][0-9]|100)$/;
export var Amount_REGEX =  /^[1-9]\d*(\.\d{1,2})?$/;
export var Amount_REGEX_3 =  /^[1-9]\d*(\.\d{1,3})?$/;
export var Amount_REGEX_4 =  /^[0-9]\d*(\.\d{1,3})?$/;


// export var Amount_REGEX =  /^([1-9]|[1-9][0-9]|100)$/;
export var PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,20}$/g;

export var LIMIT = 10; 0


export var MAX_SIZE = 5;

export var MAX_IMAGE_SIZE = 5000000;

// export const PRODUCT_NAME = {
//   "ADD_PRODUCT": "New Product Type",
//   "EDIT_PRODUCT": "Edit Product Type"
// }

// export const PAGE_OPTIONS = { 'limit': 10, 'page': 1, 'search': '' };
// export const PAGE_NUMBER = 1;
// export const ROW_ON_PAGE = 10;

export const CURRENCY_CODE = [
  { "cc": "AED", "symbol": "\u062f.\u0625;", "name": "UAE dirham" },
  { "cc": "AFN", "symbol": "Afs", "name": "Afghan afghani" },
  { "cc": "ALL", "symbol": "L", "name": "Albanian lek" },
  { "cc": "AMD", "symbol": "AMD", "name": "Armenian dram" },
  { "cc": "ANG", "symbol": "NA\u0192", "name": "Netherlands Antillean gulden" },
  { "cc": "AOA", "symbol": "Kz", "name": "Angolan kwanza" },
  { "cc": "ARS", "symbol": "$", "name": "Argentine peso" },
  { "cc": "AUD", "symbol": "$", "name": "Australian dollar" },
  { "cc": "AWG", "symbol": "\u0192", "name": "Aruban florin" },
  { "cc": "AZN", "symbol": "AZN", "name": "Azerbaijani manat" },
  { "cc": "BAM", "symbol": "KM", "name": "Bosnia and Herzegovina konvertibilna marka" },
  { "cc": "BBD", "symbol": "Bds$", "name": "Barbadian dollar" },
  { "cc": "BDT", "symbol": "\u09f3", "name": "Bangladeshi taka" },
  { "cc": "BGN", "symbol": "BGN", "name": "Bulgarian lev" },
  { "cc": "BHD", "symbol": ".\u062f.\u0628", "name": "Bahraini dinar" },
  { "cc": "BIF", "symbol": "FBu", "name": "Burundi franc" },
  { "cc": "BMD", "symbol": "BD$", "name": "Bermudian dollar" },
  { "cc": "BND", "symbol": "B$", "name": "Brunei dollar" },
  { "cc": "BOB", "symbol": "Bs.", "name": "Bolivian boliviano" },
  { "cc": "BRL", "symbol": "R$", "name": "Brazilian real" },
  { "cc": "BSD", "symbol": "B$", "name": "Bahamian dollar" },
  { "cc": "BTN", "symbol": "Nu.", "name": "Bhutanese ngultrum" },
  { "cc": "BWP", "symbol": "P", "name": "Botswana pula" },
  { "cc": "BYR", "symbol": "Br", "name": "Belarusian ruble" },
  { "cc": "BZD", "symbol": "BZ$", "name": "Belize dollar" },
  { "cc": "CAD", "symbol": "$", "name": "Canadian dollar" },
  { "cc": "CDF", "symbol": "F", "name": "Congolese franc" },
  { "cc": "CHF", "symbol": "Fr.", "name": "Swiss franc" },
  { "cc": "CLP", "symbol": "$", "name": "Chilean peso" },
  { "cc": "CNY", "symbol": "\u00a5", "name": "Chinese/Yuan renminbi" },
  { "cc": "COP", "symbol": "Col$", "name": "Colombian peso" },
  { "cc": "CRC", "symbol": "\u20a1", "name": "Costa Rican colon" },
  { "cc": "CUC", "symbol": "$", "name": "Cuban peso" },
  { "cc": "CVE", "symbol": "Esc", "name": "Cape Verdean escudo" },
  { "cc": "CZK", "symbol": "K\u010d", "name": "Czech koruna" },
  { "cc": "DJF", "symbol": "Fdj", "name": "Djiboutian franc" },
  { "cc": "DKK", "symbol": "Kr", "name": "Danish krone" },
  { "cc": "DOP", "symbol": "RD$", "name": "Dominican peso" },
  { "cc": "DZD", "symbol": "\u062f.\u062c", "name": "Algerian dinar" },
  { "cc": "EEK", "symbol": "KR", "name": "Estonian kroon" },
  { "cc": "EGP", "symbol": "\u00a3", "name": "Egyptian pound" },
  { "cc": "ERN", "symbol": "Nfa", "name": "Eritrean nakfa" },
  { "cc": "ETB", "symbol": "Br", "name": "Ethiopian birr" },
  { "cc": "EUR", "symbol": "\u20ac", "name": "European Euro" },
  { "cc": "FJD", "symbol": "FJ$", "name": "Fijian dollar" },
  { "cc": "FKP", "symbol": "\u00a3", "name": "Falkland Islands pound" },
  { "cc": "GBP", "symbol": "\u00a3", "name": "British pound" },
  { "cc": "GEL", "symbol": "GEL", "name": "Georgian lari" },
  { "cc": "GHS", "symbol": "GH\u20b5", "name": "Ghanaian cedi" },
  { "cc": "GIP", "symbol": "\u00a3", "name": "Gibraltar pound" },
  { "cc": "GMD", "symbol": "D", "name": "Gambian dalasi" },
  { "cc": "GNF", "symbol": "FG", "name": "Guinean franc" },
  { "cc": "GQE", "symbol": "CFA", "name": "Central African CFA franc" },
  { "cc": "GTQ", "symbol": "Q", "name": "Guatemalan quetzal" },
  { "cc": "GYD", "symbol": "GY$", "name": "Guyanese dollar" },
  { "cc": "HKD", "symbol": "HK$", "name": "Hong Kong dollar" },
  { "cc": "HNL", "symbol": "L", "name": "Honduran lempira" },
  { "cc": "HRK", "symbol": "kn", "name": "Croatian kuna" },
  { "cc": "HTG", "symbol": "G", "name": "Haitian gourde" },
  { "cc": "HUF", "symbol": "Ft", "name": "Hungarian forint" },
  { "cc": "IDR", "symbol": "Rp", "name": "Indonesian rupiah" },
  { "cc": "ILS", "symbol": "\u20aa", "name": "Israeli new sheqel" },
  { "cc": "INR", "symbol": "\u20B9", "name": "Indian rupee" },
  { "cc": "IQD", "symbol": "\u062f.\u0639", "name": "Iraqi dinar" },
  { "cc": "IRR", "symbol": "IRR", "name": "Iranian rial" },
  { "cc": "ISK", "symbol": "kr", "name": "Icelandic kr\u00f3na" },
  { "cc": "JMD", "symbol": "J$", "name": "Jamaican dollar" },
  { "cc": "JOD", "symbol": "JOD", "name": "Jordanian dinar" },
  { "cc": "JPY", "symbol": "\u00a5", "name": "Japanese yen" },
  { "cc": "KES", "symbol": "KSh", "name": "Kenyan shilling" },
  { "cc": "KGS", "symbol": "\u0441\u043e\u043c", "name": "Kyrgyzstani som" },
  { "cc": "KHR", "symbol": "\u17db", "name": "Cambodian riel" },
  { "cc": "KMF", "symbol": "KMF", "name": "Comorian franc" },
  { "cc": "KPW", "symbol": "W", "name": "North Korean won" },
  { "cc": "KRW", "symbol": "W", "name": "South Korean won" },
  { "cc": "KWD", "symbol": "KWD", "name": "Kuwaiti dinar" },
  { "cc": "KYD", "symbol": "KY$", "name": "Cayman Islands dollar" },
  { "cc": "KZT", "symbol": "T", "name": "Kazakhstani tenge" },
  { "cc": "LAK", "symbol": "KN", "name": "Lao kip" },
  { "cc": "LBP", "symbol": "\u00a3", "name": "Lebanese lira" },
  { "cc": "LKR", "symbol": "Rs", "name": "Sri Lankan rupee" },
  { "cc": "LRD", "symbol": "L$", "name": "Liberian dollar" },
  { "cc": "LSL", "symbol": "M", "name": "Lesotho loti" },
  { "cc": "LTL", "symbol": "Lt", "name": "Lithuanian litas" },
  { "cc": "LVL", "symbol": "Ls", "name": "Latvian lats" },
  { "cc": "LYD", "symbol": "LD", "name": "Libyan dinar" },
  { "cc": "MAD", "symbol": "MAD", "name": "Moroccan dirham" },
  { "cc": "MDL", "symbol": "MDL", "name": "Moldovan leu" },
  { "cc": "MGA", "symbol": "FMG", "name": "Malagasy ariary" },
  { "cc": "MKD", "symbol": "MKD", "name": "Macedonian denar" },
  { "cc": "MMK", "symbol": "K", "name": "Myanma kyat" },
  { "cc": "MNT", "symbol": "\u20ae", "name": "Mongolian tugrik" },
  { "cc": "MOP", "symbol": "P", "name": "Macanese pataca" },
  { "cc": "MRO", "symbol": "UM", "name": "Mauritanian ouguiya" },
  { "cc": "MUR", "symbol": "Rs", "name": "Mauritian rupee" },
  { "cc": "MVR", "symbol": "Rf", "name": "Maldivian rufiyaa" },
  { "cc": "MWK", "symbol": "MK", "name": "Malawian kwacha" },
  { "cc": "MXN", "symbol": "$", "name": "Mexican peso" },
  { "cc": "MYR", "symbol": "RM", "name": "Malaysian ringgit" },
  { "cc": "MZM", "symbol": "MTn", "name": "Mozambican metical" },
  { "cc": "NAD", "symbol": "N$", "name": "Namibian dollar" },
  { "cc": "NGN", "symbol": "\u20a6", "name": "Nigerian naira" },
  { "cc": "NIO", "symbol": "C$", "name": "Nicaraguan c\u00f3rdoba" },
  { "cc": "NOK", "symbol": "kr", "name": "Norwegian krone" },
  { "cc": "NPR", "symbol": "NRs", "name": "Nepalese rupee" },
  { "cc": "NZD", "symbol": "NZ$", "name": "New Zealand dollar" },
  { "cc": "OMR", "symbol": "OMR", "name": "Omani rial" },
  { "cc": "PAB", "symbol": "B./", "name": "Panamanian balboa" },
  { "cc": "PEN", "symbol": "S/.", "name": "Peruvian nuevo sol" },
  { "cc": "PGK", "symbol": "K", "name": "Papua New Guinean kina" },
  { "cc": "PHP", "symbol": "\u20b1", "name": "Philippine peso" },
  { "cc": "PKR", "symbol": "Rs.", "name": "Pakistani rupee" },
  { "cc": "PLN", "symbol": "z\u0142", "name": "Polish zloty" },
  { "cc": "PYG", "symbol": "\u20b2", "name": "Paraguayan guarani" },
  { "cc": "QAR", "symbol": "QR", "name": "Qatari riyal" },
  { "cc": "RON", "symbol": "L", "name": "Romanian leu" },
  { "cc": "RSD", "symbol": "din.", "name": "Serbian dinar" },
  { "cc": "RUB", "symbol": "R", "name": "Russian ruble" },
  { "cc": "SAR", "symbol": "SR", "name": "Saudi riyal" },
  { "cc": "SBD", "symbol": "SI$", "name": "Solomon Islands dollar" },
  { "cc": "SCR", "symbol": "SR", "name": "Seychellois rupee" },
  { "cc": "SDG", "symbol": "SDG", "name": "Sudanese pound" },
  { "cc": "SEK", "symbol": "kr", "name": "Swedish krona" },
  { "cc": "SGD", "symbol": "S$", "name": "Singapore dollar" },
  { "cc": "SHP", "symbol": "\u00a3", "name": "Saint Helena pound" },
  { "cc": "SLL", "symbol": "Le", "name": "Sierra Leonean leone" },
  { "cc": "SOS", "symbol": "Sh.", "name": "Somali shilling" },
  { "cc": "SRD", "symbol": "$", "name": "Surinamese dollar" },
  { "cc": "SYP", "symbol": "LS", "name": "Syrian pound" },
  { "cc": "SZL", "symbol": "E", "name": "Swazi lilangeni" },
  { "cc": "THB", "symbol": "\u0e3f", "name": "Thai baht" },
  { "cc": "TJS", "symbol": "TJS", "name": "Tajikistani somoni" },
  { "cc": "TMT", "symbol": "m", "name": "Turkmen manat" },
  { "cc": "TND", "symbol": "DT", "name": "Tunisian dinar" },
  { "cc": "TRY", "symbol": "TRY", "name": "Turkish new lira" },
  { "cc": "TTD", "symbol": "TT$", "name": "Trinidad and Tobago dollar" },
  { "cc": "TWD", "symbol": "NT$", "name": "New Taiwan dollar" },
  { "cc": "TZS", "symbol": "TZS", "name": "Tanzanian shilling" },
  { "cc": "UAH", "symbol": "UAH", "name": "Ukrainian hryvnia" },
  { "cc": "UGX", "symbol": "USh", "name": "Ugandan shilling" },
  { "cc": "USD", "symbol": "US$", "name": "United States dollar" },
  { "cc": "UYU", "symbol": "$U", "name": "Uruguayan peso" },
  { "cc": "UZS", "symbol": "UZS", "name": "Uzbekistani som" },
  { "cc": "VEB", "symbol": "Bs", "name": "Venezuelan bolivar" },
  { "cc": "VND", "symbol": "\u20ab", "name": "Vietnamese dong" },
  { "cc": "VUV", "symbol": "VT", "name": "Vanuatu vatu" },
  { "cc": "WST", "symbol": "WS$", "name": "Samoan tala" },
  { "cc": "XAF", "symbol": "CFA", "name": "Central African CFA franc" },
  { "cc": "XCD", "symbol": "EC$", "name": "East Caribbean dollar" },
  { "cc": "XDR", "symbol": "SDR", "name": "Special Drawing Rights" },
  { "cc": "XOF", "symbol": "CFA", "name": "West African CFA franc" },
  { "cc": "XPF", "symbol": "F", "name": "CFP franc" },
  { "cc": "YER", "symbol": "YER", "name": "Yemeni rial" },
  { "cc": "ZAR", "symbol": "R", "name": "South African rand" },
  { "cc": "ZMK", "symbol": "ZK", "name": "Zambian kwacha" },
  { "cc": "ZWR", "symbol": "Z$", "name": "Zimbabwean dollar" }
];
export const TIME_ZONE = [
  {
    "value": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "value": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "value": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "value": "Pacific Standard Time (Mexico)",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ]
  },
  {
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse"
    ]
  },
  {
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {
    "value": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7"
    ]
  },
  {
    "value": "Mountain Standard Time (Mexico)",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "value": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "Central Standard Time (Mexico)",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    "value": "Canada Central Standard Time",
    "abbr": "CCST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "value": "SA Pacific Standard Time",
    "abbr": "SPST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    "value": "Eastern Standard Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT"
    ]
  },
  {
    "value": "US Eastern Standard Time",
    "abbr": "UEDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    "value": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "value": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ]
  },
  {
    "value": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "value": "Central Brazilian Standard Time",
    "abbr": "CBST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Campo_Grande",
      "America/Cuiaba"
    ]
  },
  {
    "value": "SA Western Standard Time",
    "abbr": "SWST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    "value": "Pacific SA Standard Time",
    "abbr": "PSST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "value": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "value": "E. South America Standard Time",
    "abbr": "ESAST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "value": "Argentina Standard Time",
    "abbr": "AST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "value": "SA Eastern Standard Time",
    "abbr": "SEST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    "value": "Greenland Standard Time",
    "abbr": "GDT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ]
  },
  {
    "value": "Montevideo Standard Time",
    "abbr": "MST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ]
  },
  {
    "value": "Bahia Standard Time",
    "abbr": "BST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ]
  },
  {
    "value": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "value": "Mid-Atlantic Standard Time",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "text": "(UTC-02:00) Mid-Atlantic - Old",
    "utc": []
  },
  {
    "value": "Azores Standard Time",
    "abbr": "ADT",
    "offset": 0,
    "isdst": true,
    "text": "(UTC-01:00) Azores",
    "utc": [
      "America/Scoresbysund",
      "Atlantic/Azores"
    ]
  },
  {
    "value": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "text": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "value": "Morocco Standard Time",
    "abbr": "MDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "value": "UTC",
    "abbr": "UTC",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ]
  },
  {
    "value": "Greenwich Mean Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "British Summer Time",
    "abbr": "BST",
    "offset": 1,
    "isdst": true,
    "text": "(UTC+01:00) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Dublin, Lisbon",
    "utc": [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon"
    ]
  },
  {
    "value": "Greenwich Standard Time",
    "abbr": "GST",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    "value": "W. Europe Standard Time",
    "abbr": "WEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    "value": "Central Europe Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    "value": "Romance Standard Time",
    "abbr": "RDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ]
  },
  {
    "value": "Central European Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ]
  },
  {
    "value": "W. Central Africa Standard Time",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "value": "Namibia Standard Time",
    "abbr": "NST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ]
  },
  {
    "value": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ]
  },
  {
    "value": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ]
  },
  {
    "value": "Egypt Standard Time",
    "abbr": "EST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ]
  },
  {
    "value": "Syria Standard Time",
    "abbr": "SDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ]
  },
  {
    "value": "E. Europe Standard Time",
    "abbr": "EEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"

    ]
  },
  {
    "value": "South Africa Standard Time",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    "value": "FLE Standard Time",
    "abbr": "FDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ]
  },
  {
    "value": "Turkey Standard Time",
    "abbr": "TDT",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ]
  },
  {
    "value": "Israel Standard Time",
    "abbr": "JDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ]
  },
  {
    "value": "Libya Standard Time",
    "abbr": "LST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ]
  },
  {
    "value": "Jordan Standard Time",
    "abbr": "JST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ]
  },
  {
    "value": "Arabic Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ]
  },
  {
    "value": "Kaliningrad Standard Time",
    "abbr": "KST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kaliningrad, Minsk",
    "utc": [
      "Europe/Kaliningrad",
      "Europe/Minsk"
    ]
  },
  {
    "value": "Arab Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    "value": "E. Africa Standard Time",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    "value": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    "utc": [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd"
    ]
  },
  {
    "value": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Ulyanovsk"
    ]
  },
  {
    "value": "Iran Standard Time",
    "abbr": "IDT",
    "offset": 4.5,
    "isdst": true,
    "text": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ]
  },
  {
    "value": "Arabian Standard Time",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "value": "Azerbaijan Standard Time",
    "abbr": "ADT",
    "offset": 5,
    "isdst": true,
    "text": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ]
  },
  {
    "value": "Mauritius Standard Time",
    "abbr": "MST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ]
  },
  {
    "value": "Georgian Standard Time",
    "abbr": "GST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ]
  },
  {
    "value": "Caucasus Standard Time",
    "abbr": "CST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ]
  },
  {
    "value": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ]
  },
  {
    "value": "West Asia Standard Time",
    "abbr": "WAST",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    "value": "Pakistan Standard Time",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "value": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata"
    ]
  },
  {
    "value": "Sri Lanka Standard Time",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "value": "Nepal Standard Time",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "text": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Katmandu"
    ]
  },
  {
    "value": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Astana",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "value": "Bangladesh Standard Time",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "value": "Ekaterinburg Standard Time",
    "abbr": "EST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Ekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ]
  },
  {
    "value": "Myanmar Standard Time",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "text": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "value": "SE Asia Standard Time",
    "abbr": "SAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    "value": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "value": "China Standard Time",
    "abbr": "CST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ]
  },
  {
    "value": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ]
  },
  {
    "value": "Singapore Standard Time",
    "abbr": "MPST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    "value": "W. Australia Standard Time",
    "abbr": "WAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ]
  },
  {
    "value": "Taipei Standard Time",
    "abbr": "TST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ]
  },
  {
    "value": "Ulaanbaatar Standard Time",
    "abbr": "UST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ]
  },
  {
    "value": "North Asia East Standard Time",
    "abbr": "NAEST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "value": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    "value": "Korea Standard Time",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "value": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "value": "AUS Central Standard Time",
    "abbr": "ACST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ]
  },
  {
    "value": "E. Australia Standard Time",
    "abbr": "EAST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "value": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ]
  },
  {
    "value": "West Pacific Standard Time",
    "abbr": "WPST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },
  {
    "value": "Tasmania Standard Time",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "value": "Yakutsk Standard Time",
    "abbr": "YST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ]
  },
  {
    "value": "Central Pacific Standard Time",
    "abbr": "CPST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    "value": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "value": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ]
  },
  {
    "value": "UTC+12",
    "abbr": "U",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "value": "Fiji Standard Time",
    "abbr": "FST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ]
  },
  {
    "value": "Magadan Standard Time",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "value": "Kamchatka Standard Time",
    "abbr": "KDT",
    "offset": 13,
    "isdst": true,
    "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ]
  },
  {
    "value": "Tonga Standard Time",
    "abbr": "TST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    "value": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ]
  }
];


export const language = {

  email: { en: "email", ar:''},
    Email: {  en: "Email", ar:''},
  password: { en: "Password", ar:''},
    Edit: { en: "Edit", ar:''},
  Dashboard: { en: "Dashboard", ar:'erwwr'},
  Properties: { en: "Properties", ar:''},
  Payment: { en: "Payment", ar:''},
  Requests: { en: "Requests", ar:''},
  Team: { en: "Team", ar:''},
  Chat: { en: "Chat", ar:''},
  Reports: { en: 'Reports', ar: ''},
  Rate: { en: 'Rate And Review', ar: ''},
  Setting: { en: "Setting", ar:''},
  Logout: { en: "Logout", ar:''},
  Occupied: { en: "Occupied", ar:''},
  Vacant: { en: "Vacant", ar:''},
  Tenant: { en: "Tenant", ar:''},
  View: { en: "View", ar:'إظھار'},
  All: { en: "All", ar:''},
  Upcoming: { en: "Upcoming", ar:''},
  Overdue: { en: "Overdue", ar:''},
  Approved: { en: "Approved", ar:''},
  Reject: { en: "Reject", ar:''},
  Pending: { en: "Pending", ar:''},
  Name: { en: "Name", ar:''},
  House: { en: "House", ar:''},
  Contact: { en: "Contact", ar:''},
  Status: { en: "Status", ar:''},
  Add: { en: "Add", ar:''},
  Upload: { en: "Upload", ar:''},
  Information: { en: "Information", ar:''},
  Location: { en: "Location", ar:''},
    and: { en: "and", ar:''},
  Details: { en: "Details", ar:''},
  Specifications: { en: "Specifications", ar:''},
  No: { en: "No", ar:''},
  Unit: { en: "Unit", ar:''},
  Building: { en: "Building", ar:''},
  Road: { en: "Road", ar:''},
  Block: { en: "Block", ar:''},
  City: { en: "City", ar:''},
  Country: { en: "Country", ar:''},
  Map: { en: "Map", ar:''},
  SAVE: { en: "SAVE", ar:''},
  Enter: { en: "Enter", ar:''},
  Address: { en: "Address", ar:''},
  Select: { en: "Select", ar:''},
  Property: { en: "Property", ar:''},
  Type: { en: "Type", ar:''},
  Booked: { en: "Booked", ar:''},
  Phone: { en: "Phone", ar:''},
  Landlord: { en: "Landlord", ar:''},
  Manger: { en: "Manger", ar:''},
  Residential: { en: "Residential", ar:''},
  Commercial: { en: "Commercial", ar:''},
  Basic: { en: "Basic", ar:''},
  Info: { en: "Info", ar:''},
  Security: { en: "Security", ar:''},
  Monthly: { en: "Monthly", ar:''},
  Refundable: { en: "Refundable", ar:''},
  Non: { en: "Non", ar:''},
  Advance: { en: "Advance", ar:''},
  Amount: { en: "Amount", ar:''},
  Contract: { en: "Contract", ar:''},
  period: { en: "period", ar:''},
  Rent: { en: "Rent", ar:''},
  Min: { en: "Min", ar:''},
  Role: { en: "Role", ar:''},
  Max: { en: "Max", ar:''},
  Month: { en: "Month", ar: ''},
  Save: { en: "Save", ar: ''},
  Submit: { en: "Submit", ar: ''},
  Year: { en: "Year", ar: ''},
  Go: { en: 'Go', ar: ''},
    Other: { en: 'Other', ar: ''},
  to: { en: 'To', ar: ''},
  Rating: { en: 'Rating', ar: ''},
  Terms: { en: 'Terms', ar: ''},
  Comment: { en: 'Comment', ar: ''},
    Resolved: { en: 'Resolved', ar: ''},
    Browse: { en: 'Browse', ar: ''},
    Login: { en: 'Login', ar: ''},
    submit: { en: 'submit', ar: ''},
    Invites: { en: 'Invites', ar: ''},
  'Popular Projects':{ en:'Popular Projects', ar:''},
  'Are you sure, you want to log out?':{ en:'Are you sure, you want to log out?', ar:''},
  'Type your message here …':{ en:'Type your message here …', ar:''},
  'My Payments': { en: 'My Payments', ar: ''},
  'Other Information': { en: 'Other Information', ar: ''},
  'Log Out': { en: 'Log Out', ar: ''},
  'Cancel': { en: 'Cancel', ar: ''},
  'Resend OTP': { en: 'Resend OTP', ar: ''},
  'Sign in to Continue to EjariNow': { en: 'Sign in to Continue to EjariNow', ar: ''},
  'User Profile': { en: 'User Profile', ar: ''},
  'Collected Payments': { en: 'Collected Payments', ar: ''},
  'Mail': { en: 'Mail', ar: ''},
  'OTP Verification': { en: 'OTP Verification', ar: ''},
  'New Tenant': { en: 'New Tenant', ar: ''},
  'Phone No': { en: 'Phone No', ar: ''},
  'Get Direction': { en: 'Get Direction', ar: ''},
  'Pay Now': { en: 'Pay Now', ar: ''},
  'Most popular': { en: 'Most popular', ar: ''},
  'Make Payment': { en: 'Make Payment', ar: ''},
  'Property Status': { en: 'Property Status', ar: ''},
  'Select any of the below plans and make your property stand out over other properties': { en: 'Select any of the below plans and make your property stand out over other properties', ar: ''},
  'Make your property featured': { en: 'Make your property featured', ar: ''},
  'Feature Now': { en: 'Feature Now', ar: ''},
  'Send Invite': { en: 'Send Invite', ar: ''},
  'Move in Date': { en: 'Move in Date', ar: ''},
  'Invite Tenants': { en: 'Invite Tenants', ar: ''},
  'Current tenant': { en: 'Current tenant', ar: ''},
  'Pending For Approval': { en: 'Pending For Approval', ar: ''},
  'Incomplete Property': { en: 'Incomplete Property', ar: ''},
  'Edit Property': { en: 'Edit Property', ar: ''},
  'New tenants': { en: 'New tenants', ar: ''},
  'Date of Birth': { en: 'Date of Birth', ar: ''},
  'Landlord details': { en: 'Landlord details', ar: ''},
  'Total Payment': { en: 'Total Payment', ar: ''},
  'Register':{ en:'Register', ar:''},
  'Accured':{ en:'Accured', ar:''},
  'Recieved':{ en:'Recieved', ar:'مُستلم'},
  'Reviews':{ en:'Reviews', ar:''},
  'Tenants Reviews':{ en:'Tenants Reviews', ar:''},
  'Rating Count':{ en:'Counts', ar:''},
  'Owner Name':{ en:'Owner Name', ar:''},
  'NEWLY ADDED PROPERTIES':{ en:'NEWLY ADDED PROPERTIES', ar:''},
  'Hotline':{ en:'Hotline', ar:''},
  'Explore':{ en:'Explore', ar:''},
  'Incomplete':{ en:'Incomplete', ar:''},
  // 'Properties':{ en:'Properties', ar:''},
  'Approval Pending':{ en:'Approval Pending', ar:''},
  'Total Properties':{ en:'Total Properties', ar:''},
  'Not Invoiced': { en:'Not Invoiced', ar:''},
  'Rent Property': { en:'Rent Property', ar:''},
  'FAQ':{ en:'FAQ', ar:''},
  'Language':{ en:'Language', ar:''},
  'Select Advance Rent Amount':{ en:'Select Advance Rent Amount', ar:''},
  'Team List':{ en:'Team List', ar:''},
  'About':{ en:'About', ar:''},
  'About Us':{ en:'About Us', ar:''},
  'Contact Us':{ en:'Contact Us', ar:''},
  'PROPERTY FOR SALE & FOR RENT AROUND THE WORLD':{ en:'PROPERTY FOR SALE & FOR RENT AROUND THE WORLD', ar:''},
  'Search and Find Your Family House':{ en:'Search and Find Your Family House', ar:''},
  'Office spaces':{ en:'Office spaces', ar:''},
  'Shops & Showrooms':{ en:'Shops & Showrooms', ar:''},
  'Apartment':{ en:'Apartment', ar:''},
  'Villa':{ en:'Villa', ar:''},
  'HOW IT WORKS':{ en:'HOW IT WORKS', ar:''},
  'RealAdvisor is here to help':{ en:'RealAdvisor is here to help', ar:''},
  'I’am Landlord/Agent':{ en:'I’am Landlord/Agent', ar:''},
  'I’am Tenant':{ en:'I’am Tenant', ar:''},
  'READ MORE':{ en:'READ MORE', ar:''},
  'FEATURED COLLECTION':{ en:'FEATURED COLLECTION', ar:''},
  'Properties you might be interested in':{ en:'Properties you might be interested in', ar:''},
  'DOWNLOAD OUR PRODUCT':{ en:'DOWNLOAD OUR PRODUCT', ar:''},
  'Download the product':{ en:'Download the product', ar:''},
  'from Google Play store & Itunes':{ en:'from Google Play store & Itunes', ar:''},
  'FEATURES OF EJARI NOW':{ en:'FEATURES OF EJARI NOW', ar:''},
  'REALTORS':{ en:'REALTORS', ar:''},
  'TENANTS':{ en:'TENANTS', ar:''},
  'Tenants':{ en:'Tenants', ar:''},
  'Past Tenants':{ en:'Past Tenants', ar:''},
  'List of Tenants':{ en:'List of Tenants', ar:''},
  'WHAT PEOPLE SAYING':{ en:'WHAT PEOPLE SAYING', ar:''},
  'ASSOCIATED PARTNERS':{ en:'ASSOCIATED PARTNERS', ar:''},
  'FEATURES':{ en:'FEATURES', ar:''},
  'How It Works':{ en:'How It Works', ar:''},
  'PropTiger':{ en:'PropTiger', ar:''},
  'Makaan':{ en:'Makaan', ar:''},
  'BENEFITS':{ en:'BENEFITS', ar:''},
  'Rent property':{ en:'Rent property', ar:''},
  'DOWNLOADS':{ en:'DOWNLOADS', ar:''},
  'Please type the verification code':{ en:'Please type the verification code', ar:''},
  'Click on the button if you want to reopen maintenance request':{ en:'Click on the button if you want to reopen maintenance request', ar:''},
  'Android (Google Play)':{ en:'Android (Google Play)', ar:''},
  'Apple (App Store)':{ en:'Apple (App Store)', ar:''},
  'ChromeOS (Coming soon)':{ en:'ChromeOS (Coming soon)', ar:''},
  'COMPANY':{ en:'COMPANY', ar:''},
  'Job':{ en:'Job', ar:''},
  'About us':{ en:'About us', ar:''},
  'Term and Conditions':{ en:'Term and Conditions', ar:''},
  'LANGUAGE':{en:'LANGUAGE', ar:''},
  'All rights reserved.':{ en:'All rights reserved.', ar:''},
  'REALCRAFT SOLUTIONS W.L.L.':{ en:'REALCRAFT SOLUTIONS W.L.L.', ar:''},
  'Withdrawals':{ en:'Withdrawals', ar:''},
  'Bank':{ en:'Bank', ar:''},
  'Accrued Payment':{ en:'Accrued Payment', ar:''},
  'Update Bank Account':{ en:'Update Bank Account', ar:''},
  'Add account photo':{ en:'Add account photo', ar:''},
  'IBAN No':{ en:'IBAN No', ar:''},
  'Account Name':{ en:'Account Name', ar:''},
  'Account No':{ en:'Account No', ar:''},
  'Edit Bank':{ en:'Edit Bank', ar:''},
  'IBAN number':{ en:'IBAN number', ar:''},
  'Account number':{ en:'Account number', ar:''},
  'Bank Name':{ en:'Bank Name', ar:''},
  'Add Bank Account':{ en:'Add Bank Account', ar:''},
  'Username':{ en:'Username', ar:''},
  'Vacate Requests':{ en:'Vacate Requests', ar:''},
  'Vacate Request':{ en:'Vacate Request', ar:''},
  'Manage Role':{ en:'Manage Role', ar:''},
  'typing':{ en:'typing', ar:''},
  'Search for a person':{ en:'Search for a person', ar:''},
  'send':{ en:'send', ar:''},
  'Invoices & History':{ en:'Invoices & History', ar:''},
  'Invoiced':{ en:'Invoiced', ar:''},
  'History':{ en:'History', ar:''},
  'Total Received Payment':{ en:'Total Received Payment', ar:''},
  'Occupied Property':{ en:'Occupied Property', ar:''},
  'Pending Agreement':{ en:'Pending Agreement', ar:''},
  'Property History':{ en:'Property History', ar:''},
  'My Requests':{ en:'My Requests', ar:''},
  'Maintenance':{ en:'Maintenance', ar:''},
  'Queries':{ en:'Queries', ar:''},
  'Submitted by :':{ en:'Submitted by :', ar:''},
  'Maintenance Requests':{ en:'Maintenance Requests', ar:''},
  'View All':{ en:'View All', ar:''},
  'Landlord Name':{ en:'Landlord Name', ar:''},
  'House Name':{ en:'House Name', ar:''},
  'My Current Rentals':{ en:'My Current Rentals', ar:''},
  'In Agreement':{ en:'In Agreement', ar:''},
  'Showing':{ en:'Showing', ar:''},
  'Action':{ en:'Action', ar:''},
  'LOGIN':{ en:'LOGIN', ar:''},
  'SignUP':{ en:'SignUP', ar:''},
  'Already have an account?':{ en:'Already have an account?', ar:''},
  'Sign up':{ en:'Sign up', ar:''},
  'Don’t have an account?':{ en:'Don’t have an account?', ar:''},
  'Submit Feedback':{ en:'Submit Feedback', ar:''},
  'Overall Rating':{ en:'Overall Rating', ar:''},
  'How satisfied are you with tanants?':{ en:'How satisfied are you with tanants?', ar:''},
  'How satisfied are you with landlord?':{ en:'How satisfied are you with landlord?', ar:''},
  'Share your Feedback':{ en:'Share your Feedback', ar:''},
  'Review & Rating':{ en:'Review & Rating', ar:''},
  'Invoice details':{ en:'Invoice details', ar:''},
  'Rental Invoice':{ en:'Rental Invoice', ar:''},
  'of':{ en:'of', ar:''},
  'BHK': { en:'BHK',ar:''},
  'Agent/Manager': { en:'Agent/Manager',ar:''},
  'Select your type': { en:'Select your type',ar:''},
  'Hi, Tell me more about you': { en:'Hi, Tell me more about you',ar:''},
  'Invoice No:': { en:'Invoice No:',ar:''},
  'Baths': { en:'Baths',ar:''},
  'CPR Number': { en:'CPR Number',ar:''},
  'Identity Type': { en:'Identity Type',ar:''},
  'You need to upload your identity cards and documents.': { en:'You need to upload your identity cards and documents.',ar:''},
  'Upload CPR': { en:'Upload CPR',ar:''},
  'Invoice date:': { en:'Invoice date:',ar:''},
  'Residential House': { en:'Residential House',ar:''},
  'Commercial House': { en:'Commercial House',ar:''},
  'BHD': { en:'BHD',ar:''},
  'Property details': { en:'Property details',ar:''},
  'Bedroom': { en:'Bedroom',ar:''},
  'Bathroom': { en:'Bathroom',ar:''},
  'Received Payments': { en:'Received Payments',ar:''},
  'Property Type': { en:'Property Type',ar:''},
  'Property Invitation': { en:'Property Invitation',ar:''},
  'No Data': { en:'No Data',ar:''},
  'Number of parking': { en:'Number of parking',ar:''},
  'Building floors': { en:'Building floors',ar:''},
  'Floor number': { en:'Floor number',ar:''},
  'State': { en:'State',ar:''},
  'Full Name': { en:'Full Name',ar:''},
  'Year of construction': { en:'Year of construction',ar:''},
  'Number of balconies': { en:'Number of balconies',ar:''},
  'Furnishing': { en:'Furnishing',ar:''},
  'Amenities': { en:'Amenities',ar:''},
  'No Amenities': { en:'No Amenities',ar:''},
  'Landmark (Nearby Location)': { en:'Landmark (Nearby Location)',ar:''},
  'Payment details': { en:'Payment details',ar:''},
  'EWA': { en:'EWA',ar:''},
  'Upcoming Payments': { en:'Upcoming Payments',ar:''},
  'No Landmark': { en:'No Landmark',ar:''},
  'Advance Rent Amount': { en:'Advance Rent Amount',ar:''},
  'Administration Fees': { en:'Administration Fees',ar:''},
  'Parking Fees': { en:'Parking Fees',ar:''},
  'Housekeeping Fees': { en:'Housekeeping Fees',ar:''},
  'Maintenance Fees': { en:'Maintenance Fees',ar:''},
  'Other Fees': { en:'Other Fees',ar:''},
  'Contract period': { en:'Contract period',ar:''},
  'Grace Period': { en:'Grace Period',ar:''},
  'Security Amount': { en:'Security Amount',ar:''},
  'Discription': { en:'Discription', ar:''},
  'With EjariNow since': { en:'With EjariNow since', ar:''},
  'GET DIRECTION':{ en:'GET DIRECTION', ar:''},
  'View Invoice':{ en:'View Invoice', ar:''},
  'Pay':{ en:'Pay', ar:''},
  'Date':{ en:'Date', ar:''},
  'Download Invoice':{ en:'Download Invoice', ar:''},
  'Rent Amount':{ en:'Rent Amount', ar:''},
  'Min BHD':{ en:'Min BHD', ar:''},
  'Max BHD':{ en:'Max BHD', ar:''},
  'Rent Amount Range':{ en:'Rent Amount Range', ar:''},
  'Tenant List':{ en:'Tenant List', ar:''},
  'Tenant Name':{ en:'Tenant Name', ar:''},
  'Property Name':{ en:'Property Name', ar:''},
  // 'Address':{ en:'Address', ar:''},
  'Due Date':{ en:'Due Date', ar:''},
  'Paid On':{ en:'Paid On', ar:''},
  'Fees':{ en:'Fees', ar:''},
  'You Received':{ en:'You Received', ar:''},
  'Invoice Amount':{ en:'Invoice Amount', ar:''},
  'Rent Charges':{ en:'Rent Charges', ar:''},
  'sqft':{ en:'sqft', ar:''},
  'Clear All':{ en:'Clear All', ar:''},
  '(Inc. other charges)':{ en:'(Inc. other charges)', ar:''},
  'Inc. other charges':{ en:'Inc. other charges', ar:''},
  'Invoiced Payment':{ en:'Invoiced Payment', ar:''},
  'Overdue Payments':{ en:'Overdue Payments', ar:''},
  'Payments History':{ en:'Payments History', ar:''},
  'Paid Date':{ en:'Paid Date', ar:''},
  'Filter':{ en:'Filter', ar:''},
  'Send a Reminder':{ en:'Send a Reminder', ar:''},
  'Not Invoice yet':{ en:'Not Invoice yet', ar:''},
  'Sort By':{ en:'Sort By', ar:''},
  'Late Payment':{ en:'Late Payment', ar:''},
  'Total Done Payments':{ en:'Total Done Payments', ar:''},
  'Download CSV':{ en:'Download CSV', ar:''},
  'Generate Report':{ en:'Generate Report', ar:''},
  'SUBMIT':{ en:'SUBMIT', ar:''},
  'Contract Period':{ en:'Contract Period', ar:''},
  'Request Details':{ en:'Request Details', ar:''},
  'You can upload only 5 images':{ en:'You can upload only 5 images', ar:''},
  'Photo':{ en:'Photo', ar:''},
  'Description':{ en:'Description', ar:''},
  'Subject':{ en:'Subject', ar:''},
  'Place a Request':{ en:'Place a Request', ar:''},
  'Place a Query':{ en:'Place a Query', ar:''},
  'Page':{ en:'Page', ar:''},
  'Continue':{ en:'Continue', ar:''},
  'Set Password':{ en:'Set Password', ar:''},
  'BDH':{ en:'BDH', ar:''},
  'My Property Request':{ en:'My Property Request', ar:''},
  'Booking Request':{ en:'Booking Request', ar:''},
  'Remaining period':{ en:'Remaining period', ar:''},
  'Reason to vacate property':{ en:'Reason to vacate property', ar:''},
  'Uploaded document':{ en:'Uploaded document', ar:''},
  'Accepted':{ en:'Accepted', ar:''},
  'Accept':{ en:'Accept', ar:''},
  'Privacy Policy':{ en:'Privacy Policy', ar:''},
  'Agreement pending from tenant side':{ en:'Agreement pending from tenant side', ar:''},
  'Rejected':{ en:'Rejected', ar:''},
  'No Requests':{ en:'No Requests', ar:''},
  // 'Advance Rent Amount':{ en:'Advance Rent Amount', ar:''},
  'Vacate Details':{ en:'Vacate Details', ar:''},
  'Reason':{ en:'Reason', ar:''},
  'Vacate Date':{ en:'Vacate Date', ar:''},
  'Request':{ en:'Request', ar:''},
  'Sent':{ en:'Sent', ar:'مٌرسل'},
  'Password':{ en:'Password', ar:''},
  'Send':{ en:'Send', ar:''},
  'Enter your phone number to receive OTP':{ en:'Enter your phone number to receive OTP', ar:''},
  'Forgot Password?':{ en:'Forgot Password?', ar:''},
  'Forgot Password':{ en:'Forgot Password', ar:''},
  'Received':{ en:'Received', ar:'مُستلم'},
  'Contact us':{ en:'Contact us', ar:''},
  'Change Password':{ en:'Change Password', ar:''},
  'Terms & Conditions':{ en:'Terms & Conditions', ar:''},
  'Current Password':{ en:'Current Password', ar:''},
  'New Password':{ en:'New Password', ar:''},
  'Confirm Password':{ en:'Confirm Password', ar:''},
  'Required':{ en:'Required', ar:''},
  'New Password and confirm password not match':{ en:'New Password and confirm password not match', ar:''},
  'Identity Number':{ en:'Identity Number', ar:''},
  'ID/CR photo':{ en:'ID/CR photo', ar:''},
  'Identity':{ en:'Identity', ar:''},
  'Uploaded Documents':{ en:'Uploaded Documents', ar:''},
  'Expiry date':{ en:'Expiry date', ar:''},
  'Expiry dates':{ en:'Expiry dates', ar:''},
  'No Documents Found':{ en:'No Documents Found', ar:''},
  'South Meggiefort':{ en:'South Meggiefort', ar:''},
  'Introducing yet another project':{ en:'Introducing yet another project', ar:''},
  'Submitted to':{ en:'Submitted to', ar:''},
  'No Notifications Found':{ en:'No Notifications Found', ar:''},
  'My Profile':{ en:'My Profile', ar:''},
  '2010-2018 RealCraft Solutions W.L.L. All rights reserved':{ en:'2010-2018 RealCraft Solutions W.L.L. All rights reserved', ar:''},
  'Landmark':{ en:'Landmark', ar:''},
  'Notifications':{ en:'Notifications', ar:''},
  'Nearby Location':{ en:'Nearby Location', ar:''},
  'view':{ en:'view', ar:''},
  'Need Help':{ en:'Need Help', ar:''},
  'add':{ en:'add', ar:''},
  'edit':{ en:'edit', ar:''},
  'recieveAsCash':{ en:'recieveAsCash', ar:''},
  'markResolve':{ en:'markResolve', ar:''},
  'canAcceptOrReject':{ en:'canAcceptOrReject', ar:''},
  'doVacateRequest':{ en:'doVacateRequest', ar:''},
  'export':{ en:'export', ar:''},
  'properties':{ en:'properties', ar:''},
  'Title':{ en:'Title', ar:''},
  'Per Month':{ en:'Per Month', ar:''},
  'Message':{ en:'Message', ar:''},
  'invoice':{ en:'invoice', ar:''},
  'request':{ en:'request', ar:''},
  'vacate':{ en:'vacate', ar:''},
  'tenants':{ en:'tenants', ar:''},
  'team':{ en:'team', ar:''},
  'withdrawals':{ en:'withdrawals', ar:''},
  'bank':{ en:'bank', ar:''},
  'Rate Now':{ en:'Rate Now', ar:''},
  'reports':{ en:'reports', ar:''},
  'RateReview':{ en:'RateReview', ar:''},
  'View Detail':{ en:'View Detail', ar:''},
  'Photos':{ en:'Photos', ar:''},
  'N/A':{ en:'N/A', ar:''},
  'No Property Found':{ en:'No Property Found', ar:''},
  'NEXT': { en:'NEXT', ar:''},
  'Recurrent': { en: 'Recurrent', ar: ''},
  'Recurrent Date': { en: 'Recurrent Date', ar: ''},
  'Importance': { en:'Importance', ar:''},
  'Maintenance Type': { en:'Maintenance Type', ar:''},
  'Download our product':{ en:'Download our product', ar:''},
  'Search and Find Your': { en: 'Search and Find Your', ar: ''},
  'View all Projects':{ en:'View all Projects', ar:''},
  'Newly added properties':{ en:'Newly added properties', ar:''},
  'Family House':{ en:'Family House', ar:''},
  'Find Home':{ en:'Find Home', ar:''},
  'Search':{ en:'Search', ar:''},
  'Featured Collection':{ en:'Featured Collection', ar:''},
  'How it works':{ en:'How it works', ar:''},
  'Created At':{ en:'Created At', ar:''},
  'Invoice At':{ en:'Invoice At', ar:''},
  'Total Charges':{ en:'Total Charges', ar:''},
};
