import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { apiUrl } from 'src/app/global/global';
import { TranslateService } from "../../shared/lang/translate.service";
import { logger } from "codelyzer/util/logger";
import { Subscriber } from "rxjs";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit, OnDestroy {
    value: String;
    allData: any = {};
    flag: Boolean = true;
    text: String;
    temp2: Boolean = true;
    model: any;

    private subs: Subscriber<any> = new Subscriber();

    constructor(
        private activeRoute: ActivatedRoute,
        public admin: AdminService,
        private router: Router,
        public translate: TranslateService
    ) {
        this.text = activeRoute.snapshot.data.text;
        this.value = activeRoute.snapshot.data.value;
        this.getList();
        this.getCounts();
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp2 = false;
        }
    }

    getCounts() {
        const data = {
            type: this.value
        };
        this.admin.getData(apiUrl._getCounts, data)
            .subscribe(res => {
                this.model = res.data;
            }
            );
    }

    ngOnInit() {
        this.admin.loginState.subscribe(data => {
            this.temp2 = data;
        });
    }

    getList() {
        const data = {
            type: this.value
        };
        this.admin.getData(apiUrl._getWebViewList, data)
            .subscribe(res => {
                const temp = res.data.template;
                if (temp.length) {
                    this.flag = true;
                    this.allData = this.translate.lang_code === 'en' ? temp[0] : temp[1];
                } else {
                    this.flag = false;
                }
            }
            );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
