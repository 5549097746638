import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { ExternalService } from '../external.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '../../shared/lang/translate.service';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var particlesJS: any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {



    config = {
        particles: {
            number: {
                value: 70,
                density: {
                    enable: true,
                    value_area: 1400
                }
            },
            color: {
                value: '#fff'
            },
            shape: {
                type: 'polygon',
                stroke: {
                    width: 1,
                    color: '#fff'
                },
                polygon: {
                    nb_sides: 6
                }
            },
            opacity: {
                value: 1,
                random: true,
                anim: {
                    enable: true,
                    speed: 0.8,
                    opacity_min: 0.25,
                    sync: true
                }
            },
            size: {
                value: 2,
                random: true,
                anim: {
                    enable: true,
                    speed: 5,
                    size_min: 1.25,
                    sync: true
                }
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: '#fff',
                opacity: 1,
                width: 1
            },
            move: {
                enable: true,
                speed: 8,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                bounce: true,
                attract: {
                    enable: true,
                    rotateX: 2000,
                    rotateY: 2000
                }
            }
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
                onhover: {
                    enable: true,
                    mode: 'grab'
                },
                onclick: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 200,
                    line_linked: {
                        opacity: 3
                    }
                },
                repulse: {
                    distance: 250,
                    duration: 2
                }
            }
        },
        retina_detect: true
    }


    @ViewChild('videoPlayer') videoplayer: ElementRef;
    allData: any = {};
    temp = 'Residential';
    searchForm: FormGroup;
    cityData: any = [];
    filteredBrands: any[] = [];


    customOptionsOne: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: false,
        navSpeed: 500,
        nav: true,
        center: true,
        autoplayHoverPause: true,
        startPosition: 1,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 4
            }
        },

        margin: 20,
        rtl: true,
    };

    image: any;
    temp2: Boolean = true;
    temp1 = [];
    language: any = 'en';
    videoUrlEn: any;
    videoUrlAr: any;;
    public userSettings4: any = {
        showSearchButton: false,
        showCurrentLocation: false,
        geoCountryRestriction: ['bh'],
        inputPlaceholderText: this.translate.lang.Town_City,
        locationIconUrl: 'http://www.myiconfinder.com/uploads/iconsets/369f997cef4f440c5394ed2ae6f8eecd.png',
        noOfRecentSearchSave: 8
    };

    constructor(
        public router: Router,
        private api: ExternalService,
        public admin: AdminService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public translate: TranslateService,
        private _sanitizer: DomSanitizer
    ) {
        this.videoUrlEn = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/0K9jsiXC1_0');
        this.videoUrlAr = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/aX_Hx2_GzqA');

        this.language = localStorage.getItem('lang');
        let data = localStorage.getItem('type');
        this.temp = data == '2' ? 'Commercial' : 'Residential';
        for (let x = 0; x <= 1000; x = x + 100) {
            this.temp1.push(x);
        }

        this.admin.logout$.subscribe(res => {
            if (res) {
                if (!localStorage.getItem('guestToken')) {
                    this.getToken();
                } else {
                    this.getList();
                    this.getProperty();
                }
            }
        })

        this.searchForm = fb.group({
            address: [null],
            location: [null],
            lat: [null],
            lng: [null],
            typeValue: [''],
            rentAmount: fb.group({
                min: [null],
                max: [null],
            })
        });
        if (localStorage.getItem('adminToken') && localStorage.getItem('userType') && localStorage.getItem('userDocument') != 'false') {
            this.temp2 = false;
        }
    }

    play() {
        document.getElementById('vidwrap').innerHTML = '<iframe width="560" height="315" src="http://www.youtube.com/embed/xxxxxxxxx?autoplay=1" frameborder="0"></iframe>';
    }

    check() {
        let type = localStorage.getItem("userType");
        this.router.navigate([type == 'LANDLORD' || type == 'MANAGER' || type == 'TEAM_MEMBER' ? '/landlord' : '/tenants']);
    }

    filterBrands(event) {
        this.filteredBrands = [];
        for (let i = 0; i < this.cityData.length; i++) {
            let brand = this.cityData[i].name;
            if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                this.filteredBrands.push(brand);
            }
        }
        console.log(this.filteredBrands);
    }



    ngOnInit() {
        // window.scrollTo(0, 0);

        this.invokeParticles();
        this.admin.loginState.subscribe(data => {
            this.temp2 = data;
        })

        if (localStorage.getItem('profileImage')) {
            this.image = JSON.parse(localStorage.getItem('profileImage'));
        }
        this.searchForm.get('rentAmount').get('min').valueChanges.subscribe(data => {
        });
        this.searchForm.get('rentAmount').get('max').valueChanges.subscribe(data => {
        });
        if (localStorage.getItem('address')) {
            localStorage.removeItem('address');
            localStorage.removeItem('lat');
            localStorage.removeItem('lng');
        }
        if (localStorage.getItem('type')) {
            localStorage.removeItem('type');
        }
        if (!localStorage.getItem('guestToken')) {
            this.getToken();
        } else {
            this.getList();
            this.getProperty();
        }
    }



    public invokeParticles(): void {
        particlesJS('particles-js', this.config, function () { });
    }

    toggleVideo(event: any) {
        this.videoplayer.nativeElement.play();
    }

    getProperty() {
        this.admin.showLoader = false;
        this.api.getList({ type: 'City' }, apiUrl._getPropertyType).subscribe(success => {
            // if(success.data.propertyType.length){
            this.cityData = success.data.propertyType[0].propertyData;
            // }
        });
    }



    searchSubmit(form) {
        let data = {
            typeValue: form.value.typeValue
        };

        if (form.value.address) {
            data['address'] = form.value.address;
            data['lat'] = form.value.lat;
            data['lng'] = form.value.lng;
        }
        if (form.value.rentAmount.min || form.value.rentAmount.max) {
            data['rentAmount'] = JSON.stringify(form.value.rentAmount);
        }

        if (form.value.location) {
            data['location'] = form.value.location;
        }

        this.router.navigate(['/explore/property'], { queryParams: data });
    }

    getToken() {
        const data = {};
        this.api.getList(data, apiUrl._loginGuestUser).subscribe(success => {
            //
            localStorage.setItem('guestToken', success.data.accessToken);
            if (localStorage.getItem('guestToken')) {
                this.getList();
                this.getProperty();
            }
        });
    }

    getList() {
        this.api.getList({}, apiUrl._getHomePage).subscribe(success => {
            //
            this.allData = success.data;
        }, error => {
            // if (error.status == 401) {
            //     localStorage.removeItem('userType');
            //     // localStorage.removeItem('guestToken');
            //     this.router.navigate(['']);
            // }
        });
    }




    autoCompleteCallback1(selectedData: any) {
        //
        if (selectedData.response) {
            this.searchForm.patchValue({
                address: selectedData.data.formatted_address,
                lat: selectedData.data.geometry.location.lat,
                lng: selectedData.data.geometry.location.lng,
            })
            // localStorage.setItem('address', selectedData.data.formatted_address);
            // localStorage.setItem('lat', selectedData.data.geometry.location.lat);
            // localStorage.setItem('lng', selectedData.data.geometry.location.lng);
            //
            //
            //
            // this.locationForm.patchValue({
            //     lat: selectedData.response ? selectedData.data.geometry.location.lat : '',
            //     lng: selectedData.response ? selectedData.data.geometry.location.lng : '',
            //     address: selectedData.response ? selectedData.data.formatted_address : '',
            // });
        }
    }


    changeLanguage(lang) {
        if (this.translate.lang_code !== lang) {
            this.admin.lang = lang;
            localStorage.setItem('language', lang);
            window.location.reload();
        }
    }

    // scrol() {
    //     // window.scrollTo({ top: 650, behavior: 'smooth' });
    //     window.scrollTo(0, 0);
    // }


    scrollView(el: HTMLElement) {
        console.log(el);
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }


    changePropertyType(item) {
        this.router.navigate(['/explore/property'], {
            queryParams: {
                typeValue: item.type === 'Residential' ? 1 : 2,
                propertyType: item._id
            }

        });
        // window.scrollTo(0, 0);

        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    }


}
